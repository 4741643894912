import { DEFAULT_PROPERTIES } from './constants';

export const createUniqueAccessor = ({
  prefix = 'unique-accessor',
  limit = 1000,
  accessors = [],
}) => {
  const randomId = Math.floor(Math.random() * limit);
  const newAccessor = `${prefix}--${randomId}`;

  const accessorIsUsed = !!accessors.find(accessor => accessor === newAccessor);

  if (accessorIsUsed) return createUniqueAccessor();

  return newAccessor;
};

export const populateEventPropertiesFromRequest = (event) => {
  return Object.keys(event).reduce((accum, key) => {
    if (key === 'event_properties') {
      accum[key] = event[key].map(({ name: value, data_type: type }) => {
        const matchingDefaultProperty = DEFAULT_PROPERTIES.find(
          (prop) => prop.value === value
        );

        const isDefaultProperty = !!matchingDefaultProperty;

        if (isDefaultProperty) {
          return {
            value,
            type,
            propertyType: 'default',
            accessor: matchingDefaultProperty.accessor,
            persisted: true,
          };
        }
        const newAccessor = createUniqueAccessor({
          prefix: 'custom-event-property',
          accessors: event[key].map((property) => property.accessor),
        });

        return {
          value,
          type,
          propertyType: 'custom',
          accessor: newAccessor,
          persisted: true,
        };
      });
    } else {
      accum[key] = event[key];
    }

    return accum;
  }, {});
};


export const cleanEventPropertiesForRequest = event =>
  Object.keys(event).reduce((accum, key) => {
    if (key === 'event_properties') {
      accum[key] = event[key].map(({ value: name, type: data_type }) => ({
        name,
        data_type,
      }));
    } else {
      accum[key] = event[key];
    }

    return accum;
  }, {});
