import actions from './actions';
import getters from './getters';
import fns from './fns';

export { actions, getters, fns };

export default {
  actions,
  getters,
  fns,
};
