const keyMirror = require('optly/utils/key_mirror');

module.exports = {
  /**
   * Tab choices for the campaign sidebar
   */
  tabs: keyMirror({
    EXPERIENCES: null,
    VARIATIONS: null,
    PAGES: null,
    AUDIENCES: null,
    INTEGRATIONS: null,
    METRICS: null,
    CUSTOM_CODE: null,
    TRAFFIC_ALLOCATION: null,
    SCHEDULE: null,
    API_NAMES: null,
    HISTORY: null,
    SETTINGS: null,
    SUMMARY: null,
    HYPOTHESIS: null,
  }),

  /**
   * Tab choices for the settings tab
   */
  settingsTabs: keyMirror({
    CAMPAIGN_SETTINGS: null,
    INTEGRATIONS: null,
  }),

  /**
   * Placeholder for setting null value in integrations
   */
  integrations: keyMirror({
    NO_VALUE_SELECTED: null,
  }),

  /**
   * Color options for improvement text in variation table row
   */
  improvementColors: keyMirror({
    GREEN: null,
    RED: null,
    DEFAULT: null,
  }),
};
