import React from 'react';

import PropTypes from 'prop-types';

import htmlSanitizer from 'sanitizer';

import classNames from 'classnames';

import { Button, Icon, Textarea } from 'optimizely-oui';

import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import ui from 'core/ui';
import Router from 'core/router';
import { connect } from 'core/ui/decorators';
import Immutable, { toJS } from 'optly/immutable';

import Events from 'optly/services/events';
import UrlHelper from 'optly/services/url_helper';
import { fulldate } from 'optly/filters';
import { Features } from 'optly/utils/enums';
import pushStateHandler from 'optly/utils/push_state';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';
import { isWinnerRolloutFeatureEnabled } from 'optly/utils/features';

// Getters, Actions, Fns and Enums
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as V2MigrationGetters } from 'optly/modules/v2_migration';
import { actions as ReviewAndPublishActions } from 'bundles/p13n/modules/review_and_publish';
import {
  actions as LayerActions,
  getters as LayerGetters,
  fns as LayerFns,
} from 'optly/modules/entity/layer';
import {
  getters as CurrentLayerGetters,
  actions as CurrentLayerActions,
} from 'bundles/p13n/modules/current_layer';
import {
  getters as PublishStatusGetters,
  actions as PublishStatusActions,
  fns as PublishStatusFns,
} from 'bundles/p13n/modules/publish_status';
import {
  actions as CustomCodeActions,
  getters as CustomCodeGetters,
} from 'bundles/p13n/modules/custom_code';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui';
import {
  actions as CampaignManagerActions,
  enums as CampaignManagerEnums,
} from 'bundles/p13n/modules/campaign_manager';
import { actions as VerifierActions } from 'optly/modules/verifier';
import { actions as LayerExperimentActions } from 'optly/modules/entity/layer_experiment';

// Components
import ListDropdown from 'react_components/list_dropdown';
import { SidebarHeader } from 'react_components/sidebar';
import LayerActionButtons from 'bundles/p13n/components/layer_action_buttons';
import MultiConfirm from 'bundles/p13n/components/dialogs/multi_confirm';
import LayerStatusIndicator from 'bundles/p13n/components/layer_status_indicator';
import ConcurrentEditingContainer from 'bundles/p13n/components/concurrency/concurrent_editing_container';
import SchedulerInfo from 'bundles/p13n/components/campaign_manager/scheduler_info';
import { getters as DashboardGetters } from 'optly/modules/dashboard';

import getters from './getters';
import { dashboardTabs } from '../../../../sections/layers/pages/layers_dashboard/page_module/constants';
import PublishStatusText from 'bundles/p13n/components/publish_status_text';

@connect(props => {
  const { campaignType, layerId } = props;
  return {
    activeTab: DashboardGetters.getActiveTab,
    areMetricsRequired: CurrentLayerGetters.areMetricsRequired,
    canUsePersonalization: PermissionsModuleGetters.canUsePersonalization,
    currentCampaignHasUpdatesToPublish: CurrentLayerGetters.hasUpdatesToPublish,
    currentLayer: CurrentLayerGetters.layer,
    currentProjectId: CurrentProjectGetters.id,
    currentProjectName: CurrentProjectGetters.name,
    disablePublishButton: getters.disablePublishButton(
      CurrentLayerGetters.hasUpdatesToPublish,
    ),
    hasAnyProcessingAdaptiveAudienceConditions:
      CurrentLayerGetters.hasAnyProcessingAdaptiveAudienceConditions,
    hasDirtyCustomCode: CustomCodeGetters.hasDirtyCustomCode,
    hasLayerMetrics: CurrentLayerGetters.hasLayerMetrics,
    isFirstPublish: PublishStatusGetters.isFirstPublish(layerId),
    isPaused: PublishStatusGetters.isPaused(layerId),
    isUsingV2Snippet: CurrentProjectGetters.isUsingV2Snippet,
    liveCommitId: CurrentLayerGetters.liveCommitId,
    liveTag: PublishStatusGetters.liveTag(layerId),
    publishText: PublishStatusGetters.publishText(layerId, campaignType),
    shouldPublishLayerOnly: PublishStatusGetters.shouldPublishLayerOnly(
      layerId,
    ),
    singleExperiment: LayerGetters.singleExperimentByLayerId(layerId),
    v2PlatformText: V2MigrationGetters.v2PlatformText,
    currentSingleExperimentFromAllExperimentsPointingToLayer:
      CurrentLayerGetters.currentSingleExperimentFromAllExperimentsPointingToLayer,
  };
})
class NavSidebarHeader extends React.Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    campaignType: PropTypes.string.isRequired,
    canUsePersonalization: PropTypes.bool.isRequired,
    currentCampaignHasUpdatesToPublish: PropTypes.bool.isRequired,
    currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProjectId: PropTypes.number.isRequired,
    currentProjectName: PropTypes.string.isRequired,
    disablePublishButton: PropTypes.bool.isRequired,
    hasAnyProcessingAdaptiveAudienceConditions: PropTypes.bool.isRequired,
    hasDirtyCustomCode: PropTypes.bool.isRequired,
    hasLayerMetrics: PropTypes.bool.isRequired,
    isFirstPublish: PropTypes.bool.isRequired,
    isPaused: PropTypes.bool.isRequired,
    isUsingV2Snippet: PropTypes.bool.isRequired,
    layerId: PropTypes.number.isRequired,
    liveCommitId: PropTypes.number.isRequired,
    liveTag: PropTypes.instanceOf(Immutable.Map).isRequired,
    publishText: PropTypes.string.isRequired,
    shouldPublishLayerOnly: PropTypes.bool.isRequired,
    singleExperiment: PropTypes.instanceOf(Immutable.Map).isRequired,
    v2PlatformText: PropTypes.string.isRequired,
    currentSingleExperimentFromAllExperimentsPointingToLayer: PropTypes.instanceOf(
      Immutable.Map,
    ).isRequired,
  };

  constructor(props) {
    super(props);
    const { campaignType, currentLayer, currentProjectId } = props;
    this.publishStatusTexts = PublishStatusFns.getText(
      campaignType,
      currentLayer.get('name'),
    );
    // TODO(jordan): do we need this verifier
    VerifierActions.verify({
      projectId: currentProjectId,
    }).catch(() => {}); // don't error on rejection

    this.state = {
      isEditTitle: false,
      isEditDescription: false,
      isLoading: false,
    };
  }

  isPersonalizationLayer = () =>
    LayerFns.isPersonalizationLayer(this.props.currentLayer);

  handleBackClick = event => {
    const { currentProjectId, activeTab, canUsePersonalization } = this.props;
    let tab;
    if (isFeatureEnabled(Features.M1_P13N) && canUsePersonalization) {
      tab =
        activeTab ||
        (this.isPersonalizationLayer()
          ? dashboardTabs.PERSONALIZATIONS
          : dashboardTabs.EXPERIMENTS);
    }

    let target = '';
    switch (tab) {
      case dashboardTabs.EXPERIMENTS:
        target = UrlHelper.experiments(currentProjectId);
        break;
      case dashboardTabs.PERSONALIZATIONS:
        target = UrlHelper.personalizations(currentProjectId);
        break;
      default:
        target = UrlHelper.xWebHome(currentProjectId);
        break;
    }
    pushStateHandler(target, event);
  };

  goToMetricsTab = () => {
    const { hasDirtyCustomCode } = this.props;
    const experimentType = this.isPersonalizationLayer()
      ? tr('campaign')
      : tr('experiment');
    P13NUIActions.confirmNavigation(hasDirtyCustomCode, experimentType, () => {
      CustomCodeActions.resetCustomCodeTabsDirty();
      CampaignManagerActions.setTab(CampaignManagerEnums.tabs.METRICS);
      Router.go(this.getMetricsUrl());
    });
  };

  getMetricsUrl = () => {
    const { currentLayer, singleExperiment, currentProjectId } = this.props;

    let metricsUrl = UrlHelper.campaignMetrics(
      currentProjectId,
      currentLayer.get('id'),
    );
    const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(
      currentLayer,
    );
    const isABTestLayer = LayerFns.isABTestLayer(currentLayer);
    const expId = singleExperiment && singleExperiment.get('id');

    if (isMultivariateTestLayer) {
      metricsUrl = UrlHelper.mvtMetrics(currentProjectId, expId);
    } else if (isABTestLayer) {
      metricsUrl = UrlHelper.experimentMetrics(currentProjectId, expId);
    }

    return metricsUrl;
  };

  enableV2SnippetLink = () =>
    UrlHelper.projectSettingsImplementation(this.props.currentProjectId);

  onPublishClick = e => {
    // stopping propagation because the button is inside activator for list dropdown and we dont want to trigger dropdown.
    if (e) {
      e.stopPropagation();
    }

    const {
      hasAnyProcessingAdaptiveAudienceConditions,
      isFirstPublish,
      isPaused,
    } = this.props;

    const {
      adaptive,
      firstPublish,
      publish,
      publishAndStart,
    } = this.publishStatusTexts;

    const showPublishAndStartDialog = () => {
      ui.showReactDialog(MultiConfirm, {
        props: {
          title: publishAndStart.title,
          message: publishAndStart.message,
          buttons: [
            {
              testSection: 'campaign-overview-publish-only',
              message: tr('Publish Only'),
              action: this.publishCampaign,
              style: 'outline',
            },
            {
              testSection: 'campaign-overview-publish-and-start',
              message: tr('Publish and Start'),
              action: this.publishAndStartCampaign,
              style: 'highlight',
            },
          ],
        },
      });
    };

    if (isPaused) {
      if (hasAnyProcessingAdaptiveAudienceConditions) {
        ui.confirm({
          title: adaptive.title,
          message: adaptive.message,
          confirmText: publish.confirmText,
          doSanitizeHTML: false,
        }).then(() => {
          showPublishAndStartDialog();
        });
      } else {
        showPublishAndStartDialog();
      }
    } else {
      const { title, message, confirmText } = isFirstPublish
        ? firstPublish
        : publish;

      ui.confirm({
        title: hasAnyProcessingAdaptiveAudienceConditions
          ? adaptive.title
          : title,
        message: hasAnyProcessingAdaptiveAudienceConditions
          ? adaptive.message
          : message,
        confirmText,
        doSanitizeHTML: false,
      }).then(() => {
        if (isFirstPublish) {
          return this.publishAndStartCampaign();
        }
        return this.publishCampaign();
      });
    }
  };

  publishCampaign = () => {
    const { currentLayer, isFirstPublish, currentProjectId } = this.props;
    ui.showNotification({
      message: tr(
        'Your changes for <b>{0}</b> are now being published',
        htmlSanitizer.escape(currentLayer.get('name')),
      ),
    });
    PublishStatusActions.startPublishing(
      currentLayer.get('id'),
      isFirstPublish,
    );
    return ReviewAndPublishActions.publishLayerAndRefetchLayerData(
      currentLayer.get('id'),
      currentProjectId,
    )
      .then(() => {
        ui.showNotification({
          message: this.publishStatusTexts.publish.confirmationText,
        });
      })
      .always(() => {
        PublishStatusActions.finishPublishing(currentLayer.get('id'));
      });
  };

  publishAndStartCampaign = () => {
    const { currentLayer } = this.props;
    // Purposefully wait until the publish finishes before starting the campaign
    // TODO (asa): Make atomic action
    this.publishCampaign().then(() => {
      this.startCampaign(currentLayer);
    });
  };

  startCampaign = layer => {
    const { currentLayer } = this.props;
    CurrentLayerActions.startCampaign(toJS(layer)).then(() => {
      ui.showNotification({
        message: this.publishStatusTexts.start.confirmationText,
      });
      Events.trackMarketoEvent(
        'x_campaign_nav',
        'start',
        'experiment_for_layer',
        currentLayer.get('id'),
      );
    });
  };

  onPublishCampaignSettingsClick = () => {
    const {
      currentLayer,
      currentProjectId,
      hasAnyProcessingAdaptiveAudienceConditions,
      isFirstPublish,
      liveCommitId,
    } = this.props;

    const { adaptive, publish, publishLayerOnly } = this.publishStatusTexts;

    ui.confirm({
      title: hasAnyProcessingAdaptiveAudienceConditions
        ? adaptive.title
        : publishLayerOnly.title,
      message: hasAnyProcessingAdaptiveAudienceConditions
        ? adaptive.message
        : publishLayerOnly.message,
      confirmText: publishLayerOnly.confirmText,
      doSanitizeHTML: false,
    })
      .then(() => {
        PublishStatusActions.startPublishing(
          currentLayer.get('id'),
          isFirstPublish,
        );
        ui.showNotification({
          message: publishLayerOnly.confirmationText,
        });
        return ReviewAndPublishActions.publishLayerOnlyAndRefetchLayerData(
          currentLayer.get('id'),
          currentProjectId,
          liveCommitId,
        );
      })
      .then(() => {
        ui.showNotification({
          message: publish.confirmationText,
        });
      })
      .always(() => {
        PublishStatusActions.finishPublishing(currentLayer.get('id'));
      });
  };

  renderPublishLayerDropdown() {
    const { disablePublishButton, publishText } = this.props;
    return (
      <div data-test-section="campaign-overview-sidebar-layer-only-publish">
        <ListDropdown
          width="100%"
          minWidth={255}
          activator={
            <div className="lego-button-group">
              <Button
                width="full"
                style="highlight"
                isDisabled={disablePublishButton}
                onClick={this.onPublishClick}
                testSection="campaign-overview-sidebar-layer-only-publish-base-button">
                {publishText}
              </Button>
              <span style={{ 'margin-left': '2px' }} />
              <Button
                style="highlight"
                isDisabled={disablePublishButton}
                testSection="campaign-overview-sidebar-layer-only-publish-dropdown">
                <span className="flush--left">
                  <Icon name="angle-down" size="small" />
                </span>
              </Button>
            </div>
          }
          items={[
            {
              text: this.publishStatusTexts.publishLayerOnly
                .fullPublishOptionText,
              onClick: this.onPublishClick,
              testSection:
                'campaign-overview-sidebar-layer-only-publish-full-option',
            },
            {
              text: this.publishStatusTexts.publishLayerOnly
                .layerOnlyPublishOptionText,
              onClick: this.onPublishCampaignSettingsClick,
              testSection:
                'campaign-overview-sidebar-layer-only-publish-layer-only-option',
            },
          ]}
        />
      </div>
    );
  }

  getBackLinkText = () => {
    const { activeTab, canUsePersonalization } = this.props;
    const tab =
      !isFeatureEnabled(Features.M1_P13N) || !canUsePersonalization
        ? undefined
        : activeTab ||
          (this.isPersonalizationLayer()
            ? dashboardTabs.PERSONALIZATIONS
            : dashboardTabs.EXPERIMENTS);
    switch (tab) {
      case dashboardTabs.EXPERIMENTS:
        return 'Experiments';
      case dashboardTabs.PERSONALIZATIONS:
        return 'Personalization Campaigns';
      case dashboardTabs.OVERVIEW:
        return 'Overview';
      default:
        return 'Experiments Overview';
    }
  };

  handleTitleInput = value => {
    const {
      currentLayer,
      currentSingleExperimentFromAllExperimentsPointingToLayer,
    } = this.props;

    // Checking if any changes are made then only update the title
    if (currentLayer.get('name') !== value) {
      this.setState({ isLoading: true });
      LayerActions.save({
        id: currentLayer.get('id'),
        name: value,
      })
        .then(() => {
          const shouldSaveLayerExperiment = LayerFns.isSingleExperimentLayer(
            currentLayer,
          );

          if (shouldSaveLayerExperiment) {
            LayerExperimentActions.save({
              id: currentSingleExperimentFromAllExperimentsPointingToLayer.get(
                'id',
              ),
              name: value,
            });
          }
        })
        .always(() => {
          this.setState({ isLoading: false, isEditTitle: false });
        });
    } else {
      this.setState({ isEditTitle: false });
    }
  };

  handleDescriptionInput = value => {
    const { currentLayer } = this.props;

    // Checking if any changes are made then only update the description
    if (currentLayer.get('description') !== value) {
      this.setState({ isLoading: true });
      LayerActions.save({
        id: currentLayer.get('id'),
        description: value,
      }).always(() => {
        this.setState({ isLoading: false, isEditDescription: false });
      });
    } else {
      this.setState({ isEditDescription: false });
    }
  };

  render() {
    const {
      areMetricsRequired,
      currentCampaignHasUpdatesToPublish,
      currentLayer,
      currentProjectName,
      disablePublishButton,
      hasLayerMetrics,
      isUsingV2Snippet,
      liveTag,
      publishText,
      shouldPublishLayerOnly,
      singleExperiment,
      v2PlatformText,
    } = this.props;

    const isWinnerRolloutEnabled = isWinnerRolloutFeatureEnabled();

    return (
      <SidebarHeader
        projectName={currentProjectName}
        backLinkText={this.getBackLinkText()}
        backLinkOnClick={this.handleBackClick}
        title={currentLayer.get('name')}
        titleEdit={
          !this.isPersonalizationLayer()
            ? {
                isEditing: this.state.isEditTitle,
                isLoading: this.state.isLoading,
                handleSubmit: this.handleTitleInput,
                handleEditMode: () => this.setState({ isEditTitle: true }),
              }
            : null
        }
        description={currentLayer.get('description')}
        descriptionEdit={
          !this.isPersonalizationLayer()
            ? {
                isEditing: this.state.isEditDescription,
                isLoading: this.state.isLoading,
                handleSubmit: this.handleDescriptionInput,
                handleEditMode: () =>
                  this.setState({ isEditDescription: true }),
              }
            : null
        }
        publishStatusText={
          <PublishStatusText
            campaignType={LayerFns.getCampaignTypeOfLayer(currentLayer)}
            layerId={currentLayer.get('id')}
          />
        }>
        <div
          data-test-section="campaign-overview-sidebar-header"
          className={classNames({ 'push--bottom': isWinnerRolloutEnabled })}>
          <LayerStatusIndicator
            useStatusDropdown={isWinnerRolloutEnabled}
            showPublishStatusText={false}
          />
          <ConcurrentEditingContainer entityId={currentLayer.get('id')} />
          {LayerFns.isSingleExperimentLayer(currentLayer) && (
            <SchedulerInfo
              experiment={singleExperiment}
              hasUpdatesToPublish={currentCampaignHasUpdatesToPublish}
              isSingleExperimentLayer={true}
            />
          )}
          <div
            className={classNames('flex', {
              'push-double--top': !isWinnerRolloutEnabled,
              'push--top': isWinnerRolloutEnabled,
            })}>
            <div className="flex flex-align--center flex--1">
              {shouldPublishLayerOnly && this.renderPublishLayerDropdown()}
              {!shouldPublishLayerOnly && (
                <Button
                  width="full"
                  style="highlight"
                  onClick={this.onPublishClick}
                  testSection="campaign-overview-sidebar-publish"
                  isDisabled={disablePublishButton}>
                  {publishText}
                </Button>
              )}
            </div>
            {isWinnerRolloutEnabled && (
              <div className="push-double--left">
                <LayerActionButtons currentLayer={currentLayer} />
              </div>
            )}
          </div>
          {liveTag && (
            <div
              className="text--center push-half--top micro muted"
              data-test-section="campaign-overview-sidebar-campaign-last-publish-date">
              Last published {fulldate(liveTag.get('last_modified'))}
            </div>
          )}
          {!isWinnerRolloutEnabled && (
            <LayerActionButtons currentLayer={currentLayer} />
          )}
          {areMetricsRequired && !hasLayerMetrics && (
            <div
              className="flex micro vertical-align--center push--bottom"
              data-test-section="sidebar-header-add-metric-warning">
              <div className="color--brand">
                <Icon
                  color={brandBlueDark}
                  name="circle-exclamation"
                  size="small"
                />
              </div>
              <span className="push-half--left">
                Please
                <a
                  onClick={this.goToMetricsTab}
                  data-test-section="nav-header-metrics-link"
                  className="push-half--left push-half--right">
                  add a metric
                </a>
                to start this{' '}
                {this.isPersonalizationLayer() ? 'campaign' : 'experiment'}
              </span>
            </div>
          )}
          {!isUsingV2Snippet && (
            <div
              className="flex micro vertical-align--center push--bottom"
              data-test-section="turn-on-v2-snippet-text">
              <div className="color--brand">
                <Icon
                  color={brandBlueDark}
                  name="circle-exclamation"
                  size="small"
                />
              </div>
              <span className="push-half--left">
                Please{' '}
                <a href={this.enableV2SnippetLink()}>enable {v2PlatformText}</a>{' '}
                to preview and publish this campaign.
              </span>
            </div>
          )}
        </div>
      </SidebarHeader>
    );
  }
}

export default NavSidebarHeader;
