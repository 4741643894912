import React from 'react';
import { Button, Code, Input, Sheet, Disclose } from '@optimizely/axiom';
import sprintf from 'sprintf';
import PropTypes from 'prop-types';

import ui from 'core/ui';
import Immutable, { toImmutable } from 'optly/immutable';
import regexUtils from 'optly/utils/regex';
import { connect } from 'core/ui/decorators';
import ProjectEnums from 'optly/modules/entity/project/enums';
import SegmentTracking from 'optly/modules/segment';
import LoadingOverlay from 'react_components/loading_overlay';
import CodeSamplePicker from 'bundles/p13n/components/code_sample_picker';
import { fns as PermissionsFns } from 'optly/modules/permissions';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import SectionModuleGetters from 'bundles/p13n/sections/oasis_implementation/section_module/getters';
import SectionModuleFns from 'bundles/p13n/sections/oasis_implementation/section_module/fns';
import EventProperties from 'bundles/p13n/components/event_properties';
import {
  populateEventPropertiesFromRequest,
  cleanEventPropertiesForRequest,
} from 'bundles/p13n/components/event_properties/utils';
import {
  CODE_BLOCKS_WITHOUT_PROPERTIES,
  CODE_BLOCKS_WITH_PROPERTIES,
} from 'bundles/p13n/sections/oasis_implementation/section_module/enums';
import { formatEventProperties } from 'bundles/p13n/sections/oasis_implementation/section_module/propertyFormatter';

@connect({
  currentProjectEvents: SectionModuleGetters.currentProjectEvents,
  canCreateEvent: [
    CurrentProjectGetters.project,
    PermissionsFns.canCreateUserEvent,
  ],
  isFx: CurrentProjectGetters.isFlagsProject,
  isMobileOnly: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.isMobileOnlyAccount,
  ],
})
class EventConfigDialog extends React.Component {
  static componentId = 'oasis-event-config';

  state = {
    editingEvent: this.props.event,
    errors: toImmutable([]),
    isSaving: false,
    isKeyFieldTouched: false,
    submitCount: 0,
  };

  static propTypes = {
    canCreateEvent: PropTypes.bool,
    currentProjectEvents: PropTypes.instanceOf(Immutable.List),
    event: PropTypes.instanceOf(Immutable.Map).isRequired,
    isEditing: PropTypes.bool,
    isFx: PropTypes.bool,
    isMobileOnly: PropTypes.bool,
    language: PropTypes.string,
    onSaveEvent: PropTypes.func,
    syntaxHighlightingLanguage: PropTypes.string,
  };

  static defaultProps = {
    isFx: false,
  };

  componentDidMount = () => {
    const { event } = this.props;
    const eventObj = event.toJS();
    const editingEvent = populateEventPropertiesFromRequest(eventObj);

    this.setState({
      editingEvent: toImmutable(editingEvent),
    });
  };

  onSave = eventToSave => {
    const { onSaveEvent } = this.props;
    const saveDef = onSaveEvent(eventToSave).then(savedEvent => {
      ui.hideDialog();
      return savedEvent;
    });

    ui.loadingWhen('save-oasis-event', saveDef);
    return saveDef;
  };

  handleEventNameChange = inputChangeEvent => {
    const { submitCount, editingEvent, isKeyFieldTouched } = this.state;
    const { isEditing } = this.props;
    const newEventName = inputChangeEvent.target.value;
    let newEventKey;
    // if creating new event and user has not touched key field
    // auto-fill event key with suggested key value based on event name
    if (!isEditing && !isKeyFieldTouched) {
      newEventKey = SectionModuleFns.getFormattedEventAPIName(
        false,
        inputChangeEvent.target.value,
      ).toLowerCase();
    } else {
      newEventKey = editingEvent.get('api_name');
    }
    this.setState(
      prevState => ({
        editingEvent: prevState.editingEvent
          .set('name', newEventName)
          .set('api_name', newEventKey),
      }),
      () => {
        // Callback function that will be executed after setState
        if (submitCount > 0) {
          this.validate();
        }
      },
    );
  };

  handleEventKeyChange = inputChangeEvent => {
    const { submitCount } = this.state;
    const newEventKey = SectionModuleFns.getFormattedEventAPIName(
      true,
      inputChangeEvent.target.value,
    );
    this.setState(
      prevState => ({
        editingEvent: prevState.editingEvent.set('api_name', newEventKey),
        isKeyFieldTouched: true,
      }),
      () => {
        if (submitCount > 0) {
          this.validate();
        }
      },
    );
  };

  handleEventDescriptionChange = inputChangeEvent => {
    const newEventDescription = inputChangeEvent.target.value;
    this.setState(prevState => ({
      editingEvent: prevState.editingEvent.set(
        'description',
        newEventDescription,
      ),
    }));
  };

  save = () => {
    const { editingEvent } = this.state;
    const { event } = this.props;
    const editingEventObj = editingEvent.toJS();
    const cleanEvent = cleanEventPropertiesForRequest(editingEventObj);
    const isNewEvent = !editingEvent.id;

    this.setState(prevState => ({
      submitCount: prevState.submitCount + 1,
    }));
    if (this.validate()) {
      this.setState({
        isSaving: true,
      });

      const eventProperties = editingEvent.event_properties || [];

      // If the event is new, track all event properties
      if (isNewEvent && eventProperties.length > 0) {
        SegmentTracking.tracking.trackEvent(
          'Event Created with Event Properties',
          {
            eventName: editingEvent.name,
            apiName: editingEvent.api_name,
            numberOfProperties: eventProperties.length,
            propertyTypes: eventProperties.map(prop => prop.type),
          },
        );
      } else if (!isNewEvent && eventProperties.length > 0) {
        // For an existing event, track only newly added properties
        // To determine newly added properties, compare both the 'name' and 'type' field of the new and old properties
        const previousEventProperties = event.get('event_properties').toJS();

        const prevPropsNormalized = previousEventProperties.map(prop => ({
          name: prop.name,
          type: prop.data_type,
        }));

        const newPropsNormalized = eventProperties.map(prop => ({
          name: prop.value,
          type: prop.type,
        }));

        const newlyAddedProperties = newPropsNormalized.filter(
          newProp =>
            !prevPropsNormalized.some(
              prevProp =>
                prevProp.name === newProp.name &&
                prevProp.type === newProp.type,
            ),
        );

        if (newlyAddedProperties.length > 0) {
          SegmentTracking.tracking.trackEvent(
            'Event Updated with New Properties',
            {
              eventName: editingEvent.name,
              apiName: editingEvent.api_name,
              numberOfProperties: newlyAddedProperties.length,
              propertyTypes: newlyAddedProperties.map(prop => prop.type),
            },
          );
        }
      }
      this.onSave(cleanEvent).always(() => {
        this.setState({
          isSaving: false,
        });
      });
    }
  };

  validate = () => {
    const { editingEvent } = this.state;
    let errors = toImmutable({});
    const name = editingEvent.get('name');
    const apiName = editingEvent.get('api_name');
    const eventProperties = editingEvent.get('event_properties').toJS();
    const hasEmptyProperties = eventProperties.some(
      ({ value, type }) => !value || !type,
    );

    if (!apiName) {
      errors = errors.set('api_name', 'This field is required.');
    } else if (regexUtils.whiteSpaceOnly.test(apiName)) {
      errors = errors.set('api_name', 'Key must not consist of only spaces.');
    } else if (!regexUtils.eventAPIName.test(apiName)) {
      errors = errors.set(
        'api_name',
        'Key must be no longer than 64 characters and may not contain backslashes, question marks, double quotes, single quotes, or backticks.',
      );
    } else if (this.isKeyNonUnique()) {
      errors = errors.set(
        'api_name',
        `Key ${apiName} is already in use by another event in this project. Please choose a unique key.`,
      );
    }
    if (!name) {
      errors = errors.set('name', 'This field is required.');
    } else if (regexUtils.whiteSpaceOnly.test(name)) {
      errors = errors.set('name', 'Name must not consist of only spaces.');
    } else {
      const duplicateEvent = this.getDuplicateEvent();
      if (duplicateEvent) {
        errors = errors.set(
          'name',
          `This name ${name} is already in use by another UserEvent (id: ${duplicateEvent.get(
            'id',
          )}. Please provide a unique value.`,
        );
      }
    }
    if (hasEmptyProperties) {
      errors = errors.set(
        'event_properties',
        'Please enter a name and data type for all properties.',
      );
    }
    this.setState({
      errors,
    });
    return !errors.size;
  };

  getDuplicateEvent = () => {
    const { editingEvent } = this.state;
    const { currentProjectEvents } = this.props;
    return currentProjectEvents.find(
      event =>
        event.get('name') === editingEvent.get('name') &&
        event.get('id') !== editingEvent.get('id'),
    );
  };

  isKeyNonUnique = () => {
    const { editingEvent } = this.state;
    const { currentProjectEvents } = this.props;
    return currentProjectEvents.some(
      event =>
        event.get('api_name') === editingEvent.get('api_name') &&
        event.get('id') !== editingEvent.get('id'),
    );
  };

  getCodeBlock = (language, eventKey, eventProperties) => {
    const hasEventProperties = eventProperties && eventProperties.length > 0;
    const codeBlock = hasEventProperties
      ? CODE_BLOCKS_WITH_PROPERTIES[language]
      : CODE_BLOCKS_WITHOUT_PROPERTIES[language];

    if (!codeBlock) {
      this.setState({
        codeGenerationError: `No code template found for ${language}`,
      });
      return null;
    }

    const formattedProperties = hasEventProperties
      ? formatEventProperties(eventProperties, language)
      : '';

    if (language === 'html') {
      // For the "Agent" dropdown option, template takes eventKey first, then formattedProperties
      if (hasEventProperties) {
        return sprintf(
          CODE_BLOCKS_WITH_PROPERTIES[language],
          eventKey || '',
          formattedProperties || '',
        );
      }
      return sprintf(CODE_BLOCKS_WITHOUT_PROPERTIES[language], eventKey || '');
    }
    // For other languages, properties first, then eventKey
    if (hasEventProperties) {
      return sprintf(
        CODE_BLOCKS_WITH_PROPERTIES[language],
        formattedProperties || '',
        eventKey || '',
      );
    }
    return sprintf(CODE_BLOCKS_WITHOUT_PROPERTIES[language], eventKey || '');
  };

  renderCodeBlock = () => {
    const { editingEvent, codeGenerationError } = this.state;
    const eventKey = editingEvent.get('api_name');
    const eventProperties = editingEvent.get('event_properties').toJS();
    const { language, isMobileOnly, syntaxHighlightingLanguage } = this.props;

    if (codeGenerationError) {
      return (
        <div className="error-message push-double--top">
          {codeGenerationError}
        </div>
      );
    }

    let content;
    if (!ProjectEnums.mobileAndOTTSDKs.includes(language)) {
      content = (
        <CodeSamplePicker
          description={tr(
            'Choose your preferred SDK to view sample code. Copy and paste the code to track the event in your application.',
          )}
          getCodeSample={this.renderEventCode}
          showMobileOnly={isMobileOnly}
          title={tr('Event Tracking Code')}
        />
      );
    } else {
      const codeBlock =
        this.getCodeBlock(language, eventKey, eventProperties) ||
        this.getCodeBlock(
          ProjectEnums.sdkLanguages.PYTHON,
          eventKey,
          eventProperties,
        );

      content = (
        <div>
          <h5 className="weight--normal">Tracking Code</h5>
          <Code
            isHighlighted={true}
            hasCopyButton={true}
            language={syntaxHighlightingLanguage}
            type="block"
            testSection="oasis-event-code-block">
            {codeBlock}
          </Code>
        </div>
      );
    }
    return (
      <div className="push-double--top">
        <Disclose title="API tracking code">{content}</Disclose>
      </div>
    );
  };

  renderEventCode = codeSampleLanguage => {
    const { editingEvent } = this.state;
    const eventKey = editingEvent.get('api_name');
    const eventProperties = editingEvent.get('event_properties').toJS();
    const codeBlock =
      this.getCodeBlock(codeSampleLanguage, eventKey, eventProperties) ||
      this.getCodeBlock(
        ProjectEnums.sdkLanguages.PYTHON,
        eventKey,
        eventProperties,
      );
    return (
      <Code
        isHighlighted={true}
        hasCopyButton={true}
        language={
          ProjectEnums.sdkSyntaxLanguage[codeSampleLanguage] ||
          codeSampleLanguage
        }
        type="block"
        testSection="event-code-sample">
        {codeBlock}
      </Code>
    );
  };

  updateEventProperties = updatedProperties => {
    const { editingEvent } = this.state;
    return new Promise(resolve => {
      this.setState(
        prevState => ({
          editingEvent: prevState.editingEvent.set(
            'event_properties',
            toImmutable(updatedProperties),
          ),
        }),
        () => {
          resolve(editingEvent.get('event_properties').toJS());
        },
      );
    });
  };

  getFormTitle = () => {
    const { isEditing } = this.props;
    return isEditing ? 'Edit Event' : 'New Event';
  };

  getPrimaryButtonText = () => {
    const { isEditing } = this.props;
    return isEditing ? 'Save Event' : 'Create Event';
  };

  render() {
    const { canCreateEvent, isFx } = this.props;
    const { editingEvent, errors, isSaving } = this.state;
    const editingEventExperimentCount =
      editingEvent.get('experiment_count') || 0;

    return (
      <div className="reading-column">
        <Sheet
          title={this.getFormTitle()}
          onClose={ui.hideDialog}
          testSection="oasis-event-config-dialog"
          hasRequiredFieldsIndicator={true}
          subtitle={
            <p>
              Custom tracking events allow you to capture and report on visitor
              actions or events.{' '}
              <a href="https://support.optimizely.com/hc/en-us/articles/4410289407885-Metrics-in-Optimizely">
                Learn more.
              </a>
            </p>
          }
          footerButtonList={[
            <Button
              key="btn-cancel"
              style="plain"
              testSection="oasis-config-event-cancel"
              onClick={ui.hideDialog}>
              Cancel
            </Button>,
            <Button
              key="btn-save"
              style="highlight"
              testSection="oasis-config-event-save"
              isDisabled={!canCreateEvent || isSaving}
              onClick={this.save}>
              {this.getPrimaryButtonText()}
            </Button>,
          ]}>
          <LoadingOverlay loadingId="save-oasis-event">
            <div data-test-section="oasis-event-config">
              <form>
                <fieldset>
                  <ol className="lego-form-fields">
                    <li className="lego-form-field__item">
                      <Input
                        displayError={errors.has('name')}
                        id="event-name"
                        isRequired={true}
                        label="Event Name"
                        note={errors.get('name')}
                        onChange={this.handleEventNameChange}
                        placeholder="Enter an Event Name"
                        testSection="oasis-config-event-name"
                        type="text"
                        value={editingEvent.get('name') || ''}
                      />
                    </li>
                    <li className="lego-form-field__item">
                      <Input
                        displayError={errors.has('api_name')}
                        id="event-key"
                        isRequired={true}
                        label="Event Key"
                        note={errors.get('api_name')}
                        onChange={this.handleEventKeyChange}
                        placeholder="Enter an Event Key"
                        testSection="oasis-config-event-key"
                        type="text"
                        value={editingEvent.get('api_name') || ''}
                      />
                    </li>
                    <li className="lego-form-field__item">
                      <Input
                        defaultValue={editingEvent.get('description')}
                        placeholder="Add a description"
                        id="event-description"
                        label="Description"
                        onChange={this.handleEventDescriptionChange}
                        testSection="oasis-config-event-description"
                      />
                    </li>
                  </ol>
                  <br />
                  <EventProperties
                    eventProperties={editingEvent
                      .get('event_properties')
                      .toJS()}
                    eventPropertiesError={errors.get('event_properties')}
                    isEventUsedInExperiment={editingEventExperimentCount > 0}
                    isFx={isFx}
                    updateEventProperties={this.updateEventProperties}
                    sectionClassNames="push-quad--top"
                  />
                  {this.renderCodeBlock()}
                </fieldset>
              </form>
            </div>
          </LoadingOverlay>
        </Sheet>
      </div>
    );
  }
}
export default EventConfigDialog;
