import {
  isFeatureEnabled,
  getFeatureVariableBoolean,
  getFeatureVariableString,
} from '@optimizely/js-sdk-lab/src/actions';
import config from 'atomic-config';

import OptimizelyChampagneEnums from 'optly/modules/optimizely_champagne/enums';

function isChassisEnabled() {
  return isFeatureEnabled(
    OptimizelyChampagneEnums.FEATURES.optibot_chassis.FEATURE_KEY,
  );
}

function isCommonCopilotEnabled() {
  return getFeatureVariableBoolean(
    OptimizelyChampagneEnums.FEATURES.optibot_chassis.FEATURE_KEY,
    'opal_common_copilot',
  );
}

function isOpalChatEnabled() {
  const isOptiIdSession = config.get('env.IS_OPTI_ID_SESSION');
  return isOptiIdSession && isChassisEnabled && isCommonCopilotEnabled();
}

function isResultsSummaryEnabled() {
  return getFeatureVariableBoolean(
    OptimizelyChampagneEnums.FEATURES.optibot_chassis.FEATURE_KEY,
    'results_summary',
  );
}

function opalDevUrl() {
  return getFeatureVariableString(
    OptimizelyChampagneEnums.FEATURES.optibot_chassis.FEATURE_KEY,
    'opal_dev_url',
  );
}

function isExperimentTestIdeasEnabled() {
  return getFeatureVariableBoolean(
    OptimizelyChampagneEnums.FEATURES.optibot_chassis.FEATURE_KEY,
    'experiment_test_ideas',
  );
}

export default {
  isChassisEnabled,
  isCommonCopilotEnabled,
  isOpalChatEnabled,
  isResultsSummaryEnabled,
  isExperimentTestIdeasEnabled,
  opalDevUrl,
};
