import snakeCase from 'lodash/snakeCase';
import config from 'atomic-config';

import flux from 'core/flux';

import poll from 'optly/utils/poll';

import actionTypes from './action_types';
import fns from './fns';
import getters from './getters';
import constants from './constants';

const HALF_HOUR_IN_SECONDS = (60 * 60) / 2;

let PublicApiConsumerActions;

/**
 * logMessage
 * @private
 * @description Utility for logging public api token management status
 * @param {String} message Primary message to print to the console
 * @param {Any} args Any other arguments to log to the console
 */
function logMessage(message, ...args) {
  if (!__TEST__) {
    console.log(`[PUBLIC API CONSUMER] ${message}`, ...args); // eslint-disable-line
  }
}

/**
 * getTokenName
 * @private
 * @description Gets the token name for the current account
 * @returns {String} The name of the token
 */
function getTokenName() {
  const accountId = config.get('account_info.account_id');
  return `${constants.TOKEN_NAME_PREFIX}.${accountId}`;
}

/**
 * setToken
 * @private
 * @param {String} token - The bearer token to save local storage
 * @param {Date} expirationDate - The date the token expires
 */
function setToken(token, expirationDate) {
  logMessage('Saving token to local storage.');
  const serializedToken = JSON.stringify({
    token,
    expires: expirationDate.toISOString(),
  });
  window.localStorage.setItem(getTokenName(), serializedToken);
}

/**
 * getToken
 * @private
 * @description Obtains the bearer token from local storage
 * @returns {Object} The bearer token and expiration date
 */
function getToken() {
  const localStorageItem = window.localStorage.getItem(getTokenName());
  if (!localStorageItem) {
    return {};
  }

  try {
    return JSON.parse(localStorageItem);
  } catch (e) {
    logMessage('Token could not be deseralized.', e);
    return {};
  }
}

/**
 * getTimeToTokenExpiration
 * @private
 * @description Gets the amount of milliseconds until/since token expiration
 * @returns {Number} Milliseconds until/since expiration
 */
function getTimeToTokenExpiration() {
  const { expires } = getToken();
  const expirationDate = new Date(expires).getTime();
  return expirationDate - Date.now();
}

/**
 * isValidTokenAvailable
 * @private
 * @description Determines if a valid token is available in local storage
 * @returns {Boolean} Whether or not a non-expired token is available
 */
function isValidTokenAvailable() {
  const { token } = getToken();
  return token && getTimeToTokenExpiration() >= 0;
}

/**
 * ensureValidTokenExists
 * @private
 * @description If token is expired/non-existant, it fetches a token. If valid token exists, it schedules a token refresh.
 * @returns {Promise} resolves when valid token is available
 */
function ensureValidTokenExists() {
  const { token } = getToken();

  // If non-expired token exists, schedule future token refresh and resolve early
  if (isValidTokenAvailable()) {
    const refreshIn = getTimeToTokenExpiration();
    logMessage(
      `Non-expired token found. Scheduling a token refresh in ${refreshIn /
        1000}s.`,
    );

    // If an existing timer is pending, clear it and replace it
    const existingTimer = flux.evaluate(getters.scheduledRefreshTimer);
    if (existingTimer) {
      flux.dispatch(actionTypes.PUBLIC_API_CONSUMER_CLEAR_REFRESH_TIMEOUT);
      clearTimeout(existingTimer);
    }

    // Create a new refresh timer, store it in the flux store
    const tokenRefreshTimer = setTimeout(() => {
      logMessage('Executing automatically-scheduled token refresh now.');
      flux.dispatch(actionTypes.PUBLIC_API_CONSUMER_CLEAR_REFRESH_TIMEOUT);
      getNewToken().catch(err => {
        fns.capturePublicApiError(err);
        if (fns.isExpiredSessionError(err)) {
          PublicApiConsumerActions.triggerPageReload();
        }
      });
    }, refreshIn);
    flux.dispatch(
      actionTypes.PUBLIC_API_CONSUMER_SET_REFRESH_TIMEOUT,
      tokenRefreshTimer,
    );
    return Promise.resolve();
  }

  // Return token fetch promise
  logMessage(
    token
      ? 'Expired token found. Fetching a new token.'
      : 'Token not found. Fetching a new token.',
  );
  return getNewToken().catch(err => {
    fns.capturePublicApiError(err);
    if (fns.isExpiredSessionError(err)) {
      PublicApiConsumerActions.triggerPageReload();
    }
    return Promise.reject(err);
  });
}

/**
 * registerNewToken
 * @private
 * @description Stores token/expiration in local storage
 * @param {String} token - the bearer token received from OAuth2 provider
 * @param {Number} expiration - number of milliseconds until token expires
 */
function registerNewToken(token, expiration) {
  const refreshAuthTime =
    Date.now() + (expiration - HALF_HOUR_IN_SECONDS) * 1000;

  // Store the new token in local storage
  setToken(token, new Date(refreshAuthTime));
}

/**
 * getAuthorizationUrl
 * @private
 * @description Calculates the URL for the OAuth2 authorization route based on the env
 * @returns {String} Environment-specific OAuth2 authorization URL
 */
function getAuthorizationUrl() {
  // Serialize state object into a string
  const parentHost = config.get('env.HOST_URL');
  const csrf = config.get('csrf');
  const state = encodeURIComponent(
    JSON.stringify({
      csrf,
      parentHost,
    }),
  );

  // Generate and return URL
  const clientId = config.get('env.PUBLIC_API_CLIENT_ID');
  const authUrl = `${parentHost}/oauth2/authorize`;
  const redirectUrl = config.get('env.PUBLIC_API_REDIRECT_URL');
  return `${authUrl}?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=token&scopes=all&state=${state}`;
}

/**
 * generateRequestUri
 * @private
 * @description Generates the path portion of the API request URL using request config
 * @param {Object} requestConfig Configuration for request
 * @param {String} requestConfig.entityName String name of the API entity
 * @param {Number|String} requestConfig.entityId (optional) Entity ID to use in a query
 * @param {Object} requestConfig.queryParams (optional) Map of query params for URL
 */
function generateRequestUri({ entityName, entityId, queryParams = {} }) {
  // figure out how to convert all param keys to snake case
  const path = `/v2/${entityName}${entityId ? `/${entityId}` : ''}`;
  const queryString = Object.entries(queryParams)
    .reduce((currentParams, [key, value]) => {
      let newParams;
      if (Array.isArray(value)) {
        newParams = value.map(subValue => `${snakeCase(key)}=${subValue}`);
      } else {
        newParams = [`${snakeCase(key)}=${value}`];
      }
      return [...currentParams, ...newParams];
    }, [])
    .join('&');
  return `${path}${queryString ? `?${queryString}` : ''}`;
}

/**
 * makeRequestToPublicApi
 * @private
 * @description Makes a public API request based on the passed config object
 * @param {Object} requestConfig Configuration for request
 * @param {String} requestConfig.entityName String name of the API entity
 * @param {Object} requestConfig.entity (optional) Entity data to send to the API
 * @param {Number|String} requestConfig.entityId (optional) Entity ID to use in a query
 * @param {Object} requestConfig.queryParams (optional) Map of query params for URL
 * @param {Object} requestConfig.headers (optional) Map of HTTP request headers
 */
function makeRequestToPublicApi(requestConfig) {
  const url = generateRequestUri(requestConfig);
  const options = {
    headers: requestConfig.headers,
    method: requestConfig.httpVerb,
  };

  if (
    ['PATCH', 'POST'].includes(requestConfig.httpVerb) &&
    requestConfig.entity
  ) {
    options.body = JSON.stringify(requestConfig.entity);
  }

  return fetchWithPublicApiToken(url, options);
}

/**
 * waitForNextIframeMessage
 * @private
 * @returns Promise that resolves when a message is posted from the iframe, rejects if timed out
 */
function waitForNextIframeMessage(timeoutDuration) {
  return new Promise((resolve, reject) => {
    let tokenFetchTimeout;

    // Create and attach a message handler to resolve the promise if a message is received
    const messageEventHandler = event => {
      if (
        !event ||
        !event.data ||
        !event.data.type ||
        ![
          constants.TOKEN_MESSAGE_DATA_EVENT_TYPE,
          constants.TOKEN_MESSAGE_ERROR_EVENT_TYPE,
          constants.TOKEN_MESSAGE_SESSION_EXPIRED_EVENT_TYPE,
        ].includes(event.data.type)
      )
        return;

      // Clean up timeout and message handler
      window.removeEventListener('message', messageEventHandler);
      clearTimeout(tokenFetchTimeout);

      resolve(event.data);
    };
    window.addEventListener('message', messageEventHandler, false);

    // Create timeout to race with the message listener, rejects promise if timed out
    tokenFetchTimeout = setTimeout(() => {
      // Clean up message handler
      window.removeEventListener('message', messageEventHandler);

      // Reject with error, specify that the error was due to a timeout
      reject(fns.createTimeoutError());
    }, timeoutDuration);
  });
}

/**
 * attemptToGetNewToken
 * @private
 * @param {Number} timeoutDuration amount of milliseconds to wait before cancelling request
 * @returns {Promise} resolves if a success occurs, rejects if an error or timeout occurs
 */
function attemptToGetNewToken(timeoutDuration) {
  // Create a hidden iframe on the document to allow for a non-intrusive auth refresh
  const implicitAuthIframe = document.createElement('iframe');
  implicitAuthIframe.style.display = 'none';
  implicitAuthIframe.src = getAuthorizationUrl();

  // Create a listener promise that resolves when the iframe posts a message
  const iframeMessageListener = waitForNextIframeMessage(timeoutDuration);

  // Attach iframe to begin OAuth2 token refresh process
  window.document.body.appendChild(implicitAuthIframe);

  // Return a promise which settles when either a message is received or the request times out
  return iframeMessageListener.then(
    eventData => {
      let accessToken;
      let expiresIn;

      // Remove the iframe
      window.document.body.removeChild(implicitAuthIframe);

      if (eventData.type === constants.TOKEN_MESSAGE_DATA_EVENT_TYPE) {
        // If a token-containing message is received, register it with the frontend
        logMessage('Token message event received.');

        // Verify the secure state
        const state = JSON.parse(decodeURIComponent(eventData.message.state));
        if (config.get('csrf') !== state.csrf) {
          throw fns.createCsrfMismatchError();
        }

        accessToken = eventData.message.access_token;
        expiresIn = parseInt(eventData.message.expires_in, 10);
      } else if (eventData.type === constants.TOKEN_MESSAGE_ERROR_EVENT_TYPE) {
        // Jump to the catch block if the iframe posted an error
        throw fns.createIframeFailureError(eventData.message);
      } else if (
        eventData.type === constants.TOKEN_MESSAGE_SESSION_EXPIRED_EVENT_TYPE
      ) {
        // Jump to the catch block if the iframe posted an logout
        throw fns.createExpiredSessionError();
      }

      // resolveAttempt to signify that the token has been obtained
      return { didTimeout: false, accessToken, expiresIn };
    },
    err => {
      window.document.body.removeChild(implicitAuthIframe);
      throw err;
    },
  );
}

/**
 * triggerPageReload
 * @protected
 * @description Reloads the page. Function provided for test stubbing of window.location.reload
 */
function triggerPageReload() {
  window.location.reload();
}

/**
 * isPublicApiAvailable
 * @public
 * @returns {Boolean} whether or not public api is available in this environment
 */
function isPublicApiAvailable() {
  const clientId = config.get('env.PUBLIC_API_CLIENT_ID');
  const authHost = config.get('env.HOST_URL');
  const apiHost = config.get('env.PUBLIC_API_HOST');
  const redirectUrl = config.get('env.PUBLIC_API_REDIRECT_URL');

  return !!clientId && !!authHost && !!apiHost && !!redirectUrl;
}

/**
 * canGetToken
 * @public
 * @returns {Boolean} whether or not the current user can get an access token
 */
function canGetToken() {
  const accountId = config.get('account_info.account_id');

  return !!accountId;
}

/**
 * getNewToken
 * @public
 * @description Fetches a new token and schedules a token refresh for new token
 * @returns {Promise} Resolves when token has been obtained
 */
function getNewToken() {
  if (!isPublicApiAvailable() || !canGetToken()) {
    return Promise.reject(
      new Error('Public API tokens cannot be fetched in this environment.'),
    );
  }

  // If a token is already being fetched, return the existing token fetch promise
  const existingTokenFetchLock = flux.evaluate(getters.refreshLock);
  if (existingTokenFetchLock) {
    logMessage('A token is already being fetched. Blocking new fetch request.');
    return existingTokenFetchLock;
  }

  // Create aggregate promise to contain all fetch attempts (if requests time out)
  const pendingTokenFetch = new Promise((resolve, reject) => {
    let attemptCounter = 1;

    // Create a retry-based wrapper for attemptToGetNewToken and call it
    const attemptToGetNewTokenOrRetry = () => {
      logMessage(
        `Beginning request to fetch token (attempt ${attemptCounter} of ${constants.TOKEN_FETCH_MAX_ATTEMPTS}).`,
      );
      attemptToGetNewToken(
        (constants.TOKEN_FETCH_TIMEOUT_SECONDS +
          (attemptCounter - 1) *
            constants.TOKEN_FETCH_TIMEOUT_RETRY_INCREMENT) *
          1000,
      ).then(
        ({ accessToken, expiresIn }) => {
          // Save the new token
          registerNewToken(accessToken, expiresIn);

          // Resolve if the token was received
          resolve();

          // Schedule a token refresh
          ensureValidTokenExists();

          // Clear the lock to reject pending API requests and allow for future token fetches
          flux.dispatch(
            actionTypes.PUBLIC_API_CONSUMER_CLEAR_REFRESH_LOCK,
            true,
          );
        },
        err => {
          if (err && fns.isTimeoutError(err)) {
            // If request timed out and retries are available, swallow error and retry
            if (attemptCounter < constants.TOKEN_FETCH_MAX_ATTEMPTS) {
              attemptCounter += 1;
              logMessage(
                'Public API token fetch request timed out. Retrying...',
              );
              attemptToGetNewTokenOrRetry();
              return;
            }

            // Otherwise, create new retry exhaustion error and reject aggregate promise
            reject(
              fns.createRetryExhaustionError(
                constants.TOKEN_FETCH_MAX_ATTEMPTS,
              ),
            );
          } else {
            // Reject the aggregate promise if non-timeout error occurs
            reject(err);
          }

          // Clear the lock to reject pending API requests and allow for future token fetches
          flux.dispatch(
            actionTypes.PUBLIC_API_CONSUMER_CLEAR_REFRESH_LOCK,
            false,
          );
        },
      );
    };

    attemptToGetNewTokenOrRetry();
  });

  // Lock future refreshes until new token is obtained
  flux.dispatch(
    actionTypes.PUBLIC_API_CONSUMER_SET_REFRESH_LOCK,
    pendingTokenFetch,
  );
  return pendingTokenFetch;
}

/**
 * clearCurrentToken
 * @private
 * @description Removes the access token local storage item for the current account
 */
function clearCurrentToken() {
  window.localStorage.removeItem(getTokenName());
}

/**
 * fetchOne
 * @public
 * @description Makes request to fetch a single instance of an entity from public API
 * @param {String} entityName Name of the API entity
 * @param {Number|String} entityId (optional) Entity ID to use in a query
 * @param {Object} options (optional) Options to change the default request behavior
 * @param {Object} options.queryParams (optional) Map of query params for URL
 * @param {Object} options.headers (optional) Map of HTTP request headers
 * @returns {Promise} Resolves/catches when response is received
 */
function fetchOne(entityName, entityId, { queryParams, headers } = {}) {
  if (!entityId) {
    throw new Error('Must specify entity ID to fetch an entity.');
  }

  return makeRequestToPublicApi({
    entityName,
    entityId,
    queryParams,
    headers,
    httpVerb: 'GET',
  });
}

/**
 * fetchPage
 * @public
 * @description DISCLAIMER: This function should not be used for customer-created
 *              entities that can grow without bound. It makes request to fetch all
 *              instances of an entity from public API.
 * @param {String} entityName Name of the API entity
 * @param {Object} [options] (optional) Options to change the default request behavior
 * @param {Object} [options.headers] (optional) Map of HTTP request headers
 * @param {Object} [options.queryParams] (optional) Map of query params for URL
 * @returns {Promise} Resolves/catches when response is received
 */
function fetchPage(entityName, { queryParams, headers } = {}) {
  return makeRequestToPublicApi({
    entityName,
    queryParams,
    headers,
    httpVerb: 'GET',
  });
}

/**
 * save
 * @public
 * @description Creates or updates a single instance of an entity
 * @param {String} entityName Name of the API entity
 * @param {Object} entity Object representing the entity data
 * @param {Object} options (optional) Options to change the default request behavior
 * @param {Object} options.queryParams (optional) Map of query params for URL
 * @param {Object} options.headers (optional) Map of HTTP request headers
 * @returns {Promise} Resolves/catches when response is received
 */
function save(entityName, entity, { queryParams, headers } = {}) {
  return makeRequestToPublicApi({
    entityName,
    entity,
    entityId: entity.id,
    queryParams,
    headers,
    httpVerb: entity.id ? 'PATCH' : 'POST',
  });
}

/**
 * archive
 * @public
 * @description Archives a single instasnce of an entity
 * @param {String} entityName Name of the API entity
 * @param {Number|String} entityId (optional) Entity ID to use in a query
 * @param {Object} options (optional) Options to change the default request behavior
 * @param {Object} options.queryParams (optional) Map of query params for URL
 * @param {Object} options.headers (optional) Map of HTTP request headers
 * @returns {Promise} Resolves/catches when response is received
 */
function archive(entityName, entityId, { queryParams, headers } = {}) {
  if (!entityId) {
    throw new Error('Must specify entity ID to archive an entity.');
  }

  return makeRequestToPublicApi({
    entityName,
    entityId,
    queryParams,
    headers,
    httpVerb: 'DELETE',
  });
}

/**
 * fetchWithPublicApiToken
 * @public
 * @description Performs a fetch with the public API token, waits if one is being fetched
 * @param {String} path Public API path to send request
 * @param {Object?} options Normal fetch options
 */
function fetchWithPublicApiToken(path, options = {}) {
  if (!isPublicApiAvailable() || !canGetToken()) {
    return Promise.reject(
      new Error('Cannot make public API requests in this environment.'),
    );
  }

  const initializationPromise = flux.evaluate(getters.initializationLock);

  // Wait for initialization and/or a pending token fetch operation
  return initializationPromise
    .then(PublicApiConsumerActions.ensureValidTokenExists)
    .then(() => {
      return flux.evaluate(getters.refreshLock) || Promise.resolve();
    })
    .then(() => {
      // Inject token into headers option
      const updatedOptions = options;
      if (!updatedOptions.headers) {
        updatedOptions.headers = {};
      }
      updatedOptions.headers = {
        ...updatedOptions.headers,
        Authorization: `Bearer ${getToken().token}`,
      };
      updatedOptions.credentials = 'include';
      const publicApiHost = config.get('env.PUBLIC_API_APP_HOST');
      const slash = path.startsWith('/') ? '' : '/';
      const url = `${publicApiHost}${slash}${path}`;
      logMessage(`Making request to ${url} using token.`);
      return fetch(url, updatedOptions).then(response => {
        logMessage(
          `Request to ${url} responded (HTTP ${response.status} ${response.statusText})`,
        );
        if (!response.ok) {
          const error = fns.createErrorForFailedHttpResponse(
            response,
            getToken(),
            flux.evaluate(getters.lastRefreshTime),
            flux.evaluate(getters.lastRefreshSucceeded),
          );

          // If error implies invalid token, clear token from local storage
          if (response.status === 403) {
            logMessage('Clearing token due to request failure');
            PublicApiConsumerActions.clearCurrentToken();
            error.tags.didClearToken = true;
          }

          // Capture error and pass response through to consumer
          fns.capturePublicApiError(error);
        }
        return response;
      });
    });
}

/**
 * init
 * @public
 * @description Checks for a token, refreshes if needed, called on page load
 */
function init() {
  if (!isPublicApiAvailable() || !canGetToken()) {
    return;
  }

  /**
   * When a token is fetched, an iframe is attached to the DOM to complete the
   * auth flow with the OAuth2 provider. This requires window.document.body to
   * be ready before requesting a token. Therefore, waitForSelector is used to delay
   * this process until the <body> is ready.
   */
  return poll
    .waitForSelector('body')
    .then(ensureValidTokenExists)
    .then(() => {
      // Once ensureValidTokenExists resolves, a token is ready to be used.
      logMessage('Initialization succeeded. Clearing initialization lock.');
      flux.dispatch(actionTypes.PUBLIC_API_CONSUMER_CLEAR_INITIALIZATION_LOCK);
    })
    .catch(err => {
      logMessage(`Initialization failed. Reason: ${err.message}`);
      flux.dispatch(
        actionTypes.PUBLIC_API_CONSUMER_CLEAR_INITIALIZATION_LOCK,
        Promise.reject(err),
      );
    });
}

export {
  init,
  getNewToken,
  fetchWithPublicApiToken,
  isPublicApiAvailable,
  canGetToken,
  fetchOne,
  fetchPage,
  save,
  archive,
  triggerPageReload,
  ensureValidTokenExists,
  clearCurrentToken,
  getToken,
};

export default PublicApiConsumerActions = {
  init,
  getNewToken,
  fetchWithPublicApiToken,
  isPublicApiAvailable,
  canGetToken,
  fetchOne,
  fetchPage,
  save,
  archive,
  triggerPageReload,
  ensureValidTokenExists,
  clearCurrentToken,
  getToken,
};
