const $ = require('jquery');
const config = require('atomic-config');

const authGetters = require('optly/modules/auth/getters');

const { isImmutable } = require('optly/immutable');

const flux = require('core/flux');
const ui = require('core/ui').default;

const LayerGetters = require('optly/modules/entity/layer/getters').default;
const LayerFns = require('optly/modules/entity/layer/fns').default;
const LayerExperimentActions = require('optly/modules/entity/layer_experiment/actions')
  .default;
const LayerExperimentFns = require('optly/modules/entity/layer_experiment/fns')
  .default;
const PermissionsGetters = require('optly/modules/permissions/getters').default;
const P13NUIActions = require('bundles/p13n/modules/ui/actions').default;
const tr = require('optly/translate');

const {
  VariationSummaryConstants,
} = require('optly/modules/entity/variation/enum');

const getters = require('./getters');
const actionTypes = require('./action_types');
// const { actions: EditorActions } = require('../editor');

/**
 * Sets the currently active side tab in the CampaignManager.
 * @param {CampaignManager.enums.tabs} tab The tab to show.
 */
exports.setTab = function(tab) {
  flux.dispatch(actionTypes.P13N_CAMPAIGN_SELECT_TAB, {
    tab,
  });
};

/**
 * Sets the currently active settings tab within the CampaignManager settings panel.
 * @param {CampaignManager.enums.settingsTabs} tab The tab to show.
 */
exports.setSettingsTab = function(tab) {
  flux.dispatch(actionTypes.P13N_CAMPAIGN_SET_SETTINGS_TAB, {
    tab,
  });
};

/**
 * Sets the dirty state for the indicated tab/subtab.
 * @param {CampaignManager.enums.tabs} tab The tab that is dirty.
 * @param {Boolean} isDirty The state to set for the tab.
 */
exports.setSettingsDirty = function(tab, isDirty) {
  flux.dispatch(actionTypes.P13N_CAMPAIGN_SET_SETTINGS_DIRTY, {
    tab,
    isDirty,
  });
};

/**
 * Resets the dirty states to clean for the settings tabs.
 */
exports.resetSettingsDirty = function() {
  flux.dispatch(actionTypes.P13N_CAMPAIGN_RESET_SETTINGS_DIRTY);
};

/**
 * Sets whether user is currently creating a new variation
 * @param {Boolean} status
 */
exports.setCreatingVariationStatus = function(status) {
  flux.dispatch(actionTypes.P13N_SET_CREATING_VARIATION_STATUS, {
    status,
  });
};

exports.startCreatingVariation = function() {
  flux.dispatch(actionTypes.P13N_START_CREATING_VARIATION);
};

exports.startRenamingVariation = function(variationId) {
  flux.dispatch(actionTypes.P13N_START_RENAMING_VARIATION, {
    variationId,
  });
};

exports.stopRenamingVariation = function() {
  flux.dispatch(actionTypes.P13N_STOP_RENAMING_VARIATION);
};

/**
 * If newName is a valid name for the currently renaming variation, save it and
 * show a loading spinner while saving. Otherwise show a notification with the
 * validation error message.
 * @param {String} newName
 * @param {Immutable.Map} experiment
 * @return {Deferred} The save deferred, or a rejected deferred if the name is
 * invalid
 */
exports.trySavingRenamedVariation = (newName, experiment) => {
  if (!experiment) {
    experiment = flux.evaluateToJS(getters.singleExperiment);
  }
  if (isImmutable(experiment)) {
    experiment = experiment.toJS();
  }
  const validation = LayerExperimentFns.validateVariationName(
    experiment,
    newName,
  );
  if (!validation.valid) {
    ui.showNotification({
      message: validation.message,
      type: 'error',
    });
    return $.Deferred().reject();
  }
  const variationId = flux.evaluate(getters.currentlyRenamingVariationId);
  const saveDef = LayerExperimentActions.renameVariation(
    experiment.id,
    variationId,
    newName,
  ).always(() => {
    exports.stopRenamingVariation();
  });
  ui.loadingWhen('variations-panel', saveDef);
  return saveDef;
};

exports.getRequestError = () => ({
  error: true,
  errorMessage:
    'Sorry, we were not able to generate new suggestions for this content.',
});

exports.filterVariationsToGenerateDescription = variations => {
  return variations.filter(variation => {
    if (
      variation.summaryState !== VariationSummaryConstants.DISABLED &&
      variation.summaryState !== VariationSummaryConstants.EDITED &&
      variation.actions.length
    ) {
      return variation;
    }
  });
};

exports.getVariationIds = variations => {
  return variations.map(variation => variation.variation_id);
};

exports.fetchVariationsSummary = async (experimentId, variations) => {
  const variationSummaryResponse = await fetch('/opti-ai/variation-summary', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'x-csrf-token':
        flux.evaluate(authGetters.csrfToken) || config.get('csrf', ''),
    },
    body: JSON.stringify({
      experimentId,
      variationIds: exports.getVariationIds(variations),
      summaryState: VariationSummaryConstants.GENERATED,
    }),
  });
  return variationSummaryResponse.json();
};

exports.getVariationsSummary = async (experimentId, variations) => {
  try {
    const variationSummary = await exports.fetchVariationsSummary(
      experimentId,
      variations,
    );

    if (!variationSummary.succeeded) {
      return {
        error: true,
        errorMessage: variationSummary.error,
      };
    }

    return (
      variationSummary.variations ?? {
        [variations[0].variation_id]: {
          summary: variationSummary.summary,
          summaryState: variationSummary.summaryState,
        },
      }
    );
  } catch (error) {
    console.error('error', error);
    return exports.getRequestError();
  }
};

exports.addSummaryToExperimentVariations = (variations, variationsObject) => {
  return variations.map(variation => {
    if (variation.variation_id in variationsObject) {
      return {
        ...variation,
        description: variationsObject[variation.variation_id].summary,
        summaryState: variationsObject[variation.variation_id].summaryState,
      };
    }

    return variation;
  });
};

exports.saveSummary = async ({
  currentVariationsList,
  experiment,
  variations,
}) => {
  const experimentId = experiment.layer_experiment_id ?? experiment.id;
  const newVariation = await exports.getVariationsSummary(
    experimentId,
    variations,
  );

  if (newVariation.error) {
    return {
      succeeded: newVariation.succeeded,
      error: newVariation.error,
      errorMessage: newVariation.errorMessage,
    };
  }

  const experimentVariations = exports.addSummaryToExperimentVariations(
    currentVariationsList,
    newVariation,
  );

  return exports.trySavingVariationDescription(
    experiment,
    experimentVariations,
  );
};

/**
 * Save the variation description
 * @param {Object} experiment
 * @param {Array} variations
 * @return {Deferred} The save deferred, or a rejected deferred if the name is
 * invalid
 */
exports.trySavingVariationDescription = (experiment, variations) => {
  let newExperiment = experiment;

  if (!experiment) {
    newExperiment = flux.evaluateToJS(getters.singleExperiment);
  }
  if (isImmutable(experiment)) {
    newExperiment = experiment.toJS();
  }

  return LayerExperimentActions.save({ id: newExperiment.id, variations });
};

/**
 * Use P13NUI.actions.confirmDeleteEntity to open a dialog for deleting a
 * the argument variation
 * @param {Number} variationId
 * @param {Immutable.Map} experiment
 */
exports.openDeleteVariationDialog = (variationId, experiment) => {
  if (!experiment) {
    experiment = flux.evaluate(getters.singleExperiment);
  }
  const variationName = experiment
    .get('variations')
    .find(variation => variation.get('variation_id') === variationId)
    .get('name');
  P13NUIActions.confirmDeleteEntity({
    deleteAction() {
      const saveDef = LayerExperimentActions.deleteVariationById(
        experiment.get('id'),
        variationId,
      );
      ui.loadingWhen('variations-panel', saveDef);
      saveDef.then(() => {
        ui.showNotification({
          message: tr(
            'The variation <b>{0}</b> has been deleted.',
            variationName,
          ),
        });
      });
    },
    entityType: variationName,
    publishWarning: tr('This change cannot be undone.'),
    message: tr('Are you sure you want to delete {0}?', variationName),
    confirmText: tr('Delete Variation'),
    cancelText: tr('Cancel'),
  });
};

/**
 * Open stop variation dialog for the argument variation
 * @param {Number} variationId
 * @param {Immutable.Map} experiment
 * @return {Object}
 */
exports.openStopVariationDialog = (variationId, experiment) => {
  if (!experiment) {
    experiment = flux.evaluate(getters.singleExperiment);
  }
  const toStop = experiment
    .get('variations')
    .find(variation => variation.get('variation_id') === variationId);
  const layer = flux.evaluate(LayerGetters.byId(experiment.layer_id));

  return ui.showReactDialog(
    // TODO: eliminate this global require (circular dependency resolution possibly required)
    // eslint-disable-next-line global-require
    require('bundles/p13n/components/dialogs/stop_variation_dialog').default,
    {
      props: {
        variation: toStop,
        experiment,
        onSaving(saveDef) {
          ui.loadingWhen('variations-panel', saveDef);
        },
        canUseWebStickyBucketing: flux.evaluate(
          PermissionsGetters.canUseWebStickyBucketing,
        ),
        isP13NCampaign: LayerFns.isPersonalizationLayer(layer),
      },
    },
  );
};

/**
 * Duplicate the argument variation and show a loading spinner while the
 * experiment entity is being saved
 * @param {Number} variationId
 * @return {Deferred}
 */
exports.duplicateVariation = variationId => {
  const experimentId = flux.evaluate(getters.singleExperimentId);
  const saveDef = LayerExperimentActions.duplicateVariationById(
    experimentId,
    variationId,
  );
  ui.loadingWhen('variations-panel', saveDef);
  return saveDef;
};

/**
 * Restore the argument variation and show a loading spinner while the
 * experiment entity is being saved
 * @param {Number} variationId
 * @return {Deferred}
 */
exports.restoreVariation = variationId => {
  const experimentId = flux.evaluate(getters.singleExperimentId);
  const saveDef = LayerExperimentActions.restoreVariationById(
    experimentId,
    variationId,
  );
  ui.loadingWhen('variations-panel', saveDef);
  return saveDef;
};

/**
 * Duplicate the argument variation and show a loading spinner while the
 * experiment entity is being saved
 * @param {Number} variationId
 * @param {Immutable.Map} experiment
 * @return {Deferred}
 */
exports.duplicateVariationByExperiment = (variationId, experiment) => {
  if (!experiment) {
    experiment = flux.evaluate(getters.singleExperiment);
  }
  const saveDef = LayerExperimentActions.duplicateVariationById(
    experiment.get('id'),
    variationId,
  );
  ui.loadingWhen('variations-panel', saveDef);
  return saveDef;
};

/**
 * Restore the argument variation and show a loading spinner while the
 * experiment entity is being saved
 * @param {Number} variationId
 * @param {Immutable.Map} experiment
 * @return {Deferred}
 */
exports.restoreVariationByExperiment = (variationId, experiment) => {
  if (!experiment) {
    experiment = flux.evaluate(getters.singleExperiment);
  }
  const saveDef = LayerExperimentActions.restoreVariationById(
    experiment.get('id'),
    variationId,
  );
  ui.loadingWhen('variations-panel', saveDef);
  return saveDef;
};
