/**
 * Sidebar presentational components
 *
 * Example:
 *
 * <Sidebar>
 *   <SidebarHeader
 *      projectName={ currentProject.get('project_name') }
 *      backLinkOnClick={ this.navigateBack }
 *      backLinkText={ this.getBackLinkText() }
 *      showBackLink={ showBackLink }
 *      title={ currentLayer.get('name') }
 *      isFullHeight={ isFullHeight }>
 *      <SidebarDetailsList items={ details } />
 *   </SidebarHeader>
 *   <SidebarNavListBody>
 *     <SidebarNavList label={listName}
 *       testSection='james-list-of-links'>
 *       <SidebarNavListItem href='/james/page1'
 *         testSection='page1-link'>
 *         <span>LINK TO JAMES 1</span>
 *       </SidebarNavListItem>
 *       <SidebarNavListItem href='/james/page2'
 *         isActive={true}
 *         testSection='page2-link'>
 *         <span>LINK TO JAMES 2</span>
 *       </SidebarNavListItem>
 *       <SidebarNavListItem href='/james/page3'
 *         testSection='page3-link'>
 *         <span>LINK TO JAMES 3</span>
 *       </SidebarNavListItem>
 *     </SidebarNavList>
 *   </SidebarNavListBody>
 * </Sidebar>
 *
 */
import {
  ButtonIcon,
  CopyButton,
  Feedback,
  Icon,
  Input,
  Link,
  Poptip,
  Textarea,
} from '@optimizely/axiom';
import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import NativeListener from 'react-native-listener';

import pushStateHandler from 'optly/utils/push_state';

const EditProps = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleEditMode: PropTypes.func.isRequired,
});

const EditableInput = ({ type = 'text', inputRef, editProp, ...props }) => {
  const Component = type === 'text' ? Input : Textarea;

  return (
    <Component
      ref={inputRef}
      type={type}
      focus={true}
      onBlur={e => editProp.handleSubmit(e.target.value)}
      isDisabled={editProp.isLoading}
      onKeyDown={e => {
        // Handling enter key
        if (e.keyCode === 13 || e.key === 'Enter') {
          inputRef?.current?.blur();
          return;
        }
      }}
      {...props}
    />
  );
};

EditableInput.propTypes = {
  type: PropTypes.string,
  inputRef: PropTypes.object.isRequired,
  editProp: EditProps.isRequired,
};

export class Sidebar extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isResizable: PropTypes.bool,
    testSection: PropTypes.string,
  };

  static defaultProps = {
    isResizable: false,
  };

  render() {
    const { isResizable, testSection, children } = this.props;
    return (
      <div
        data-ui-component={true}
        data-test-section={testSection}
        className={classNames({
          'two-col__nav': !isResizable,
          'stage__item__content--column': true,
        })}>
        {children}
      </div>
    );
  }
}

export class SidebarHeader extends React.Component {
  onCopySuccess = this.onCopySuccess.bind(this);

  onFeedbackClose = this.onFeedbackClose.bind(this);

  inputTitleRef = React.createRef();
  inputDescriptionRef = React.createRef();

  state = {
    isFeedbackOpen: false,
    isExpanded: false,
  };

  static propTypes = {
    backLinkOnClick: PropTypes.func.isRequired,
    backLinkText: PropTypes.string.isRequired,
    children: PropTypes.node,
    isFullHeight: PropTypes.bool,
    isFullStackProject: PropTypes.bool,
    isResultsPage: PropTypes.bool,
    projectName: PropTypes.string.isRequired,
    scrollHeader: PropTypes.bool,
    showBackLink: PropTypes.bool,
    spaceBelow: PropTypes.bool,
    title: PropTypes.string,
    titleEdit: PropTypes.oneOfType([EditProps, PropTypes.oneOf([null])]),
    description: PropTypes.string,
    descriptionEdit: PropTypes.oneOfType([EditProps, PropTypes.oneOf([null])]),
    publishStatusText: PropTypes.node,
  };

  static defaultProps = {
    isFullHeight: false,
    isFullStackProject: false,
    showBackLink: true,
    spaceBelow: false,
    title: null,
  };

  onCopySuccess() {
    this.setState({ isFeedbackOpen: true });
  }

  onFeedbackClose() {
    this.setState({ isFeedbackOpen: false });
  }

  renderEditIconButton(shouldEdit, editProp, editFor) {
    // Only show edit icon button if the edit prop is passed and item should be editable
    return (
      shouldEdit &&
      editProp &&
      !editProp.isEditing && (
        <Poptip
          className="flex-self--center protip-item"
          size="small"
          content={`Edit ${editFor}`}
          ariaHasPopup={true}
          position="top"
          trigger="mouseenter">
          <ButtonIcon
            iconName="pen"
            title={'edit'}
            size="small"
            style="unstyled"
            onClick={() => {
              editProp.handleEditMode();
            }}
            testSection={`edit-${editFor}-btn`}
          />
        </Poptip>
      )
    );
  }

  renderSidenavTitle() {
    const { isFullStackProject, isResultsPage, title, titleEdit } = this.props;
    const { isFeedbackOpen } = this.state;
    const maxCharCount = 22;

    return (
      <div className="flex flex--row flex-justified--between protip-content-to-hover">
        {titleEdit && titleEdit.isEditing ? (
          <EditableInput
            inputRef={this.inputTitleRef}
            type="text"
            defaultValue={title}
            editProp={titleEdit}
            testSection={'header-title-input'}
          />
        ) : (
          <h4
            className={classNames(
              'sidenav__header__title flush--bottom force-break weight--bold',
              {
                gamma: !isFullStackProject,
                delta: isFullStackProject && title.length <= maxCharCount,
                zeta: isFullStackProject && title.length > maxCharCount,
                monospace: isFullStackProject,
              },
            )}
            title={title}
            data-test-section="header-title">
            {title}
          </h4>
        )}

        {isResultsPage ? (
          <Poptip
            className="flex-self--end"
            size="small"
            content="Copy experiment name"
            ariaHasPopup={true}
            position="top"
            trigger="mouseenter">
            <CopyButton
              style="plain"
              size="small"
              usesTextLabel={false}
              textToCopy={title}
              onCopy={this.onCopySuccess}
            />
          </Poptip>
        ) : (
          this.renderEditIconButton(!!title, titleEdit, 'title')
        )}
        <Feedback
          type="good-news"
          open={isFeedbackOpen}
          onClose={this.onFeedbackClose}>
          Experiment name copied to clipboard
        </Feedback>
      </div>
    );
  }

  renderSidenavDescription() {
    const descriptionTruncateLimit = 85;
    const { descriptionEdit, description } = this.props;
    const { isExpanded } = this.state;
    const placeholder = 'Add description..';

    return (
      <div className="sidenav__description flex flex--row flex-justified--between protip-content-to-hover">
        {descriptionEdit.isEditing ? (
          <EditableInput
            inputRef={this.inputDescriptionRef}
            type="textarea"
            placeholder={placeholder}
            editProp={descriptionEdit}
            defaultValue={description}
            testSection="sidenav-description-input"
          />
        ) : (
          <div>
            <div
              className={classNames('micro muted', {
                'truncate-text': !isExpanded,
              })}
              onClick={() => descriptionEdit.handleEditMode()}>
              {description}
            </div>
            {/* Truncate the description if it exceeds the limit and show the link to expand it */}
            {description && description.length > descriptionTruncateLimit && (
              <div
                className="link micro"
                onClick={() => this.setState({ isExpanded: !isExpanded })}>
                {`show ${isExpanded ? 'less' : 'more'}`}
              </div>
            )}
            {!description && (
              <div
                className="link micro"
                onClick={() => descriptionEdit.handleEditMode()}>
                {placeholder}
              </div>
            )}
          </div>
        )}
        {this.renderEditIconButton(
          !!description,
          descriptionEdit,
          'description',
        )}
      </div>
    );
  }

  render() {
    const {
      isFullHeight,
      projectName,
      showBackLink,
      backLinkText,
      backLinkOnClick,
      title,
      scrollHeader,
      spaceBelow,
      descriptionEdit,
      publishStatusText,
    } = this.props;

    return (
      <div
        className={classNames({
          sidenav__header: true,
          'sidenav__header--full-height': isFullHeight,
          'overflow-y--auto': scrollHeader,
          'hard--bottom': !spaceBelow,
        })}>
        <div className="micro muted" data-test-section="header-project-name">
          {projectName}
        </div>
        {showBackLink && (
          <div className="push--bottom">
            <NativeListener onClick={backLinkOnClick}>
              <a className="nav-link" data-test-section="header-back-link">
                <Icon
                  className="push-half--right"
                  name="arrow-left"
                  size="small"
                  color={brandBlueDark}
                  fill={brandBlueDark}
                />
                {backLinkText}
              </a>
            </NativeListener>
          </div>
        )}
        {/* Show Publish status text component if passed as props */}
        {publishStatusText}

        {/* showing title only if title props is passed */}
        {title && this.renderSidenavTitle()}

        {/* Showing description edit option only if descriptionEdit props is passed */}
        {descriptionEdit && this.renderSidenavDescription()}

        {this.props.children}
      </div>
    );
  }
}

export const SidebarDetailsList = ({ items, spaceBelow }) => {
  const itemList = items.map(item => {
    if (item.isVisible !== undefined && !item.isVisible) {
      return null;
    }

    return [
      <div
        key={`label-${item.label}`}
        className={classNames({
          sidenav__section__title: true,
          'flush--bottom': true,
          'push--top': true,
          'border--top': item.showBorderTop,
        })}>
        <h6
          className={classNames({
            'push--top': item.showBorderTop,
          })}>
          {item.label}
        </h6>
      </div>,
      <span
        title={item.title}
        key={`value-${item.label}`}
        className="sidenav__section__item">
        {item.value}
      </span>,
    ];
  });

  return (
    <div
      className={classNames({
        sidenav__details: true,
        'soft-double--bottom': spaceBelow,
      })}>
      {itemList}
    </div>
  );
};

SidebarDetailsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]).isRequired,
    }),
  ).isRequired,
  spaceBelow: PropTypes.bool,
};

SidebarDetailsList.defaultProps = {
  spaceBelow: false,
};

export const SidebarNavListBody = props => (
  <div className="sidenav__body">{props.children}</div>
);
SidebarNavListBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SidebarNavList = props => (
  <div className="sidenav__section" data-test-section={props.testSection}>
    <div className="sidenav__section__title">
      <h5>{props.label}</h5>
      {props.rightLabel && <h6>{props.rightLabel}</h6>}
      {props.popover}
    </div>
    <ul className="nav-list no-border--top">{props.children}</ul>
  </div>
);

SidebarNavList.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  popover: PropTypes.node,
  rightLabel: PropTypes.string,
  testSection: PropTypes.string,
};

export const SidebarNavListItem = props => {
  const onClickHandler =
    props.onClick || pushStateHandler.bind(null, props.href);
  return (
    <li
      className={classNames(
        Object.assign(
          {
            'nav-list__link': true,
            'is-active': props.isActive,
          },
          props.classNames,
        ),
      )}
      data-test-section={props.testSection}>
      <Link
        onClick={onClickHandler}
        href={props.href}
        testSection={`${props.testSection}-link`}>
        <div className="soft--ends soft-double--sides">{props.children}</div>
      </Link>
    </li>
  );
};

SidebarNavListItem.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.object,
  href: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  testSection: PropTypes.string,
};

export const GroupedSidebarNavList = props => (
  <div
    key={'grouped-sidenav' + props.label}
    className="sidenav__grouped__section"
    data-test-section={props.testSection}>
    <div
      key={props.label}
      className="flex flex-justified--between flex-align--center grouped-nav-header"
      onClick={() => props.onToggle(props.label)}>
      <span
        className={classNames('grouped-nav-label', {
          isActive: props.isActive,
        })}>
        {props.label}
      </span>
      <span
        className={
          props.isActive ? 'lego-arrow-inline--down' : 'lego-arrow-inline--up'
        }
      />
    </div>
    <ul
      className={classNames('grouped-nav-list', {
        isActive: props.isActive,
      })}>
      {props.children}
    </ul>
  </div>
);

GroupedSidebarNavList.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  testSection: PropTypes.string,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

export default {
  Sidebar,
  SidebarHeader,
  SidebarNavList,
  SidebarNavListItem,

  SidebarDetailsList,
  SidebarNavListBody,
  GroupedSidebarNavList,
};
