/* eslint-disable class-methods-use-this */

import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Textarea, Icon } from 'optimizely-oui';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import SegmentTracking from 'optly/modules/segment';
import ui from 'core/ui';
import {
  actions as EditorActions,
  constants as EditorConstants,
} from 'bundles/p13n/modules/editor';
import LoadingOverlay from 'react_components/loading_overlay';
import FormatFilterSize from 'optly/filters/format_file_size';

import { Features } from 'optly/utils/enums';

import constants from './constants';

export default class ImageInputManager extends React.Component {
  static propTypes = {
    imageEditingEnabled: PropTypes.bool.isRequired,
    isEditingSelector: PropTypes.bool.isRequired,
    isEditorReadOnly: PropTypes.bool.isRequired,
    onImageChange: PropTypes.func.isRequired,
    setupChange: PropTypes.func.isRequired,
  };

  state = {
    url: '',
    image: { ...EditorConstants.IMAGE_PROPERTIES },
    selectedMethod: '',
    uploading: false, // Track the uploading state
    shouldShowDamPicker: false,
    shouldEnableDamPicker: false,
    instances: [],
  };

  componentDidMount() {
    const { vueEvents } = this.context;
    const { setupChange } = this.props;
    window.addEventListener('message', this.handleChooseFromDAM, false);

    if (vueEvents) {
      vueEvents.$on(
        constants.EventTypes.CALCULATE_IMAGE_PORPERTIES,
        this.handleCalculateImageProperties,
      );
      vueEvents.$on(constants.EventTypes.SET_IMAGE_URL, this.setValue);
      setupChange();
    }

    const externalPermissions =
      window.optlyConfig?.account_info?.permissions?.externalPermissions;

    if (externalPermissions?.cmp) {
      this.setState({ shouldShowDamPicker: true });

      const instances = Object.entries(externalPermissions.cmp)
        .filter(
          ([instanceId, instanceObj]) =>
            instanceObj.user_permissions?.includes('dam_picker') &&
            instanceObj.internal_id, // CMP internal id is required for dam picker to work
        )
        .map(([instanceId, instanceObj]) => instanceObj.internal_id);

      if (instances.length !== 0) {
        this.setState({ shouldEnableDamPicker: true, instances });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleChooseFromDAM, false);
  }

  setValue = url => {
    this.setState({ url });
  };

  /**
   * Emit an event whenever the image changes with the new URL.
   */
  onImageUrlChanged = event => {
    const { onImageChange } = this.props;
    this.setState({
      url: event.target.value,
      image: { ...EditorConstants.IMAGE_PROPERTIES },
    });
    SegmentTracking.tracking.trackEvent('[image source] - url_change', {
      image_url: event.target.value,
    });
    onImageChange(event.target.value);
  };

  /**
   * Initialize the image object based on the provided displayValue.
   * @param displayValue
   */
  handleCalculateImageProperties = displayValue => {
    let image;
    const { image: imageFromState } = this.state;
    const imageObject = new Image();
    const { imageEditingEnabled } = this.props;
    if (!imageEditingEnabled) {
      image = { ...EditorConstants.IMAGE_PROPERTIES }; // Reset everything
    } else {
      image = { ...imageFromState };
    }
    const previousDisplayValue = image.displayValue;
    image.displayValue = displayValue;

    if (previousDisplayValue !== image.displayValue) {
      EditorActions.setImagePropertiesReact(image).finally(() => {
        imageObject.src = image.absoluteUrl;
        imageObject.onload = this.onImageLoad;
        imageObject.onerror = this.onImageError;
        this.setState({
          image,
          url: image.displayValue,
        });
      });
    } else {
      this.setState({
        image,
        url: image.displayValue,
      });
    }
  };

  /**
   * To be called on the change event for an file input element. Uploads the local image
   * supplied by the user to the optimizely CDN and sets the url of the that image in the
   * change store.
   *
   * @param {Event} event Change event
   * @return nothing
   */
  uploadImageAndSetAsSource = event => {
    const { onImageChange } = this.props;
    const { files } = event.target;

    if (files.length) {
      const file = files[0];
      this.setState({ uploading: true }); // Add state to indicate upload is in progress

      EditorActions.uploadImage(file)
        .then(url => {
          onImageChange(url);
          this.setState({
            url, // Store the URL in state
            uploading: false, // Set uploading to false when completed
          });
          SegmentTracking.tracking.trackEvent(
            '[image source] [file input] - image uploaded',
            {
              image_url: url,
            },
          );
        })
        .catch(error => {
          // Handle error scenario possibly with a notification
          ui.showNotification({
            type: 'error',
            message:
              error.message ||
              'There was an error uploading your image. Please try again.',
          });
          this.setState({ uploading: false }); // Ensure state is correctly updated in error case
        });
      // If there is a utility method managing loading states, hook into it.
      ui.loadingWhen('img-upload', file);
    }
  };

  // Scale down to thumbnail size while keeping proportions
  getProportionalHeight = image =>
    image.height > image.width
      ? constants.THUMBNAIL_MAX_DIMENSION
      : (constants.THUMBNAIL_MAX_DIMENSION / image.width) * image.height;

  // Scale down to thumbnail size while keeping proportions
  getProportionalWidth = image =>
    image.height > image.width
      ? (constants.THUMBNAIL_MAX_DIMENSION / image.height) * image.width
      : constants.THUMBNAIL_MAX_DIMENSION;

  onImageLoad = ({ target: imageObject }) => {
    this.setState(prevState => ({
      image: {
        ...prevState.image,
        height: imageObject.height,
        width: imageObject.width,
        thumbnailHeight: this.getProportionalHeight(imageObject),
        thumbnailWidth: this.getProportionalWidth(imageObject),
      },
    }));
  };

  onImageError = ({ target: imageObject }) => {
    // if we hit this it's probably because this is an invalid url
    const { image: imageFromState } = this.state;
    if (imageFromState.src === imageObject.absoluteUrl) {
      this.setState(prevState => ({
        image: {
          ...prevState.image,
          width: 0,
          height: 0,
          thumbnailWidth: 0,
          thumbnailHeight: 0,
        },
      }));
    }
  };

  handlePickerChange = event => {
    const method = event.target.value;
    this.setState({ selectedMethod: method });
    switch (method) {
      case 'dam':
        this.openDAMPicker();
        SegmentTracking.tracking.trackEvent('[image source] [DAM] - open');
        break;
      case 'upload':
        if (this.fileInputRef) {
          this.fileInputRef.click();
          SegmentTracking.tracking.trackEvent(
            '[image source] [file input] - open',
          );
        }
        break;
      default:
        break;
    }
  };

  handleChooseFromDAM = event => {
    if (event.origin === constants.CMP_URL) {
      if (
        Array.isArray(event.data) &&
        event.data.length > 0 &&
        event.data[0].url
      ) {
        this.setState({ url: event.data[0].url, selectedMethod: '' });
        this.props.onImageChange(event.data[0].url);
        SegmentTracking.tracking.trackEvent(
          '[image source] [DAM] - image selected',
          {
            image_url: event.data[0].url,
          },
        );
      } else {
        console.error('Invalid event data structure:', event.data);
      }
    } else {
      console.error('Invalid event origin:', event.origin);
    }
  };

  openDAMPicker = () => {
    // Options for the library picker
    const { instances } = this.state;
    const options = {
      instanceIds: instances,
      assetTypes: ['image'],
      multiSelect: false,
    }; // can be passed as {} if no options are required
    // Encode the options for passing as a query parameter
    const encodedOptions = window.btoa(JSON.stringify(options));

    const queryParams = instances ? `?pickerOptions=${encodedOptions}` : '';

    // Open DAM picker logic here
    window.open(
      `${constants.CMP_URL}/cloud/library-picker${queryParams}`,
      'Library',
      'popup',
      'width=600,height=400',
    );
  };

  render() {
    const {
      image,
      url,
      selectedMethod,
      shouldShowDamPicker,
      shouldEnableDamPicker,
    } = this.state;
    const {
      isEditingSelector,
      isEditorReadOnly,
      imageEditingEnabled,
    } = this.props;
    const style = {
      backgroundPosition: 'center center',
      backgroundImage: `url( ${image.absoluteUrl} )`,
      backgroundSize: `${image.thumbnailWidth}px ${image.thumbnailHeight}px`,
    };

    return (
      <LoadingOverlay
        isIdLoading={this.state.uploading}
        loadingId="img-upload"
        className="img-upload">
        <div className="img-upload__data">
          <div className="img-upload__img" style={style} />
          <div className="img-upload__stats flex--1 force-break">
            <ul>
              <li data-test-section="image-filename">{image.filename}</li>
              <li className="muted" data-test-section="image-size">
                {FormatFilterSize(image.size)}
              </li>
              <li className="muted" data-test-section="image-dimensions">
                {`${image.width} x ${image.height}`}
              </li>
            </ul>
          </div>
        </div>
        <div className="push-half--bottom">
          <Textarea
            value={url}
            onChange={this.onImageUrlChanged}
            testSection="image-input-textarea"
            isDisabled={isEditingSelector || isEditorReadOnly}
          />
        </div>
        <div>
          {isFeatureEnabled(Features.DAM_PICKER) && shouldShowDamPicker ? ( // Check if org has CMP instance
            <div>
              <select
                onChange={this.handlePickerChange}
                value={selectedMethod}
                style={{
                  padding: '5px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
                data-test-section="image-input-manager-select">
                <option
                  value=""
                  data-test-section="image-input-manager-select-select-image">
                  {tr('Select Image')}
                </option>
                <option
                  value="upload"
                  data-test-section="image-input-manager-select-upload-image">
                  {tr('Upload Image')}
                </option>
                <option
                  value="dam"
                  disabled={!shouldEnableDamPicker}
                  data-test-section="image-input-manager-select-dam-picker">
                  Choose from DAM
                </option>
              </select>
              <input
                type="file"
                ref={ref => {
                  this.fileInputRef = ref;
                }}
                onChange={this.uploadImageAndSetAsSource}
                style={{ display: 'none' }}
              />
            </div>
          ) : (
            <div>
              <span className="file-input">
                <input
                  type="file"
                  onChange={this.uploadImageAndSetAsSource}
                  disabled={isEditingSelector || !imageEditingEnabled}
                />
                <span
                  className={classNames(
                    'oui-button',
                    'anchor--left',
                    'oui-button--small',
                    'oui-button--outline',
                    {
                      'oui-button--disabled':
                        isEditingSelector || !imageEditingEnabled,
                    },
                  )}>
                  <div className="flex">
                    <span className="push-half--right flex--dead-center">
                      <Icon name="upload" size="small" />
                    </span>
                    {tr('Upload New')}
                  </div>
                </span>
              </span>
            </div>
          )}
        </div>
      </LoadingOverlay>
    );
  }
}
