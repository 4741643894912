import { Button } from '@optimizely/axiom';
import React from 'react';
import PropTypes from 'prop-types';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

function GetTestIdeasUsingOpal({ track, ...props }) {
  const getTestIdeasUsingOpal = () => {
    const customEvent = new CustomEvent('TestIdeasUsingOpal');
    document.dispatchEvent(customEvent);

    const segmentParams = {
      buttonName: 'Get Test Ideas',
      ...props,
    };

    // Track when the button is clicked
    track('Get Test Ideas Using Opal', segmentParams);
  };

  return (
    <Button
      data-test-section="get-test-ideas-using-opal-button"
      style="outline"
      leftIcon="sparkles"
      onClick={getTestIdeasUsingOpal}>
      Get Test Ideas
    </Button>
  );
}
GetTestIdeasUsingOpal.propTypes = {
  track: PropTypes.func.isRequired,
};

export default withTrack(GetTestIdeasUsingOpal);
