import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';

import Immutable from 'optly/immutable';

import { connect } from 'core/ui/decorators';

import { getters as PublishStatusGetters } from 'bundles/p13n/modules/publish_status';
import { fns as LayerFns } from 'optly/modules/entity/layer';
import LayerGetters from 'optly/modules/entity/layer/getters';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';


@connect(props => {
  const { campaignType, layerId } = props;

  return {
    isChangingStatus: PublishStatusGetters.isChangingStatus(layerId),
    publishStatusText: PublishStatusGetters.publishStatusText(
      layerId,
      campaignType,
    ),
    layerExperiment: LayerGetters.singleExperimentByLayerId(layerId),
  };
})
class PublishStatusText extends React.Component {
  static propTypes = {
    campaignType: PropTypes.string.isRequired,
    isChangingStatus: PropTypes.bool.isRequired,
    layerExperiment: PropTypes.instanceOf(Immutable.Map).isRequired,
    layerId: PropTypes.number.isRequired,
    publishStatusText: PropTypes.string.isRequired,
  };

  render() {
    const { isChangingStatus, publishStatusText, layerExperiment } = this.props;

    return (
      <React.Fragment>
        {isChangingStatus && (
          <div
            className="lego-spinner lego-spinner--tiny"
            data-test-section="campaign-overview-sidebar-spinner"
          />
        )}
        <div
          className={classNames('micro muted push-half--right',{
            'push-half--left': isChangingStatus,
          })}
          data-test-section="campaign-overview-sidebar-publish-status">
          {LayerExperimentFns.isStatsAcceleratorExperiment(layerExperiment) &&
          !LayerFns.isPersonalizationLayer
            ? 'Stats Accelerator Test'
            : publishStatusText}
        </div>
      </React.Fragment>
    );
  }
}

export default PublishStatusText;
