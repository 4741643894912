/* eslint-disable class-methods-use-this */
import classNames from 'classnames';

import PropTypes from 'prop-types';

import React from 'react';

import { Icon } from 'optimizely-oui';

import { api as SegmentJSAPI } from '@optimizely/segment-js';

import events from 'optly/services/events';
import flux from 'core/flux';

import Immutable from 'optly/immutable';
import ui from 'core/ui';
import urls from 'optly/services/url_helper_legacy';
import urlUtil from 'optly/utils/url';

import {
  constants as EditorConstants,
  getters as EditorGetters,
} from 'bundles/p13n/modules/editor';
import { fns as LayerFns } from 'optly/modules/entity/layer';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import { fns as ProjectFns } from 'optly/modules/entity/project';
import UrlHelper from 'optly/services/url_helper';
import { getters as V2MigrationGetters } from 'optly/modules/v2_migration';
import ShareLinkReactComponent from 'bundles/p13n/components/dialogs/share_link';

import {
  Dropdown,
  DropdownContents,
  DropdownListItem,
} from 'react_components/dropdown';
import Link from 'react_components/link';

class PreviewButton extends React.Component {
  static propTypes = {
    currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentLayerExperimentOrSection: PropTypes.instanceOf(Immutable.Map)
      .isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    selectedView: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  registerMarketoPreview = () => {
    events.trackMarketoEvent(
      'x_editor_sidebar',
      'preview',
      'experiment',
      this.props.currentLayerExperimentOrSection.get('id'),
    );
  };

  disablePreviewButton = () => {
    const {
      currentLayer,
      currentLayerExperimentOrSection,
      currentProject,
      selectedView,
    } = this.props;

    const isUsingV2Snippet = ProjectFns.isUsingV2Snippet(currentProject);
    const isArchived =
      (currentLayer && currentLayer.get('archived')) ||
      (currentLayerExperimentOrSection &&
        currentLayerExperimentOrSection.get('status') ===
          LayerExperimentEnums.status.ARCHIVED);
    return !selectedView || !isUsingV2Snippet || isArchived;
  };

  handleShareLinkClick = () => {
    ui.showReactDialog(
      ShareLinkReactComponent,
      {
        dataBindings: {
          shareLinkUrl: EditorGetters.previewUrlForActionWithoutTool,
        },
      },
      {
        fullScreen: false,
      },
    );
  };

  onClickSidebarPreview = () => {
    SegmentJSAPI.track('Preview Mode');
  };

  render() {
    const { currentProject } = this.props;
    const isUsingV2Snippet = ProjectFns.isUsingV2Snippet(currentProject);
    const previewLinkClasses = classNames({
      'oui-button': true,
      'oui-button--highlight': true,
      'oui-button--full': true,
      'pointer-events--none': this.disablePreviewButton(),
    });

    return (
      <div>
        <div
          className="flex lego-button-group sidebar-preview-button push--bottom"
          onClick={this.onClickSidebarPreview}
          data-test-section="change-list-preview">
          <a
            className={previewLinkClasses}
            href={flux.evaluate(EditorGetters.previewUrlForAction)}
            onClick={this.registerMarketoPreview}
            target="_blank"
            rel="noopener noreferrer"
            data-test-section="change-list-preview-link"
            disabled={this.disablePreviewButton()}>
            Preview
          </a>

          <Dropdown
            activator={
              <button
                type="button"
                className="oui-button oui-button--highlight"
                disabled={this.disablePreviewButton()}
                data-test-section="change-list-preview-dropdown-button"
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  marginLeft: '2px',
                }}>
                <span className="flush--left">
                  <Icon name="angle-down" size="small" />
                </span>
              </button>
            }>
            <DropdownContents>
              <DropdownListItem>
                <a
                  className="lego-dropdown__block-link nowrap"
                  href={flux.evaluate(EditorGetters.previewUrlForAction)}
                  target="_blank"
                  rel="noopener noreferrer">
                  Preview Tool
                </a>
              </DropdownListItem>
              <DropdownListItem>
                <a
                  className="lego-dropdown__block-link nowrap"
                  onClick={this.handleShareLinkClick}
                  data-test-section="share-link">
                  Share Link
                </a>
              </DropdownListItem>
            </DropdownContents>
          </Dropdown>
        </div>
        {!isUsingV2Snippet && (
          <div
            className="lego-media soft--top"
            data-test-section="turn-on-v2-snippet-text">
            <Icon
              name="circle-exclamation"
              size="small"
              className="oui-icon color--brand lego-media__img"
            />
            <div className="lego-media__body micro">
              Please{' '}
              <Link
                href={UrlHelper.projectSettingsImplementation(
                  currentProject.id,
                )}>
                {' '}
                enable {flux.evaluate(V2MigrationGetters.v2PlatformText)}{' '}
              </Link>{' '}
              in your snippet to use the preview function.
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PreviewButton;
