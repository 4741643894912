import React from 'react';
import classNames from 'classnames';
import { Icon, Button, Poptip } from 'optimizely-oui';

export default function CustomizationButton(props) {
  return (
    <Poptip className="custom-main-content-poptip" content="Show/hide columns">
      <Button
        testSection="custom-main-content-poptip-gear-button"
        size="narrow"
        {...props}
        className={classNames(
          'button--outline oui-button--outline',
          props.className,
        )}>
        <Icon name="columns-3" size="small" className="push--top" />
      </Button>
    </Poptip>
  );
}
