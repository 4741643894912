import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'core/ui/decorators';

import {
  actions as HypothesisLinkingActions,
  getters as HypothesisLinkingGetters,
} from 'bundles/p13n/modules/hypothesis_linking';

import HypothesisLinkSearchPicker from 'bundles/p13n/components/hypothesis_link_search_picker';
import HypothesisForm from 'bundles/p13n/components/hypothesis_form';

@connect({
  shouldCreateHypothesis: HypothesisLinkingGetters.shouldCreateHypothesis,
})
class HypothesisLink extends Component {
  static propTypes = {
    currentProjectId: PropTypes.number.isRequired,
    shouldCreateHypothesis: PropTypes.bool,
  };

  static defaultProps = {
    shouldCreateHypothesis: false,
  };

  componentWillUnmount() {
    HypothesisLinkingActions.shouldCreateHypothesis(false);
  }

  render() {
    const { shouldCreateHypothesis, currentProjectId } = this.props;

    return (
      <div data-testid="hypothesis" className="push-double--bottom">
        <HypothesisLinkSearchPicker projectId={currentProjectId} />
        <div className="push-triple--bottom" />

        {shouldCreateHypothesis && <HypothesisForm />}
      </div>
    );
  }
}

export default HypothesisLink;
