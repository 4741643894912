import React from 'react';

import flux from 'core/flux';

import NavConstants from 'optly/services/navigation';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import { constants as P13NUIConstants } from 'bundles/p13n/modules/ui';
import PermissionsGetters from 'optly/modules/permissions/getters';

import RoutingFns from '../../routing_fns';

/*
 * Routing functions that ensure required dynamic bundles have been fetched
 * We store the returned dynamic bundle module on the function object itself
 */
function PluginsSection(ctx, next) {
  import('bundles/p13n/sections/plugin_builder')
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(PluginsSection, module);
    })
    .then(next);
}

/*
 * Routes for the plugins section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/settings/integrations/:plugin_id',
    metadata: {
      name: 'Analytics Integration Editor',
      category: 'Project Settings',
    },
    handle: [
      [
        PluginsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.setActiveTab(
        NavConstants.NavItems.SETTINGS,
        NavConstants.SettingsTabs.INTEGRATIONS_TAB,
      ),
      (ctx, next) => PluginsSection.routingSetup(ctx, next),
      (ctx, next) => PluginsSection.pages.PluginEditor.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Analytics Integration Editor',
          category: 'Project Settings',
        }),
      () => {
        RoutingFns.renderMainRegion(
          PluginsSection.pages.PluginEditor.component,
          {
            options: {
              previousView: P13NUIConstants.PreviousViews.INTEGRATIONS,
            },
          },
        );
      },
    ],
  },
  /**
   TODO: Its added temporarily, just for the testing purpose
   on reactified version of Plugin Editor
   */
  {
    match: '/v2/projects/:proj_id/settings/integrations_react/:plugin_id',
    handle: [
      [
        PluginsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.setActiveTab(
        NavConstants.NavItems.SETTINGS,
        NavConstants.SettingsTabs.INTEGRATIONS_TAB,
      ),
      (ctx, next) => PluginsSection.routingSetup(ctx, next),
      (ctx, next) =>
        PluginsSection.pagesReact.PluginEditorReact.routingSetup(ctx, next),
      () => {
        RoutingFns.renderMainRegion(
          <PluginsSection.pagesReact.PluginEditorReact.component
            previousView={P13NUIConstants.PreviousViews.INTEGRATIONS}
          />,
        );
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/implementation/extensions',
    shouldHandle: () =>
      Promise.resolve(flux.evaluate(PermissionsGetters.canUsePlugins)),
    metadata: { name: 'Extensions', category: 'Implementation' },
    handle: [
      [
        PluginsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PAGES,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.authenticatePlugins,
      RoutingFns.setActiveTab(
        NavConstants.NavItems.PAGES,
        NavConstants.DataLayerTabs.PLUGIN_TAB,
      ),
      RoutingFns.parseQueryFilters(
        FilterableTableEnums.tableIds.P13N_DATA_LAYER_PLUGINS,
      ),
      (ctx, next) => PluginsSection.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Templates',
          category: 'Implementation',
        }),
      () =>
        RoutingFns.renderMainRegion(
          <PluginsSection.pages.Dashboard.component />,
        ),
    ],
  },
  {
    match: '/v2/projects/:proj_id/extensions/:plugin_id',
    metadata: { name: 'Extensions Visual Editor', category: 'Implementation' },
    handle: [
      [
        PluginsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PAGES,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.authenticatePlugins,
      RoutingFns.setActiveTab(
        NavConstants.NavItems.PAGES,
        NavConstants.DataLayerTabs.PLUGIN_TAB,
      ),
      (ctx, next) => PluginsSection.routingSetup(ctx, next),
      (ctx, next) => PluginsSection.pages.PluginEditor.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Extensions',
          category: 'Implementation',
        }),
      () => {
        RoutingFns.renderMainRegion(
          PluginsSection.pages.PluginEditor.component,
          {
            options: {
              previousView: P13NUIConstants.PreviousViews.EXTENSIONS,
            },
          },
        );
      },
    ],
  },
  /**
   TODO: Its added temporarily, just for the testing purpose
   on reactified version of Plugin Editor
   */
  {
    match: '/v2/projects/:proj_id/extensions_react/:plugin_id',
    handle: [
      [
        PluginsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PAGES,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.authenticatePlugins,
      RoutingFns.setActiveTab(
        NavConstants.NavItems.PAGES,
        NavConstants.DataLayerTabs.PLUGIN_TAB,
      ),
      (ctx, next) => PluginsSection.routingSetup(ctx, next),
      (ctx, next) =>
        PluginsSection.pagesReact.PluginEditorReact.routingSetup(ctx, next),
      () => {
        RoutingFns.renderMainRegion(
          <PluginsSection.pagesReact.PluginEditorReact.component
            previousView={P13NUIConstants.PreviousViews.EXTENSIONS}
          />,
        );
      },
    ],
  },
];

export default routes;
