/* eslint-disable class-methods-use-this */
import PropTypes from 'prop-types';
import React from 'react';
import htmlSanitizer from 'sanitizer';

import { Button, Sheet } from 'optimizely-oui';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Immutable from 'optly/immutable';

import Router from 'core/router';
import ui from 'core/ui';
import UrlHelper from 'optly/services/url_helper';

import { actions as SentryActions } from 'optly/modules/sentry';
import { connect } from 'core/ui/decorators';

import LoadingOverlay from 'react_components/loading_overlay';
import LayerSettings from 'bundles/p13n/components/layer_settings';
import MVTSectionsComponent from 'bundles/p13n/components/experiment_editing/mvt_sections';

import {
  actions as CurrentlyEditingExperimentActions,
  enums as CurrentlyEditingExperimentEnums,
  getters as CurrentlyEditingExperimentGetters,
} from 'bundles/p13n/modules/currently_editing_experiment';
import {
  getters as HypothesisLinkingGetters,
  fns as HypothesisLinkingFns,
} from 'bundles/p13n/modules/hypothesis_linking';
import HypothesisLinkingConstants from 'bundles/p13n/modules/hypothesis_linking/constants';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import {
  actions as LayerActions,
  constants as LayerConstants,
  enums as LayerEnums,
} from 'optly/modules/entity/layer';
import { fns as PermissionsFns } from 'optly/modules/permissions';
import { actions as ViewActions } from 'optly/modules/entity/view';
import HypothesisLink from 'bundles/p13n/components/hypothesis_link';

import Targeting from '../ab_mvt_targeting';

const LOADING_ID = 'mvt-layer-detail';
@withTrack
@connect({
  canUpdateLayer: [
    CurrentProjectGetters.project,
    PermissionsFns.canUpdateLayer,
  ],
  currentProjectId: CurrentProjectGetters.id,
  errors: CurrentlyEditingExperimentGetters.errors,
  experimentSectionsToCreateForMVT:
    CurrentlyEditingExperimentGetters.experimentSectionsToCreateForMVT,
  layerDataToSave: CurrentlyEditingExperimentGetters.layerDataToSave(
    LayerEnums.policy.MULTIVARIATE,
  ),
  hypothesisToLink: HypothesisLinkingGetters.hypothesisToLink,
  hypothesisToCreate: HypothesisLinkingGetters.hypothesisToCreate,
  openInNewTabAfterHypothesisCreation:
    HypothesisLinkingGetters.openInNewTabAfterHypothesisCreation,
  layerExperimentToCreateForMVT:
    CurrentlyEditingExperimentGetters.layerExperimentToCreateForMVT,
  name: CurrentlyEditingExperimentGetters.name,
  targetingType: CurrentlyEditingExperimentGetters.targetingType(
    LayerEnums.policy.MULTIVARIATE,
  ),
})
class MVTExperimentDialog extends React.Component {
  static propTypes = {
    canUpdateLayer: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    errors: PropTypes.instanceOf(Immutable.Map).isRequired,
    experimentSectionsToCreateForMVT: PropTypes.object.isRequired,
    hypothesisToCreate: PropTypes.object,
    hypothesisToLink: PropTypes.object,
    layerDataToSave: PropTypes.object.isRequired,
    layerExperimentToCreateForMVT: PropTypes.object.isRequired,
    name: PropTypes.string,
    openInNewTabAfterHypothesisCreation: PropTypes.bool,
    targetingType: PropTypes.string.isRequired,
    track: PropTypes.func.isRequired,
  };

  static defaultProps = {
    name: '',
    hypothesisToCreate: {},
    hypothesisToLink: null,
    openInNewTabAfterHypothesisCreation: false,
  };

  constructor(props) {
    super(props);

    CurrentlyEditingExperimentActions.init({
      mode: CurrentlyEditingExperimentEnums.modes.CREATE,
      isMultivariateTest: true,
    });
  }

  clearNameError = () => {
    CurrentlyEditingExperimentActions.setErrors({
      name: '',
    });
  };

  fetchNewlyCreatedViewIfNecessaryAndCompleteSave = (policy, newLayer) => {
    const { currentProjectId, targetingType } = this.props;
    let fetchDeferred;

    if (targetingType === LayerConstants.TargetingTypes.URL) {
      fetchDeferred = ViewActions.fetch(newLayer.url_targeting.view_id);
    } else {
      fetchDeferred = $.Deferred().resolve();
    }
    fetchDeferred.then(() => {
      const url = UrlHelper.campaignHome(currentProjectId, newLayer.id);
      ui.hideDialog().then(() => {
        Router.go(url);
      });
    });
  };

  linkHypothesis = async (experimentId, experimentType) => {
    const {
      hypothesisToLink,
      hypothesisToCreate,
      currentProjectId,
      openInNewTabAfterHypothesisCreation,
    } = this.props;

    if (hypothesisToCreate?.name) {
      const response = await HypothesisLinkingFns.createAndLinkHypothesis(
        hypothesisToCreate,
        experimentId,
        currentProjectId,
        experimentType,
      );

      if (response?.id && openInNewTabAfterHypothesisCreation) {
        window.open(
          `${HypothesisLinkingConstants.CMP_BASE_URL}/cloud/hypothesis/${response?.id}?activeTab=brief`,
          '_blank',
        );
      }
    } else {
      HypothesisLinkingFns.linkHypothesis(
        hypothesisToLink,
        experimentId,
        currentProjectId,
        experimentType,
      );
    }
  };

  saveMultivariateTest = () => {
    const {
      experimentSectionsToCreateForMVT,
      layerDataToSave,
      layerExperimentToCreateForMVT,
      name,
    } = this.props;

    const sanitizedName = htmlSanitizer.escape(name);

    const validationErrorsMap = CurrentlyEditingExperimentActions.validateFormDataOnSave(
      LayerEnums.policy.MULTIVARIATE,
    );

    if (Object.values(validationErrorsMap).some(error => !!error)) {
      Object.values(validationErrorsMap).forEach(message => {
        if (message) {
          ui.showNotification({
            message,
            type: 'error',
          });
        }
      });
      return;
    }

    try {
      const saveDef = LayerActions.createMultivariateTestLayer(
        layerDataToSave,
        layerExperimentToCreateForMVT,
        experimentSectionsToCreateForMVT,
      ).then(({ newLayer, experimentId }) => {
        const { track } = this.props;
        const segmentParams = {
          type: newLayer.type,
          experimentId: newLayer.id,
          experimentName: newLayer.name,
        };
        ui.showNotification({
          message: tr(
            'The multivariate test <b>{0}</b> has been created.',
            sanitizedName,
          ),
        });
        this.fetchNewlyCreatedViewIfNecessaryAndCompleteSave(
          LayerEnums.policy.MULTIVARIATE,
          newLayer,
        );
        track('Experiment Created', segmentParams);

        // Link and Create CMP Hypothesis
        this.linkHypothesis(experimentId, newLayer.type);
      });

      ui.loadingWhen(LOADING_ID, saveDef);
    } catch (e) {
      ui.showNotification({
        type: 'error',
        message:
          'There was an error creating a new Multivariate Test. Please refresh the page and try again.',
      });

      SentryActions.withScope(scope => {
        scope.setExtra('layerData', layerDataToSave);
        SentryActions.captureException(e);
      });
    }
  };

  render() {
    const { canUpdateLayer, errors, currentProjectId } = this.props;

    return (
      <Sheet
        testSection="layer-detail"
        title="New Multivariate Test"
        hasRequiredFieldsIndicator={true}
        onClose={ui.hideDialog}
        footerButtonList={[
          <Button
            key="cancel"
            onClick={ui.hideDialog}
            style="plain"
            testSection="cancel-button">
            Cancel
          </Button>,
          <Button
            data-track-id="2.0-Campaign-CreateNewCampaign.CreateCampaign"
            isDisabled={!canUpdateLayer}
            key="create"
            onClick={this.saveMultivariateTest}
            style="highlight"
            testSection="save-experiment-button">
            Create Experiment
          </Button>,
        ]}>
        <LoadingOverlay loadingId={LOADING_ID}>
          <LayerSettings
            nameError={errors.get('name')}
            onNameChange={this.clearNameError}
          />
          {isFeatureEnabled('hypothesis_linking') && (
            <HypothesisLink currentProjectId={currentProjectId} />
          )}
          <div className="push-quad--bottom">
            <Targeting />
          </div>
          <MVTSectionsComponent
            onAddSection={CurrentlyEditingExperimentActions.addSection}
            onRemoveSection={CurrentlyEditingExperimentActions.removeSection}
            onUpdateSectionName={
              CurrentlyEditingExperimentActions.updateSectionName
            }
            onAddVariationToSection={
              CurrentlyEditingExperimentActions.addVariationToSection
            }
            onRemoveVariationFromSection={
              CurrentlyEditingExperimentActions.removeVariationFromSection
            }
            onUpdateVariationInSection={
              CurrentlyEditingExperimentActions.updateVariationInSection
            }
          />
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default MVTExperimentDialog;
