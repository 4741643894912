/* eslint-disable camelcase */
import { Set } from 'immutable';

import { constants as MetricConstants } from 'optly/modules/entity/metric';

import { toImmutable, toJS } from 'optly/immutable';
import guid from 'optly/utils/guid';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import flux from 'core/flux';

import {
  BOOLEAN_OPERATORS,
  GLOBAL_EVENTS,
  METRIC_TYPES,
  NUMERIC_OPERATORS,
  STRING_OPERATORS,
  COMPOUND_METRIC_TYPE,
} from './constants';

/**
 * Gets the event according to the metricType.
 * Click and page events are maps, so we have to make them arrays
 */
export function getEventsByType({
  metricType,
  clickEvents,
  customEvents,
  pageEvents,
}) {
  const events = {
    [METRIC_TYPES.CLICK]: Object.values(toJS(clickEvents)),
    [METRIC_TYPES.CUSTOM]: toJS(customEvents),
    [METRIC_TYPES.PAGEVIEW]: Object.values(toJS(pageEvents)),
    [METRIC_TYPES.GLOBAL]: GLOBAL_EVENTS,
    [COMPOUND_METRIC_TYPE]: [
      ...toJS(customEvents),
      ...Object.values(toJS(clickEvents)),
      ...Object.values(toJS(pageEvents)),
    ],
  };
  if (events[metricType]) {
    events[metricType].sort((a, b) =>
      a.name.toUpperCase().localeCompare(b.name.toUpperCase()),
    );
  }
  return events[metricType] || [];
}

export function getDefaultAggregatorValue(event) {
  if (event.id === 'revenue') {
    return MetricConstants.aggregationOptions.TOTAL_REVENUE;
  }

  return MetricConstants.aggregationOptions.UNIQUE_CONVERSIONS;
}

export const getIsAbandonmentMetric = aggregator => {
  return (
    aggregator === MetricConstants.aggregator.BOUNCE ||
    aggregator === MetricConstants.aggregator.EXIT
  );
};

export function getAggregatorOptions(metricType, event) {
  if (
    metricType === METRIC_TYPES.GLOBAL ||
    event.id === MetricConstants.field.REVENUE
  ) {
    return MetricConstants.overallRevenueAggregatorOptions;
  }
  const isFlagsProject = flux.evaluate(CurrentProjectGetters.isFlagsProject);

  /* Default values: */
  const eventAggregatorOptions = [
    MetricConstants.aggregationOperations[
      MetricConstants.aggregationOptions.UNIQUE_CONVERSIONS
    ],
    MetricConstants.aggregationOperations[
      MetricConstants.aggregationOptions.TOTAL_CONVERSIONS
    ],
    MetricConstants.aggregationOperations[
      MetricConstants.aggregationOptions.TOTAL_REVENUE
    ],
    MetricConstants.aggregationOperations[
      MetricConstants.aggregationOptions.TOTAL_VALUE
    ],
  ];

  if (metricType === METRIC_TYPES.PAGEVIEW && !isFlagsProject) {
    eventAggregatorOptions.push(
      MetricConstants.aggregationOperations[
        MetricConstants.aggregationOptions.BOUNCE_RATE
      ],
    );
    eventAggregatorOptions.push(
      MetricConstants.aggregationOperations[
        MetricConstants.aggregationOptions.EXIT_RATE
      ],
    );
  }

  return eventAggregatorOptions;
}

export function getScopeOptions(aggregator, layer) {
  const {
    aggregationOptions: { TOTAL_REVENUE, TOTAL_VALUE, BOUNCE_RATE, EXIT_RATE },
  } = MetricConstants;

  const policy = layer.get('policy');

  if ([BOUNCE_RATE, EXIT_RATE].includes(aggregator)) {
    return MetricConstants.eventScopeOptions;
  }

  if ([TOTAL_REVENUE, TOTAL_VALUE].includes(aggregator)) {
    return [
      ...MetricConstants.layerBasedScopeOptions[policy],
      ...MetricConstants.revenueScopeOptions,
    ];
  }

  return MetricConstants.layerBasedScopeOptions[policy];
}

export function getInitialScopeValue(layer) {
  return MetricConstants.layerBasedScopeOptions[layer.get('policy')][0].value;
}

export function isOverallRevenueEvent(event) {
  return event.id === MetricConstants.field.REVENUE;
}

export function getAggregatorValues(aggregatorValue) {
  const { aggregator, field } =
    MetricConstants.aggregationOperations[aggregatorValue] || {};

  return { aggregator, field };
}

export function getEventPropertiesPayload(combineOperator, conditions) {
  const formattedConditions = Object.values(conditions).map(
    ({ id, ...restCondition }) => ({
      ...restCondition,
    }),
  );
  const event_properties = {
    filter: {
      conditions: formattedConditions,
    },
  };

  if (formattedConditions.length) {
    event_properties.filter.combine_operator = combineOperator;
  }

  return event_properties;
}

export function formatConditions(eventProperties) {
  const conditionsArray = eventProperties.filter?.conditions || [];
  const conditionsMap = {};
  for (let i = 0; i < conditionsArray.length; i++) {
    conditionsMap[i] = { id: i, ...conditionsArray[i] };
  }

  return {
    conditions: conditionsMap,
    combineOperator: eventProperties.filter?.combine_operator || 'and',
  };
}

export function buildMetricWrapper(metricForm, layer) {
  const {
    aggregator: selectedAggregator,
    alias,
    combineOperator,
    conditions,
    event,
    filterByProperties,
    isDraft,
    name,
    scope,
    type,
    winningDirection: winning_direction,
    compoundNumerator,
    compoundDenominator,
  } = metricForm;
  const { id: event_id, event_type } = event;

  const event_properties = getEventPropertiesPayload(
    combineOperator,
    conditions,
  );

  const { aggregator, field } = getAggregatorValues(selectedAggregator);
  const display_title = name || event?.name;

  const eventMetric = {
    alias: alias || guid(),
    aggregator,
    display_unit: null,
    scope,
    winning_direction,
    event_properties: filterByProperties ? event_properties : undefined,
    is_draft: isDraft,
    display_title,
    event_id,
    event_type,
    field,
    name: display_title,
  };

  let constructedMetric = eventMetric;

  if (event_id === MetricConstants.field.REVENUE) {
    const revenueMetric = toImmutable({
      ...eventMetric,
      event_id: null,
      event_type: null,
      field: MetricConstants.field.REVENUE,
    });

    constructedMetric = revenueMetric;
  }

  if (compoundNumerator && compoundDenominator) {
    const subMetrics = getCompoundSubmetrics(
      compoundNumerator,
      compoundDenominator,
      scope,
    );

    const ratioMetric = {
      aggregator: 'ratio',
      alias: eventMetric.alias,
      display_title,
      is_draft: isDraft,
      metrics: subMetrics,
      scope,
      winning_direction,
    };

    constructedMetric = ratioMetric;
  }

  const aggregatorOptions = getAggregatorOptions(type, event);
  const scopeOptions = getScopeOptions(event, layer);
  const metricWrapper = toImmutable({
    metric: constructedMetric,
    aggregatorOptions,
    scopeOptions,
    description: '',
    name: '',
  });

  return metricWrapper;
}

export function getAggregatorFromOperations(selectedAggregator, field = null) {
  const aggregator = Object.keys(MetricConstants.aggregationOperations).find(
    operationKey => {
      const operation = MetricConstants.aggregationOperations[operationKey];
      return (
        operation.aggregator === selectedAggregator && operation.field === field
      );
    },
  );
  return aggregator;
}

export function buildMetricFormFromWrapper(metricWrapper) {
  const metric = metricWrapper.get('metric');
  const eventProperties = metric.get('event_properties')?.get('filter');
  const eventId = metric.get('event_id');
  const eventType = metric.get('event_type');
  const metricField = metric.get('field');
  const metricType =
    !eventType && metricField === MetricConstants.field.REVENUE
      ? 'GLOBAL'
      : eventType?.toUpperCase();

  const aggregator = getAggregatorFromOperations(
    metric.get('aggregator'),
    metricField,
  );

  const metrics = metric?.get('metrics')?.toJS() || [];
  const compoundNumerator = metrics[0];
  const compoundDenominator = metrics[1];
  let type = METRIC_TYPES[metricType];
  if (aggregator === MetricConstants.aggregator.RATIO) {
    type = COMPOUND_METRIC_TYPE;
  }

  const createConditionId = conditions => {
    const id = Date.now() + 1;
    if (conditions[id]) {
      return createConditionId(conditions);
    }
    return id;
  };
  const conditions =
    eventProperties
      ?.get('conditions')
      ?.toJS()
      ?.reduce((accum, condition) => {
        const id = createConditionId(accum);

        accum[id] = {
          ...condition,
          id,
        };
        return accum;
      }, {}) || {};

  return {
    name: metric.get('display_title'),
    alias: metric.get('alias'),
    filterByProperties: !!eventProperties,
    combineOperator: eventProperties?.get('combine_operator') || 'and',
    isDraft: metric.get('is_draft') || false,
    winningDirection: metric.get('winning_direction'),
    aggregator,
    scope: metric.get('scope'),
    conditions,
    eventId,
    type,
    compoundNumerator,
    compoundDenominator,
  };
}

export function getDefaultOperator(eventType) {
  const DEFAULT_OPERATORS = {
    string: STRING_OPERATORS.is,
    number: NUMERIC_OPERATORS['='],
    boolean: BOOLEAN_OPERATORS.is,
  };

  return DEFAULT_OPERATORS[eventType] || '';
}

export function mapEventProperties(eventProperties) {
  const map = {};

  eventProperties.forEach(property => {
    map[property.name] = property;
  });
  return map;
}

export function formatEventProperties(eventProperties) {
  return eventProperties.map(({ name }) => ({
    label: name,
    value: name,
  }));
}

export function getOperatorOptions(type) {
  const OPERATORS = {
    string: STRING_OPERATORS,
    number: NUMERIC_OPERATORS,
    boolean: BOOLEAN_OPERATORS,
  };

  const selectedOperators = OPERATORS[type] || {};

  return Object.entries(selectedOperators).map(([label, value]) => ({
    label,
    value,
  }));
}

export function validateMetricForm(metricForm) {
  const errors = {};

  if (
    (metricForm.name && !metricForm.name.trim()) ||
    (!metricForm.name && !metricForm.event.name)
  ) {
    errors.name = 'Please provide a valid name for the metric';
  }

  if (metricForm.type !== COMPOUND_METRIC_TYPE && !metricForm.event.name) {
    errors.event = 'Please select an event.';
  }

  const conditionsError = validateConditions(metricForm.conditions);
  if (conditionsError) {
    errors.conditions = conditionsError;
  }

  const { compoundNumerator, compoundDenominator } = metricForm;

  if (
    compoundNumerator?.event?.id &&
    compoundDenominator?.event?.id &&
    compoundNumerator.event.id === compoundDenominator.event.id
  ) {
    errors.ratio = 'Numerator and Denominator cannot have the same event.';
  }

  return errors;
}

export function validateConditions(conditions) {
  const allConditions = Object.values(conditions);
  const hasEmptyValue = allConditions.some(condition => !condition.value);

  if (hasEmptyValue) {
    return 'Please enter a value for all conditions.';
  }

  let conditionsSet = Set();
  allConditions.forEach(condition => {
    const { name, value, type, operator } = condition;
    conditionsSet = conditionsSet.add(
      toImmutable({ name, value, type, operator }),
    );
  });

  if (conditionsSet.size !== allConditions.length) {
    return 'Duplicate conditions are not allowed';
  }
  return null;
}

export function getCompoundSubmetrics(
  compoundNumerator,
  compoundDenominator,
  scope,
) {
  const {
    aggregator: numeratorAggregator,
    field: numeratorField,
  } = getAggregatorValues(compoundNumerator.aggregator);
  const numeratorMetric = {
    event_id: compoundNumerator.event?.id,
    event_type: compoundNumerator.event?.event_type,
    scope,
    aggregator: numeratorAggregator,
    field: numeratorField || undefined,
  };

  const {
    aggregator: denominatorAggregator,
    field: denominatorField,
  } = getAggregatorValues(compoundDenominator.aggregator);
  const denominatorMetric = {
    event_id: compoundDenominator.event?.id,
    event_type: compoundDenominator.event?.event_type,
    scope,
    aggregator: denominatorAggregator,
    field: denominatorField || undefined,
  };

  return [numeratorMetric, denominatorMetric];
}

export default {};
