/**
 * Audience Combinations Builder Simple:
 * This audience combinations builder only renders the simple view, ie does not allow a user to edit complex audience combinations.
 * It is currently only used in the Flags UI.
 *
 */
import React, { useState, useEffect, useMemo } from 'react';

import { Spinner } from 'optimizely-oui';

import AudienceActions from 'optly/modules/entity/audience/actions';
import { fns as LayerExperimentFns } from 'optly/modules/entity/layer_experiment';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import { useGetters } from 'core/ui/methods/connect_getters';
import FlagsAudienceCombinationsBuilder, {
  FlagsAudienceCombinationsBuilderProps as ComponentProps,
} from './flags_audience_combinations_builder';

export interface FlagsAudienceCombinationsBuilderProps extends ComponentProps {}

const FlagsAudienceCombinationsBuilderWithData = ({
  audienceConditions = [],
  canEditAudience = true,
  onChange,
}: FlagsAudienceCombinationsBuilderProps) => {
  const { audiences, projectId } = useGetters({
    audiences: CurrentProjectGetters.audiences,
    projectId: CurrentProjectGetters.id,
  }) as { audiences: Immutable.List<any>; projectId: number };

  const [isLoadingAudiences, setIsLoadingAudiences] = useState(true);

  useEffect(() => {
    setIsLoadingAudiences(true);
    const audienceIds = LayerExperimentFns.deriveAudienceIdsFromAudienceConditionsPOJO(
      audienceConditions,
    );

    if (audienceIds.length > 0) {
      const byAudienceIds = {
        project_id: projectId,
        id: audienceIds,
      };
      AudienceActions.fetchAll(byAudienceIds).always(() => {
        setIsLoadingAudiences(false);
      });
    } else {
      setIsLoadingAudiences(false);
    }
  }, []);

  return (
    <div className="position--relative">
      {isLoadingAudiences ? (
        <Spinner />
      ) : (
        <FlagsAudienceCombinationsBuilder
          audiences={audiences.toJS()}
          audienceConditions={audienceConditions}
          canEditAudience={canEditAudience}
          projectId={projectId}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default FlagsAudienceCombinationsBuilderWithData;
