/* Using eslint-disable flag due to improper declarations on legacy code */
/* eslint-disable class-methods-use-this */
import { EmptyDashboard, Spinner, Table } from '@optimizely/axiom';
import PropTypes from 'prop-types';
import React from 'react';

import { SortableTableHeader } from 'react_components/sortable_table';

import { connect } from 'core/ui/decorators';
import { deepEqual } from 'core/utils/react';
import { loadingWhenFetchAllPages } from 'core/modules/loading/actions';

import Immutable from 'optly/immutable';
import tr from 'optly/translate';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import {
  actions as FilterableTableActions,
  enums as FilterableTableEnums,
  fns as FilterableTableFns,
} from 'optly/modules/filterable_table';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerFns from 'optly/modules/entity/layer/fns';
import LayerActions from 'optly/modules/entity/layer/actions';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';
import { getters as V2MigrationGetters } from 'optly/modules/v2_migration';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';

import { pageableEntityTable } from 'bundles/p13n/components/entity_dashboard/entity_table';
import DashboardTableFilterControls from 'bundles/p13n/components/entity_dashboard/table_filter_controls';
import { ShowAvailableArchivedItemsCta as ShowArchivedItemsCtaIfAvailableComponent } from 'bundles/p13n/components/available_items_cta';
import { DEFAULT_COLUMNS_VISIBILITY } from 'optly/modules/dashboard/constants';

import LayerTableRow from './components/layer_table_row';
import CreateDropdown from './components/create_dropdown';

const TABLE_ID = FilterableTableEnums.tableIds.P13N_LAYERS;
const EntityTable = pageableEntityTable(TABLE_ID);

const STATUS_OPTIONS = [
  {
    label: tr('Active'),
    value: LayerEnums.status.ACTIVE,
  },
  {
    label: tr('Running'),
    value: LayerEnums.status.RUNNING,
  },
  {
    label: tr('Paused'),
    value: LayerEnums.status.PAUSED,
  },
  {
    label: tr('Draft'),
    value: LayerEnums.status.DRAFT,
  },
  {
    label: tr('Archived'),
    value: LayerEnums.status.ARCHIVED,
  },
  {
    label: tr('Concluded'),
    value: LayerEnums.status.CONCLUDED,
  },
];

@connect({
  currentProject: CurrentProjectGetters.project,
  shouldShowRowActions: PermissionsModuleGetters.canUseLayerTableRowActions,
  v2FirstRunText: V2MigrationGetters.v2FirstRunText,
  v2FirstRunImage: V2MigrationGetters.v2FirstRunImage,
})
class LayersTable extends React.Component {
  static displayName = 'LayersTable';

  static propTypes = {
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    dashboardLayers: PropTypes.instanceOf(Immutable.List).isRequired,
    isTableLoading: PropTypes.bool.isRequired,
    searchApiStatus: PropTypes.shape({
      canListEntities: PropTypes.bool.isRequired,
      canQueryEntities: PropTypes.bool.isRequired,
    }).isRequired,
    shouldShowRowActions: PropTypes.bool.isRequired,
    typeFilterAvailableOptions: PropTypes.arrayOf(PropTypes.string),
    v2FirstRunImage: PropTypes.string.isRequired,
    v2FirstRunText: PropTypes.object.isRequired,
  };

  shouldComponentUpdate = deepEqual();

  filterItemByString = (item, search) =>
    FilterableTableFns.matchesFields(
      item,
      // This dashboard's getter aliases "id" as "unique_id"
      ['name', 'description', 'unique_id'],
      search,
    );

  filterItemByStatus = (dashboardLayer, selectedFilterValue) => {
    const layerStatus = LayerFns.getStatus(
      dashboardLayer.get('layer'),
      dashboardLayer.get('liveTag'),
    );
    if (selectedFilterValue === LayerEnums.status.ACTIVE) {
      return layerStatus !== LayerEnums.status.ARCHIVED;
    }
    return selectedFilterValue === layerStatus;
  };

  shouldShowJiraContent = () => {
    const { currentProject } = this.props;

    return (
      currentProject.getIn(['jira_integration', 'enabled']) &&
      !!currentProject.getIn(['jira_integration', 'resource_id'])
    );
  };

  renderTableRow = dashboardLayer => {
    const { currentProject, shouldShowRowActions } = this.props;
    const layerId = dashboardLayer.getIn(['layer', 'id']);
    return (
      <LayerTableRow
        key={layerId}
        currentProject={currentProject}
        jiraIntegrationEnabled={true}
        jiraIntegrationSettings={currentProject.get('jira_integration')}
        dashboardLayer={dashboardLayer}
        shouldShowRowActions={shouldShowRowActions}
        columnsVisibility={DEFAULT_COLUMNS_VISIBILITY}
      />
    );
  };

  renderTableHeader = () => {
    const { shouldShowRowActions } = this.props;
    return (
      <Table.TR>
        <SortableTableHeader field="name" type="string">
          Name
        </SortableTableHeader>
        <SortableTableHeader field="type" type="string">
          Type
        </SortableTableHeader>
        {this.shouldShowJiraContent() && <Table.TH>Jira</Table.TH>}
        <SortableTableHeader field="status" type="string">
          Status
        </SortableTableHeader>
        <SortableTableHeader field="last_modified" type="date">
          Modified
        </SortableTableHeader>
        <Table.TH>Results</Table.TH>
        {shouldShowRowActions && <Table.TH isNumerical={true} />}
      </Table.TR>
    );
  };

  componentDidMount() {
    const { searchApiStatus } = this.props;
    const searchApiEventAttributes = {
      use_search_api_for_sonic_search_boxes: searchApiStatus.canQueryEntities,
      use_search_api_for_sonic_tables: searchApiStatus.canListEntities,
    };
  }

  fetchDataForStatus = status => {
    const { currentProject } = this.props;

    loadingWhenFetchAllPages(
      TABLE_ID,
      LayerActions.fetchAllByStatus({
        projectId: currentProject.get('id'),
        byPage: true,
        archived: status === LayerEnums.status.ARCHIVED,
      }),
    );
  };

  renderEmptyState = () => {
    const {
      currentProject,
      isTableLoading,
      v2FirstRunText,
      v2FirstRunImage,
      typeFilterAvailableOptions,
    } = this.props;
    if (isTableLoading) {
      return <Spinner hasOverlay={true} />;
    }
    return (
      <EmptyDashboard
        button={
          <CreateDropdown
            addDataTrackId={true}
            allowedLayerTypes={typeFilterAvailableOptions}
          />
        }
        description={
          <>
            {v2FirstRunText.subtitle}
            <ShowArchivedItemsCtaIfAvailableComponent
              fetchAnyProjectEntityAndReturnTrueIfPresent={() =>
                new Promise(async resolve => {
                  const projectLayerExperiment = await LayerExperimentActions.fetchPage(
                    {
                      project_id: currentProject.get('id'),
                      $limit: 1,
                      $idsOnly: true,
                    },
                  );
                  resolve(
                    !!(projectLayerExperiment && projectLayerExperiment.length),
                  );
                })
              }
              handleFetchArchivedItems={() => {
                this.fetchDataForStatus(LayerEnums.status.ARCHIVED);
                FilterableTableActions.setFilter(TABLE_ID, {
                  status: LayerEnums.status.ARCHIVED,
                });
              }}
            />
          </>
        }
        headline={v2FirstRunText.title}
        imagePath={v2FirstRunImage}
      />
    );
  };

  render() {
    const {
      isTableLoading,
      dashboardLayers,
      typeFilterAvailableOptions,
    } = this.props;

    if (dashboardLayers.size === 0) {
      return this.renderEmptyState();
    }

    return (
      <div className="flex flex--1 flex--column">
        <div className="flex push-double--ends push-quad--sides">
          <DashboardTableFilterControls
            tableId={TABLE_ID}
            statusOptions={STATUS_OPTIONS}
            onStatusChange={this.fetchDataForStatus}
            inputPlaceholder="Filter by name or description"
            inputWidth="width--300"
          />
          <CreateDropdown
            addDataTrackId={true}
            allowedLayerTypes={typeFilterAvailableOptions}
          />
        </div>
        <EntityTable
          tableId={TABLE_ID}
          isLoading={isTableLoading}
          data={dashboardLayers}
          entityPlural="experiments or campaigns"
          filterItemByString={this.filterItemByString}
          filterItemByStatus={this.filterItemByStatus}
          renderTableRow={this.renderTableRow}
          renderTableHeader={this.renderTableHeader}
          defaultSortBy={{ field: 'last_modified', type: 'date', dir: 'desc' }}
          defaultFilters={{ status: LayerEnums.status.ACTIVE }}
        />
      </div>
    );
  }
}

export default LayersTable;
