import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Label, SelectDropdown } from '@optimizely/axiom';

import stringUtils from 'optly/utils/str';
import { constants as MetricConstants } from 'optly/modules/entity/metric';

import { EventsSelectorWrapper as EventsSelector } from './events_selector';
import { COMPOUND_METRIC_TYPE } from './constants';

export const CompoundMetricEvents = ({
  onChange,
  formValues,
  isDisabled,
  error,
}) => {
  const COMPOUND_AGGREGATOR_OPTIONS = [
    MetricConstants.aggregationOperations[
      MetricConstants.aggregationOptions.UNIQUE_CONVERSIONS
    ],
    MetricConstants.aggregationOperations[
      MetricConstants.aggregationOptions.TOTAL_CONVERSIONS
    ],
    MetricConstants.aggregationOperations[
      MetricConstants.aggregationOptions.TOTAL_REVENUE
    ],
    MetricConstants.aggregationOperations[
      MetricConstants.aggregationOptions.TOTAL_VALUE
    ],
  ];

  const numerator = useMemo(() => formValues.compoundNumerator, [
    formValues.compoundNumerator,
  ]);
  const denominator = useMemo(() => formValues.compoundDenominator, [
    formValues.compoundDenominator,
  ]);

  const numeratorEvent = useMemo(() => numerator?.event, [numerator?.event]);
  const denominatorEvent = useMemo(() => denominator?.event, [
    denominator?.event,
  ]);

  const numeratorAggregator = useMemo(
    () => MetricConstants.aggregationOperations[numerator?.aggregator]?.label,
    [numerator?.aggregator],
  );
  const denominatorAggregator = useMemo(
    () => MetricConstants.aggregationOperations[denominator?.aggregator]?.label,
    [denominator?.aggregator],
  );

  const winningDirection = useMemo(
    () =>
      MetricConstants.winningDirectionOptions.find(
        option => option.value === formValues.winningDirection,
      )?.label,
    [formValues.winningDirection],
  );

  const metricName = useMemo(() => formValues.name, [formValues.name]);

  const showSummary = useMemo(
    () =>
      !!numeratorEvent &&
      !!numeratorAggregator &&
      !!denominatorEvent &&
      !!denominatorAggregator &&
      !!winningDirection &&
      !!metricName,
    [
      numeratorEvent,
      numeratorAggregator,
      denominatorEvent,
      denominatorAggregator,
      winningDirection,
      metricName,
    ],
  );

  const updateNumeratorEvent = useCallback(
    event => {
      setTimeout(() => {
        onChange('compoundNumerator', {
          ...(formValues?.compoundNumerator || {
            aggregator: MetricConstants.aggregationOptions.UNIQUE_CONVERSIONS,
          }),
          event,
        });
      });
    },
    [formValues.compoundNumerator],
  );

  const updateDenominatorEvent = useCallback(
    event => {
      setTimeout(() => {
        onChange('compoundDenominator', {
          ...(formValues?.compoundDenominator || {
            aggregator: MetricConstants.aggregationOptions.UNIQUE_CONVERSIONS,
          }),
          event,
        });
      });
    },
    [formValues.compoundDenominator],
  );

  const updateNumeratorAggregator = useCallback(
    aggregator => {
      onChange('compoundNumerator', {
        ...(formValues?.compoundNumerator || {}),
        aggregator,
      });
    },
    [formValues.compoundNumerator],
  );

  const updateDenominatorAggregator = useCallback(
    aggregator => {
      onChange('compoundDenominator', {
        ...(formValues?.compoundDenominator || {}),
        aggregator,
      });
    },
    [formValues.compoundDenominator],
  );

  return (
    <div>
      <div className="push-double--bottom">
        <Label className={error ? 'color--bad-news' : ''}>
          Numerator Event
        </Label>
        <div
          className="flex flex--row flex-justified--between"
          style={{ gap: '8px' }}>
          <EventsSelector
            value={numeratorEvent?.name || ''}
            metricType={COMPOUND_METRIC_TYPE}
            onChange={updateNumeratorEvent}
            isDisabled={isDisabled}
            displayError={!!error}
          />
          <SelectDropdown
            data-test-section="ratio-numerator-aggregator-dropdown"
            items={COMPOUND_AGGREGATOR_OPTIONS}
            minDropdownWidth={175}
            onChange={updateNumeratorAggregator}
            value={numerator?.aggregator}
            width="175px"
          />
        </div>
      </div>
      <div>
        <Label className={error ? 'color--bad-news' : ''}>
          Denominator Event
        </Label>
        <div
          className="flex flex--row flex-justified--between"
          style={{ gap: '8px' }}>
          <EventsSelector
            value={denominatorEvent?.name || ''}
            metricType={COMPOUND_METRIC_TYPE}
            onChange={updateDenominatorEvent}
            isDisabled={isDisabled}
            displayError={!!error}
          />
          <SelectDropdown
            data-test-section="ratio-denominator-aggregator-dropdown"
            items={COMPOUND_AGGREGATOR_OPTIONS}
            minDropdownWidth={175}
            onChange={updateDenominatorAggregator}
            value={denominator?.aggregator}
            width="175px"
          />
        </div>
      </div>
      {error && <div className="oui-form-note color--bad-news">{error}</div>}
      {showSummary && (
        <div>
          <Label>Summary</Label>
          <div
            style={{
              backgroundColor: '#F5F5F5',
              minHeight: 88,
            }}
            className="flex flex-align--center flex-justified--center soft-double--sides">
            <div
              className="flex flex--row flex-align--center flex-justified--center"
              style={{ width: '100%' }}>
              <div
                className="flex flex-justified--center"
                style={{ textWrap: 'nowrap', maxWidth: '70%' }}>
                <span
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                  }}>
                  {metricName}
                </span>
                <span className="push-half--sides"> = </span>
                <span style={{ fontWeight: 600 }}>{winningDirection}</span>
                <span className="push-half--sides"> in </span>
              </div>
              <div className="flex flex--column">
                <div
                  className="flex flex-justified--center"
                  style={{
                    borderBottom: '1px solid black',
                    textWrap: 'nowrap',
                  }}>
                  <span style={{ fontWeight: 600 }}>
                    {stringUtils.capitalize(numeratorAggregator)}
                  </span>
                  <span className="push-half--sides">of</span>
                  <span style={{ fontWeight: 600 }}>{numeratorEvent.name}</span>
                </div>
                <div
                  className="flex flex-justified--center"
                  style={{ textWrap: 'nowrap' }}>
                  <span style={{ fontWeight: 600 }}>
                    {stringUtils.capitalize(denominatorAggregator)}
                  </span>
                  <span className="push-half--sides">of</span>
                  <span style={{ fontWeight: 600 }}>
                    {denominatorEvent.name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CompoundMetricEvents.propTypes = {
  formValues: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

CompoundMetricEvents.defaultProps = {
  isDisabled: false,
};

export default { CompoundMetricEvents };
