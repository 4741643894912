import PropTypes from 'prop-types';
import React from 'react';

import { Label } from 'optimizely-oui';

import Immutable, { toImmutable } from 'optly/immutable';
import Layer from 'optly/modules/entity/layer';
import PagesPopover from 'bundles/p13n/components/help_popovers/pages';
import SelectDropdown from 'react_components/select_dropdown';
import {
  fns as ViewFns,
  constants as ViewConstants,
} from 'optly/modules/entity/view';

import {
  actions as OptimizelyChampagneActions,
  enums as OptimizelyChampagneEnums,
} from 'optly/modules/optimizely_champagne';

import ComponentModuleConstants from '../../component_module/constants';

import UrlTargetingCreation from '../url_targeting_creation';
import ViewsForLayer from '../views_for_layer';

class TargetingCreation extends React.Component {
  constructor(props) {
    super(props);

    // TODO(WEB-2627) Implement experiment winner
    const variation = OptimizelyChampagneActions.activate(
      OptimizelyChampagneEnums.TestUrlTargetingSetup.EXPERIMENT_KEY,
    );

    this.shouldRenderWithUrlTargeting = props.canUseUrlTargeting;

    // Only apply test treatment here if
    // 1. This is an ab experiment
    // 2. The account is whitelisted for URL_TARGETING
    // 3. The test is active, ie returns a variation
    if (props.isABExperiment && props.canUseUrlTargeting && variation) {
      this.shouldRenderWithUrlTargeting =
        variation ===
        OptimizelyChampagneEnums.TestUrlTargetingSetup.Variations
          .SIMPLIFIED_CREATION_FLOW;
    }
  }

  handleTargetingChange = targetingType => {
    const {
      urlTargetingConfig,
      onChangeUrlTargeting,
      setTargetingType,
    } = this.props;

    if (
      targetingType === Layer.constants.TargetingTypes.URL &&
      urlTargetingConfig.get('edit_url') !== ''
    ) {
      onChangeUrlTargeting(urlTargetingConfig);
    }
    setTargetingType(targetingType);
  };

  usePageAsUrlTarget = view => {
    // TODO(APPX-969) Use Full URL Targeting Config in Creation Dialog when "Set Page as URL Target" is selected
    const viewEditUrl = view.get('edit_url');
    const includeCondition = toImmutable(
      ViewConstants.DEFAULT_URL_CONDITION,
    ).set('value', viewEditUrl);
    const conditions = ViewFns.serializeConditions(
      toImmutable([includeCondition]),
      toImmutable([toImmutable(ViewConstants.DEFAULT_URL_CONDITION)]),
    );

    const urlTargetingConfig = Layer.constants.DEFAULT_URL_TARGETING_CONFIG.set(
      'conditions',
      conditions,
    ).set('edit_url', viewEditUrl);

    const { onChangeUrlTargeting, setTargetingType } = this.props;
    onChangeUrlTargeting(urlTargetingConfig);
    setTargetingType(Layer.constants.TargetingTypes.URL);
  };

  renderWithoutUrlTargeting = () => {
    const {
      addedViews,
      selectedViewIds,
      onChangeSelectedViewIds,
      views,
      viewsError,
      canUseUrlTargeting,
    } = this.props;
    return (
      <form>
        <fieldset>
          <ol className="lego-form-fields">
            <li className="lego-form-field__item">
              <h3>
                Pages
                <PagesPopover />
              </h3>
              <p className="push-double--bottom">
                You can choose a single page, or more than one if you&apos;re
                testing a funnel or cross-site experience.
              </p>
              <ViewsForLayer
                onChange={onChangeSelectedViewIds}
                addedViews={addedViews}
                selectedViewIds={selectedViewIds}
                views={views}
                error={viewsError}
                canUseUrlTargeting={canUseUrlTargeting}
                shouldUseEditorPageAsUrlTarget={true}
              />
            </li>
          </ol>
        </fieldset>
      </form>
    );
  };

  renderWithUrlTargeting = () => {
    const {
      onChangeUrlTargeting,
      addedViews,
      selectedViewIds,
      onChangeSelectedViewIds,
      urlTargetingConfig,
      views,
      urlTargetingConditionsError,
      urlTargetingApiNameError,
      urlTargetingUrlError,
      viewsError,
      canUseUrlTargeting,
      targetingType,
      campaignType,
    } = this.props;

    let entityName;
    let verb = 'test';

    switch (campaignType) {
      case Layer.enums.campaignTypes.MULTIARMED_BANDIT:
        entityName = Layer.humanReadable.TEXT_BY_CAMPAIGN_TYPE.MULTIARMED_BANDIT.name.toLowerCase();
        verb = 'optimize';
        break;
      case Layer.enums.campaignTypes.MULTIVARIATE:
        entityName = Layer.humanReadable.TEXT_BY_CAMPAIGN_TYPE.MULTIVARIATE.name.toLowerCase();
        break;
      case Layer.enums.campaignTypes.P13N_CAMPAIGN:
        entityName = Layer.humanReadable.TEXT_BY_CAMPAIGN_TYPE.P13N_CAMPAIGN.name.toLowerCase();
        break;
      default:
        entityName = Layer.humanReadable.TEXT_BY_CAMPAIGN_TYPE.SINGLE_EXPERIMENT.name.toLowerCase();
    }

    return (
      <div>
        <h3>Activation</h3>
        <p className="push-double--bottom">
          Define where you’d like this to run. Choose to either target by URL or
          select saved pages to {verb} a funnel or cross-site experience.
        </p>
        <Label>Target By</Label>
        <SelectDropdown
          items={ComponentModuleConstants.targetingTypeItems}
          value={targetingType}
          onChange={this.handleTargetingChange}
          width="20%"
          testSection="targeting-creation-select"
          trackId="2.0-Campaign-CreateNewCampaign-Targeting.Creation.Toggle"
        />
        <div className="soft-double--top">
          {targetingType === Layer.constants.TargetingTypes.URL && (
            <UrlTargetingCreation
              config={urlTargetingConfig}
              onChange={onChangeUrlTargeting}
              conditionsError={urlTargetingConditionsError}
              apiNameError={urlTargetingApiNameError}
              urlError={urlTargetingUrlError}
            />
          )}
          {targetingType === Layer.constants.TargetingTypes.SAVED_PAGES && (
            <ViewsForLayer
              onChange={onChangeSelectedViewIds}
              addedViews={addedViews}
              selectedViewIds={selectedViewIds}
              views={views}
              error={viewsError}
              canUseUrlTargeting={canUseUrlTargeting}
              usePageAsUrlTarget={this.usePageAsUrlTarget}
              shouldUseEditorPageAsUrlTarget={true}
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div data-test-section="targeting-creation">
        {this.shouldRenderWithUrlTargeting
          ? this.renderWithUrlTargeting()
          : this.renderWithoutUrlTargeting()}
      </div>
    );
  }
}

TargetingCreation.propTypes = {
  addedViews: PropTypes.instanceOf(Immutable.Set),
  campaignType: PropTypes.oneOf(Object.values(Layer.enums.campaignTypes)),
  canUseUrlTargeting: PropTypes.bool,
  isABExperiment: PropTypes.bool.isRequired,
  onChangeSelectedViewIds: PropTypes.instanceOf(Function).isRequired,
  onChangeUrlTargeting: PropTypes.instanceOf(Function).isRequired,
  /**
   * Currently selected views
   */
  selectedViewIds: PropTypes.instanceOf(Immutable.Set),
  setTargetingType: PropTypes.instanceOf(Function).isRequired,
  targetingType: PropTypes.string.isRequired,
  urlTargetingApiNameError: PropTypes.string,
  urlTargetingConditionsError: PropTypes.string,
  /**
   * Url targeting config for the layer
   */
  urlTargetingConfig: PropTypes.instanceOf(Immutable.Map),
  urlTargetingUrlError: PropTypes.string,
  /**
   * All views for the project
   */
  views: PropTypes.instanceOf(Immutable.List),
  viewsError: PropTypes.string,
};

TargetingCreation.defaultProps = {
  addedViews: Immutable.Set(),
  campaignType: Layer.enums.campaignTypes.SINGLE_EXPERIMENT,
  selectedViewIds: Immutable.Set(),
  urlTargetingConfig: ComponentModuleConstants.DEFAULT_URL_TARGETING_CONFIG,
};

export default TargetingCreation;
