import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  DialogNew,
  Dropdown,
  Fieldset,
  Tile,
  Typography,
  Radio,
  SearchPicker,
  Spinner,
} from '@optimizely/axiom';
import { api as SegmentJSAPI } from '@optimizely/segment-js';

import Immutable from 'optly/immutable';

import { connect } from 'core/ui/decorators';
import truncate from 'optly/filters/truncate';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import LayerGetters from 'optly/modules/entity/layer/getters';

import { AUDIENCE_OPTIONS } from './constants';
import generateAudienceJSON from './fns';

const title = 'Deploy Variation';
const subtitle =
  'This will direct new and returning visitors from other variations to the selected variation.';

@connect(props => {
  const { layer } = props;
  const layerId = layer.get('id');
  return {
    currentAudiences: LayerGetters.audiencesFromSingleExperimentsPointingToLayerId(
      layerId,
    ),
    currentLayerHoldback: LayerGetters.holdbackByLayerId(layerId),
    isMultivariateTestLayer: LayerGetters.isMultivariateTestByLayerId(layerId),
    currentExperiment: LayerGetters.singleExperimentByLayerId(layerId),
    experimentVariations: LayerGetters.unarchivedVariationsFromMvtOrNonMvtExperimentPoitingToLayerId(
      layerId,
    ),
    projectAudiences: CurrentProjectGetters.audiences,
  };
})
export default class DeployExperimentDialog extends React.PureComponent {
  static propTypes = {
    currentAudiences: PropTypes.instanceOf(Immutable.List).isRequired,
    currentExperiment: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentLayerHoldback: PropTypes.number.isRequired,
    experimentVariations: PropTypes.instanceOf(Immutable.List).isRequired,
    isMultivariateTestLayer: PropTypes.bool.isRequired,
    onCloseHandler: PropTypes.func.isRequired,
    projectAudiences: PropTypes.instanceOf(Immutable.List).isRequired,
    submitDeploy: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      currentAudiences,
      currentLayerHoldback,
      experimentVariations,
      projectAudiences,
    } = props;

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      audienceType: currentAudiences.size
        ? AUDIENCE_OPTIONS.CURRENT_AUDIENCE
        : AUDIENCE_OPTIONS.EVERYONE,
      currentAudiences: currentAudiences.toJS(),
      expVariations: experimentVariations.toJS(),
      isDeploying: false,
      otherAudiences: [],
      placeholder: 'Search and add audiences',
      projectAudiences: projectAudiences.toJS(),
      showLess: false,
      showMore: false,
      trafficValue: 100 - currentLayerHoldback,
      variation: null,
      variationsToDeploy: [],
    };
  }

  onShowMoreClick = () => {
    this.setState(prevState => ({ showMore: !prevState.showMore }));
  };

  onShowLessClick = () => {
    this.setState(prevState => ({ showLess: !prevState.showLess }));
  };

  handleVariationChange = id => {
    const { expVariations } = this.state;
    const variation = expVariations.find(
      ({ variation_id }) => variation_id === id,
    );
    this.setState({
      variationsToDeploy: variation.variation_ids,
      variation: id,
    });
  };

  handleCloseDialog = () => {
    const { onCloseHandler } = this.props;
    onCloseHandler();
  };

  handleDeployClick = () => {
    const { submitDeploy, currentExperiment } = this.props;
    const {
      audienceType,
      trafficValue,
      otherAudiences,
      variationsToDeploy,
    } = this.state;
    this.setState({ isDeploying: true });

    SegmentJSAPI.track('Clicks on deploy button CTA');

    let audienceIdsToSave = [];
    if (audienceType === AUDIENCE_OPTIONS.OTHER_AUDIENCE) {
      audienceIdsToSave = otherAudiences.map(aud => aud.id);
    }

    const audienceConditions = currentExperiment.get('audience_conditions');

    submitDeploy({
      deployedVariationIds: variationsToDeploy,
      experimentId: currentExperiment.get('id'),
      audienceConditions: generateAudienceJSON({
        audienceIds: audienceIdsToSave,
        audienceType,
        audienceConditions,
      }),
      layerHoldback: 100 * (100 - Number(trafficValue)),
    });
  };

  isDeployButtonDisabled = () => {
    const {
      audienceType,
      isDeploying,
      otherAudiences,
      trafficValue,
      variation,
    } = this.state;
    return (
      !variation ||
      !audienceType ||
      !(trafficValue >= 0 && trafficValue <= 100) ||
      isDeploying ||
      (audienceType === AUDIENCE_OPTIONS.OTHER_AUDIENCE &&
        !otherAudiences.length)
    );
  };

  addOtherAudience = audience => {
    this.setState(prevState => ({
      otherAudiences: [...prevState.otherAudiences, audience],
    }));
  };

  selectedOtherAudienceIds = () => {
    const { otherAudiences } = this.state;
    return otherAudiences.map(audience => audience.id);
  };

  removeOtherAudience = audience => {
    this.setState(prevState => ({
      otherAudiences: prevState.otherAudiences.filter(
        otherAudience => otherAudience.id !== audience.id,
      ),
    }));
  };

  audienceSearchFunction = ({ query }) => {
    const { projectAudiences } = this.state;
    let filteredAudiences = projectAudiences;
    if (query) {
      filteredAudiences = projectAudiences.filter(audience =>
        audience.name.toLowerCase().includes(query.toLowerCase()),
      );
    }
    return new Promise(resolve => {
      resolve(filteredAudiences);
    });
  };

  handleTrafficValueChange = event => {
    const { value } = event.target;
    // check if value is a number and between 0 and 100
    if (!Number.isNaN(Number(value)) && value >= 0 && value <= 100) {
      this.setState({ trafficValue: Number(value) });
    }
  };

  isCurrentAudiencesEmpty = () => {
    const { currentAudiences } = this.state;
    return !currentAudiences || currentAudiences.length === 0;
  };

  render() {
    const { isMultivariateTestLayer } = this.props;
    const {
      audienceType,
      currentAudiences,
      expVariations,
      otherAudiences,
      placeholder,
      showMore,
      trafficValue,
      variation,
    } = this.state;
    const restCurrentAudiences = currentAudiences?.slice(1);
    const audienceSize = currentAudiences.length;
    const dialogContent = (
      <>
        <Fieldset>
          <Typography type="subhead">
            {isMultivariateTestLayer ? 'Combination' : 'Variation'}
          </Typography>
          <Typography type="body" tag="p">
            Choose which {isMultivariateTestLayer ? 'combination' : 'variation'}{' '}
            to direct traffic to.
          </Typography>
          <div data-test-section="deploy-experiment-dialog-variations-section">
            <Dropdown
              buttonContent={
                variation
                  ? truncate(
                      expVariations.find(
                        ({ variation_id }) => variation_id === variation,
                      )[isMultivariateTestLayer ? 'description' : 'name'],
                      50,
                    )
                  : 'Select...'
              }
              arrowIcon="down"
              fullWidth={true}>
              <Dropdown.Contents>
                {expVariations.map(({ variation_id, name, description }) => (
                  <Dropdown.ListItem key={variation_id}>
                    <Dropdown.BlockLink
                      onClick={() => this.handleVariationChange(variation_id)}
                      testSection={`deploy-experiment-dialog-variations-option-${variation_id}`}>
                      <Dropdown.BlockLinkText
                        text={
                          isMultivariateTestLayer
                            ? truncate(description, 50)
                            : truncate(name, 50)
                        }
                      />
                    </Dropdown.BlockLink>
                  </Dropdown.ListItem>
                ))}
              </Dropdown.Contents>
            </Dropdown>
          </div>
        </Fieldset>
        <Fieldset>
          <Typography type="subhead">Audience</Typography>
          <Typography type="body" tag="p">
            Choose which visitors see the variation:
          </Typography>
          <Radio
            checked={audienceType === AUDIENCE_OPTIONS.EVERYONE}
            name="everyone-audience"
            label={
              <React.Fragment>
                <Typography type="subhead">
                  {!this.isCurrentAudiencesEmpty()
                    ? 'Everyone'
                    : 'Everyone / Current Audience'}
                </Typography>
                <p>All visitors who visit the Page(s) targeted.</p>
              </React.Fragment>
            }
            onChange={() => {
              this.setState({ audienceType: AUDIENCE_OPTIONS.EVERYONE });
            }}
          />
          {!this.isCurrentAudiencesEmpty() && (
            <React.Fragment>
              <Radio
                checked={audienceType === AUDIENCE_OPTIONS.CURRENT_AUDIENCE}
                name="current-audience"
                label={
                  <React.Fragment>
                    <Typography type="subhead">Current Audience</Typography>
                    <p>Visitors matching this experiment's Audience(s):</p>
                  </React.Fragment>
                }
                onChange={() => {
                  this.setState({
                    audienceType: AUDIENCE_OPTIONS.CURRENT_AUDIENCE,
                  });
                }}
              />
              <div
                className="push-triple--left push-double--bottom"
                data-test-section="deploy-experiment-dialog-current-audiences-section">
                <Tile
                  key={currentAudiences[0].id}
                  name={truncate(currentAudiences[0].name, 100)}
                  description={`ID: ${currentAudiences[0].id}`}
                  testSection={`deploy-experiment-current-audience-tile-${currentAudiences[0].id}`}
                />
                {!showMore && restCurrentAudiences.length > 0 && (
                  <Button
                    onClick={this.onShowMoreClick}
                    style="unstyled"
                    testSection="deploy-experiment-dialog-learn-more">
                    +{audienceSize - 1} more
                  </Button>
                )}
                {showMore &&
                  restCurrentAudiences.length > 0 &&
                  restCurrentAudiences.map(currentAudience => (
                    <Tile
                      key={currentAudience.id}
                      name={truncate(currentAudience.name, 100)}
                      description={`ID: ${currentAudience.id}`}
                      testSection={`deploy-experiment-current-audience-tile-${currentAudience.id}`}
                    />
                  ))}
                {showMore && (
                  <Button
                    onClick={this.onShowMoreClick}
                    style="unstyled"
                    testSection="deploy-experiment-dialog-learn-less">
                    See less
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
          <Radio
            checked={audienceType === AUDIENCE_OPTIONS.OTHER_AUDIENCE}
            name="other-audience"
            label={
              <React.Fragment>
                <Typography type="subhead">Other Audience</Typography>
                <p>Select a different Audience.</p>
              </React.Fragment>
            }
            onChange={() => {
              this.setState({ audienceType: AUDIENCE_OPTIONS.OTHER_AUDIENCE });
            }}
          />
          {audienceType === AUDIENCE_OPTIONS.OTHER_AUDIENCE && (
            <div
              className="push-triple--left push-double--bottom"
              data-test-section="deploy-experiment-dialog-other-audiences-section">
              <SearchPicker
                key="other-audiences-search-picker"
                onItemSelected={(index, options) => {
                  this.addOtherAudience(options[index]);
                }}
                searchFunction={this.audienceSearchFunction}
                selectedEntityIds={this.selectedOtherAudienceIds()}
                supportedTypes={['audience']}>
                {({
                  availableEntities,
                  currentFauxFocusIndex,
                  makeSearchRequest,
                  isLoading,
                  renderInput,
                  resultsText,
                  searchQuery,
                }) => (
                  <Dropdown
                    width={600}
                    fullWidth={true}
                    renderActivator={activatorProps =>
                      renderInput({
                        ...activatorProps,
                        placeholder,
                        testSection: 'audience-search-picker-input',
                        onFocus: () => {
                          makeSearchRequest(searchQuery);
                        },
                      })
                    }
                    testSection="deploy-experiment-dialog-dropdown"
                    isLoading={isLoading}>
                    <Dropdown.Contents isLoading={isLoading} direction="right">
                      <Dropdown.ListItem
                        ignoreToggle={true}
                        key="search-results">
                        <span className="micro muted soft--sides">
                          {resultsText.summary}
                        </span>
                      </Dropdown.ListItem>
                      {availableEntities.map((item, index) => (
                        <Dropdown.ListItem key={item.id}>
                          <Dropdown.BlockLink
                            hasFauxFocus={currentFauxFocusIndex === index + 1}
                            onClick={() => this.addOtherAudience(item)}>
                            <Dropdown.BlockLinkText
                              text={truncate(item.name, 50)}
                              searchQuery={searchQuery}
                            />
                            <Dropdown.BlockLinkSecondaryText
                              secondaryText={`ID: ${String(item.id)}`}
                              searchQuery={searchQuery}
                            />
                          </Dropdown.BlockLink>
                        </Dropdown.ListItem>
                      ))}
                      {isLoading && <Spinner hasOverlay={true} />}
                    </Dropdown.Contents>
                  </Dropdown>
                )}
              </SearchPicker>
              {otherAudiences && otherAudiences.length > 0 && (
                <div className="push--top">
                  {otherAudiences.map(aud => (
                    <Tile
                      key={aud.id}
                      name={truncate(aud.name, 100)}
                      description={`ID: ${aud.id}`}
                      onDismiss={() => this.removeOtherAudience(aud)}
                      testSection={`deploy-experiment-other-audience-tile-${aud.id}`}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </Fieldset>
        <Fieldset>
          <Typography type="subhead">Traffic Allocation</Typography>
          <Typography type="body" tag="p">
            The portion of site visitors who meet your audience conditions and
            see the experiment.
          </Typography>
          <input
            type="number"
            value={trafficValue}
            onChange={this.handleTrafficValueChange}
            className="width--75 lego-text-input line--1 text--right"
            min="0"
            max="100"
            data-test-section="deploy-dialog-experiment-allocation-input"
          />
          <span className="push-half--left push--right">%</span>
        </Fieldset>
      </>
    );

    return (
      <DialogNew
        title={title}
        subtitle={subtitle}
        className="deploy-experiment-dialog"
        testSection="deploy-experiment-dialog"
        onClose={this.handleCloseDialog}
        hasCloseButton={true}
        footerButtonList={[
          <Button
            key="close"
            onClick={this.handleCloseDialog}
            style="plain"
            testSection="deploy-experiment-dialog-cancel-button">
            Cancel
          </Button>,
          <Button
            key="deploy"
            onClick={this.handleDeployClick}
            style="highlight"
            isDisabled={this.isDeployButtonDisabled()}
            testSection="deploy-experiment-dialog-deploy-button">
            Deploy
          </Button>,
        ]}>
        {dialogContent}
      </DialogNew>
    );
  }
}
