import { customAlphabet } from 'nanoid';

/**
 * This is a wrapper around the npm package Nano ID that enables it
 * to return fixed-length nano IDs.
 *
 * If it's fine to use variable-length nano IDs, then just import
 * the Nano ID library directly. The library generates nano IDs with
 * 21 characters by default.
 *
 * By default, the character set includes A to Z, a to z, 0 to 9, - , and _.
 * This is a typical character set used for URL-safe Base64 encoding.
 *
 * Variable nano IDs with only letters and numbers does not make sense because
 * in the rare cases a nano ID is generated with only -'s and _'s, an
 * empty string will be returned. Therefore no such wrapper function is included
 * here.
 */

/**
 * Generates a nano unique ID of a fixed length.
 *
 * Set lettersAndNumbersOnly to true to remove underscores and dashes from this
 * set. Note that setting this to true slightly increases the likelihood of a
 * collision since multiple values returned by the library can become the
 * same value once -'s and _'s are removed (e.g. ABC_D = ABC-D = ABCD).
 *
 * It is recommended to use a length of at least 7 to reduce the likelihood
 * of a collision to a reasonable state. The library by default returns nano
 * IDs between 7-14 which we prefer to follow, although higher lengths wull
 * greatly reduce the likelihood for collision.
 *
 * Given that we're using a character set of 64 (or 62 without _'s and -'s),
 * the chances of collsions is (64 ^ length) which for 7 is approximately 4
 * trillion possible characters.
 *
 * @param  {Number} length
 * @param  {Boolean} lettersAndNumbersOnly
 * @return {String}
 */
export const generateFixedLengthNanoId = (
  length = 7,
  lettersAndNumbersOnly = false,
) => {
  // Define the alphabet based on the `lettersAndNumbersOnly` flag
  const alphabet = lettersAndNumbersOnly
    ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';

  // Create a custom ID generator with the given alphabet and length
  const customIdGenerator = customAlphabet(alphabet, length);

  // Generate and return the ID
  return customIdGenerator();
};

export default {
  generateFixedLengthNanoId,
};
