import { flatten } from 'lodash';

import moment from 'moment';

import locationHelper from 'optly/location';
import parseQueryParams from 'optly/utils/parse_query_params';

import {
  ApiFilterType,
  EntityType,
  FormatWarnings,
  UiEntityType,
  UiFilterType,
} from './constants';

// Array of allowed api filters. @example: ['project_id', 'start_time', 'entity_type', ...]
const API_FILTER_WHITELIST = Object.values(ApiFilterType);

// Array of allowed entity types. @example: ['multiarmed_bandit_test', 'ab_test', 'page', ...]
const UI_ENTITY_TYPE_WHITELIST = Object.values(UiEntityType).map(
  ({ type }) => type,
);

// Array of allowed UI filters. @example: ['end_time', 'entity', 'start_time', 'type']
const UI_FILTER_WHITELIST = flatten(
  Object.values(UiFilterType).map(({ filterName }) => filterName),
);

// The Change History UI and API uses an "entity" parameter with a value of "type:id" (e.g. ?entity=experiment:123)
const ENTITY_QUERY_PARAM_REGEX = /^\w+:\d+$/;

// The Change History UI "Search By type:id" Input will split on IDs or colon-delimited type:id values (e.g. '123 page:456')
const ENTITY_SEARCH_INPUT_REGEX = /((\w+:\d+)|\d+)/;

// If multiple "type" parameters are loaded, the dropdown will show "Multiple" as the selected option and highlight each type in the men
const ENTITY_TYPE_DROPDOWN_MULTIPLE_SELECTED_TEXT = 'Multiple';

// Only numbers can be used for the "id" parameter
const LOG_ID_REGEX = /^\d+$/;

/**
 * @name MULTILINE_STRING_JSON_VALUE_REGEX
 * @description Used and tested in computeApiBodyDiff. This map will return and
 *  outdent JSON values that are multiline strings. This is helpful in
 *  preventing awkward text wrapping and in ensuring that multiline
 *  strings have the full width of the container.
 *
 * @example "{
 *   "audience_conditions": "[\n  \"and\"\n]",
 *   "status": "paused",
 * }" => "{
 *   "audience_conditions": "
 * [\n  \"and\"\n]",
 *   "status": "paused",
 * }"
 */
const MULTILINE_STRING_JSON_VALUE_REGEX = {
  find: /": "(.*\\n.*)"/g,
  replace: '": "\n$1"',
};

/**
 * @name ESCAPED_NEW_LINE_BREAK_REGEX
 * @description  Used and tested in computeApiBodyDiff. This map will find and
 *  replace escaped line breaks. This is helpful in presenting custom code or stringified JSON
 *
 * @example "{
 *   "audience_conditions": "
 * [\n  \"and\"\n]",
 *   "status": "paused",
 * }" => "{
 *   "audience_conditions": "
 * [
 *   \"and\"
 * ]",
 * "status": "paused",
 * }"
 */
const ESCAPED_LINE_BREAK_REGEX = {
  find: /\\n/g,
  replace: '\n',
};

/**
 * @name ESCAPED_DOUBLE_QUOTE_REGEX
 * @description Used and tested in computeApiBodyDiff. This map will
 *  find and replace escaped double quotes. This helps to make
 *  JSON values that use double quotes more easily parsable at a glance.
 *
 * @example "{
 *   "audience_conditions": "
 * [
 *   \"and\"
 * ]",
 * "status": "paused",
 * }" => "{
 *   "audience_conditions": "
 * [
 *   "and"
 * ]",
 * "status": "paused",
 * }"
 */
const ESCAPED_DOUBLE_QUOTE_REGEX = {
  find: /\\"/g,
  replace: '"',
};

/**
 * @name extendJsonIfValueIsDefined
 * @description Used in and tested via computeApiBodyDiff. Provided an object, property, and value, only extend the object if the value is defined
 *
 * @example
 *  - extendJsonIfValueIsDefined({ acc: { prop1: 'Hello world' }, property: 'prop2', value: undefined }) => { prop1: 'Hello world' }
 *  - extendJsonIfValueIsDefined({ acc: { prop1: 'Hello world' }, property: 'prop2', value: 'Yolo' }) => { prop1: 'Hello world', prop2: 'Yolo' }
 *
 * @returns {Object}
 */
function extendJsonIfValueIsDefined({ acc, property, value }) {
  return {
    ...acc,
    ...(typeof value !== 'undefined' ? { [property]: value } : {}),
  };
}

/**
 * @name formatAndStringifyNonEmptyJson
 * @description Used in and tested via computeApiBodyDiff. Provided an object, stringify and pretty JSON if there are
 *  keys, otherwise return an empty string
 *
 * @example
 *  - formatAndStringifyNonEmptyJson({}) =>  ''
 *  - formatAndStringifyNonEmptyJson({ keyOne: 'name' }) =>  '{
 *    keyOne: 'name'
 *  }'
 *
 * @param json {Object}
 *
 * @returns {String}
 *  Stringified and pretty JSON or an empty string if there aren't any keys
 */
function formatAndStringifyNonEmptyJson(json) {
  return Object.keys(json).length > 0 ? JSON.stringify(json, null, 2) : '';
}

/**
 * @name tryToFormatStringifiedJson
 * @description Used in and tested via ComplexEntityTypeToTransformFnMap. Attempts to parse and stringify / indent
 *  JSON. If an error occurs, it just returns the original value.
 *
 * @example { really_cool_key: ["and", { cool_item: true }] } => "{
 *   really_cool_key: [
 *    "and",
 *    { cool_item: true }
 *    ]
 * }"
 *
 * @param itemToFormat {String} String JSON to parse and prettify
 *
 * @returns {String} Prettified JSON (if JSON) or non-JSON value passed
 */
function tryToFormatStringifiedJson(itemToFormat) {
  try {
    return JSON.stringify(JSON.parse(itemToFormat), null, 2);
  } catch (e) {
    return itemToFormat;
  }
}

/**
 * @name cleanFiltersForApi
 * @description Returns cleaned filters by removing unrecognized filters, removing unset filters, and stripping out null values
 *
 * @param filters {Object}
 *
 * @returns {Object}
 * @example
 *  {
 *    all_entities: false,
 *    per_page: 30,
 *    page: 1,
 *    project_id: 123,
 *    end_time: 'END_OF_TIME',
 *    entity: ['experiment:123'],
 *    entity_type: ['campaign', 'experiment:personalization],
 *    start_time: 'BEGINNING_OF_TIME',
 *  }
 */
export function cleanFiltersForApi(filters) {
  return Object.entries(filters).reduce(
    (accumulator, [filterKey, filterValue]) => {
      if (
        ![...API_FILTER_WHITELIST, ...UI_FILTER_WHITELIST].includes(
          filterKey,
        ) ||
        filterValue === null ||
        typeof filterValue === 'undefined'
      ) {
        return accumulator;
      }
      if (filterKey === UiFilterType.TYPE.filterName) {
        return {
          ...accumulator,
          [ApiFilterType.ENTITY_TYPE]: filterValue.reduce(
            (entityTypesAcc, type) => {
              // Filter out unrecognized values to avoid throwing when computeAllowedUrlFiltersFromUrl isn't used prior
              if (!UiEntityType[type]) {
                return entityTypesAcc;
              }
              return [...entityTypesAcc, ...UiEntityType[type].entityTypes];
            },
            [],
          ),
        };
      }
      return {
        ...accumulator,
        [filterKey]: filterValue,
      };
    },
    {},
  );
}

/* eslint-disable camelcase */
/**
 * @name ComplexEntityTypeToTransformFnMap
 * @description Used in computeApiBodyDiff. This map defines functions
 *  that, for a given entityType, should safely extend, map, or reduce only expected
 *  parts of a change. As you'll see in the examples below, other keys and
 *  values should always be passed through and the presence of values should never
 *  be assumed. The examples below defined the test expectations used
 *  in the component module ./tests.js file. The below examples should be used
 *  as a guide for how these should be added to or updated.
 *
 * @example ComplexEntityTypeToTransformFnMap.audience({}) => {}
 * @example ComplexEntityTypeToTransformFnMap.campaign({
 *      "other_key": "other_value"
 *   }) => {
 *      "other_key": "other_value"
 *   }
 * @example ComplexEntityTypeToTransformFnMap.experiment({
 *      "environments": {},
 *      "other_key_1": "other_value_1",
 *   }) => {
 *      "environments": {},
 *      "other_key_1": "other_value_1",
 *   }
 * @example ComplexEntityTypeToTransformFnMap.page({
 *      "other_key_1": "other_value_1",
 *      "conditions": "[\"and\"]"
 *   }) => {
 *      "other_key_1": "other_value_1",
 *      "conditions": "[\n  \"and\"\n]"
 *   }
 * @example ComplexEntityTypeToTransformFnMap.feature({
 *      "environments": {
 *        "production": {
 *          "rollout_rules": [
 *            {
 *              "audience_conditions": "[\"and\"]",
 *              "other_key_4": "other_value_4",
 *            },
 *          ],
 *          "other_key_3": "other_value_3",
 *      },
 *      "other_key_2": "other_value_2",
 *      },
 *      "other_key_1": "other_value_1",
 *   }) => {
 *      "environments": {
 *        "production": {
 *          "rollout_rules": [
 *            {
 *              "audience_conditions": "[\n  \"and\"\n]",
 *              "other_key_3": "other_value_3",
 *            },
 *          ],
 *      },
 *      "other_key_2": "other_value_2",
 *      },
 *      "other_key_1": "other_value_1",
 *   }
 */
export const ComplexEntityTypeToTransformFnMap = {
  /**
   * @name ComplexEntityTypeToTransformFnMap.audience
   * @description This transform fn prettifies conditions' string JSON
   */
  [EntityType.audience.entityType]: change => {
    const { conditions, ...changes } = change || {};
    return {
      ...changes,
      ...(conditions
        ? { conditions: tryToFormatStringifiedJson(conditions) }
        : {}),
    };
  },
  /**
   * @name ComplexEntityTypeToTransformFnMap.campaign
   * @description This transform fn prettifies url_targeting.conditions' string JSON
   */
  [EntityType.campaign.entityType]: change => {
    const { url_targeting, ...changes } = change || {};
    return {
      ...changes,
      ...(url_targeting
        ? {
            url_targeting: {
              ...url_targeting,
              conditions: tryToFormatStringifiedJson(url_targeting.conditions),
            },
          }
        : {}),
    };
  },
  /**
   * @name ComplexEntityTypeToTransformFnMap.experiment
   * @description This transform fn prettifies audience_conditions' and url_targeting.conditions' string JSON
   */
  [EntityType.experiment.entityType]: change => {
    const { audience_conditions, url_targeting, ...changes } = change || {};
    return {
      ...changes,
      ...(audience_conditions
        ? {
            audience_conditions: tryToFormatStringifiedJson(
              audience_conditions,
            ),
          }
        : {}),
      ...(url_targeting
        ? {
            url_targeting: {
              ...url_targeting,
              conditions: tryToFormatStringifiedJson(url_targeting.conditions),
            },
          }
        : {}),
    };
  },
  /**
   * @name ComplexEntityTypeToTransformFnMap.feature
   * @description This transform fn prettifies audience_conditions' string JSON within an environment's rollout_rules
   */
  [EntityType.feature.entityType]: change => {
    const { environments, ...changes } = change || {};
    return {
      ...changes,
      ...(environments
        ? {
            environments: {
              ...Object.entries(environments).reduce(
                (environmentsAcc, [environmentKey, environmentValue]) => ({
                  ...environmentsAcc,
                  [environmentKey]: {
                    ...environmentValue,
                    rollout_rules: environmentValue.rollout_rules.map(
                      rolloutRule => ({
                        ...rolloutRule,
                        audience_conditions: tryToFormatStringifiedJson(
                          rolloutRule.audience_conditions,
                        ),
                      }),
                    ),
                  },
                }),
                {},
              ),
            },
          }
        : {}),
    };
  },
  /**
   * @name ComplexEntityTypeToTransformFnMap.page
   * @description This transform fn prettifies conditions' string JSON
   */
  [EntityType.page.entityType]: change => {
    const { conditions, ...changes } = change || {};
    return {
      ...changes,
      ...(conditions
        ? { conditions: tryToFormatStringifiedJson(conditions) }
        : {}),
    };
  },
};
/* eslint-enable camelcase */

/**
 * @name computeAllowedUrlFiltersFromState
 * @description Returns allowed filters by removing empty filters or ones not in the filter whitelist
 *   Also creates an array of filters that should be removed
 * @example
 *    computeAllowedUrlFiltersFromState({
 *      end_time: null,
 *      entity: ['attribute:789'],
 *      type: [],
 *      project_id: 999,
 *      start_time: 'BEGINNING_OF_TIME',
 *      other_filter: 'other_value',
 *    }) => ([
 *      {
 *        entity: ['attribute:789'],
 *        project_id: 999,
 *        start_time: 'BEGINNING_OF_TIME',
 *      },
 *      ['type'],
 *    ])
 *
 * @param {Object} filters
 *
 * @returns {Array.<[Object, Array]>}
 */
export function computeAllowedUrlFiltersFromState(filters) {
  return Object.entries(filters).reduce(
    ([filtersAcc, removeParamsAcc], [filterName, filterValue]) => {
      if (!UI_FILTER_WHITELIST.includes(filterName)) {
        return [filtersAcc, removeParamsAcc];
      }

      // Do not include items that are undefined, null, or empty arrays
      if (
        typeof filterValue === 'undefined' ||
        filterValue === null ||
        (filterValue && !filterValue.length)
      ) {
        return [filtersAcc, removeParamsAcc.concat(filterName)];
      }

      return [
        {
          ...filtersAcc,
          [filterName]: filterValue,
        },
        removeParamsAcc,
      ];
    },
    [{}, []],
  );
}

/**
 * @name computeAllowedUrlFiltersFromUrl
 * @description Returns allowed filters from URL by removing unexpected filters and coercing and filtering entity
 *   values to finite Numbers and ensuring that only valid type values are included
 * @example
 *    computeAllowedUrlFiltersFromUrl(
 *      'https://changehistory.com?entity=attribute:789&entity=not_a_number&entity_type=experiment&entity_type=unexpected_entity&unexpected_filter=unexpected_value&start_time=BEGINNING_OF_TIME&end_time=END_OF_TIME',
 *    ) => ({
 *      end_time: 'END_OF_TIME',
 *      entity: ['attribute:789'],
 *      entity_type: ['experiment'],
 *      start_time: 'BEGINNING_OF_TIME',
 *    })
 *
 * @param {String} url (optional) Defaults to window.location.search via locationHelper.getSearch()
 *
 * @returns {Object}
 */
export function computeAllowedUrlFiltersFromUrl(
  url = locationHelper.getSearch(),
) {
  const filtersFromUrl = parseQueryParams(url);
  return Object.entries(filtersFromUrl).reduce(
    (filtersAcc, [paramName, paramValue]) => {
      if (!UI_FILTER_WHITELIST.includes(paramName)) {
        return filtersAcc;
      }

      let value = paramValue;

      // For the id param, coerce and filter params to a map of valid numbers
      if (paramName === UiFilterType.LOG_ID.filterName) {
        value = [].concat(paramValue).filter(item => LOG_ID_REGEX.test(item));
      }

      // For the entity param, coerce and filter params to a map of type:id key/value pairs
      if (paramName === UiFilterType.SEARCH.filterName) {
        value = []
          .concat(paramValue)
          .filter(item => ENTITY_QUERY_PARAM_REGEX.test(item));
      }

      // Ensure that all type params have expected values
      if (paramName === UiFilterType.TYPE.filterName) {
        value = []
          .concat(paramValue)
          .filter(item => UI_ENTITY_TYPE_WHITELIST.includes(item));
      }

      return {
        ...filtersAcc,
        [paramName]: value,
      };
    },
    {},
  );
}

/**
 * @name computeApiBody
 * @description
 *  Creates the stringified JSON output meant to resemble the before and after state of the API.
 *
 * @example
 * const changes = [
 *   {
 *     property: 'name',
 *     before: 'Old name',
 *     after: 'New name'
 *   },
 *   {
 *     property: 'description',
 *     before: 'Old description',
 *     after: 'New description'
 *   }
 * ]
 *
 * computeApiBodyDiff(changes) => ([
 *   '{
 *     name: 'Old name',
 *     description: 'Old description',
 *   }',
 *   '{
 *     name: 'Old name',
 *     description: 'Old description',
 *   }',
 * ]);
 *
 * @param {Array.<Object.<Object>>} subChangeList
 *  An array of a single change history change's sub-changes. Each item of the array will be an object that has
 *  a property, before, and after key.
 * @param {Object} prettyConfig
 * @param {String} prettyConfig.entityType
 * @param {Number} prettyConfig.prettyDiffLineCap
 *  Max amount of lines allowed to be formatted. Lines will be formatted, checked for length, and the unformatted lines
 *  will be returned if they exceed this.
 * @param {Boolean} prettyConfig.shouldPrettifyDiff
 *  Defaults to false. If true, will make the before and after diff's more readable via
 *  JSON formatting and string replacement
 *
 * @returns {Array.<String>,<String>}
 */
export function computeApiBodyDiff(
  subChangeList,
  { entityType, prettyDiffLineCap, shouldPrettifyDiff },
) {
  const [beforeJson, afterJson] = (subChangeList || []).reduce(
    ([beforeAcc, afterAcc], change) => {
      const { after, before, property } = change;
      return [
        extendJsonIfValueIsDefined({ property, acc: beforeAcc, value: before }),
        extendJsonIfValueIsDefined({ property, acc: afterAcc, value: after }),
      ];
    },
    [{}, {}],
  );

  if (shouldPrettifyDiff) {
    const complexEntityTypeToTransformFn =
      entityType && ComplexEntityTypeToTransformFnMap[entityType];
    const [
      beforeJsonFormatted,
      afterJsonFormatted,
      formatWarningEnum,
    ] = this.formatBeforeAndAfter(
      [beforeJson, afterJson],
      complexEntityTypeToTransformFn,
      prettyDiffLineCap,
    );

    return [beforeJsonFormatted, afterJsonFormatted, formatWarningEnum];
  }

  return [
    formatAndStringifyNonEmptyJson(beforeJson),
    formatAndStringifyNonEmptyJson(afterJson),
  ];
}

/**
 * @name computeAuthorTitleText
 * @description
 *  Creates a string with the first name, last name, and email, depending what's available
 *
 * @example
 *  - computeAuthorTitleText({ display_name: 'Optimizely System', email: 'should.not.show@optimizely.com' }) => (
 *    'Optimizely System'
 *  )
 *  - computeAuthorTitleText({ display_name: 'jim.halpert@dundermifflin.com', email: 'jim.halpert@dundermifflin.com', first_name: 'Jim', last_name: 'Halpert' }) => (
 *    'Jim Halpert (jim.halpert@dundermifflin.com)'
 *  )
 *  - computeAuthorTitleText({ display_name: 'jim.halpert@dundermifflin.com',email: 'jim.halpert@dundermifflin.com', first_name: 'Jim' }) => (
 *    'jim.halpert@dundermifflin.com'
 *  )
 *  - computeAuthorTitleText({ display_name: 'jim.halpert@dundermifflin.com',email: 'jim.halpert@dundermifflin.com', last_name: 'Halpert' }) => (
 *    'jim.halpert@dundermifflin.com'
 *  )
 *
 * @param {Object} user
 * @param {String} user.display_name (optional)
 * @param {String} user.email (optional)
 * @param {String} user.first_name (optional)
 * @param {String} user.last_name (optional)
 *
 * @returns {String}
 */
export function computeAuthorTitleText({
  email,
  display_name: displayName,
  first_name: firstName,
  last_name: lastName,
} = {}) {
  if (displayName !== email) {
    return displayName;
  }
  if (!firstName || !lastName) {
    return email || displayName || '';
  }
  return `${firstName} ${lastName} (${email})`;
}

/* eslint-disable camelcase */
/**
 * @name computeEntityFilterFromFetchedEntities
 * @description
 *  Manager change history will use the initialEntityFilter prop to initially fetch
 *  changes related to that entity. This method will take an object of fetch entities
 *  and compute the array of entityType:entityId strings needed for that initial request
 * @example computeEntityFilterFromFetchedEntities({
 *  project: [{ id: 111 }],
 *  environment: [{ id: 222 }],
 *  campaign: [{ id: 333, metrics: [{ event_id: 444 }], view_ids: [666],  }],
 *  experiment: [{ audience_ids: [777], feature_flag_id: 888, id: 999, metrics: [{ event_id: 123 }] }],
 *  feature: [{ environments: { production: { rollout_rules: [{ audience_ids: [456, 789 ] }]}}}],
 *  group: [{ id: 822, public_entities: [{ id: 333 }, { id: 999 }] }],
 *  section: [{ id: 484, feature_flag_id: 848 }],
 * }) => ([
 *  'project:111',
 *  'environment:222',
 *  'campaign:333', 'event:444', 'section:555', 'page:666',
 *  'audience:777', 'feature:888', 'experiment:999', 'event:123',
 *  'audience:456', 'audience:789',
 *  'group:822',
 *  'section:484',  'feature:848',
 * ])
 *
 * @param {Object} fetchedEntities Internal API entites returned within keys of type EntityType[type].entityType
 * @returns {Array.<String>} Returns an array of strings of format entityType:entityId
 */
export function computeEntityFilterFromFetchedEntities(fetchedEntities = {}) {
  const campaigns = fetchedEntities[EntityType.campaign.entityType] || [];
  const experiments = fetchedEntities[EntityType.experiment.entityType] || [];
  const groups = fetchedEntities[EntityType.group.entityType] || [];
  const features = fetchedEntities[EntityType.feature.entityType] || [];
  const sections = fetchedEntities[EntityType.section.entityType] || [];

  const entityFilters = [];

  // e.g. 'campaign:333', 'event:444', 'page:666'
  campaigns.forEach(({ id, metrics, view_ids }) => {
    entityFilters.push(`${EntityType.campaign.entityType}:${id}`);
    (metrics || [])
      .filter(({ event_id }) => Number.isFinite(event_id))
      .forEach(({ event_id }) =>
        entityFilters.push(`${EntityType.event.entityType}:${event_id}`),
      );
    (view_ids || []).forEach(pageId =>
      entityFilters.push(`${EntityType.page.entityType}:${pageId}`),
    );
  });

  // e.g. 'audience:777', 'feature:888', 'experiment:999', 'event:123'
  experiments.forEach(({ audience_ids, feature_flag_id, id, metrics, layer_id }) => {
      (audience_ids || []).forEach(audienceId =>
        entityFilters.push(`${EntityType.audience.entityType}:${audienceId}`),
      );
      if (feature_flag_id) {
        entityFilters.push(
          `${EntityType.feature.entityType}:${feature_flag_id}`,
        );
      }
      entityFilters.push(`${EntityType.experiment.entityType}:${id}`);
      if (layer_id) {
        entityFilters.push(`${EntityType.metric_set.entityType}:${layer_id}`);
      }
      (metrics || [])
        .filter(({ event_id }) => Number.isFinite(event_id))
        .forEach(({ event_id }) =>
          entityFilters.push(`${EntityType.event.entityType}:${event_id}`),
        );
    },
  );

  // e.g. 'audience:456', 'feature:789'
  features.forEach(({ environments, id }) => {
    entityFilters.push(`${EntityType.feature.entityType}:${id}`);
    Object.values(environments || []).forEach(({ rollout_rules }) =>
      (rollout_rules || []).forEach(({ audience_ids }) =>
        (audience_ids || []).forEach(audienceId => {
          entityFilters.push(`${EntityType.audience.entityType}:${audienceId}`);
        }),
      ),
    );
  });

  // e.g. 'group:822' - If any experiment or campaign IDs are in the public_entities object, push a filter for taht group ID
  let entityIds;
  groups.forEach(({ id: groupId, public_entities }) => {
    entityIds = entityIds || [...campaigns, ...experiments].map(({ id }) => id);
    if (public_entities.some(({ id }) => entityIds.includes(id))) {
      entityFilters.push(`${EntityType.group.entityType}:${groupId}`);
    }
  });

  // e.g. 'section:484',  'feature:848',
  sections.forEach(({ id, feature_flag_id }) => {
    entityFilters.push(`${EntityType.section.entityType}:${id}`);
    if (feature_flag_id) {
      entityFilters.push(`${EntityType.feature.entityType}:${feature_flag_id}`);
    }
  });

  // Return an array of deduplicated filters
  return [...new Set(entityFilters)];
}
/* eslint-enable camelcase */

/**
 * @name computeEntityTypeDropdownSelectedValue
 * @description
 *  Provided an array of UiEntityType.type, returns the placeholder, readable selected value (if one), or "Multiple" value (if more than one)
 *
 * @example
 *  - computeEntityTypeDropdownSelectedValue(['ab_test']) => 'A/B Test'
 *  - computeEntityTypeDropdownSelectedValue(['ab_test', 'attribute']) => 'Multiple'
 *  - computeEntityTypeDropdownSelectedValue([]) => 'Any'
 *  - computeEntityTypeDropdownSelectedValue() => 'Any'
 *
 * @param {Array} types - of type UiEntityType
 *
 * @returns {String}
 */
export function computeEntityTypeDropdownSelectedValue(types = []) {
  const [selectedEntityType] = types || [];

  if (types.length > 1) {
    return ENTITY_TYPE_DROPDOWN_MULTIPLE_SELECTED_TEXT;
  }

  const selectedEntityTypeConfig = UiEntityType[selectedEntityType];
  if (selectedEntityTypeConfig) {
    return selectedEntityTypeConfig.readableName;
  }

  return UiFilterType.TYPE.placeholder;
}

/**
 * @name computeReadableType
 * @description
 *  Based off an entity's "type" and optional "sub_type", returns the EntityType.subTypeToReadableNameMap, readableName, or if nothing else, type
 *
 * @example
 *  - computeReadableType({ subType: 'personalization', type: 'experiment' }) => 'Experience'
 *  - computeReadableType({ subType: 'unknown_sub_type', type: 'experiment' }) => 'Experiment'
 *  - computeReadableType({ subType: undefined, type: 'attribute' }) => 'Attribute'
 *  - computeReadableType({ subType: undefined, type: 'unknown_type' }) => 'unknown_type'
 *
 * @param {String} subType
 * @param {String} type
 *
 * @returns {String}
 */
export function computeReadableType({ subType, type }) {
  const entityTypeEnum = EntityType[type];
  if (entityTypeEnum) {
    const { readableName, subTypeToReadableNameMap } = entityTypeEnum;
    return (
      (subTypeToReadableNameMap && subTypeToReadableNameMap[subType]) ||
      readableName
    );
  }
  return type;
}

/**
 * @name computeSearchIdsArray
 * @description Split search input string and coerce to a list of colon delimited type:id strings, filtering out invalid
 *              items or adding the default 'experiment' type for standalone IDs
 * @example
 *  - computeSearchIdsArray('123 page:456 abc789 def') => [experiment:123, page:456, experiment:789]
 *
 * @param {String} searchText
 * @return {Array.<string>}
 */
export function computeSearchIdsArray(searchText) {
  const searchTextToParse = searchText || '';
  return searchTextToParse
    .split(ENTITY_SEARCH_INPUT_REGEX)
    .reduce((accumulator, item) => {
      if (
        !item ||
        accumulator.includes(item) ||
        !ENTITY_SEARCH_INPUT_REGEX.test(item)
      ) {
        return accumulator;
      }
      if (Number.isFinite(Number(item))) {
        return accumulator.concat(
          `${EntityType.experiment.entityType}:${item}`,
        );
      }
      return accumulator.concat(item);
    }, []);
}

/**
 * @name computeUpdatedTypeList
 * @description Provided a list of currently selected types and selected type, return a list of new types.
 *  Optionally allows for the appending of valid types when shouldAppendNonAnyType is truthy
 * @example
 *  - computeUpdatedTypeList({ selectedTypes: [], type: 'attribute' }) => ['attributes']
 *  - computeUpdatedTypeList({ selectedTypes: ['attribute'], shouldAppendNonAnyType: false, type: 'page' }) => ['page']
 *  - computeUpdatedTypeList({ selectedTypes: ['attribute'], shouldAppendNonAnyType: true, type: 'page' }) => ['attribute', 'page']
 *  - computeUpdatedTypeList({ selectedTypes: ['attribute', 'page'], shouldAppendNonAnyType: true, type: 'attribute' }) => ['page']
 *  - computeUpdatedTypeList({ selectedTypes: ['attribute'], shouldAppendNonAnyType: true, type: 'any' }) => []
 *
 * @param {Array.<string>} selectedTypes
 * @param {String} type
 * @param {Boolean} shouldAppendNonAnyType (optional)
 *
 * @return {Array.<string>}
 */
export function computeUpdatedTypeList({
  selectedTypes = [],
  type,
  shouldAppendNonAnyType = false,
}) {
  if (type === UiEntityType.any.type) {
    return [];
  }
  if (shouldAppendNonAnyType && selectedTypes.includes(type)) {
    return selectedTypes.filter(item => item !== type);
  }
  return shouldAppendNonAnyType ? [...selectedTypes, type] : [type];
}

/**
 * @name formatBeforeAndAfter
 * @description Used in and tested via computeApiBodyDiff. Provided a transformFn and array of diffs, this will
 *  transform a diff (change) via a ComplexEntityTypeToTransformFnMap method and find and replace strings
 *  for better, more readable formatting
 *
 * @param diffs {Array.<[Object]>}
 * @param complexEntityTypeTransformFn {Function}
 * @param prettyDiffLineCap {Number}
 *
 * @returns {Array.<string]>}
 */
export function formatBeforeAndAfter(
  diffs,
  complexEntityTypeTransformFn,
  prettyDiffLineCap,
) {
  const formattedAndStringifiedJson = diffs
    .map(diff =>
      typeof complexEntityTypeTransformFn === 'function' &&
      typeof diff === 'object'
        ? complexEntityTypeTransformFn(diff)
        : diff,
    )
    .map(formatAndStringifyNonEmptyJson);

  // Skip regex replacement if there will be too many lines to performantly load in CodeDiff
  // TODO(APPX-1352) Find better solution once https://github.com/praneshr/react-diff-viewer/issues/25 is fixed
  if (
    this.hasAnyDiffExceededLineCap(
      formattedAndStringifiedJson,
      prettyDiffLineCap,
    )
  ) {
    return [...formattedAndStringifiedJson, FormatWarnings.TOO_LARGE.id];
  }
  return formattedAndStringifiedJson.map(diff =>
    diff
      .replace(
        MULTILINE_STRING_JSON_VALUE_REGEX.find,
        MULTILINE_STRING_JSON_VALUE_REGEX.replace,
      )
      .replace(
        ESCAPED_DOUBLE_QUOTE_REGEX.find,
        ESCAPED_DOUBLE_QUOTE_REGEX.replace,
      )
      .replace(ESCAPED_LINE_BREAK_REGEX.find, ESCAPED_LINE_BREAK_REGEX.replace),
  );
}

/**
 * @name getReadableDateRange
 * @description Provided startTime & endTime selections along with the earliestSelectableDate,
 * returns a readable date Range string for use in the DateFilter
 *
 * @example
 *  getReadableDateRange('2019-07-29T21:23:19.794Z', '2019-07-09T05:00:00.000Z', {Moment}) =>
 *  - Apr 8, 2019 - Apr 10, 2019 (i.e. both a startTime and endTime date passed)
 *  - Apr 8, 2019 (i.e. both the startTime and endTime date are the same day)
 *  - Apr 8, 2019 to Today (i.e. endTime date is Today)
 *  - Through Apr 10, 2019 (i.e. startTime date is the same as earliestSelectableDate)
 *  - Anytime (i.e. no startTime/endTime selected)
 *
 * @param {String} startTime
 * @param {String} endTime
 * @param {Object} earliestSelectableDate - Moment.js date object
 *
 * @returns {String}
 */
export function getReadableDateRange(
  startTime,
  endTime,
  earliestSelectableDate,
) {
  const readableStartTime = startTime
    ? moment(startTime).format('MMM D, YYYY')
    : null;
  if (
    startTime &&
    endTime &&
    moment(startTime).isSame(moment(endTime), 'day')
  ) {
    // range is a single day
    if (moment(startTime).isSame(moment(), 'day')) {
      return 'Today';
    }
    return readableStartTime;
  }
  // range is more than a single day
  const readableEndTime = endTime
    ? moment(endTime).format('MMM D, YYYY')
    : null;
  if (readableStartTime && moment(endTime).isSame(moment(), 'day')) {
    return `${readableStartTime} to Today`;
  }
  if (
    readableEndTime &&
    moment(startTime).isSame(earliestSelectableDate, 'day')
  ) {
    return `Through ${readableEndTime}`;
  }
  if (readableStartTime && readableEndTime) {
    return `${readableStartTime} - ${readableEndTime}`;
  }
  return UiFilterType.DATE_RANGE.placeholder;
}

/**
 * @name hasAnyDiffExceededLineCap
 * @description Used in formatBeforeAndAfter. Provided an array of strings and a max line cap, this will return
 *  a Boolean indicate whether the line cap was exceeded by any diff.
 *
 * @example
 *  hasAnyDiffExceededLineCap(['string_with_2_\n_chars_\n_\n', 'string_with_2_\n_chars_\n'], 2) => false
 *  hasAnyDiffExceededLineCap(['string_with_3_\n_chars_\n_\n', 'string_with_2_\n_chars_\n'], 2) => true
 *  hasAnyDiffExceededLineCap(['string_with_3_\n_chars_\n_\n', 'string_with_3_\n_chars_\n_\n'], 2) => true
 *
 * @param {Array<String>} diffStrings
 * @param {Number} prettyDiffLineCap
 *
 * @returns {Boolean}
 */
export function hasAnyDiffExceededLineCap(
  formattedDiffStrings,
  prettyDiffLineCap,
) {
  return formattedDiffStrings
    .map(formattedDiffString => {
      if (typeof formattedDiffString !== 'string') {
        return null;
      }
      return (
        (formattedDiffString.match(ESCAPED_LINE_BREAK_REGEX.find) || '')
          .length + 1
      );
    })
    .some(count => count > prettyDiffLineCap);
}

/**
 * @name isUiFiltered
 * @description Provided change history filters, checks if any UI filter has been set
 *
 * @param {Object} filters
 *
 * @returns {Boolean}
 */
export function isUiFiltered(filters) {
  return UI_FILTER_WHITELIST.some(filterName =>
    Array.isArray(filters[filterName])
      ? filters[filterName].length
      : !!filters[filterName],
  );
}

/**
 * @name timeAgo
 * @description Provided a dateString, returns a relative, readable string
 * @example
 *  timeAgo('2019-07-29T21:23:19.794Z') =>
 *  - Today, 11:31 AM (i.e. if today, "Today" and time)
 *  - Yesterday, 10:27 AM (i.e. if yesterday, "yesterday" and time)
 *  - July 8, 6:01 PM (i.e. if this year, full month and time)
 *  - Dec 31 2018, 11:59 PM (i.e. if a previous year, abbreviated month and time)
 *
 * @param {String} dateString
 *
 * @returns {String}
 */
export function timeAgo(dateString) {
  const date = moment(dateString);
  const now = moment();

  if (date.isSame(now, 'day')) {
    return `Today, ${date.format('LT')}`; // e.g. Today, 11:31 AM
  }

  const yesterday = now.subtract(1, 'days');
  if (date.isSame(yesterday, 'day')) {
    return `Yesterday, ${date.format('LT')}`; // e.g. Yesterday, 10:27 AM
  }

  if (date.isSame(now, 'year')) {
    return date.format('MMMM D, LT'); // e.g. July 8, 6:01 PM
  }

  return date.format('MMM D YYYY, LT'); // e.g. Dec 31 2018, 11:59 PM
}

export default {
  cleanFiltersForApi,
  ComplexEntityTypeToTransformFnMap,
  computeAllowedUrlFiltersFromState,
  computeAllowedUrlFiltersFromUrl,
  computeApiBodyDiff,
  computeAuthorTitleText,
  computeEntityTypeDropdownSelectedValue,
  computeEntityFilterFromFetchedEntities,
  computeReadableType,
  computeSearchIdsArray,
  computeUpdatedTypeList,
  formatBeforeAndAfter,
  getReadableDateRange,
  hasAnyDiffExceededLineCap,
  isUiFiltered,
  timeAgo,
};
