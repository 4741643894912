import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  ButtonIcon,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Typography,
} from '@optimizely/axiom';

import moment from 'moment';

import { connect } from 'core/ui/decorators';

import {
  actions as HypothesisLinkingActions,
  getters as HypothesisLinkingGetters,
} from 'bundles/p13n/modules/hypothesis_linking';

const FORM_STYLES = {
  padding: '16px 32px',
  backgroundColor: '#F5F5F5',
};
const HEADING_STYLE = { paddingBottom: '8px' };
const CHECKBOX_CONTAINER_STYLE = { paddingTop: '32px' };
const TYPOGRAPHY_STYLE = { letterSpacing: '-0.6%', color: '#707070' };
const DATE_PICKER_WRAPPER_STYLE = { position: 'relative' };
const CANCEL_BUTTON_STYLE = {
  marginBottom: '9px',
  position: 'absolute',
  right: '7px',
};

export class DatePickerWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    hasClearButton: PropTypes.bool,
    onClearButtonClick: PropTypes.func,
  };

  static defaultProps = {
    hasClearButton: false,
    onClearButtonClick: () => {},
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const nestedDiv = this.wrapperRef.current.querySelector(
      '[data-oui-component="true"]',
    );

    if (nestedDiv) {
      nestedDiv.style.width = '100%';
    }
  }

  componentWillUnmount() {
    this.wrapperRef = null;
  }

  render() {
    const { children, hasClearButton, onClearButtonClick } = this.props;
    return (
      <div
        className="flex flex--row flex-align--end"
        style={DATE_PICKER_WRAPPER_STYLE}
        data-testid="date-picker-wrapper">
        <div className="flex--1" ref={this.wrapperRef}>
          {children}
        </div>
        {hasClearButton && (
          <div style={CANCEL_BUTTON_STYLE}>
            <ButtonIcon
              data-testid="date-picker-wrapper-clear-button"
              iconName="xmark"
              style="plain"
              size="small"
              onClick={onClearButtonClick}
            />
          </div>
        )}
      </div>
    );
  }
}

class HypothesisService {
  static updateName = value => {
    HypothesisLinkingActions.setHypothesisName(value);
  };

  static updatePlannedStartDate = date => {
    const dateString = date?.format() ?? null;
    HypothesisLinkingActions.setHypothesisPlannedStartDate(dateString);
  };

  static updatePlannedEndDate = date => {
    const dateString = date?.format() ?? null;
    HypothesisLinkingActions.setHypothesisPlannedEndDate(dateString);
  };

  static resetForm() {
    HypothesisLinkingActions.saveHypothesisToLink(null);
    HypothesisLinkingActions.setHypothesisName(null);
    HypothesisLinkingActions.setHypothesisPlannedEndDate(null);
    HypothesisLinkingActions.setHypothesisPlannedStartDate(null);
  }

  static toggleNewTabOption(value) {
    HypothesisLinkingActions.setHypothesisOpenInNewTabAfterCreation(value);
  }

  static cancelHypothesis() {
    HypothesisLinkingActions.shouldCreateHypothesis(false);
  }
}

@connect({
  hypothesisPlannedEndDate: HypothesisLinkingGetters.hypothesisPlannedEndDate,
  hypothesisPlannedStartDate:
    HypothesisLinkingGetters.hypothesisPlannedStartDate,
  openInNewTabAfterHypothesisCreation:
    HypothesisLinkingGetters.openInNewTabAfterHypothesisCreation,
})
class HypothesisForm extends Component {
  static propTypes = {
    hypothesisPlannedEndDate: PropTypes.string,
    hypothesisPlannedStartDate: PropTypes.string,
    openInNewTabAfterHypothesisCreation: PropTypes.bool,
  };

  static defaultProps = {
    hypothesisPlannedEndDate: '',
    hypothesisPlannedStartDate: '',
    openInNewTabAfterHypothesisCreation: false,
  };

  componentWillUnmount() {
    HypothesisService.resetForm();
  }

  componentDidMount() {
    HypothesisLinkingActions.setHypothesisOpenInNewTabAfterCreation(false);
  }

  render() {
    const {
      hypothesisPlannedStartDate,
      hypothesisPlannedEndDate,
      openInNewTabAfterHypothesisCreation,
    } = this.props;

    return (
      <div style={FORM_STYLES} data-testid="hypothesis-form">
        <h5 style={HEADING_STYLE}>New Hypothesis</h5>
        <Form className="width--1-1" data-testid="hypothesis-creation-form">
          <Form.Section className="width--1-1">
            <Form.Item>
              <Input
                className="lego-text-input width--3-5"
                onChange={e => HypothesisService.updateName(e.target.value)}
                placeholder="Add a title"
                label="Hypothesis Title"
                type="text"
                required={true}
                data-testid="hypothesis-name-input"
              />
            </Form.Item>

            <Form.Row isFullWidth={true}>
              <Form.Item>
                <DatePickerWrapper
                  data-testid="start-date-picker-wrapper"
                  hasClearButton={!!hypothesisPlannedStartDate}
                  onClearButtonClick={() => {
                    HypothesisService.updatePlannedStartDate(null);
                  }}>
                  <DatePicker
                    className="width--1-1 "
                    inputLabel="Planned Start Date"
                    inputPlaceholder="Select start date"
                    isAbsolutelyPositioned={true}
                    isOutsideRange={date =>
                      hypothesisPlannedEndDate
                        ? date.isAfter(hypothesisPlannedEndDate)
                        : false
                    }
                    value={
                      hypothesisPlannedStartDate
                        ? moment(hypothesisPlannedStartDate)
                        : null
                    }
                    onDateChange={HypothesisService.updatePlannedStartDate}
                    data-testid="start-date-picker"
                  />
                </DatePickerWrapper>
              </Form.Item>
              <Form.Item>
                <DatePickerWrapper
                  data-testid="end-date-picker-wrapper"
                  hasClearButton={!!hypothesisPlannedEndDate}
                  onClearButtonClick={() =>
                    HypothesisService.updatePlannedEndDate(null)
                  }>
                  <DatePicker
                    className="width--1-1"
                    inputLabel="Planned End Date"
                    inputPlaceholder="Select end date"
                    isAbsolutelyPositioned={true}
                    isOutsideRange={date =>
                      hypothesisPlannedStartDate
                        ? date.isBefore(hypothesisPlannedStartDate)
                        : false
                    }
                    value={
                      hypothesisPlannedEndDate
                        ? moment(hypothesisPlannedEndDate)
                        : null
                    }
                    onDateChange={HypothesisService.updatePlannedEndDate}
                    data-testid="end-date-picker"
                  />
                </DatePickerWrapper>
              </Form.Item>
            </Form.Row>
            <Typography
              style={TYPOGRAPHY_STYLE}
              type="caption"
              data-testid="date-description">
              The Planned Start Date and Planned End Date refer to the overall
              experiment timeline and do not impact the actual running dates
              within this experiment.
            </Typography>
            <Form.Item>
              <div style={CHECKBOX_CONTAINER_STYLE}>
                <Checkbox
                  checked={openInNewTabAfterHypothesisCreation}
                  label="After creating a new hypothesis, open it in a new browser tab."
                  onChange={() =>
                    HypothesisService.toggleNewTabOption(
                      !openInNewTabAfterHypothesisCreation,
                    )
                  }
                  data-testid="new-tab-checkbox"
                />
              </div>
            </Form.Item>
          </Form.Section>
        </Form>
        <Button
          variant="outline"
          size="small"
          onClick={HypothesisService.cancelHypothesis}
          data-testid="cancel-hypothesis-button">
          Cancel
        </Button>
      </div>
    );
  }
}

export default HypothesisForm;
