export const AUDIENCE_OPTIONS = {
  EVERYONE: 'everyone',
  CURRENT_AUDIENCE: 'current_audience',
  OTHER_AUDIENCE: 'other_audience',
};

export const MATCH_TYPES = {
  OR: 'or',
  AND: 'and',
};

export default { AUDIENCE_OPTIONS, MATCH_TYPES };
