/* eslint-disable class-methods-use-this */
import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import DraggableList from 'react-draggable-list';
import { withTrack } from '@optimizely/segment-js/dist/decorators';
import {
  Attention,
  Dropdown,
  HelpPopover,
  Link,
  Icon,
  Pill,
  Button,
  ButtonIcon,
  Tile,
} from 'optimizely-oui';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Immutable, { toImmutable } from 'optly/immutable';
import flux from 'core/flux';
import guid from 'optly/utils/guid';
import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import { deepEqual } from 'core/utils/react';
import UrlHelper from 'optly/services/url_helper';

// FOR OUTLIER FILTERING WHITELIST
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
// END OUTLIER FILTERING WHITELIST
import {
  actions as CurrentProjectActions,
  getters as CurrentProjectGetters,
} from 'optly/modules/current_project';
import {
  actions as EventActions,
  getters as EventGetters,
} from 'optly/modules/entity/event';
import LayerFns from 'optly/modules/entity/layer/fns';
import LayerGetters from 'optly/modules/entity/layer/getters';
import {
  enums as LayerExperimentEnums,
  fns as LayerExperimentFns,
} from 'optly/modules/entity/layer_experiment';
import {
  constants as MetricConstants,
  fns as MetricFns,
} from 'optly/modules/entity/metric';
import { getters as MetricTemplateGetters } from 'optly/modules/entity/metric_template';
import {
  actions as MetricsManagerModuleActions,
  getters as MetricsManagerModuleGetters,
} from 'bundles/p13n/modules/metrics_manager';
import ProjectActions from 'optly/modules/entity/project/actions';
import ProjectEnums from 'optly/modules/entity/project/enums';
import ProjectFns from 'optly/modules/entity/project/fns';
import { getters as ViewGetters } from 'optly/modules/entity/view';
import {
  fns as PermissionsModuleFns,
  getters as PermissionsModuleGetters,
} from 'optly/modules/permissions';
import {
  getters as CurrentLayerGetters,
  actions as CurrentLayerActions,
} from 'bundles/p13n/modules/current_layer';

import FilterList from 'react_components/filter_list';
import LoadingOverlay from 'react_components/loading_overlay';

import CreateEventDialogForMetrics from 'bundles/p13n/components/data_layer/create_event';

import MetricEditor from '../metric_editor';
import OutlierFilter from '../outlier_filter';
import {
  actions as MetricsPickerModuleActions,
  constants as MetricsPickerModuleConstants,
  getters as MetricsPickerModuleGetters,
} from './component_module';
import MetricsDragAndDrop from './subcomponents/metrics_drag_and_drop';
import EmptyMetrics from '../empty_metrics';
import {
  MetricDescription,
  MetricName,
} from './subcomponents/metrics_tile_info';

import { getFullMetricDescription } from '../metrics_manager_utils';
import { MetricsModalWrapper } from '../../../metrics_modal/metrics_modal_wrapper';

const MultiArmedBanditWarning = (
  <div
    className="soft--ends push--sides"
    data-test-section="multiarmed-bandit-warning">
    <Attention type="brand">
      You can add multiple metrics, but multi-armed bandit optimizations only
      use the primary metric to determine traffic allocation. You can't change
      or remove the primary metric after the optimization has started.
    </Attention>
  </div>
);

// Full Stack Only
class TokenTemplate extends React.Component {
  static propTypes = {
    anySelected: PropTypes.number.isRequired,
    commonProps: PropTypes.instanceOf(Object).isRequired,
    dragHandle: PropTypes.func.isRequired,
    item: PropTypes.instanceOf(Object).isRequired,
    itemSelected: PropTypes.number.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const { anySelected } = this.props;
    // Update component only when nothing is selected. This will avoid updating the
    // component while dragging is happening.
    return !anySelected || !nextProps.anySelected;
  }

  render() {
    const {
      anySelected,
      commonProps,
      dragHandle,
      item,
      itemSelected,
    } = this.props;

    const {
      alias,
      canEditPrimaryMetric,
      currentlyEditingMetricWrapper,
      disableEdit,
      editMetricFn,
      index,
      layer,
      metricWrapper,
      pushMetricBottomFn,
      pushMetricTopFn,
      removeMetricFn,
      workingMetricWrappers,
    } = item;

    const {
      onCancel,
      onCommit,
      onEditMetric,
      onUpdate,
      isCustomProject,
    } = commonProps;

    const editClickFn = () => {
      editMetricFn();
      onEditMetric();
    };

    const canChangeMetric =
      index || !layer.get('earliest') || canEditPrimaryMetric;

    const shouldShowFDRNotification =
      index === MetricsPickerModuleConstants.METRIC_LIMIT &&
      !itemSelected &&
      !LayerFns.isMultiArmedBandit(layer);

    const shouldShowMABNotification =
      index === 0 &&
      LayerFns.isMultiArmedBandit(layer) &&
      workingMetricWrappers.size > 1 &&
      !itemSelected;

    const token = (
      <Pill
        backgroundColor={!index ? 'primary' : 'secondary'}
        description={getFullMetricDescription(metricWrapper)}
        isDismissible={true}
        isDraggable={true}
        name={metricWrapper.get('name')}
        onDismiss={removeMetricFn}
        order={index + 1}
        testSection="metric-token"
      />
    );

    if (!canChangeMetric) {
      return null;
    }

    return (
      <div
        className={classNames({
          'border--top': !anySelected,
        })}
        data-test-section="layer-metric-row"
        data-event-api-name={metricWrapper.get('eventApiName')}
        data-event-id={metricWrapper.getIn(['metric', 'event_id'])}>
        {(!currentlyEditingMetricWrapper ||
          (currentlyEditingMetricWrapper &&
            currentlyEditingMetricWrapper.get('alias') !== alias)) && (
          <div className="flex flex-align--center">
            <div className="push--ends">{dragHandle(token)}</div>
            <div className="push-double--left flex--1" />
            {!index && (
              <div className="push-double--left push--right muted">
                Primary Metric
              </div>
            )}
            <Dropdown
              arrowIcon="down"
              buttonContent="Actions"
              size="small"
              style="plain"
              placement="bottom-end"
              testSection="actions-dropdown">
              <Dropdown.Contents>
                {!disableEdit && (
                  <Dropdown.ListItem hideOnClick={true}>
                    <Dropdown.BlockLink
                      onClick={editClickFn}
                      testSection="edit-metric-button">
                      <Dropdown.BlockLinkText text="Edit" />
                    </Dropdown.BlockLink>
                  </Dropdown.ListItem>
                )}
                <Dropdown.ListItem hideOnClick={true}>
                  <Dropdown.BlockLink
                    onClick={pushMetricTopFn}
                    testSection="push-metric-top-button">
                    <Dropdown.BlockLinkText text="Move to top" />
                  </Dropdown.BlockLink>
                </Dropdown.ListItem>
                <Dropdown.ListItem hideOnClick={true}>
                  <Dropdown.BlockLink
                    onClick={pushMetricBottomFn}
                    testSection="push-metric-bottom-button">
                    <Dropdown.BlockLinkText text="Move to bottom" />
                  </Dropdown.BlockLink>
                </Dropdown.ListItem>
              </Dropdown.Contents>
            </Dropdown>
          </div>
        )}
        {currentlyEditingMetricWrapper &&
          currentlyEditingMetricWrapper.get('alias') === alias && (
            <div className="push--ends">
              <MetricEditor
                dragHandle={dragHandle}
                indexValue={index + 1}
                isCustomProject={isCustomProject}
                layer={layer}
                metricWrapper={metricWrapper}
                onCancel={onCancel}
                onConfirm={onCommit}
                onUpdate={onUpdate}
              />
            </div>
          )}
        {shouldShowFDRNotification && (
          <div className="draggable-token--warning lego-media">
            <Icon
              className="lego-icon color--brand lego-media__img"
              name="circle-exclamation"
              size="small"
            />

            <div className="lego-media__body">
              <p>
                It will take longer to see any movement for lower ranked
                metrics. This helps reach statistical significance sooner for
                the events you care most about. We recommend ordering events by
                priority.
              </p>
            </div>
          </div>
        )}
        {shouldShowMABNotification && MultiArmedBanditWarning}
      </div>
    );
  }
}

@withTrack
@connect(props => {
  return {
    accountPermissions: AdminAccountGetters.accountPermissions,
    clickEvents: MetricsPickerModuleGetters.allPageClickEvents,
    currentProjectOnly: MetricsPickerModuleGetters.shouldUseCurrentProject,
    customEvents: MetricsPickerModuleGetters.customEventsGetterV2,
    isFullStackProject: CurrentProjectGetters.isFullStackProject,
    isFlagsProject: CurrentProjectGetters.isFlagsProject,
    layer: CurrentLayerGetters.layer,
    layerExperiments: LayerGetters.experimentsByLayerId(props.layer.get('id')),
    metricTemplates: MetricTemplateGetters.activeMetrics,
    pageEvents: MetricsPickerModuleGetters.allPageViewEvents,
    project: CurrentProjectGetters.project,
    canUseCrossProjectMetrics:
      PermissionsModuleGetters.canUseCrossProjectMetrics,
    views: ViewGetters.entityCache,
    workingMetricWrappers: MetricsManagerModuleGetters.workingMetricWrappers,
  };
})
class MetricsPicker extends React.Component {
  static displayName = 'MetricsPicker';

  constructor(props) {
    super(props);

    const {
      isFullStackProject,
      isFlagsProject,
      layerExperiments,
      metricWrappers,
    } = props;

    const activeExperiments = LayerExperimentFns.filterLayerExperimentsByStatus(
      layerExperiments,
      LayerExperimentEnums.statusFilter.ACTIVE,
    );

    activeExperiments.map(experiment => {
      this.isStatsAcceleratorExperiment =
        LayerExperimentFns.isStatsAcceleratorExperiment(experiment) ||
        this.isStatsAcceleratorExperiment ||
        false;
      this.isMultiArmedBanditExperiment =
        LayerExperimentFns.isMultiArmedBandit(experiment) ||
        this.isMultiArmedBanditExperiment ||
        false;
    });

    MetricsManagerModuleActions.updateWorkingMetricWrappers(metricWrappers);
  }

  static propTypes = {
    accountPermissions: PropTypes.instanceOf(Immutable.List).isRequired,
    canUseCrossProjectMetrics: PropTypes.bool.isRequired,
    clickEvents: PropTypes.instanceOf(Immutable.OrderedMap).isRequired,
    containerFn: PropTypes.func,
    createEvent: PropTypes.func,
    currentProjectOnly: PropTypes.bool,
    customEvents: PropTypes.instanceOf(Immutable.List).isRequired,
    isFlagsProject: PropTypes.bool,
    isFullStackProject: PropTypes.bool,
    layer: PropTypes.instanceOf(Immutable.Map).isRequired,
    layerExperiments: PropTypes.instanceOf(Immutable.List).isRequired,
    metricWrappers: PropTypes.instanceOf(Immutable.List).isRequired,
    onEditMetric: PropTypes.func,
    onEditMetricFinish: PropTypes.func,
    pageEvents: PropTypes.instanceOf(Immutable.OrderedMap).isRequired,
    project: PropTypes.instanceOf(Immutable.Map).isRequired,
    views: PropTypes.instanceOf(Immutable.Map).isRequired,
    workingMetricWrappers: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  static defaultProps = {
    containerFn: () => {
      const dialogContainer = $('.dialog');

      if (dialogContainer.length) {
        return dialogContainer[0];
      }

      return document.body;
    },
    createEvent: (__, onSave) => {
      ui.showReactDialog(
        CreateEventDialogForMetrics,
        {
          props: {
            onSave,
            clickEventsDisabled: true,
          },
        },
        {
          isOuiDialog: true,
          fullScreen: true,
          dismissOnBack: true,
        },
      );
    },
    currentProjectOnly: true,
    // Since these are being fired in multiple places it's easier to just default them than
    // to actually check for their existence everywhere.
    onEditMetric: () => {},
    onEditMetricFinish: () => {},
  };

  state = {
    currentlyEditingMetricWrapper: null,
    errorMessage: null,
  };

  shouldComponentUpdate = deepEqual(['track']);

  fullStackCrossProjectMetricsLink = CurrentProjectActions.getHelpCopy(
    'cross_project_metrics_link',
  );

  // can be deprecated when fullstack is deprecated
  addMetric = metricEvent => {
    const { layer, onEditMetric, workingMetricWrappers } = this.props;

    const scopeDefault =
      MetricConstants.layerBasedScopeOptions[layer.get('policy')][0].value;

    const constructedEventMetric = toImmutable({
      aggregator: MetricConstants.aggregator.UNIQUE,
      display_title: metricEvent.get('display_title')
        ? metricEvent.get('display_title')
        : null,
      display_unit: null,
      event_id: metricEvent.get('id'),
      event_type: metricEvent.get('type'),
      field: null,
      name: metricEvent.get('name'),
      scope: scopeDefault,
      winning_direction: MetricConstants.winning_direction.INCREASING,
    });

    const constructedRevenueMetric = toImmutable({
      aggregator: MetricConstants.aggregator.SUM,
      display_title: null,
      display_unit: null,
      event_id: null,
      event_type: null,
      field: MetricConstants.field.REVENUE,
      scope: scopeDefault,
      winning_direction: MetricConstants.winning_direction.INCREASING,
    });

    let constructedMetric;
    let aggregatorOptions;
    let scopeOptions =
      MetricConstants.layerBasedScopeOptions[layer.get('policy')];

    switch (metricEvent.get('id')) {
      case 'revenue':
        constructedMetric = constructedRevenueMetric;
        aggregatorOptions = MetricFns.getAggregatorOptions(
          constructedRevenueMetric,
        );
        scopeOptions = scopeOptions.concat(MetricConstants.revenueScopeOptions);
        break;
      default:
        constructedMetric = constructedEventMetric;
        aggregatorOptions = MetricFns.getAggregatorOptions(
          constructedEventMetric,
        );
    }

    const metricWrapper = toImmutable({
      metric: constructedMetric,
      aggregatorOptions,
      scopeOptions,
      alias: MetricConstants.WORKING_METRIC_ALIAS,
      description: '',
      name: '',
    });

    this.setState({
      currentlyEditingMetricWrapper: metricWrapper,
    });

    MetricsManagerModuleActions.updateWorkingMetricWrappers(
      workingMetricWrappers.push(metricWrapper),
    );

    onEditMetric();
  };

  selectMetric = selectedMetricTemplate => {
    const { layer, onEditMetric, workingMetricWrappers } = this.props;

    const eventName = MetricFns.getMetricEventName(
      selectedMetricTemplate,
      flux.evaluate(EventGetters.entityCache),
      flux.evaluate(ViewGetters.entityCache),
    );

    const metric = toImmutable({
      aggregator: selectedMetricTemplate.get('aggregator'),
      display_title: selectedMetricTemplate.get('name'),
      display_unit: selectedMetricTemplate.get('display_unit'),
      event_id: selectedMetricTemplate.get('event_id'),
      event_type: selectedMetricTemplate.get('event_type'),
      field: selectedMetricTemplate.get('field'),
      name: eventName,
      scope: selectedMetricTemplate.get('scope'),
      winning_direction: selectedMetricTemplate.get('winning_direction'),
    });

    const aggregatorOptions = MetricFns.getAggregatorOptions(metric);
    const scopeOptions = MetricFns.getScopeOptions(
      selectedMetricTemplate.get('aggregator'),
      layer.get('policy'),
    );

    const metricWrapper = toImmutable({
      metric,
      aggregatorOptions,
      scopeOptions,
      alias: MetricConstants.WORKING_METRIC_ALIAS,
      description: selectedMetricTemplate.get('name'),
      name: selectedMetricTemplate.get('name'),
    });

    this.setState({
      currentlyEditingMetricWrapper: metricWrapper,
    });

    MetricsManagerModuleActions.updateWorkingMetricWrappers(
      workingMetricWrappers.push(metricWrapper),
    );

    onEditMetric();
  };

  canEditPrimaryMetric = () => {
    const { layer } = this.props;

    if (!layer) {
      return true;
    }

    if (!layer.get('metrics') || !layer.get('metrics').size) {
      return true;
    }

    if (!layer.get('earliest')) {
      return true;
    }

    return (
      !this.isMultiArmedBanditExperiment && !this.isStatsAcceleratorExperiment
    );
  };

  convertEventListToItems = (list, label, isSubcategory) => {
    const { currentProjectOnly, views } = this.props;

    return toImmutable({
      items: list
        .map(event => {
          let eventViewId;
          if (event.get('view_id')) {
            eventViewId = event.get('view_id');
          }
          if (event.get('event_type') === MetricConstants.event_type.PAGEVIEW) {
            eventViewId = event.get('id');
          }
          return toImmutable({
            category: eventViewId
              ? views.getIn([eventViewId, 'edit_url'])
              : null,
            description: !currentProjectOnly
              ? event.get('project_name', '')
              : null,
            id: event.get('id', null),
            item_type: 'existing_event',
            name: event.get('name', ''),
            type: event.get('event_type', ''),
          });
        })
        .toList(),
      key: label.toLowerCase().replace(' ', '_'),
      label,
      isSubcategory,
    });
  };

  disableEdit = () => {
    const { workingMetricWrappers } = this.props;
    const { currentlyEditingMetricWrapper } = this.state;

    if (!currentlyEditingMetricWrapper) {
      return false;
    }

    if (
      currentlyEditingMetricWrapper.get('alias') ===
      MetricConstants.WORKING_METRIC_ALIAS
    ) {
      return true;
    }

    const matchingWorkingMetricWrapper = workingMetricWrappers.find(
      metricWrapper =>
        metricWrapper.get('alias') ===
        currentlyEditingMetricWrapper.get('alias'),
    );

    return (
      matchingWorkingMetricWrapper &&
      !Immutable.is(matchingWorkingMetricWrapper, currentlyEditingMetricWrapper)
    );
  };

  editMetric = metricWrapper => {
    this.openMetricModal('Edit', metricWrapper);
    this.setState({
      currentlyEditingMetricWrapper: metricWrapper,
    });
  };

  pushMetricTop = metricWrapper => {
    const { workingMetricWrappers } = this.props;

    MetricsPickerModuleActions.pushMetricWrapperToTop(
      metricWrapper,
      workingMetricWrappers,
      this.canEditPrimaryMetric(),
    );
  };

  pushMetricBottom = metricWrapper => {
    const { workingMetricWrappers } = this.props;

    MetricsPickerModuleActions.pushMetricWrapperToBottom(
      metricWrapper,
      workingMetricWrappers,
    );
  };

  showCrossProjectMetrics = () => {
    const { canUseCrossProjectMetrics, project } = this.props;
    const isLegacyMobileProject = ProjectFns.isMobileOrOTTProject(project);

    // Account must have access to crossProjectMetrics feature
    // and must not be a legacy mobile project
    return canUseCrossProjectMetrics && !isLegacyMobileProject;
  };

  shouldHideCreateAudienceBtn() {
    const { isFullStackProject, isFlagsProject } = this.props;

    return (
      isFeatureEnabled('disable_creating_full_stack_entities') &&
      isFullStackProject &&
      !isFlagsProject
    );
  }

  renderAllProjectsHelp = () => {
    return (
      <HelpPopover behavior="hover" popoverTitle="Cross Project Events">
        <p>
          Choosing "all projects" will show events across all projects you have
          access to in both Web and Feature Experimentation. Note: Page trimming
          will not consider cross-project events when evaluating pages,
          potentially causing issues with event tracking. Visit your snippet
          settings to check if page trimming is enabled.
        </p>
      </HelpPopover>
    );
  };

  // can be deprecated when fullstack is deprecated
  generateLists = () => {
    const {
      clickEvents,
      currentProjectOnly,
      customEvents,
      pageEvents,
      project,
    } = this.props;

    let globalMetrics = toImmutable({
      items: [
        {
          description: '',
          id: 'revenue',
          item_type: 'global_metric',
          name: 'Overall Revenue',
          type: null,
        },
      ],
      key: 'global_metrics',
    });

    globalMetrics = globalMetrics.set(
      'label',
      <div>
        <div className="zeta">Global Metrics</div>
        <div className="micro weight--normal muted">
          These metrics are tracked across all your events.
        </div>
      </div>,
    );

    let eventMetrics;
    const items = [];
    const customEventsList = this.convertEventListToItems(
      customEvents,
      'Custom Events',
      !ProjectFns.isCustomProject(project),
    );

    const createEvent = toImmutable({
      description: null,
      id: 'create-event',
      isBold: true,
      item_type: 'create_event',
      name: 'Create New Event...',
      skipFilter: true,
      type: null,
    });

    if (ProjectFns.isCustomProject(project)) {
      eventMetrics = toImmutable(customEventsList);
      if (!this.shouldHideCreateAudienceBtn()) {
        eventMetrics = eventMetrics.set(
          'items',
          eventMetrics.get('items').unshift(createEvent),
        );
      }
    } else {
      items.push(createEvent);
      items.push(customEventsList);
      items.push(
        this.convertEventListToItems(
          clickEvents,
          'Click Events',
          !ProjectFns.isCustomProject(project),
        ),
      );
      items.push(
        this.convertEventListToItems(
          pageEvents,
          'Page Events',
          !ProjectFns.isCustomProject(project),
        ),
      );
      eventMetrics = toImmutable({
        items,
        key: 'event_metrics',
      });
    }

    eventMetrics = eventMetrics.set(
      'label',
      <div className="flex flex-row">
        <div className="flex--1">
          <div className="zeta">Events</div>
          <div className="micro weight--normal muted">
            To create an event specific metric, pick an event from the list.
          </div>
        </div>
        {this.showCrossProjectMetrics() && (
          <div
            className="flex flex-align--end"
            data-test-section="cross-project-metrics-toggle">
            {currentProjectOnly && <span>Current project only</span>}
            {!currentProjectOnly && (
              <a
                data-test-section="single-project-toggle"
                onClick={this.selectCurrentProject}>
                Current project only
              </a>
            )}
            <span className="push-half--sides">|</span>
            {!currentProjectOnly && (
              <span>
                All projects
                {this.renderAllProjectsHelp()}
              </span>
            )}
            {currentProjectOnly && (
              <>
                <a
                  data-test-section="all-projects-toggle"
                  onClick={this.selectAllProjects(true)}>
                  All projects
                </a>
                {this.renderAllProjectsHelp()}
              </>
            )}
          </div>
        )}
      </div>,
    );

    return toImmutable([eventMetrics, globalMetrics]);
  };

  // can be deprecated when fullstack is deprecated
  handleListItemClick = (clickEvent, item) => {
    const { createEvent } = this.props;
    const { currentlyEditingMetricWrapper } = this.state;

    // Only one metric can be edited at any given time, so if one is already being worked on make the click action
    // on the event list a no-op.
    if (currentlyEditingMetricWrapper) {
      return;
    }

    const onSave = event => {
      // Don't need to save page events, those are already saved
      if (event.edit_url) {
        return $.Deferred().resolve();
      }
      return EventActions.save(event);
    };

    switch (item.get('item_type')) {
      case 'create_event':
        createEvent(null, onSave);
        break;
      case 'existing_event':
      case 'global_metric':
        this.addMetric(item);
        break;
      default:
        throw new Error('Unknown item_type');
    }
  };

  removeMetric = metricWrapper => {
    const innerRemoveEvent = innerMetricWrapper => {
      const { workingMetricWrappers } = this.props;
      const { currentlyEditingMetricWrapper } = this.state;
      const newWorkingMetricWrappers = workingMetricWrappers.filterNot(
        workingMetricWrapper =>
          workingMetricWrapper.get('alias') === innerMetricWrapper.get('alias'),
      );

      MetricsManagerModuleActions.updateWorkingMetricWrappers(
        newWorkingMetricWrappers,
      );

      if (
        currentlyEditingMetricWrapper &&
        innerMetricWrapper.get('alias') ===
          currentlyEditingMetricWrapper.get('alias')
      ) {
        this.setState({
          currentlyEditingMetricWrapper: null,
        });
      }
    };

    const eventIdForMetric = metricWrapper.getIn(['metric', 'event_id']);
    if (!eventIdForMetric) {
      innerRemoveEvent(metricWrapper);
      return;
    }
    const eventOrPageForMetric =
      flux.evaluate(EventGetters.byId(eventIdForMetric)) ||
      flux.evaluate(ViewGetters.byId(eventIdForMetric));

    if (eventOrPageForMetric && eventOrPageForMetric.get('archived')) {
      ui.confirm({
        title: tr('Remove metric with archived event?'),
        message: tr(
          'Only active events can be added as metrics to campaigns. You will not be able to add this metric back to the campaign until the event is active.',
        ),
        confirmText: tr('Remove Archived Metric'),
        isWarning: true,
      }).then(() => {
        innerRemoveEvent(metricWrapper);
      });
    } else if (
      eventOrPageForMetric &&
      eventOrPageForMetric.get('variation_specific')
    ) {
      ui.confirm({
        title: tr('Remove metric with campaign specific event?'),
        message: tr(
          'Only metrics with project level events can be added to campaigns in the metric picker. You will need to re-create this metric in the variation editor to add it back to the campaign.',
        ),
        confirmText: tr('Remove Campaign Metric'),
        isWarning: true,
      }).then(() => {
        innerRemoveEvent(metricWrapper);
      });
    } else {
      innerRemoveEvent(metricWrapper);
    }
  };

  saveMetric = updatedMetricWrapper => {
    const { layer, onEditMetricFinish, workingMetricWrappers } = this.props;

    let updatedWorkingMetricWrappers = workingMetricWrappers.map(
      workingMetricWrapper => {
        if (
          workingMetricWrapper.get('alias') ===
          updatedMetricWrapper.get('alias')
        ) {
          // We should only have one 'Working' metric at any given time, update this to a temporary alias
          if (
            updatedMetricWrapper.get('alias') ===
            MetricConstants.WORKING_METRIC_ALIAS
          ) {
            updatedMetricWrapper = updatedMetricWrapper.set('alias', guid());
          }

          return updatedMetricWrapper;
        }

        return workingMetricWrapper;
      },
    );

    updatedWorkingMetricWrappers = MetricFns.createMetricWrappers(
      updatedWorkingMetricWrappers.map(metricWrapper =>
        metricWrapper.get('metric'),
      ),
      layer,
      flux.evaluate(ViewGetters.entityCache),
      flux.evaluate(EventGetters.entityCache),
    );

    const metricAliases = updatedWorkingMetricWrappers.map(workingMetric =>
      workingMetric.get('alias'),
    );
    if (metricAliases.size !== metricAliases.toSet().size) {
      this.setState({
        errorMessage: 'Metrics must be unique.',
      });
    } else {
      this.setState({
        currentlyEditingMetricWrapper: null,
        errorMessage: null,
      });

      MetricsManagerModuleActions.updateWorkingMetricWrappers(
        updatedWorkingMetricWrappers,
      );

      onEditMetricFinish();
    }
  };

  selectCurrentProject = () => {
    MetricsPickerModuleActions.setUseCurrentProject(true);
  };

  selectAllProjects = showLoader => () => {
    if (showLoader) {
      ui.loadingStart('metrics-picker-react');
    }
    const loadingDef = Promise.all([
      ProjectActions.fetchAll(
        {
          project_status: ProjectEnums.project_status.ACTIVE,
        },
        { excludeFields: ['jira_integration'] },
      ),
      CurrentLayerActions.fetchAllCrossProjectMetrics(),
    ]).then(() => {
      if (showLoader) {
        ui.loadingStop('metrics-picker-react');
      }
    });
    MetricsPickerModuleActions.setUseCurrentProject(false);
    ui.loadingWhen('metrics-picker-react', loadingDef);
  };

  unsetCurrentlyEditingMetric = () => {
    const { onEditMetricFinish, workingMetricWrappers } = this.props;
    const { currentlyEditingMetricWrapper } = this.state;

    if (
      currentlyEditingMetricWrapper &&
      currentlyEditingMetricWrapper.get('alias') ===
        MetricConstants.WORKING_METRIC_ALIAS
    ) {
      const newWorkingMetricWrappers = workingMetricWrappers.filter(
        metricWrapper =>
          metricWrapper.get('alias') !== MetricConstants.WORKING_METRIC_ALIAS,
      );

      MetricsManagerModuleActions.updateWorkingMetricWrappers(
        newWorkingMetricWrappers,
      );
    }

    this.setState({
      currentlyEditingMetricWrapper: null,
      errorMessage: null,
    });

    onEditMetricFinish();
  };

  updateCurrentlyEditingMetricWrapper = metricWrapper => {
    this.setState({
      currentlyEditingMetricWrapper: metricWrapper,
    });
  };

  updateWorkingMetricWrappers = listItems => {
    const { workingMetricWrappers } = this.props;
    let newMetricWrappers = toImmutable(
      _.map(listItems, item => item.metricWrapper),
    );

    if (!this.canEditPrimaryMetric()) {
      newMetricWrappers = newMetricWrappers.unshift(
        workingMetricWrappers.first(),
      );
    }
    MetricsManagerModuleActions.updateWorkingMetricWrappers(newMetricWrappers);
  };

  componentWillUnmount() {
    MetricsManagerModuleActions.resetWorkingMetricWrappers();
    MetricsPickerModuleActions.reset();
  }

  openMetricModal(action, metricWrapper) {
    ui.showReactDialog(
      MetricsModalWrapper,
      {
        props: {
          metricWrapper,
          onClose: ui.hideDialog,
          onSave: ui.hideDialog,
          title: `${action} Metric`,
        },
      },
      {
        isOuiDialog: true,
        fullScreen: true,
        dismissOnBack: true,
      },
    );
  }

  render() {
    const {
      accountPermissions,
      containerFn,
      currentProjectOnly,
      isFullStackProject,
      layer,
      onEditMetric,
      project,
      workingMetricWrappers,
    } = this.props;
    const { currentlyEditingMetricWrapper, errorMessage } = this.state;

    let metricTokens = workingMetricWrappers.map((metricWrapper, index) => {
      const editMetricFn = () => {
        this.editMetric(metricWrapper);
      };

      const removeMetricFn = () => {
        this.removeMetric(metricWrapper);
      };

      const pushMetricBottomFn = () => {
        this.pushMetricBottom(metricWrapper);
      };

      const pushMetricTopFn = () => {
        this.pushMetricTop(metricWrapper);
      };

      return {
        alias: metricWrapper.get('alias'),
        canEditPrimaryMetric: this.canEditPrimaryMetric(),
        currentlyEditingMetricWrapper,
        disableEdit: this.disableEdit(),
        editMetricFn,
        index,
        indexId: metricWrapper.get('indexId'),
        layer,
        metricWrapper,
        pushMetricBottomFn,
        pushMetricTopFn,
        removeMetricFn,
        workingMetricWrappers,
      };
    });

    if (!this.canEditPrimaryMetric()) {
      metricTokens = metricTokens.delete(0);
    }

    const commonProps = {
      onCancel: this.unsetCurrentlyEditingMetric,
      onCommit: this.saveMetric,
      onEditMetric,
      onUpdate: this.updateCurrentlyEditingMetricWrapper,
      isCustomProject: ProjectFns.isCustomProject(project),
    };

    const isEventPropertiesEnabled =
      isFeatureEnabled('event_properties') && !isFullStackProject;

    const MABPrimaryMetricDescription = getFullMetricDescription(
      workingMetricWrappers.first(),
    );

    const MABPrimaryMetricName =
      (workingMetricWrappers &&
        workingMetricWrappers.size &&
        workingMetricWrappers.first().get('name')) ||
      '';
    const projectId = project.get('id');

    return (
      <div className="position--relative">
        <div
          className={`push-double--bottom flex flex--row flex-align--center ${
            PermissionsModuleFns.canUseOutlierFiltering(
              accountPermissions,
              layer,
            )
              ? 'justify-content-between'
              : 'justify-content-end'
          }`}>
          {PermissionsModuleFns.canUseOutlierFiltering(
            accountPermissions,
            layer,
          ) && (
            <div className="flex">
              <OutlierFilter layer={layer} />
            </div>
          )}
        </div>
        <div>
          {!this.canEditPrimaryMetric() && (
            <div data-test-section="read-only-metric-token">
              <Tile
                description={
                  <MetricDescription
                    description={MABPrimaryMetricDescription}
                  />
                }
                isDraggable={false}
                key="MAB-primary-metric"
                name={<MetricName name={MABPrimaryMetricName} />}
                status="Primary"
                testSection="metric-tile-MAB-primary"
              />
              <div>
                {this.isStatsAcceleratorExperiment && (
                  <div
                    className="soft--ends push--sides"
                    data-test-section="stats-accelerator-enabled-warning">
                    <Attention type="brand">
                      You can't change or remove the primary metric after the
                      experiment has started because Stats Accelerator is
                      enabled.
                    </Attention>
                  </div>
                )}
                {workingMetricWrappers.size &&
                  workingMetricWrappers.size > 1 &&
                  LayerFns.isMultiArmedBandit(layer) &&
                  MultiArmedBanditWarning}
              </div>
            </div>
          )}
          {isEventPropertiesEnabled && workingMetricWrappers.size === 0 && (
            <EmptyMetrics
              addMetricHandler={() => this.openMetricModal('Add')}
            />
          )}
          {isEventPropertiesEnabled && workingMetricWrappers.size > 0 && (
            <>
              <MetricsDragAndDrop
                metricTileItems={metricTokens.toJS()}
                updateWorkingMetricWrappers={this.updateWorkingMetricWrappers}
                removeMetric={this.removeMetric}
                isMultiArmedBandit={LayerFns.isMultiArmedBandit(layer)}
                isStatsAcceleratorExperiment={this.isStatsAcceleratorExperiment}
              />
              <Button
                style="outline"
                className="push-double--top"
                onClick={() => this.openMetricModal('Add')}
                leftIcon="add"
                testSection="add-metric-button">
                Add Metric
              </Button>
            </>
          )}
          {!isEventPropertiesEnabled && (
            <DraggableList
              commonProps={commonProps}
              container={containerFn}
              list={metricTokens.toJS()}
              itemKey="alias"
              onMoveEnd={this.updateWorkingMetricWrappers}
              padding={0}
              template={TokenTemplate}
              unsetZIndex={true}
            />
          )}
          {errorMessage && (
            <div
              className="lego-form-note lego-form-note--bad-news push--bottom"
              data-test-section="metric-manager-error-message">
              {errorMessage}
            </div>
          )}
          {!isEventPropertiesEnabled && (
            <LoadingOverlay loadingId="metrics-picker-react">
              {!currentProjectOnly && (
                <div className="push--ends">
                  <Attention
                    alignment="center"
                    type="brand"
                    testSection="cross-project-event-sdk-compatibility-warning">
                    Using cross-project Metrics requires Optimizely SDK version
                    3.0 or greater.
                    {this.fullStackCrossProjectMetricsLink && (
                      <span className="push-half--left">
                        <Link
                          newWindow={true}
                          href={this.fullStackCrossProjectMetricsLink}>
                          Learn More
                        </Link>
                        .
                      </span>
                    )}
                  </Attention>
                </div>
              )}
              <FilterList
                inputPlaceholder="Browse for Metrics"
                isDisabled={!!currentlyEditingMetricWrapper}
                lists={this.generateLists()}
                itemOnClick={this.handleListItemClick}
                testSection="metric-picker-metric-options"
              />
            </LoadingOverlay>
          )}
        </div>
      </div>
    );
  }
}

export const constants = MetricsPickerModuleConstants;

export default MetricsPicker;
