import flux from 'core/flux';

import actionTypes from './action_types';

function saveLinkedHypothesis(hypothesis) {
  flux.dispatch(actionTypes.SAVE_LINKED_HYPOTHESIS, { hypothesis });
}

function saveHypothesisToLink(hypothesis) {
  flux.dispatch(actionTypes.SAVE_HYPOTHESIS_TO_LINK, { hypothesis });
}

function shouldCreateHypothesis(mode) {
  flux.dispatch(actionTypes.SHOULD_CREATE_HYPOTHESIS, { mode });
}

function setHypothesisName(name) {
  flux.dispatch(actionTypes.SET_HPT_NAME, { name });
}

function setHypothesisPlannedStartDate(date) {
  flux.dispatch(actionTypes.SET_HPT_PLANNED_START_DATE, { date });
}

function setHypothesisPlannedEndDate(date) {
  flux.dispatch(actionTypes.SET_HPT_PLANNED_END_DATE, { date });
}

function setHypothesisOpenInNewTabAfterCreation(mode) {
  flux.dispatch(actionTypes.SET_HPT_OPEN_IN_NEW_TAB_AFTER_CREATION, { mode });
}

export default {
  saveLinkedHypothesis,
  saveHypothesisToLink,
  shouldCreateHypothesis,
  setHypothesisName,
  setHypothesisPlannedStartDate,
  setHypothesisPlannedEndDate,
  setHypothesisOpenInNewTabAfterCreation,
};
