import PropTypes from 'prop-types';
import React from 'react';

import { api as SegmentJSAPI } from '@optimizely/segment-js';

import { Icon } from 'optimizely-oui';

import { isWinnerRolloutM2FeatureEnabled } from 'optly/utils/features';

import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import Router from 'core/router';
import UrlHelper from 'optly/services/url_helper';
import Events from 'optly/services/events';

import Immutable, { toJS } from 'optly/immutable';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import {
  fns as PublishStatusFns,
  getters as PublishStatusGetters,
} from 'bundles/p13n/modules/publish_status';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import { fns as LayerFns } from 'optly/modules/entity/layer';

import { Dropdown, DropdownContents } from 'react_components/dropdown';

import ConcludeExperimentDialog from 'bundles/p13n/components/dialogs/conclude_layer';
import DeployExperimentDialog from 'bundles/p13n/components/dialogs/deploy_layer';

import StatusOption from './subComponents/status_option';

import getters from './getters';

import {
  startLayer,
  pauseLayer,
  archiveLayer,
  archiveAndPauseLayer,
  unarchiveLayer,
  concludeAndPauseLayer,
  concludeLayer,
  concludeAndDeployLayer,
} from './actions';

import { STATUS_ACTIONS, statusActionsMap } from './constants';

const getDropdownActivator = statusLabel => (
  <button
    type="button"
    className="oui-button oui-button--full soft--left text--left"
    data-test-section="status-switcher-dropdown">
    <div className="flex" data-test-section="selected-status">
      <div className="flex--1 truncate">{statusLabel}</div>
      <div className="text--right">
        <span>
          <Icon name="angle-down" size="small" />
        </span>
      </div>
    </div>
  </button>
);

const showNotification = message => {
  ui.showNotification({ message });
};

@connect(({ currentLayer }) => ({
  canArchiveLayer: [
    CurrentProjectGetters.project,
    PermissionsModuleFns.canDeleteLayer,
  ],
  canConcludeLayer: [
    CurrentProjectGetters.project,
    PermissionsModuleFns.canDeleteLayer,
  ],
  canDeactivateLayer: [
    CurrentProjectGetters.project,
    PermissionsModuleFns.canDeactivateLayer.bind(PermissionsModuleFns),
  ],
  currentLayer: CurrentLayerGetters.layer,
  canStartLayer: getters.canStartLayer,
  currentProjectId: CurrentProjectGetters.id,
  hasAnyProcessingAdaptiveAudienceConditions:
    CurrentLayerGetters.hasAnyProcessingAdaptiveAudienceConditions,
  isActive: PublishStatusGetters.isActive(currentLayer.get('id')),
}))
class StatusSwitcher extends React.Component {
  static propTypes = {
    canArchiveLayer: PropTypes.bool.isRequired,
    canConcludeLayer: PropTypes.bool.isRequired,
    canDeactivateLayer: PropTypes.bool.isRequired,
    canStartLayer: PropTypes.bool.isRequired,
    currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProjectId: PropTypes.number.isRequired,
    experimentStatus: PropTypes.string.isRequired,
    hasAnyProcessingAdaptiveAudienceConditions: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    liveCommit: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  state = {
    showConcludeDialog: false,
    showDeployDialog: false,
    conclusionValues: {
      result: '',
      conclusions: '',
      deployChecked: false,
    },
  };

  getAvailableActions = experimentStatus => {
    const {
      canArchiveLayer,
      canDeactivateLayer,
      canStartLayer,
      liveCommit,
      canConcludeLayer,
    } = this.props;

    const { actions = [] } = statusActionsMap[experimentStatus] || {};

    return actions.filter(
      ({ id }) =>
        id === STATUS_ACTIONS.UNARCHIVE ||
        (id === STATUS_ACTIONS.CONCLUDE && canConcludeLayer) ||
        (id === STATUS_ACTIONS.START && canStartLayer) ||
        (id === STATUS_ACTIONS.PAUSE && liveCommit && canDeactivateLayer) ||
        (id === STATUS_ACTIONS.ARCHIVE && canArchiveLayer),
    );
  };

  isLayerRunning = () => {
    const { currentLayer, isActive, liveCommit } = this.props;
    const isLayerConcluded = currentLayer?.get('concluded');
    return isActive && !isLayerConcluded && liveCommit;
  };

  handleStatusSwitcherClick = action => {
    const {
      currentLayer,
      currentProjectId,
      isActive,
      liveCommit,
      hasAnyProcessingAdaptiveAudienceConditions: hasAdaptiveAudiences,
    } = this.props;

    const layerId = currentLayer.get('id');
    const currentLayerJS = toJS(currentLayer);
    const campaignType = LayerFns.getCampaignTypeOfLayer(currentLayerJS);
    const text = PublishStatusFns.getText(
      campaignType,
      currentLayer.get('name'),
    );

    switch (action) {
      case STATUS_ACTIONS.START: {
        if (liveCommit) {
          const { start, adaptive } = text;

          const onSuccessAction = () => {
            showNotification(start.confirmationText);

            Events.trackMarketoEvent(
              'x_campaign_nav',
              'start',
              'experiment_for_layer',
              layerId,
            );

            /* This core API event is implemented in the frontend so that the context from which it was invoked can be inferred. */
            SegmentJSAPI.track('Experiment Started');
          };

          ui.confirm({
            title: hasAdaptiveAudiences ? adaptive.title : start.title,
            message: hasAdaptiveAudiences ? adaptive.message : start.message,
            confirmText: start.confirmText,
            doSanitizeHTML: false,
          }).then(() => {
            startLayer(currentLayer, onSuccessAction);
          });
        }
        break;
      }
      case STATUS_ACTIONS.PAUSE:
        if (liveCommit) {
          const { pause } = text;

          const onSuccessAction = () => {
            showNotification(pause.confirmationText);
          };

          ui.confirm({
            title: pause.title,
            message: pause.mesage,
            isWarning: true,
            confirmText: pause.confirmText,
          }).then(() => {
            pauseLayer(currentLayer, onSuccessAction);
          });
        }
        break;
      case STATUS_ACTIONS.ARCHIVE: {
        const { archiveAndPause, archive } = text;

        const onSuccesAction = () => {
          showNotification(archive.confirmationText);

          /* This core API event is implemented in the frontend so that the context from which it was invoked can be inferred. */
          SegmentJSAPI.track('Experiment Archived');

          const campaignOverview = UrlHelper.xWebHome(currentProjectId);
          Router.go(campaignOverview);
        };

        if (this.isLayerRunning()) {
          ui.confirm({
            title: archiveAndPause.title,
            message: archiveAndPause.message,
            isWarning: true,
            confirmText: archiveAndPause.confirmText,
          }).then(() => {
            archiveAndPauseLayer(
              currentLayerJS,
              currentProjectId,
              liveCommit,
              onSuccesAction,
            );
          });
        } else {
          ui.confirm({
            title: archive.title,
            message: archive.message,
            isWarning: true,
            confirmText: archive.confirmText,
          }).then(() => {
            archiveLayer(currentLayerJS, onSuccesAction);
          });
        }
        break;
      }
      case STATUS_ACTIONS.UNARCHIVE: {
        const { unarchive } = text;

        const onSuccessAction = () => {
          showNotification(unarchive.message);
        };

        unarchiveLayer(currentLayerJS, onSuccessAction);
        break;
      }
      case STATUS_ACTIONS.CONCLUDE: {
        this.setState({ showConcludeDialog: true });
        break;
      }
      default:
        // eslint-disable-next-line no-console
        console.warn(`This ${action} option is not available.`);
        break;
    }
  };

  onCloseConcludeDialog = () => {
    this.setState({ showConcludeDialog: false });
  };

  onConcludeHandler = () => {
    const { currentLayer } = this.props;
    const plainCurrentLayer = toJS(currentLayer);
    const campaignType = LayerFns.getCampaignTypeOfLayer(plainCurrentLayer);
    const text = PublishStatusFns.getText(
      campaignType,
      currentLayer.get('name'),
    );
    showNotification(text.conclude.message);
    this.setState({ showConcludeDialog: false });
  };

  onDeployHandler = () => {
    const { currentLayer } = this.props;
    const plainCurrentLayer = toJS(currentLayer);
    const campaignType = LayerFns.getCampaignTypeOfLayer(plainCurrentLayer);
    const text = PublishStatusFns.getText(
      campaignType,
      currentLayer.get('name'),
    );
    showNotification(text.deploy.message);
  };

  onCloseDeployDialog = () => {
    this.setState({ showDeployDialog: false });
  };

  submitConclusion = ({ result, conclusions, deployChecked }) => {
    const { currentLayer, currentProjectId, isActive, liveCommit } = this.props;
    this.setState({
      conclusionValues: {
        result,
        conclusions,
        deployChecked,
      },
    });
    if (deployChecked) {
      this.setState({ showConcludeDialog: false, showDeployDialog: true });
    } else {
      const plainLayer = currentLayer.toJS();
      if (this.isLayerRunning()) {
        concludeAndPauseLayer(
          plainLayer,
          result,
          conclusions,
          currentProjectId,
          this.onConcludeHandler,
        );
      } else {
        concludeLayer(plainLayer, result, conclusions, this.onConcludeHandler);
      }
    }
  };

  submitDeploy = ({
    experimentId,
    deployedVariationIds,
    audienceConditions,
    layerHoldback,
  }) => {
    const { currentLayer } = this.props;
    const { conclusionValues } = this.state;
    const plainLayer = currentLayer.toJS();

    showNotification(
      tr(
        'Concluding and deploying the experiment <b>{0}<b> ...',
        plainLayer.name,
      ),
    );
    this.setState({ showDeployDialog: false });

    concludeAndDeployLayer(
      experimentId,
      deployedVariationIds,
      audienceConditions,
      layerHoldback,
      plainLayer,
      conclusionValues,
      this.onDeployHandler,
    );
  };

  render() {
    const { experimentStatus, currentLayer } = this.props;
    const { showConcludeDialog, showDeployDialog } = this.state;
    const allowDeploy = isWinnerRolloutM2FeatureEnabled();

    const { label: statusLabel } = statusActionsMap[experimentStatus];
    const availableActions = this.getAvailableActions(experimentStatus);

    return (
      <>
        <div
          className="sidebar-status-switcher push--bottom"
          data-test-section="status-switcher">
          <Dropdown width="100%" activator={getDropdownActivator(statusLabel)}>
            <DropdownContents minWidth="100%">
              {availableActions.map(({ id, label }) => (
                <StatusOption
                  key={id}
                  actionId={id}
                  value={label}
                  isSelected={label === statusLabel}
                  onStatusClick={this.handleStatusSwitcherClick}
                />
              ))}
            </DropdownContents>
          </Dropdown>
        </div>
        {showConcludeDialog && (
          <ConcludeExperimentDialog
            layer={currentLayer}
            onCloseHandler={this.onCloseConcludeDialog}
            submitConclusion={this.submitConclusion}
            allowDeploy={allowDeploy}
          />
        )}
        {showDeployDialog && allowDeploy && (
          <DeployExperimentDialog
            layer={currentLayer}
            onCloseHandler={this.onCloseDeployDialog}
            submitDeploy={this.submitDeploy}
          />
        )}
      </>
    );
  }
}

export default StatusSwitcher;
