import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';

import Immutable from 'optly/immutable';

import { connect } from 'core/ui/decorators';
import capitalize from 'optly/filters/capitalize';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { getters as PublishStatusGetters } from 'bundles/p13n/modules/publish_status';
import { baseGetterPaths as ResultsBaseGetterPaths } from 'bundles/p13n/sections/results/section_module/constants';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { fns as LayerFns } from 'optly/modules/entity/layer';
import { fns as ProjectFns } from 'optly/modules/entity/project';

import StatusSwitcherComponent from 'bundles/p13n/components/status_switcher';
import PublishStatusText from '../publish_status_text';

@connect(props => {
  const { layerId, selectedExperimentId, shouldUseExperimentStatus } = props;
  let greenStatusGetter = PublishStatusGetters.showGreenStatus(layerId);
  let statusTextGetter = PublishStatusGetters.statusText(layerId);

  if (shouldUseExperimentStatus) {
    greenStatusGetter = PublishStatusGetters.showGreenStatusByExperimentId(
      selectedExperimentId,
    );
    statusTextGetter = PublishStatusGetters.statusTextByExperimentId(
      selectedExperimentId,
    );
  }

  return {
    showGreenStatus: greenStatusGetter,
    statusText: statusTextGetter,
    publishStatus: PublishStatusGetters.publishStatus(layerId),
  };
})
class StatusText extends React.Component {
  static propTypes = {
    publishStatus: PropTypes.instanceOf(Object).isRequired,
    showGreenStatus: PropTypes.bool.isRequired,
    statusText: PropTypes.string.isRequired,
    useStatusDropdown: PropTypes.bool,
  };

  static defaultProps = {
    useStatusDropdown: false,
  };

  shouldShowStatusText = () => {
    const {
      useStatusDropdown,
      publishStatus: { pausing, starting, firstPublish },
    } = this.props;

    return (
      !useStatusDropdown ||
      (useStatusDropdown &&
        [pausing, starting, firstPublish].some(status => status))
    );
  };

  render() {
    const { showGreenStatus, statusText } = this.props;

    return this.shouldShowStatusText() ? (
      <div
        className={classNames({
          micro: true,
          'push-half--sides': true,
          'color--good-news': showGreenStatus,
          muted: !showGreenStatus,
        })}
        data-test-section="campaign-overview-sidebar-campaign-status">
        {capitalize(statusText)}
      </div>
    ) : null;
  }
}

@connect(props => {
  const { currentProjectId, layerId } = props;

  return {
    isUploadingToCDN: PublishStatusGetters.isUploadingToCDN(
      currentProjectId,
      layerId,
    ),
  };
})
class UploadingToCDN extends React.Component {
  static propTypes = {
    currentProjectId: PropTypes.number.isRequired,
    isUploadingToCDN: PropTypes.bool.isRequired,
    layerId: PropTypes.number.isRequired,
  };

  render() {
    const { isUploadingToCDN } = this.props;

    if (!isUploadingToCDN) {
      return null;
    }

    return <div className="muted micro">(Uploading to CDN)</div>;
  }
}

@connect({
  currentLayer: CurrentLayerGetters.layer,
  currentProject: CurrentProjectGetters.project,
  currentProjectId: CurrentProjectGetters.id,
  selectedExperimentId: ResultsBaseGetterPaths.selectedExperimentId,
  liveCommit: CurrentLayerGetters.liveCommitTag,
})
class LayerStatusIndicator extends React.Component {
  static propTypes = {
    currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProjectId: PropTypes.number.isRequired,
    liveCommit: PropTypes.instanceOf(Immutable.Map).isRequired,
    selectedExperimentId: PropTypes.number,
    useStatusDropdown: PropTypes.bool,
    showPublishStatusText: PropTypes.bool,
  };

  static defaultProps = {
    selectedExperimentId: null,
    useStatusDropdown: false,
    showPublishStatusText: true,
  };

  constructor(props) {
    super(props);

    this.layerId = props.currentLayer.get('id');
  }

  campaignType = () => {
    const { currentLayer } = this.props;

    return LayerFns.getCampaignTypeOfLayer(currentLayer);
  };

  shouldUseExperimentStatus = () => {
    const { currentProject, selectedExperimentId } = this.props;

    return ProjectFns.isCustomProject(currentProject) && selectedExperimentId;
  };

  render() {
    const {
      currentProjectId,
      selectedExperimentId,
      currentLayer,
      liveCommit,
      useStatusDropdown,
      showPublishStatusText,
    } = this.props;

    let statusDropdownSection;

    if (useStatusDropdown) {
      statusDropdownSection = (
        <div className="push--top">
          <span>Status</span>
          <StatusSwitcherComponent
            currentLayer={currentLayer}
            liveCommit={liveCommit}
            experimentStatus={LayerFns.getStatus(currentLayer, liveCommit)}
          />
        </div>
      );
    }

    return (
      <div>
        <div className="flex flex-align--center">
          {/* This component is being used by P13nLegacyCampaignEditorComponent, so using props to keep itself working for that legacy component */}
          {showPublishStatusText && (
            <PublishStatusText
              campaignType={this.campaignType()}
              layerId={this.layerId}
            />
          )}
          {!useStatusDropdown && <span className="muted">&#8226;</span>}
          <StatusText
            layerId={this.layerId}
            selectedExperimentId={selectedExperimentId}
            shouldUseExperimentStatus={this.shouldUseExperimentStatus()}
            useStatusDropdown={useStatusDropdown}
          />
        </div>
        <UploadingToCDN
          currentProjectId={currentProjectId}
          layerId={this.layerId}
        />
        {statusDropdownSection}
      </div>
    );
  }
}

export default LayerStatusIndicator;
