import React, { useState, useEffect } from 'react';
import { computeSelectedAudienceNamesMapPOJO } from '../component_module/fns';
import { useGetters } from 'core/ui/methods/connect_getters';
import { toImmutable } from 'optly/immutable';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { fns as LayerExperimentFns } from 'optly/modules/entity/layer_experiment';
import AudienceActions from 'optly/modules/entity/audience/actions';
import AudienceFns from 'optly/modules/entity/audience/fns';
import { AudienceCondition } from '../component_module/types';

export const AudienceCombinationNames = function({
  audienceConditions,
  audiences,
  projectId,
}: {
  audienceConditions: AudienceCondition[];
  audiences: Immutable.List<{}>;
  projectId: number;
}) {
  const [isLoadingAudiences, setIsLoadingAudiences] = useState(true);

  useEffect(() => {
    setIsLoadingAudiences(true);
    const audienceIds = LayerExperimentFns.deriveAudienceIdsFromAudienceConditionsPOJO(
      audienceConditions,
    );
    const audienceNameMap: {
      [audienceId: number]: any;
    } = computeSelectedAudienceNamesMapPOJO(
      audienceConditions,
      audiences.toJS(),
    );
    const audienceIdsToFetch = audienceIds.filter((audienceId: number) => {
      return !!audienceNameMap[audienceId];
    });
    if (audienceIds.length > 0) {
      const byAudienceIds = {
        project_id: projectId,
        id: audienceIdsToFetch,
      };
      AudienceActions.fetchAll(byAudienceIds).always(() => {
        setIsLoadingAudiences(false);
      });
    } else {
      setIsLoadingAudiences(false);
    }
  }, []);

  if (isLoadingAudiences) {
    return <span className="style--italic">Loading audiences...</span>;
  }

  const audienceNames = AudienceFns.getPlaceHolderNameFromAudiences(
    toImmutable(audienceConditions),
    audiences,
  );

  return <span>{audienceNames}</span>;
};

const AudienceCombinationNamesWithData = ({
  audienceConditions,
}: {
  audienceConditions: AudienceCondition[];
}) => {
  const { audiences, projectId } = useGetters({
    audiences: CurrentProjectGetters.audiences,
    projectId: CurrentProjectGetters.id,
  }) as { audiences: Immutable.List<any>; projectId: number };
  return (
    <AudienceCombinationNames
      audienceConditions={audienceConditions}
      audiences={audiences}
      projectId={projectId}
    />
  );
};

export default AudienceCombinationNamesWithData;
