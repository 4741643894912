import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, ProgressDots, ButtonIcon } from '@optimizely/axiom';
import { api as SegmentJSAPI } from '@optimizely/segment-js';

import {
  isWinnerRolloutFeatureEnabled,
  isWinnerRolloutM2FeatureEnabled,
} from 'optly/utils/features';

import ui from 'core/ui';
import Immutable, { toJS } from 'optly/immutable';
import { connect } from 'core/ui/decorators';

import Events from 'optly/services/events';
import Layer from 'optly/modules/entity/layer';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import PublishStatusFns from 'bundles/p13n/modules/publish_status/fns';
import { actions as CurrentLayerActions } from 'bundles/p13n/modules/current_layer';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { actions as VerifierActions } from 'optly/modules/verifier';
import {
  getters as LayerSummaryGetters,
  fns as LayerSummaryFns,
} from 'optly/modules/entity/layer_summary';
import { actions as LiveCommitTagActions } from 'optly/modules/entity/live_commit_tag';
import { getters as LoadingGetters } from 'core/modules/loading';
import { fns as PermissionsFns } from 'optly/modules/permissions';
import { fns as DesktopAppFns } from 'optly/modules/desktop_app';

import DuplicateLayerDialog from 'bundles/p13n/components/dialogs/duplicate_layer';
import JiraOptions from 'bundles/p13n/components/jira/options';
import ConcludeExperimentDialog from 'bundles/p13n/components/dialogs/conclude_layer';
import DeployExperimentDialog from 'bundles/p13n/components/dialogs/deploy_layer';

const GA4LearnMoreLink =
  'https://docs.developers.optimizely.com/experimentation/v10.0.0-web/docs/integrate-google-analytics-4-ga4#send-variations-to-ga4:~:text=GA4%20audience%20limit%3A-,GA4%20audience%20maintenance,-Optimizely%20Web%20Experimentation';

const GA4EnabledMessage = tr(
  ' Please note that this will also permanently delete any corresponding audiences in Google Analytics. <a href="{0}" rel="noopener noreferrer" target="_blank">Learn more</a>',
  GA4LearnMoreLink,
);
@connect({
  canUpdateJiraLinks: [
    CurrentProjectGetters.project,
    PermissionsFns.canUpdateJiraLinks,
  ],
  layerSummaries: LayerSummaryGetters.entityCache,
  isLoadingLinks: LoadingGetters.isLoading('jira-links'),
  jiraIntegrationSettings: CurrentProjectGetters.projectIntegrationById('jira'),
  ga4Integration: CurrentProjectGetters.projectIntegrationById(
    'google_analytics_4',
  ),
})
class LayerTableRowActions extends React.Component {
  static propTypes = {
    canUpdateJiraLinks: PropTypes.bool,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    dashboardLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    ga4Integration: PropTypes.instanceOf(Immutable.Map).isRequired,
    handleEntityUpdate: PropTypes.func.isRequired,
    isLoadingLinks: PropTypes.bool,
    jiraIntegrationEnabled: PropTypes.bool.isRequired,
    layerSummaries: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
    canUpdateJiraLinks: false,
    isLoadingLinks: false,
  };

  state = {
    showConcludeDialog: false,
    showDeployDialog: false,
    conclusionValues: {
      result: '',
      conclusions: '',
      deployChecked: false,
    },
  };

  isArchived = () => {
    const { dashboardLayer } = this.props;
    return dashboardLayer.get('status') === Layer.enums.status.ARCHIVED;
  };

  isPaused = () => {
    const { dashboardLayer } = this.props;
    return dashboardLayer.get('status') === Layer.enums.status.PAUSED;
  };

  isRunning = () => {
    const { dashboardLayer } = this.props;
    return dashboardLayer.get('status') === Layer.enums.status.RUNNING;
  };

  isConcluded = () => {
    const { dashboardLayer } = this.props;
    return dashboardLayer.get('status') === Layer.enums.status.CONCLUDED;
  };

  shouldShowStartOption = () => {
    const { currentProject, dashboardLayer } = this.props;
    const layer = dashboardLayer.get('layer');
    return (
      this.isPaused() &&
      PermissionsModuleFns.canPublishLayer(currentProject) &&
      !Layer.fns.isLayerReadonly(layer)
    );
  };

  shouldShowPauseOption = () => {
    const { currentProject, dashboardLayer } = this.props;
    const layer = dashboardLayer.get('layer');
    return (
      this.isRunning() &&
      PermissionsModuleFns.canDeactivateLayer(currentProject) &&
      !Layer.fns.isLayerReadonly(layer)
    );
  };

  shouldShowArchiveOption = () => {
    const { currentProject } = this.props;
    return (
      !this.isArchived() && PermissionsModuleFns.canDeleteLayer(currentProject)
    );
  };

  shouldShowUnarchiveOption = () => {
    const { currentProject } = this.props;
    return (
      this.isArchived() && PermissionsModuleFns.canDeleteLayer(currentProject)
    );
  };

  shouldShowDuplicateOption = () => {
    const isWinnerRolloutEnabled = isWinnerRolloutFeatureEnabled();
    return !isWinnerRolloutEnabled || !this.isArchived();
  };

  shouldShowConcludeOption = () => {
    const { currentProject } = this.props;

    return (
      isWinnerRolloutFeatureEnabled() &&
      (this.isRunning() || this.isPaused()) &&
      PermissionsModuleFns.canDeleteLayer(currentProject)
    );
  };

  hasAnyProcessingAdaptiveAudienceConditions = () => {
    const { dashboardLayer, layerSummaries } = this.props;
    if (!dashboardLayer.get('layer')) return null;
    return LayerSummaryFns.summaryHasAnyProcessingAdaptiveAudienceConditions(
      layerSummaries.get(dashboardLayer.getIn(['layer', 'id'])),
    );
  };

  duplicate = () => {
    const { dashboardLayer } = this.props;
    ui.showReactDialog(
      DuplicateLayerDialog,
      {
        props: {
          layer: dashboardLayer.get('layer'),
        },
      },
      {
        fullScreen: true,
        dismissOnBack: true,
        isOuiDialog: true,
      },
    );
  };

  start = () => {
    const { dashboardLayer, handleEntityUpdate } = this.props;
    const layer = dashboardLayer.get('layer');
    const liveTag = dashboardLayer.get('liveTag');

    const publishText = PublishStatusFns.getText(
      Layer.fns.getCampaignTypeOfLayer(layer),
      layer.get('name'),
    );

    const hasAnyProcessingAdaptiveAudienceConditions = this.hasAnyProcessingAdaptiveAudienceConditions();

    if (liveTag) {
      ui.confirm({
        title: hasAnyProcessingAdaptiveAudienceConditions
          ? publishText.adaptive.title
          : publishText.start.title,
        message: hasAnyProcessingAdaptiveAudienceConditions
          ? publishText.adaptive.message
          : publishText.start.message,
        confirmText: publishText.start.confirmText,
        doSanitizeHTML: !hasAnyProcessingAdaptiveAudienceConditions,
      }).then(() => {
        CurrentLayerActions.startCampaign(toJS(layer)).then(() => {
          handleEntityUpdate().then(() => {
            ui.showNotification({
              message: publishText.start.confirmationText,
            });
            Events.trackMarketoEvent(
              'x_experiment_list',
              'start',
              'experiment_for_layer',
              layer.get('id'),
            );
          });
        });
      });
    }
  };

  pause = () => {
    const { dashboardLayer, handleEntityUpdate } = this.props;
    const layer = dashboardLayer.get('layer');
    const liveTag = dashboardLayer.get('liveTag');
    const publishText = PublishStatusFns.getText(
      Layer.fns.getCampaignTypeOfLayer(layer),
      layer.get('name'),
    );
    if (liveTag) {
      ui.confirm({
        title: publishText.pause.title,
        message: publishText.pause.message,
        isWarning: true,
        confirmText: publishText.pause.confirmText,
      }).then(() => {
        CurrentLayerActions.pauseCampaign(toJS(layer)).then(() => {
          handleEntityUpdate().then(() => {
            ui.showNotification({
              message: publishText.pause.confirmationText,
            });
          });
        });
      });
    }
  };

  archive = (isGA4IntegrationEnabled = false) => {
    const { dashboardLayer, currentProject, handleEntityUpdate } = this.props;
    const layer = dashboardLayer.get('layer');
    const liveTag = dashboardLayer.get('liveTag');
    const publishText = PublishStatusFns.getText(
      Layer.fns.getCampaignTypeOfLayer(layer),
      layer.get('name'),
    );
    const plainJSLayer = toJS(layer);
    const plainJSLiveTag = toJS(liveTag);

    const extraMessage = isGA4IntegrationEnabled ? `${GA4EnabledMessage}` : '';

    if (plainJSLiveTag && this.isRunning()) {
      ui.confirm({
        title: publishText.archiveAndPause.title,
        message: `${publishText.archiveAndPause.message}${extraMessage}`,
        isWarning: true,
        confirmText: publishText.archiveAndPause.confirmText,
        doSanitizeHTML: false,
      }).then(() =>
        Layer.actions.archive(plainJSLayer).then(() =>
          LiveCommitTagActions.deactivateTag(plainJSLiveTag)
            .then(updatedLiveTag => {
              VerifierActions.verify({
                projectId: currentProject.get('id'),
                revisionToVerify: updatedLiveTag.project_code_revision,
              }).catch(() => {}); // don't error on rejection
            })
            .then(() => {
              handleEntityUpdate().then(() => {
                ui.showNotification({
                  message: publishText.archiveAndPause.confirmationText,
                });
              });
            }),
        ),
      );
    } else {
      ui.confirm({
        title: publishText.archive.title,
        message: `${publishText.archive.message}${extraMessage}`,
        isWarning: true,
        confirmText: publishText.archive.confirmText,
        doSanitizeHTML: false,
      }).then(() =>
        Layer.actions.archive(plainJSLayer).then(() => {
          handleEntityUpdate().then(() => {
            ui.showNotification({
              message: publishText.archive.confirmationText,
            });
          });
        }),
      );
    }
  };

  unarchive = () => {
    const { dashboardLayer, handleEntityUpdate } = this.props;
    const layer = dashboardLayer.get('layer');
    const publishText = PublishStatusFns.getText(
      Layer.fns.getCampaignTypeOfLayer(layer),
      layer.get('name'),
    );
    Layer.actions.unarchive(toJS(layer)).then(() => {
      handleEntityUpdate().then(() => {
        ui.showNotification({
          message: publishText.unarchive.message,
        });
      });
    });
  };

  conclude = () => {
    SegmentJSAPI.track('Clicks on initial conclude CTA');
    this.setState({ showConcludeDialog: true });
  };

  getDropdownContents = () => {
    const {
      isLoadingLinks,
      canUpdateJiraLinks,
      dashboardLayer,
      currentProject,
      jiraIntegrationEnabled,
    } = this.props;
    const layer = dashboardLayer.get('layer');
    const shouldShowJiraOptions =
      jiraIntegrationEnabled &&
      currentProject.getIn(['jira_integration', 'enabled']);
    const isPersonalizationLayer = Layer.fns.isPersonalizationLayer(layer);

    let duplicateOption;
    const isMultivariateTestLayer = Layer.fns.isMultivariateTestLayer(layer);
    if (isMultivariateTestLayer) {
      duplicateOption = (
        <Dropdown.ListItem hideOnClick={true}>
          <Dropdown.BlockLink
            onClick={() => {}}
            testSection="layer-table-row-dropdown-duplicate-disabled"
            isLink={false}
            minWidth="150">
            <Dropdown.BlockLinkText text="Duplicate" />
            <Dropdown.BlockLinkSecondaryText
              isWarning={true}
              secondaryText={tr(
                'We do not support duplication of multivariate tests at this time.',
              )}
            />
          </Dropdown.BlockLink>
        </Dropdown.ListItem>
      );
    } else {
      duplicateOption = (
        <Dropdown.ListItem hideOnClick={true}>
          <Dropdown.BlockLink
            onClick={this.duplicate}
            testSection="layer-table-row-dropdown-duplicate">
            <Dropdown.BlockLinkText text="Duplicate" />
          </Dropdown.BlockLink>
        </Dropdown.ListItem>
      );
    }

    let startOption = (
      <Dropdown.ListItem hideOnClick={true}>
        <Dropdown.BlockLink
          onClick={this.start}
          testSection="layer-table-row-dropdown-start">
          <Dropdown.BlockLinkText text="Start" />
        </Dropdown.BlockLink>
      </Dropdown.ListItem>
    );

    if (this.hasAnyProcessingAdaptiveAudienceConditions() === null) {
      startOption = (
        <Dropdown.ListItem>
          <Dropdown.BlockLink
            isLink={false}
            onClick={() => {}}
            testSection="layer-table-row-dropdown-start">
            <div className="flex flex-align--center">
              <div className="muted flex--1">
                <Dropdown.BlockLinkText text="Start" />
              </div>
              <ProgressDots testSection="layer-summary-progressing-dot" />
            </div>
          </Dropdown.BlockLink>
        </Dropdown.ListItem>
      );
    }

    const isGA4IntegrationEnabled = layer
      .getIn(['integration_settings'], Immutable.List())
      .toSeq()
      .some(
        item =>
          item.get('integration_id') === 'google_analytics_4' &&
          item.get('enabled'),
      );

    return (
      <React.Fragment>
        {this.shouldShowDuplicateOption() && duplicateOption}
        {this.shouldShowStartOption() && startOption}
        {this.shouldShowPauseOption() && (
          <Dropdown.ListItem hideOnClick={true}>
            <Dropdown.BlockLink
              onClick={this.pause}
              testSection="layer-table-row-dropdown-pause">
              <Dropdown.BlockLinkText text="Pause" />
            </Dropdown.BlockLink>
          </Dropdown.ListItem>
        )}
        {this.shouldShowArchiveOption() && (
          <Dropdown.ListItem hideOnClick={true}>
            <Dropdown.BlockLink
              onClick={() => this.archive(isGA4IntegrationEnabled)}
              testSection="layer-table-row-dropdown-archive">
              <Dropdown.BlockLinkText text="Archive" />
            </Dropdown.BlockLink>
          </Dropdown.ListItem>
        )}
        {this.shouldShowUnarchiveOption() && (
          <Dropdown.ListItem hideOnClick={true}>
            <Dropdown.BlockLink
              onClick={this.unarchive}
              testSection="layer-table-row-dropdown-unarchive">
              <Dropdown.BlockLinkText text="Unarchive" />
            </Dropdown.BlockLink>
          </Dropdown.ListItem>
        )}
        {shouldShowJiraOptions && !isLoadingLinks && canUpdateJiraLinks && (
          <Dropdown.ListItem hideOnClick={true} key="jira-options">
            <JiraOptions
              newWindow={DesktopAppFns.isDesktopApp()}
              source={layer}
              sourceType={isPersonalizationLayer ? 'campaign' : 'experiment'}
            />
          </Dropdown.ListItem>
        )}
        {this.shouldShowConcludeOption() && (
          <Dropdown.ListItem hideOnClick={true}>
            <Dropdown.BlockLink
              onClick={this.conclude}
              testSection="status-conclude-option">
              <Dropdown.BlockLinkText text="Conclude" />
            </Dropdown.BlockLink>
          </Dropdown.ListItem>
        )}
      </React.Fragment>
    );
  };

  onCloseConcludeDialog = () => {
    this.setState({ showConcludeDialog: false });
  };

  onCloseDeployDialog = () => {
    this.setState({ showDeployDialog: false });
  };

  submitConclusion = ({ result, conclusions, deployChecked }) => {
    const { dashboardLayer, currentProject } = this.props;
    const layer = dashboardLayer.get('layer');
    const liveTag = dashboardLayer.get('liveTag');
    this.setState({
      conclusionValues: {
        result,
        conclusions,
        deployChecked,
      },
    });
    if (deployChecked) {
      this.setState({ showConcludeDialog: false, showDeployDialog: true });
    } else {
      const plainLayer = layer.toJS();
      if (liveTag && this.isRunning()) {
        Layer.actions
          .concludeAndPause(
            plainLayer,
            result,
            conclusions,
            currentProject.get('id'),
          )
          .then(() => this.onConcludeHandler());
      } else {
        Layer.actions
          .conclude({
            ...plainLayer,
            concluded_results_outcome: result,
            concluded_conclusions: conclusions,
          })
          .then(() => this.onConcludeHandler());
      }
    }
  };

  submitDeploy = ({
    experimentId,
    deployedVariationIds,
    audienceConditions,
    layerHoldback,
  }) => {
    const { currentProject, dashboardLayer } = this.props;
    const { conclusionValues } = this.state;
    const plainLayer = dashboardLayer.get('layer').toJS();

    ui.showNotification({
      message: tr(
        'Concluding and deploying the experiment <b>{0}<b> ...',
        plainLayer.name,
      ),
    });
    this.setState({ showDeployDialog: false });

    Layer.actions
      .concludeAndDeployLayer(
        plainLayer,
        conclusionValues,
        experimentId,
        deployedVariationIds,
        audienceConditions,
        layerHoldback,
      )
      .then(() => this.onDeployHandler())
      .then(() =>
        Layer.actions.syncDataAfterDeploy(
          plainLayer.id,
          experimentId,
          plainLayer.type,
          currentProject.get('id'),
        ),
      );
  };

  onConcludeHandler = () => {
    const { dashboardLayer, handleEntityUpdate } = this.props;
    const layer = dashboardLayer.get('layer');
    const publishText = PublishStatusFns.getText(
      Layer.fns.getCampaignTypeOfLayer(layer),
      layer.get('name'),
    );
    this.setState({ showConcludeDialog: false });
    handleEntityUpdate().then(() => {
      ui.showNotification({ message: publishText.conclude.message });
    });
  };

  onDeployHandler = () => {
    const { dashboardLayer, handleEntityUpdate } = this.props;
    const layer = dashboardLayer.get('layer');
    const publishText = PublishStatusFns.getText(
      Layer.fns.getCampaignTypeOfLayer(layer),
      layer.get('name'),
    );
    handleEntityUpdate().then(() => {
      ui.showNotification({ message: publishText.deploy.message });
    });
  };

  render() {
    const { currentProject, dashboardLayer, ga4Integration } = this.props;
    const { showConcludeDialog, showDeployDialog } = this.state;
    const allowDeploy = isWinnerRolloutM2FeatureEnabled();

    const layer = dashboardLayer.get('layer');

    const dropdownContents = layer ? (
      this.getDropdownContents()
    ) : (
      <Dropdown.ListItem key="progress-dots">
        <div className="center--child push--ends">
          <ProgressDots />
        </div>
      </Dropdown.ListItem>
    );

    const googleAnalyticsPropertyId = ga4Integration.getIn(
      ['settings', 'ga4_property_id'],
      null,
    );

    const dropDownWidth =
      googleAnalyticsPropertyId !== null && googleAnalyticsPropertyId !== ''
        ? '250px'
        : '200px';

    return (
      <>
        <Dropdown
          activator={
            <ButtonIcon
              iconFill="default"
              iconName="ellipsis-solid"
              size="small"
              style="plain"
              testSection="view-actions"
            />
          }
          isDisabled={!PermissionsModuleFns.canUpdateLayer(currentProject)}
          width={dropDownWidth}
          size="small"
          style="plain"
          placement="bottom-end"
          testSection={`layer-table-row-dropdown-${dashboardLayer.get(
            'unique_id',
          )}`}>
          <Dropdown.Contents>{dropdownContents}</Dropdown.Contents>
        </Dropdown>
        {showConcludeDialog && (
          <ConcludeExperimentDialog
            layer={layer}
            onCloseHandler={this.onCloseConcludeDialog}
            submitConclusion={this.submitConclusion}
            allowDeploy={allowDeploy}
          />
        )}
        {showDeployDialog && allowDeploy && (
          <DeployExperimentDialog
            layer={layer}
            onCloseHandler={this.onCloseDeployDialog}
            submitDeploy={this.submitDeploy}
          />
        )}
      </>
    );
  }
}

export default LayerTableRowActions;
