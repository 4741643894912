import _ from 'lodash';
import moment from 'moment';

import parseParams from 'optly/utils/parse_query_params';
import nanoid from 'optly/utils/nanoid';

import constants from './constants';

/**
 * This is a helper function used by exports.formatDates that takes a Unix
 * timestamp and converts it into ISO8601 format.
 *
 * If there is a UTC time offset given, it converts the timestamp into that
 * time zone. If not, it will use the browser's time zone.
 *
 * @param  {Number} time
 * @param  {String} offset
 * @return {String} formatted time
 */
const _formatTime = (time, offset) => {
  let timeObject = moment(time);
  if (offset) {
    timeObject = timeObject.utcOffset(offset);
  }
  return timeObject.format();
};

/**
 * This function is used to pass query parameters from the frontend to the
 * backend in the request. Only query parameters with an underscore are sent
 * in the request.
 *
 * This was originally used for debugging, specifically to switch between
 * different backend servers for processing requests from the frontend.
 * @param  {Object} existingParams [ The existing query parameters ]
 * @return {Object}                [ The existing query parameters and any
 *                                   additional ones ]
 */
export const appendAdditionalQueryParams = existingParams => {
  if (!existingParams) {
    existingParams = {};
  }

  const locationParams = parseParams(window.location.href);
  const locationParamsToAppend = {};
  _.forEach(locationParams, (value, key) => {
    if (key[0] === '_') {
      locationParamsToAppend[key.substring(1)] = value;
    }
  });
  return _.defaults({}, existingParams, locationParamsToAppend);
};

/**
 * This function takes the begin and end time in the request data and formats
 * it from a Unix timestamp into an ISO8601 format using the Moment library.
 *
 * If there is a user selected time zone, it uses that time zone, but if not, it
 * uses the browser's time zone.
 *
 * @param {Object} requestData
 * @param {String} selectedTimezone
 * @return {Object} request data with time reformatted
 */
export const formatDates = (requestData, selectedTimezone) => {
  // Example user selected time zone: GMT-09:00
  // We want to strip our GMT to get just the UTC time offset (e.g. -09:00)
  const timeOffset = selectedTimezone
    ? selectedTimezone.replace(/GMT/g, '')
    : '';

  if (requestData.begin) {
    requestData.begin = _formatTime(requestData.begin, timeOffset);
  }
  if (requestData.end) {
    requestData.end = _formatTime(requestData.end, timeOffset);
  }
  return requestData;
};

/**
 * This generates a trace ID. It is sent in the header in all
 * requests made to the Results API and is used for tracing a request
 * from the frontend through the backend for logging and debugging purposes.
 *
 * A new trace ID is generated whenever the query parameters change (e.g. new
 * date range, new segment) or a new campaign, experiment, or experience is
 * loaded.
 *
 * The trace ID is made up of a version, the current timestamp in hex, and a
 * randomly generated ID of length 8 with [A-Za-z] characters.
 *
 * @return {String} [ the generated Trace ID ]
 */
export const generateTraceId = () => {
  const version = constants.TRACE_ID_VERSION;
  const hexTimestamp = Math.floor(Date.now() / 1000).toString(16);
  const randomId = nanoid.generateFixedLengthNanoId(8, true);
  return `${version}-${hexTimestamp}-${randomId}`;
};

/**
 * This returns the correct Results API urls to use when making requests to the
 * Results API. By default, we use the PROD urls.
 *
 * However, this function allows us to switch to the CANARY urls via the
 * api_source query parameter in the url. This is useful for testing out the
 * latest version of the Results API on the Frontend if the latest version
 * has not been deployed yet.
 *
 * It is not currently in use, but retained in case we want to repurpose it later
 *
 * @return {Object} [ The Results API urls to make requests to ]
 */
export const getApiUrls = () => {
  let apiUrls = constants.API_URLS.PROD;

  const locationParams = parseParams(window.location.href);
  const apiSource = locationParams[constants.API_SOURCE_QUERY_PARAM];

  if (apiSource) {
    const matchingSource = constants.API_URLS[apiSource.toUpperCase()];
    if (matchingSource) {
      apiUrls = matchingSource;
    }
  }

  return apiUrls;
};

/**
 * Returns the name of the data source the Results API used indicated in the
 * response header.
 *
 * @param {Object} xhr
 * @return {string}
 */
export const getResultsDataSource = xhr => {
  // Silently ignore if method isn't available because we stub XHR objects with$.Deferred during tests
  if (!xhr.getResponseHeader) {
    return null;
  }

  return xhr.getResponseHeader('X-Results-Data-Source');
};

export default {
  appendAdditionalQueryParams,
  formatDates,
  generateTraceId,
  getApiUrls,
  getResultsDataSource,
};
