import sprintf from 'sprintf';

import OasisImplementationEnums from 'bundles/p13n/sections/oasis_implementation/section_module/enums';

import { toJS } from 'optly/immutable';

import EventActions from 'optly/modules/entity/event/actions';

import { constants as MetricConstants } from 'optly/modules/entity/metric';
import { formatEventProperties } from 'bundles/p13n/sections/oasis_implementation/section_module/propertyFormatter';

import { DefaultMetrics, ReadableMetricsFields, UNDEFINED } from './constants';

/* eslint-disable no-unused-vars */
import {
  Event,
  EventFromSearchApi,
  EventFromSearchApiType,
  Metric,
  MetricAggregatorEnum,
  MetricFieldEnum,
  MetricScopeEnum,
  MetricWinningDirectionEnum,
  SheetMetricTypeEnum,
} from './types';
import {
  formatConditions,
  getAggregatorValues,
  getCompoundSubmetrics,
  getEventPropertiesPayload,
} from '../../metrics/metrics_modal/utils';

import {
  COMPOUND_METRIC_TYPE,
  GLOBAL_EVENTS,
  METRIC_TYPES,
} from '../../metrics/metrics_modal/constants';

/**
 * @description A null value will prompt this being set in selected metrics component
 */
export const computeDefaultMetricFromSearchApiEvent = ({
  id,
  name,
}: {
  id: number;
  name: string;
}): Metric => {
  return {
    ...DefaultMetrics.CUSTOM,
    display_title: name,
    event_id: id,
  };
};

/* eslint-disable camelcase */
/**
 * @description Given an event from the internal api, coerce it to the search api format
 */
export const computeSearchApiEventFormatFromEvent = (
  event: Event,
): EventFromSearchApi => {
  const {
    archived,
    api_name,
    created,
    description,
    id,
    last_modified,
    name,
    project_id,
  } = event;
  return {
    archived,
    created,
    description,
    id,
    last_modified,
    name: name || api_name,
    project_id,
    type: EventFromSearchApiType.Event,
  };
};
/* eslint-enable camelcase */

/* eslint-disable no-bitwise */
/**
 * @description Adds a short unique key to end of the provided string(s)
 * @example
 *  generateOrConcatenateUniqueId() => "6f6s2"
 *  generateOrConcatenateUniqueId(['_']) => "_6f6s2"
 *  generateOrConcatenateUniqueId(['my_event_key', '_']) => "my_event_key_6f6s2"
 */
export const generateOrConcatenateUniqueId = (
  valuesToConcatenate: string[] = [],
) => {
  const fiveCharacterUniqueId = 'xfxsx'
    .replace(/[x]/g, c => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .toLowerCase();
  return valuesToConcatenate.join('') + fiveCharacterUniqueId;
};
/* eslint-enable no-bitwise */

/**
 * @description Given the aggregator and field value, return readable name if possible or otherwise just the values themselves
 */
export const getAggregatorAndFieldDescription = (
  metricAggregator: MetricAggregatorEnum,
  metricField: MetricFieldEnum | null,
): string => {
  const aggregatorToFieldMap = ReadableMetricsFields.aggregatorAndField[
    metricAggregator
  ] as { [key: string]: string };
  const aggregatorAndFieldDescription =
    aggregatorToFieldMap && aggregatorToFieldMap[metricField || UNDEFINED];
  if (aggregatorAndFieldDescription) {
    return aggregatorAndFieldDescription;
  }
  return `${metricAggregator} ${metricField}`;
};

/**
 * @description Given a language, eventKey and event properties, return example code
 */
export const getCodeBlock = (
  language: string,
  eventKey?: string,
  eventProperties: any[] = [],
): string => {
  const CodeBlocksWithProperties = OasisImplementationEnums.CODE_BLOCKS_WITH_PROPERTIES as {
    [key: string]: string;
  };

  const CodeBlocksWithoutProperties = OasisImplementationEnums.CODE_BLOCKS_WITHOUT_PROPERTIES as {
    [key: string]: string;
  };

  if (
    !CodeBlocksWithProperties[language] ||
    !CodeBlocksWithoutProperties[language]
  ) {
    throw new Error(`No code template found for language: ${language}`);
  }

  const formattedProperties = formatEventProperties(eventProperties, language);
  const hasEventProperties = eventProperties && eventProperties.length > 0;

  if (language === 'html') {
    // For Agent/'html', the order is eventKey first, then formattedProperties
    if (hasEventProperties) {
      return sprintf(
        CodeBlocksWithProperties[language],
        eventKey || '',
        formattedProperties || '',
      );
    }
    return sprintf(CodeBlocksWithoutProperties[language], eventKey || '');
  }
  // For other languages, the order is formattedProperties first, then eventKey
  if (hasEventProperties) {
    return sprintf(
      CodeBlocksWithProperties[language],
      formattedProperties || '',
      eventKey || '',
    );
  }
  return sprintf(CodeBlocksWithoutProperties[language], eventKey || '');
};

/**
 * @description Returns a readable description of the metric. Also returns whether the metric configuration is advanced and therefore requires a read only UI.
 */
export const getMetricDescription = (
  metricWinningDirection: MetricWinningDirectionEnum,
  metricAggregator: MetricAggregatorEnum,
  metricField: MetricFieldEnum | null,
  metricScope: MetricScopeEnum,
  apiName = '',
): { description: string; isAdvancedConfigFromApi: boolean } => {
  const winningDirection =
    ReadableMetricsFields.winningDirection[metricWinningDirection];
  const aggregatorAndField = getAggregatorAndFieldDescription(
    metricAggregator,
    metricField,
  );
  const scope = ReadableMetricsFields.scope[metricScope];

  const eventName = apiName ? ` ${apiName}` : '';
  if (winningDirection && aggregatorAndField && scope) {
    return {
      description: `${winningDirection} in ${aggregatorAndField} per ${scope} for${eventName} event`,
      isAdvancedConfigFromApi: false,
    };
  }
  return {
    description: `Winning direction: ${metricWinningDirection}, aggregator: ${metricAggregator}, field: ${metricField}, scope: ${metricScope} for${eventName} event`,
    isAdvancedConfigFromApi: true,
  };
};

export function getEventNameFromCache(
  eventId: number | null,
  eventEntityCache: Immutable.Map<string, any>,
  viewEntityCache: Immutable.Map<string, any>,
) {
  const eventName = eventEntityCache.getIn([eventId, 'api_name']);
  const viewName = viewEntityCache.getIn([eventId, 'name']);

  return eventName || viewName;
}

export function getRatioMetricDescription(
  metric: Metric,
  eventEntityCache: Immutable.Map<string, any>,
  viewEntityCache: Immutable.Map<string, any>,
): string {
  const { metrics = [] } = metric;
  const [numerator, denominator] = metrics;

  const winningDirection =
    ReadableMetricsFields.winningDirection[metric.winning_direction];

  const numeratorEventName = getEventNameFromCache(
    numerator?.event_id,
    eventEntityCache,
    viewEntityCache,
  );

  const denominatorEventName = getEventNameFromCache(
    denominator?.event_id,
    eventEntityCache,
    viewEntityCache,
  );

  return `${winningDirection} in ratio for ${numeratorEventName} over ${denominatorEventName}`;
}

function getMetricType(metric: Metric) {
  const metricType =
    !metric.event_type && metric.field === MetricConstants.field.REVENUE
      ? 'GLOBAL'
      : metric.event_type?.toUpperCase() || '';

  return METRIC_TYPES[metricType as keyof typeof METRIC_TYPES];
}

export function getScopeOptions(aggregator: string) {
  const {
    aggregationOptions: { TOTAL_REVENUE, TOTAL_VALUE, BOUNCE_RATE, EXIT_RATE },
  } = MetricConstants;

  if ([BOUNCE_RATE, EXIT_RATE].includes(aggregator)) {
    return MetricConstants.eventScopeOptions;
  }

  if ([TOTAL_REVENUE, TOTAL_VALUE].includes(aggregator)) {
    return [
      {
        label: ReadableMetricsFields.scope.visitor,
        value: MetricScopeEnum.Visitor,
      },
      ...MetricConstants.revenueScopeOptions,
    ];
  }

  return [
    {
      label: ReadableMetricsFields.scope.visitor,
      value: MetricScopeEnum.Visitor,
    },
  ];
}

function getAggregator(
  aggregatorValue: string,
  field: string | null = null,
): string | undefined {
  const aggregationOperationsArray = Object.keys(
    MetricConstants.aggregationOperations,
  );
  const aggregator = aggregationOperationsArray.find(operationKey => {
    const operation = MetricConstants.aggregationOperations[operationKey];
    return (
      operation.aggregator === aggregatorValue && operation.field === field
    );
  });

  return aggregator;
}

export async function getMetricForm(
  metric: Metric,
  configuredEvent: Immutable.Map<string, any>,
) {
  const {
    aggregator: aggregatorValue,
    display_title: name,
    event_id: eventId,
    event_properties = {},
    scope,
    winning_direction: winningDirection,
    isDraft,
    field = null,
  } = metric;

  const metricType = getMetricType(metric);
  const { conditions, combineOperator } = formatConditions(event_properties);
  const filterByProperties = !!Object.values(conditions).length;

  let event = toJS(configuredEvent);
  if (!event && eventId && metricType === SheetMetricTypeEnum.Custom) {
    event = await EventActions.fetch(eventId);
  }

  if (name === METRIC_TYPES.GLOBAL) {
    event = GLOBAL_EVENTS[0];
  }

  const aggregator = getAggregator(aggregatorValue, field);

  const form = {
    aggregator,
    combineOperator,
    conditions,
    event,
    filterByProperties,
    isDraft,
    name,
    scope,
    type: metricType,
    winningDirection,
  };

  return form;
}

export async function getRatioMetricForm(
  metric: Metric,
  numeratorMetricEvent: Immutable.Map<string, any>,
  denominatorMetricEvent: Immutable.Map<string, any>,
) {
  const {
    aggregator,
    display_title: name,
    metrics = [{}, {}],
    scope,
    winning_direction: winningDirection,
    isDraft,
  } = metric;

  const metricType = COMPOUND_METRIC_TYPE;

  const compoundNumerator = metrics[0] as Metric;
  const compoundDenominator = metrics[1] as Metric;

  let numeratorEvent = toJS(numeratorMetricEvent);
  if (!numeratorEvent && compoundNumerator.event_id) {
    numeratorEvent = await EventActions.fetch(compoundNumerator.event_id);
  }

  let denominatorEvent = toJS(denominatorMetricEvent);
  if (!denominatorEvent && compoundDenominator.event_id) {
    denominatorEvent = await EventActions.fetch(compoundDenominator.event_id);
  }

  const numeratorAggregator = getAggregator(
    compoundNumerator.aggregator,
    compoundNumerator.field,
  );

  const denominatorAggregator = getAggregator(
    compoundDenominator.aggregator,
    compoundDenominator.field,
  );

  const form = {
    aggregator,
    compoundNumerator: {
      ...compoundNumerator,
      event: numeratorEvent,
      aggregator: numeratorAggregator,
    },
    compoundDenominator: {
      ...compoundDenominator,
      event: denominatorEvent,
      aggregator: denominatorAggregator,
    },
    isDraft,
    name,
    scope,
    type: metricType,
    winningDirection,
  };

  return form;
}

export function getModalMetric(metricForm: any): Metric {
  const {
    aggregator: selectedAggregator,
    combineOperator,
    conditions,
    event,
    name,
    scope,
    filterByProperties,
    winningDirection: winning_direction,
    compoundNumerator,
    compoundDenominator,
    isDraft,
  } = metricForm;

  const event_properties = getEventPropertiesPayload(
    combineOperator,
    conditions,
  );

  const { aggregator, field } = getAggregatorValues(selectedAggregator);
  const display_title = name || event?.name;

  if (event.id === 'revenue') {
    const revenueMetric = {
      aggregator,
      display_title,
      field: field || undefined,
      scope,
      winning_direction,
      isDraft,
    } as Metric;

    return revenueMetric;
  }

  if (compoundNumerator && compoundDenominator) {
    const subMetrics = getCompoundSubmetrics(
      compoundNumerator,
      compoundDenominator,
      scope,
    );

    const ratioMetric = {
      aggregator: MetricAggregatorEnum.Ratio,
      scope,
      display_title,
      metrics: subMetrics,
      winning_direction,
      isDraft,
    } as Metric;

    return ratioMetric;
  }

  const configuredMetric = {
    aggregator,
    display_title,
    event_id: event?.id,
    event_properties: filterByProperties ? event_properties : undefined,
    event_type: event?.event_type,
    field: field || undefined,
    scope,
    winning_direction,
    isDraft,
  } as Metric;

  return configuredMetric;
}

export default {
  computeDefaultMetricFromSearchApiEvent,
  computeSearchApiEventFormatFromEvent,
  generateOrConcatenateUniqueId,
  getAggregatorAndFieldDescription,
  getCodeBlock,
  getMetricDescription,
  getModalMetric,
};
