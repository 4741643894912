import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Dropdown,
  HelpPopover,
  Icon,
  Input,
  Spinner,
  Poptip,
} from '@optimizely/axiom';

import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import { connect } from 'core/ui/decorators';

import { actions as CurrentLayerActions } from 'bundles/p13n/modules/current_layer';
import ProjectActions from 'optly/modules/entity/project/actions';
import ProjectEnums from 'optly/modules/entity/project/enums';
import { isFilterTermInItem } from 'optly/utils/filter';
import Immutable from 'optly/immutable';
import { actions as EventModuleActions } from 'optly/modules/entity/event';

import flux from 'core/flux';

import {
  actions as MetricsPickerModuleActions,
  getters as MetricsPickerModuleGetters,
} from '../metrics_manager_react/subcomponents/metrics_picker_react/component_module';
import { getEventsByType } from './utils';
import { EVENT_TOOLTIP_CONTENT } from './constants';

@connect(() => ({
  clickEvents: MetricsPickerModuleGetters.allPageClickEvents,
  customEvents: MetricsPickerModuleGetters.customEventsGetterV2,
  pageEvents: MetricsPickerModuleGetters.allPageViewEvents,
  currentProjectOnly: MetricsPickerModuleGetters.shouldUseCurrentProject,
}))
export class EventsSelectorWrapper extends React.Component {
  static propTypes = {
    clickEvents: PropTypes.instanceOf(Immutable.OrderedMap).isRequired,
    currentProjectOnly: PropTypes.bool.isRequired,
    customEvents: PropTypes.instanceOf(Immutable.List).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    metricType: PropTypes.string.isRequired,
    pageEvents: PropTypes.instanceOf(Immutable.OrderedMap).isRequired,
  };

  render() {
    const { metricType, clickEvents, customEvents, pageEvents } = this.props;
    const events = getEventsByType({
      metricType,
      clickEvents,
      customEvents,
      pageEvents,
    });

    return <EventsSelector events={events} {...this.props} />;
  }
}

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

function EventsSelector({
  events,
  currentProjectOnly,
  value,
  onChange,
  metricType,
  isDisabled,
  displayError,
  note,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [allEventsLoaded, setAllEventsLoaded] = useState(false);
  const prevType = usePrevious(metricType);

  useEffect(() => {
    setIsLoading(true);
    const projectId = flux.evaluateToJS(CurrentProjectGetters.id);
    Promise.all([
      EventModuleActions.fetchAll({
        project_id: projectId,
      }),
    ]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const fetchAllProjects = e => {
    e.stopPropagation();
    if (!allEventsLoaded) {
      setIsLoading(true);
      Promise.all([
        ProjectActions.fetchAll(
          {
            project_status: ProjectEnums.project_status.ACTIVE,
          },
          { excludeFields: ['jira_integration'] },
        ),
        CurrentLayerActions.fetchAllCrossProjectMetrics(),
      ]).then(() => {
        setIsLoading(false);
        setAllEventsLoaded(true);
      });
    }

    MetricsPickerModuleActions.setUseCurrentProject(false);
  };

  const fetchCurrentProject = e => {
    e.stopPropagation();
    MetricsPickerModuleActions.setUseCurrentProject(true);
  };

  const filteredEvents = useMemo(() => {
    if (searchQuery === '') {
      return events;
    }

    return events.filter(event =>
      isFilterTermInItem(
        searchQuery,
        `${event.name} ${event.description} ${event.category}`,
      ),
    );
  }, [events, searchQuery]);

  const currentProjectButton = currentProjectOnly ? (
    <span>Current project only</span>
  ) : (
    <a onClick={fetchCurrentProject}>Current project only</a>
  );

  const allProjectsButton = currentProjectOnly ? (
    <a onClick={fetchAllProjects}>All projects</a>
  ) : (
    <span>All projects</span>
  );

  useEffect(() => {
    if ((prevType && metricType && prevType !== metricType) || !value) {
      const firstEvent = events?.at(0) || {};
      onChange(firstEvent);
    }
  }, [metricType, value]);

  return (
    <Poptip
      content={EVENT_TOOLTIP_CONTENT}
      isInline={true}
      disabled={!isDisabled}
      style={{ flex: 1 }}>
      <Dropdown
        isDisabled={isDisabled}
        arrowIcon="down"
        buttonContent={value || 'Select an event...'}
        fullWidth={true}
        testSection="event-selector-dropdown"
        displayError={displayError}>
        <Dropdown.Contents style={{ maxWidth: '100%' }}>
          <div className="soft-half">
            <Input
              onClick={e => e.stopPropagation()}
              testSection="block-list-input"
              type="search"
              placeholder="Browse"
              onChange={e => setSearchQuery(e.target.value)}
              isFilter={true}
            />
            <div className="flex soft-half" style={{ gap: '4px' }}>
              <span>Show: </span>
              {currentProjectButton}
              <span>|</span>
              <div className="flex">
                {allProjectsButton}
                <HelpPopover
                  behavior="hover"
                  popoverTitle="Cross Project Events">
                  <p>
                    Choosing "all projects" will show events across all projects
                    you have access to in both Web and Feature Experimentation.
                    Note: Page trimming will not consider cross-project events
                    when evaluating pages, potentially causing issues with event
                    tracking. Visit your snippet settings to check if page
                    trimming is enabled.
                  </p>
                </HelpPopover>
              </div>
            </div>
            <div
              style={{
                maxHeight: '200px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}>
              {isLoading ? (
                <div
                  className="flex flex-align--center flex-justified--center"
                  style={{ height: '100px' }}>
                  <Spinner />
                </div>
              ) : (
                filteredEvents.map(event => (
                  <EventListItem
                    event={event}
                    currentProjectOnly={currentProjectOnly}
                    onChange={onChange}
                    key={`event-[${event.id}]`}
                  />
                ))
              )}
            </div>
          </div>
        </Dropdown.Contents>
      </Dropdown>
      {displayError && (
        <div className="oui-form-bad-news">
          <div className="oui-form-note">{note}</div>
        </div>
      )}
    </Poptip>
  );
}

Event.propTypes = {
  currentProjectOnly: PropTypes.bool.isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  metricType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function EventListItem({ event, currentProjectOnly, onChange }) {
  return (
    <a
      className="flex  flex-align--center lego-dropdown__block-link soft--sides"
      onClick={() => onChange(event)}
      data-test="event-list-item">
      <Icon
        className="push--right flex-shrink--none"
        color={brandBlueDark}
        name="plus"
        size="small"
      />
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}>
        {event.name}
        <div className="micro muted">
          {!currentProjectOnly && event.project_name}
        </div>
      </div>
    </a>
  );
}

EventListItem.propTypes = {
  currentProjectOnly: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default {
  EventsSelectorWrapper,
};
