import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from 'optimizely-oui';

function ShowAvailableItemsCta({
  type,
  fetchAnyProjectEntityAndReturnTrueIfPresent,
  handleFetchItems,
}) {
  const [status, setStatus] = useState('idle');

  const loadArchivedItems = () => {
    let isMounted = true;

    if (status === 'idle') {
      setStatus('loading');
      fetchAnyProjectEntityAndReturnTrueIfPresent().then(data => {
        if (isMounted) {
          setStatus(data ? 'success' : 'failed');
        }
      });
    }

    return () => {
      isMounted = false;
    };
  };

  useEffect(loadArchivedItems, []);

  return status === 'success' ? (
    <p className="push--top flex flex--dead-center">
      <span className="push-half--right">Or</span>
      <Button
        ariaLabel={`Show ${type} items`}
        onClick={handleFetchItems}
        style="unstyled"
        testSection={`show-${type}-items-button`}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link>{`show ${type} items`}</Link>.
      </Button>
    </p>
  ) : null;
}

ShowAvailableItemsCta.propTypes = {
  fetchAnyProjectEntityAndReturnTrueIfPresent: PropTypes.func.isRequired,
  handleFetchItems: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['archived', 'concluded']).isRequired,
};

function ShowAvailableArchivedItemsCta({
  fetchAnyProjectEntityAndReturnTrueIfPresent,
  handleFetchArchivedItems,
}) {
  return (
    <ShowAvailableItemsCta
      type="archived"
      fetchAnyProjectEntityAndReturnTrueIfPresent={
        fetchAnyProjectEntityAndReturnTrueIfPresent
      }
      handleFetchItems={handleFetchArchivedItems}
    />
  );
}

ShowAvailableArchivedItemsCta.propTypes = {
  fetchAnyProjectEntityAndReturnTrueIfPresent: PropTypes.func.isRequired,
  handleFetchArchivedItems: PropTypes.func.isRequired,
};

function ShowAvailableConcludedItemsCta({
  fetchAnyProjectEntityAndReturnTrueIfPresent,
  handleFetchConcludedItems,
}) {
  return (
    <ShowAvailableItemsCta
      type="concluded"
      fetchAnyProjectEntityAndReturnTrueIfPresent={
        fetchAnyProjectEntityAndReturnTrueIfPresent
      }
      handleFetchItems={handleFetchConcludedItems}
    />
  );
}

ShowAvailableConcludedItemsCta.propTypes = {
  fetchAnyProjectEntityAndReturnTrueIfPresent: PropTypes.func.isRequired,
  handleFetchConcludedItems: PropTypes.func.isRequired,
};

export { ShowAvailableArchivedItemsCta, ShowAvailableConcludedItemsCta };
