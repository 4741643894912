const STORE_NAME = 'hypothesis_linking';

const linkedHypothesis = [STORE_NAME, 'linkedHypothesis'];
const hypothesisToLink = [STORE_NAME, 'hypothesisToLink'];
const shouldCreateHypothesis = [STORE_NAME, 'shouldCreateHypothesis'];
const openInNewTabAfterHypothesisCreation = [
  STORE_NAME,
  'openInNewTabAfterHypothesisCreation',
];
const hypothesisName = [STORE_NAME, 'hypothesisName'];
const hypothesisPlannedStartDate = [STORE_NAME, 'hypothesisPlannedStartDate'];
const hypothesisPlannedEndDate = [STORE_NAME, 'hypothesisPlannedEndDate'];

const hypothesisToCreate = [
  hypothesisName,
  hypothesisPlannedStartDate,
  hypothesisPlannedEndDate,
  (name, plannedStartDate, plannedEndDate) => {
    return {
      name,
      plannedStartDate,
      plannedEndDate,
    };
  },
];

export default {
  linkedHypothesis,
  hypothesisToLink,
  shouldCreateHypothesis,
  hypothesisName,
  hypothesisPlannedStartDate,
  hypothesisPlannedEndDate,
  hypothesisToCreate,
  openInNewTabAfterHypothesisCreation,
};
