/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EmptyDashboard, Icon, Table } from 'optimizely-oui';

import chassisFlags from 'optly/services/chassis_flags';
import { connect } from 'core/ui/decorators';

import Immutable, { toMap } from 'optly/immutable';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import LayerActions from 'optly/modules/entity/layer/actions';
import LayerEnums from 'optly/modules/entity/layer/enums';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';
import DashboardActions from 'optly/modules/dashboard/actions';
import { isWinnerRolloutFeatureEnabled } from 'optly/utils/features';

import EntitySearchConstants from 'bundles/p13n/components/entity_search/component_module/constants';
import EntitySearchTable from 'bundles/p13n/components/entity_search_table';
import {
  ShowAvailableArchivedItemsCta as ShowArchivedItemsCtaIfAvailableComponent,
  ShowAvailableConcludedItemsCta as ShowConcludedItemsCtaIfAvailableComponent,
} from 'bundles/p13n/components/available_items_cta';

import {
  DEFAULT_COLUMNS_VISIBILITY,
  DEFAULT_EXPERIMENTS_COLUMNS_VISIBILITY,
  DEFAULT_PERSONALIZATION_COLUMNS_VISIBILITY,
} from 'optly/modules/dashboard/constants';

import ExperimentTableRow from './components/layer_table_row';
import CreateDropdown from './components/create_dropdown';
import CustomizationButton from './components/customization_button';
import CustomizationDrawer from './components/customization_drawer';
import PageModuleConstants from './page_module/constants';
import GetTestIdeasUsingOpal from './components/get_test_ideas_using_opal';

const statusFilterOptions = [
  {
    label: 'Active',
    value: LayerEnums.status.ACTIVE,
  },
  {
    label: 'Running',
    value: LayerEnums.status.RUNNING,
  },
  {
    label: 'Paused',
    value: LayerEnums.status.PAUSED,
  },
  {
    label: 'Draft',
    value: LayerEnums.status.DRAFT,
  },
  {
    label: 'Archived',
    value: LayerEnums.status.ARCHIVED,
  },
  {
    label: 'Concluded',
    value: LayerEnums.status.CONCLUDED,
  },
];

const selectAllOption = {
  label: 'All',
  value: 'all',
};

const typeFilterOptions = [
  {
    label: 'A/B Test',
    value: LayerEnums.type.AB,
  },
  {
    label: 'Multivariate Test',
    value: LayerEnums.type.MULTIVARIATE,
  },
  {
    label: 'Multi-Armed Bandit',
    value: LayerEnums.type.MULTIARMED_BANDIT,
  },
  {
    label: 'Personalization Campaign',
    value: LayerEnums.type.PERSONALIZATION,
  },
];

const columnsVisibilityPerTab = toMap({
  experiments: DEFAULT_EXPERIMENTS_COLUMNS_VISIBILITY,
  personalizations: DEFAULT_PERSONALIZATION_COLUMNS_VISIBILITY,
  overview: DEFAULT_COLUMNS_VISIBILITY,
});

export const CUSTOMIZATION_HEADERS = toMap({
  name: { index: 1, header: 'Name' },
  type: { index: 2, header: 'Type' },
  jira: { index: 3, header: 'Jira' },
  status: { index: 4, header: 'Status' },
  creator: { index: 5, header: 'Creator' },
  last_modified: { index: 6, header: 'Modified' },
  first_published: { index: 7, header: 'First Published' },
  last_paused: { index: 8, header: 'Last Paused' },
  last_published: { index: 9, header: 'Last Published' },
  primary_metric: { index: 10, header: 'Primary Metric' },
  days_running: { index: 11, header: 'Days Running' },
  variations: { index: 12, header: 'Variations' },
  pages: { index: 13, header: 'Pages' },
  audiences: { index: 14, header: 'Audiences' },
  targeting_method: { index: 15, header: 'Targeting Method' },
  unique_id: { index: 16, header: 'Experiment ID' },
  traffic_allocation: { index: 17, header: 'Traffic Allocation' },
  distribution_mode: { index: 18, header: 'Distribution Mode' },
  results: { index: 19, header: 'Results' },
  concluded_results_outcome: { index: 20, header: 'Results Outcome' },
});

export const CUSTOMIZATION_WIDTH = {
  defaultValues: {
    name: '400px',
    type: '210px',
    jira: '100px',
    status: '105px',
    last_modified: '120px',
    results: '105px',
    actions: '40px',
  },
  fixedValues: {
    type: '104px',
    jira: '88px',
    status: '80px',
    last_modified: '88px',
    first_published: '88px',
    last_paused: '88px',
    last_published: '88px',
    days_running: '56px',
    variations: '64px',
    targeting_method: '64px',
    unique_id: '96px',
    traffic_allocation: '64px',
    distribution_mode: '128px',
    results: '82px',
    actions: '40px',
  },
};

const checkDefaultColumns = columnsVisibility => {
  const columns = columnsVisibility;
  const visibleColumns = columns.filter(column => column);

  return (
    (visibleColumns.size === 6 &&
      visibleColumns.get('name') &&
      visibleColumns.get('type') &&
      visibleColumns.get('jira') &&
      visibleColumns.get('status') &&
      visibleColumns.get('last_modified') &&
      visibleColumns.get('results')) ||
    (visibleColumns.size === 5 &&
      visibleColumns.get('name') &&
      visibleColumns.get('type') &&
      visibleColumns.get('status') &&
      visibleColumns.get('last_modified') &&
      visibleColumns.get('results'))
  );
};

@connect({
  currentProject: CurrentProjectGetters.project,
  shouldShowRowActions: PermissionsModuleGetters.canUseLayerTableRowActions,
})
class SearchExperimentTable extends React.Component {
  static displayName = 'SearchExperimentTable';

  static propTypes = {
    changeFilters: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    changeQuery: PropTypes.func.isRequired,
    changeSort: PropTypes.func.isRequired,
    changeTypeFilter: PropTypes.func.isRequired,
    columnsVisibility: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentSearchOptions: PropTypes.shape({}).isRequired,
    dashboardLayers: PropTypes.instanceOf(Immutable.List).isRequired,
    entityTypes: PropTypes.arrayOf(PropTypes.string),
    handleEntityUpdate: PropTypes.func.isRequired,
    isTableLoading: PropTypes.bool.isRequired,
    searchApiStatus: PropTypes.shape({
      canListEntities: PropTypes.bool.isRequired,
      canQueryEntities: PropTypes.bool.isRequired,
    }).isRequired,
    shouldShowRowActions: PropTypes.bool.isRequired,
    submitQuery: PropTypes.func.isRequired,
    tab: PropTypes.string.isRequired,
    totalPages: PropTypes.number.isRequired,
    typeFilterAvailableOptions: PropTypes.arrayOf(PropTypes.string),
    useCustomTable: PropTypes.bool,
    v2FirstRunImage: PropTypes.string.isRequired,
    v2FirstRunText: PropTypes.object.isRequired,
  };

  static defaultProps = {
    useCustomTable: true,
    typeFilterAvailableOptions: [],
  };

  state = {
    focusKey: '',
    isCustomizationDrawerOpen: false,
  };

  shouldShowJiraContent = () => {
    const { currentProject } = this.props;

    return (
      currentProject.get('jira_integration') &&
      currentProject.getIn(['jira_integration', 'enabled']) &&
      !!currentProject.getIn(['jira_integration', 'resource_id'])
    );
  };

  renderSortableHeader = (
    field,
    type,
    key,
    sort,
    order,
    toggleSortForColumn,
    extraClass,
    width,
  ) => {
    const { focusKey } = this.state;
    const classes = classNames({
      flex: true,
      'flex--row': true,
      'flex-align--center': true,
      'flex-justified--end': false,
      [extraClass]: true,
    });
    const defaultOrder =
      EntitySearchConstants.searchDefaultSortOrder[key] ||
      EntitySearchConstants.searchSortOrders.ASC;
    const nextOrder = key !== sort && key === focusKey ? defaultOrder : order;
    const sortArrow = (
      <Icon
        name={
          nextOrder === EntitySearchConstants.searchSortOrders.ASC
            ? 'caret-up-solid'
            : 'caret-down-solid'
        }
        size="small"
      />
    );
    return (
      <Table.TH width={width} field={key} type={type}>
        <div className={classes}>
          <span
            onClick={() => toggleSortForColumn(key)}
            onKeyDown={() => toggleSortForColumn(key)}
            role="button"
            tabIndex="0"
            onMouseEnter={() => {
              this.setState({
                focusKey: key,
              });
            }}
            onMouseLeave={() => {
              this.setState({
                focusKey: '',
              });
            }}
            className="flex flex-align--center cursor--pointer"
            data-test-section={`${key}-sort-toggle`}>
            {field} {[sort, focusKey].includes(key) && sortArrow}
          </span>
        </div>
      </Table.TH>
    );
  };

  renderTableHeader = (sort, order, toggleSortForColumn) => {
    const {
      shouldShowRowActions,
      columnsVisibility,
      useCustomTable,
    } = this.props;

    let columnsWidth = {};
    const areDefaultColumns = checkDefaultColumns(columnsVisibility);

    if (areDefaultColumns) {
      columnsWidth = CUSTOMIZATION_WIDTH.defaultValues;
    } else if (useCustomTable) {
      columnsWidth = CUSTOMIZATION_WIDTH.fixedValues;
    }

    const isWinnerRolloutEnabled = isWinnerRolloutFeatureEnabled();

    return (
      <Table.TR>
        {columnsVisibility.get('name') &&
          this.renderSortableHeader(
            'Name',
            'string',
            EntitySearchConstants.searchSortableKeys.NAME,
            sort,
            order,
            toggleSortForColumn,
            'min-width--150',
            columnsWidth.name || '',
          )}
        {columnsVisibility.get('type') && (
          <Table.TH width={columnsWidth.type || ''}>
            <div className="min-width--104">Type</div>
          </Table.TH>
        )}
        {this.shouldShowJiraContent() && columnsVisibility.get('jira') && (
          <Table.TH width={columnsWidth.jira || ''}>
            <div className="min-width--88">Jira</div>
          </Table.TH>
        )}
        {columnsVisibility.get('status') &&
          this.renderSortableHeader(
            'Status',
            'string',
            EntitySearchConstants.searchSortableKeys.STATUS,
            sort,
            order,
            toggleSortForColumn,
            'min-width--80',
            columnsWidth.status || '',
          )}
        {columnsVisibility.get('concluded_results_outcome') &&
          isWinnerRolloutEnabled && (
            <Table.TH>
              <div className="min-width--100">Results Outcome</div>
            </Table.TH>
          )}
        {columnsVisibility.get('creator') && (
          <Table.TH>
            <div className="min-width--232">Creator</div>
          </Table.TH>
        )}
        {columnsVisibility.get('last_modified') &&
          this.renderSortableHeader(
            'Modified',
            'date',
            EntitySearchConstants.searchSortableKeys.LAST_MODIFIED,
            sort,
            order,
            toggleSortForColumn,
            'min-width--88',
            columnsWidth.last_modified || '',
          )}
        {columnsVisibility.get('first_published') && (
          <Table.TH width={columnsWidth.first_published || ''}>
            <div className="min-width--88">First Published</div>
          </Table.TH>
        )}
        {columnsVisibility.get('last_paused') && (
          <Table.TH width={columnsWidth.last_paused || ''}>
            <div className="min-width--88">Last Paused</div>
          </Table.TH>
        )}
        {columnsVisibility.get('last_published') && (
          <Table.TH width={columnsWidth.last_published || ''}>
            <div className="min-width--88">Last Published</div>
          </Table.TH>
        )}
        {columnsVisibility.get('primary_metric') && (
          <Table.TH>
            <div className="min-width--150">Primary Metric</div>
          </Table.TH>
        )}
        {columnsVisibility.get('days_running') && (
          <Table.TH width={columnsWidth.days_running || ''} isNumerical={true}>
            <div className="min-width--56">Days Running</div>
          </Table.TH>
        )}
        {columnsVisibility.get('variations') && (
          <Table.TH width={columnsWidth.variations || ''} isNumerical={true}>
            <div className="min-width--64">Variations</div>
          </Table.TH>
        )}
        {columnsVisibility.get('pages') && (
          <Table.TH>
            <div className="min-width--150">Pages</div>
          </Table.TH>
        )}
        {columnsVisibility.get('audiences') && (
          <Table.TH>
            <div className="min-width--150">Audiences</div>
          </Table.TH>
        )}
        {columnsVisibility.get('targeting_method') && (
          <Table.TH width={columnsWidth.targeting_method || ''}>
            <div className="min-width--64">Targeting Method</div>
          </Table.TH>
        )}
        {columnsVisibility.get('unique_id') && (
          <Table.TH width={columnsWidth.unique_id || ''}>
            <div className="min-width--96">Experiment ID</div>
          </Table.TH>
        )}
        {columnsVisibility.get('traffic_allocation') && (
          <Table.TH
            width={columnsWidth.traffic_allocation || ''}
            isNumerical={true}>
            <div className="min-width--64">Traffic Allocation</div>
          </Table.TH>
        )}
        {columnsVisibility.get('distribution_mode') && (
          <Table.TH width={columnsWidth.distribution_mode || ''}>
            <div className="min-width--128">Distribution Mode</div>
          </Table.TH>
        )}
        {columnsVisibility.get('results') && (
          <Table.TH width={columnsWidth.results || ''}>
            <div className="min-width--82">Results</div>
          </Table.TH>
        )}
        {shouldShowRowActions && (
          <Table.TH width={columnsWidth.actions || ''} isNumerical={true} />
        )}
      </Table.TR>
    );
  };

  renderTableRow = dashboardLayer => {
    const {
      currentProject,
      handleEntityUpdate,
      shouldShowRowActions,
      columnsVisibility,
    } = this.props;

    return (
      <ExperimentTableRow
        key={dashboardLayer.get('unique_id')}
        columnsVisibility={columnsVisibility}
        currentProject={currentProject}
        jiraIntegrationEnabled={true}
        jiraIntegrationSettings={currentProject.get('jira_integration')}
        dashboardLayer={dashboardLayer}
        handleEntityUpdate={handleEntityUpdate}
        shouldShowJiraContent={this.shouldShowJiraContent()}
        shouldShowRowActions={shouldShowRowActions}
      />
    );
  };

  trackFirstPageLoad = () => {
    const { searchApiStatus } = this.props;
    const searchApiEventAttributes = {
      use_search_api_for_sonic_search_boxes: searchApiStatus.canQueryEntities,
      use_search_api_for_sonic_tables: searchApiStatus.canListEntities,
    };
  };

  fetchAnyProjectEntityAndReturnTrueIfPresent = () =>
    new Promise(async resolve => {
      const { currentProject, typeFilterAvailableOptions } = this.props;
      const projectLayerExperiment = await LayerActions.fetchPage(
        {
          project_id: currentProject.get('id'),
          $limit: 1,
          $idsOnly: true,
          status: LayerEnums.status.ARCHIVED.toLowerCase(),
          type: typeFilterAvailableOptions.map(type => `${type}`),
        },
        {
          force: true,
        },
      );
      resolve(!!(projectLayerExperiment && projectLayerExperiment.length));
    });

  fetchAnyConcludedProjectEntityAndReturnTrueIfPresent = () =>
    new Promise(async resolve => {
      const { currentProject, typeFilterAvailableOptions } = this.props;
      const projectLayerExperiment = await LayerActions.fetchPage(
        {
          project_id: currentProject.get('id'),
          $limit: 1,
          $idsOnly: true,
          status: LayerEnums.status.CONCLUDED.toLowerCase(),
          type: typeFilterAvailableOptions.map(type => `${type}`),
        },
        {
          force: true,
        },
      );
      resolve(!!(projectLayerExperiment && projectLayerExperiment.length));
    });

  renderEmptyDashboard = ({
    onStatusFilterChange,
    shouldRenderEmptyDashboard,
  }) => {
    const { v2FirstRunText, v2FirstRunImage } = this.props;

    const buttonProp = {};
    // This is because of CJS-4886, we need to render always the EmptyDashboard.
    // But, if we always include the button then we are going to break the Cypress tests because we are going to have two Create Experiment Dropdowns.
    // To avoid this, we need to render it when the Dashboard is empty only.
    if (shouldRenderEmptyDashboard) {
      buttonProp.button = this.renderMainActionButtons();
    }

    return (
      <EmptyDashboard
        {...buttonProp}
        description={
          <>
            {v2FirstRunText.subtitle}
            <ShowArchivedItemsCtaIfAvailableComponent
              fetchAnyProjectEntityAndReturnTrueIfPresent={
                this.fetchAnyProjectEntityAndReturnTrueIfPresent
              }
              handleFetchArchivedItems={() =>
                onStatusFilterChange(LayerEnums.status.ARCHIVED)
              }
            />
            <ShowConcludedItemsCtaIfAvailableComponent
              fetchAnyProjectEntityAndReturnTrueIfPresent={
                this.fetchAnyConcludedProjectEntityAndReturnTrueIfPresent
              }
              handleFetchConcludedItems={() =>
                onStatusFilterChange(LayerEnums.status.CONCLUDED)
              }
            />
          </>
        }
        headline={v2FirstRunText.title}
        imagePath={v2FirstRunImage}
      />
    );
  };

  renderMainActionButtons = () => {
    const { useCustomTable, typeFilterAvailableOptions } = this.props;

    const showTestIdeasUsingOpal =
      chassisFlags.isOpalChatEnabled() &&
      chassisFlags.isExperimentTestIdeasEnabled();

    if (!useCustomTable) {
      return (
        <CreateDropdown
          addDataTrackId={true}
          allowedLayerTypes={typeFilterAvailableOptions}
        />
      );
    }

    if (showTestIdeasUsingOpal) {
      return (
        <div className="flex">
          <div className="push--right">
            <GetTestIdeasUsingOpal />
          </div>
          <div className="push--right">
            <CreateDropdown
              addDataTrackId={true}
              allowedLayerTypes={typeFilterAvailableOptions}
            />
          </div>
          <CustomizationButton onClick={this.toggleCustomizationDrawer} />
        </div>
      );
    }

    return (
      <div className="flex">
        <div className="push--right">
          <CustomizationButton onClick={this.toggleCustomizationDrawer} />
        </div>
        <CreateDropdown
          addDataTrackId={true}
          allowedLayerTypes={typeFilterAvailableOptions}
        />
      </div>
    );
  };

  toggleCustomizationDrawer = () => {
    this.setState(state => ({
      isCustomizationDrawerOpen: !state.isCustomizationDrawerOpen,
    }));
  };

  handleSubmitCustomization = newVisibilityState => {
    const newFieldsKeys = Object.keys(newVisibilityState);
    const { currentProject, tab } = this.props;
    DashboardActions.setColumnsVisibility(
      tab,
      currentProject.get('id'),
      newFieldsKeys,
    );
  };

  getCustomizationColumnsState = () => {
    const { columnsVisibility, tab } = this.props;

    const columns = columnsVisibilityPerTab
      .get(tab)
      .entrySeq()
      .map(e => {
        return {
          ...CUSTOMIZATION_HEADERS.get(e[0]),
          id: e[0],
          isVisible: columnsVisibility.get(e[0]),
        };
      })
      .filter(({ id }) => {
        if (id === 'jira') {
          return this.shouldShowJiraContent();
        }
        if (id === 'concluded_results_outcome') {
          return isWinnerRolloutFeatureEnabled();
        }
        return true;
      });

    return columns.sortBy(c => c.index);
  };

  getInitialFilterValue = () => {
    const { currentSearchOptions } = this.props;
    if (currentSearchOptions.archived) {
      return LayerEnums.status.ARCHIVED;
    }
    switch (currentSearchOptions.status) {
      case 'running':
        return LayerEnums.status.RUNNING;
      case 'paused':
        return LayerEnums.status.PAUSED;
      case 'draft':
        return LayerEnums.status.DRAFT;
      case 'concluded':
        return LayerEnums.status.CONCLUDED;
      default:
        return LayerEnums.status.ACTIVE;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getInitialTypeFilterValue = () => {
    return selectAllOption.value;
  };

  compileTypeOptions = () => {
    const { typeFilterAvailableOptions } = this.props;

    return typeFilterAvailableOptions
      ? [
          selectAllOption,
          ...typeFilterOptions.filter(option =>
            (typeFilterAvailableOptions || []).includes(option.value),
          ),
        ]
      : [];
  };

  render() {
    const {
      dashboardLayers,
      currentSearchOptions,
      isTableLoading,
      changeFilters,
      changeTypeFilter,
      changePage,
      changeQuery,
      changeSort,
      currentProject,
      submitQuery,
      totalPages,
      useCustomTable,
    } = this.props;

    const { isCustomizationDrawerOpen } = this.state;

    return (
      <>
        <EntitySearchTable
          changeFilters={changeFilters}
          changeTypeFilter={changeTypeFilter}
          changePage={changePage}
          changeQuery={changeQuery}
          changeSort={changeSort}
          currentProject={currentProject}
          currentSearchOptions={currentSearchOptions}
          data={dashboardLayers}
          defaultFilterValue={LayerEnums.status.ACTIVE}
          defaultTypeFilterValue={selectAllOption.value}
          entityPlural="experiments or campaigns"
          filterOptions={statusFilterOptions}
          filterToStatuses={PageModuleConstants.filterToStatuses}
          filterToArchivedState={PageModuleConstants.filterToArchivedState}
          filterToTypes={PageModuleConstants.filterToTypes}
          initialFilterValue={this.getInitialFilterValue()}
          initialTypeFilterValue={this.getInitialTypeFilterValue()}
          isTableLoading={isTableLoading}
          onFirstPageLoad={this.trackFirstPageLoad}
          renderCreateDropdown={this.renderMainActionButtons}
          renderEmptyDashboard={this.renderEmptyDashboard}
          renderTableHeader={this.renderTableHeader}
          renderTableRow={this.renderTableRow}
          typeFilterOptions={this.compileTypeOptions()}
          submitQuery={submitQuery}
          totalPages={totalPages}
          useCustomTable={useCustomTable}
        />
        {useCustomTable && (
          <CustomizationDrawer
            isOpen={isCustomizationDrawerOpen}
            columns={this.getCustomizationColumnsState()}
            onClose={this.toggleCustomizationDrawer}
            onSubmit={this.handleSubmitCustomization}
          />
        )}
      </>
    );
  }
}

export default SearchExperimentTable;
