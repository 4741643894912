import _ from 'lodash';

import { getters as PluginModuleGetters } from 'optly/modules/entity/plugin';

import { toImmutable } from 'optly/immutable';

import enums from './enums';
import fns from './fns';
import fieldTypesConfig from './config';

export const data = ['currentlyEditingPlugin'];

export const pluginConfig = ['currentlyEditingPlugin', 'config'];

export const formValues = ['currentlyEditingPlugin', 'formValues'];

export const pluginConfigFormSchema = [
  pluginConfig,
  function(config) {
    const formSchema = config.get('form_schema');
    if (!formSchema) {
      return toImmutable([]);
    }
    return formSchema.map(field =>
      toImmutable({
        // We are omitting 'default_value' here to avoid losing input focus after
        // the/ user types into the widget builder sidebar form. The inputs are
        // connected to the field default value. If the default was included in
        // this object, the sidebar component would rerender when the default
        // changed, causing focus to be lost.
        name: field.get('name'),
        label: field.get('label'),
        field_type: field.get('field_type'),
        options: field.get('options'),
      }),
    );
  },
];

export const hasUnsavedChanges = [
  pluginConfig,
  PluginModuleGetters.entityCache,
  function(currentPlugin, entityCache) {
    if (currentPlugin.get('optimizely_template')) {
      return false;
    }
    // Filter out fields not used by the extension editor from the entity cache
    const cachedPlugin = entityCache.get(currentPlugin.get('id'));

    // If the plugin isn't cached yet, that means it definitely hasn't saved.
    // This also provides a sanity check for potentially calling toJS on an
    // undefined object.
    if (!cachedPlugin) {
      return true;
    }
    return !currentPlugin.equals(cachedPlugin);
  },
];

export const pluginConfigEditPageUrl = [
  pluginConfig,
  function(pluginConfig) {
    return pluginConfig.get('edit_page_url');
  },
];

export const formSchemaDefaults = [
  pluginConfig,
  function(config) {
    const formSchema = config.get('form_schema');
    if (!formSchema) {
      return toImmutable({});
    }
    return fns.mapFormSchemaDefaults(formSchema);
  },
];
export const formSchemaOptions = [
  pluginConfig,
  function(config) {
    const formSchema = config.get('form_schema');
    if (!formSchema) {
      return toImmutable({});
    }
    return fns.mapFormSchemaOptions(formSchema);
  },
];
export const previewData = [
  pluginConfig,
  formSchemaDefaults,
  function(config, defaults) {
    return toImmutable({
      config,
      default_values: defaults,
    });
  },
];

/**
 * When one selector field is being edited, all other selectors should be disabled
 * This getter returns true if the field name of the currently editing selector
 * does not match the provided field name
 */
export const isSelectorDisabled = function(selectorFieldName) {
  return [
    ['currentlyEditingPlugin', 'currentlyEditingSelector'],
    function(currentlyEditingSelector) {
      if (!currentlyEditingSelector) {
        return false;
      }
      return currentlyEditingSelector !== selectorFieldName;
    },
  ];
};

/**
 * Get the type options to display in creating or editing a field
 * @returns {Array}
 */
export const fieldTypeOptions = [
  pluginConfig,
  function(config) {
    const options = [];
    _.forEach(fieldTypesConfig, (value, type) => {
      if (!_.includes(enums.omittedFields[config.get('plugin_type')], type)) {
        options.push({
          name: value.name,
          value: type,
        });
      }
    });
    return options;
  },
];

/**
 *
 * @param {String} inputConfigName - api name of plugin input field
 * @returns {Array} A getter that retrieves the String value of the input related to the inputConfigName
 */
export function formInputValue(inputConfigName) {
  return [
    formValues,
    pluginFormValues => pluginFormValues.get(inputConfigName),
  ];
}

export default {
  data,
  pluginConfig,
  formValues,
  pluginConfigFormSchema,
  hasUnsavedChanges,
  pluginConfigEditPageUrl,
  formSchemaDefaults,
  formSchemaOptions,
  previewData,
  isSelectorDisabled,
  fieldTypeOptions,
  formInputValue,
};
