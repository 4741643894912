import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { useState, useMemo } from 'react';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import { Button, ButtonIcon, Icon, Table } from 'optimizely-oui';

import locationHelper from 'optly/location';
import UrlUtil from 'optly/utils/url';

import ListDropdown from 'react_components/list_dropdown';
import ReactComponentsLink from 'react_components/link';

import SentryActions from 'optly/modules/sentry/actions';

import ChangeHistoryActions from '../component_module/actions';
import ChangeHistoryFns from '../component_module/fns';
import { EntityType, SegmentEvents } from '../component_module/constants';
import ChangesTableRowDetails from './changes_table_row_details';

// Custom top padding to properly align text with "Show / Hide details" button.
const TEXT_TABLE_DATA_TOP_PADDING = { paddingTop: 6 };

const ChangesTableRow = ({
  change,
  canUserSeeRevisionsWithoutChanges,
  handleShowHideAll,
  isAllDetailExpanded,
  prettyDiffLineCap,
  shouldPrettifyDiff,
  track,
}) => {
  // React hook to handle isDetailExpanded state
  const [isDetailExpanded, setIsDetailExpanded] = useState(isAllDetailExpanded);
  const onDetailToggle = altKeyUsed => {
    if (altKeyUsed) {
      handleShowHideAll(!isDetailExpanded);
      return;
    }
    setIsDetailExpanded(!isDetailExpanded);
    track(
      !isDetailExpanded
        ? SegmentEvents.SHOW_DETAILS_CLICKED
        : SegmentEvents.HIDE_DETAILS_CLICKED,
    );
  };

  const authorTitleText = ChangeHistoryFns.computeAuthorTitleText(change.user);
  const shouldShowChangeDetails =
    isDetailExpanded && !!(change && change.changes && change.changes.length);

  const showEllipsisButton = useMemo(
    () => !(canUserSeeRevisionsWithoutChanges && change.id < 0),
    [canUserSeeRevisionsWithoutChanges, change.id],
  );

  return (
    <React.Fragment>
      <Table.TR testSection={`changes-table-row-${change.id}`}>
        <Table.TD verticalAlign="top">
          <div style={TEXT_TABLE_DATA_TOP_PADDING}>
            <ChangeDescription
              name={change.entity.name}
              subType={change.entity.sub_type}
              summary={change.summary}
              type={change.entity.type}
              url={change.entity.ui_url}
            />
          </div>
        </Table.TD>
        <Table.TD verticalAlign="top">
          <div
            data-test-section="changes-table-change-name"
            style={TEXT_TABLE_DATA_TOP_PADDING}>
            <div className="flex flex-wrap" data-test-section="change-author">
              <div
                aria-label={authorTitleText}
                className="truncate"
                title={authorTitleText}>
                {change.user.display_name}
              </div>
            </div>
          </div>
        </Table.TD>
        <Table.TD verticalAlign="top">
          <div
            data-test-section="change-time"
            style={TEXT_TABLE_DATA_TOP_PADDING}>
            {ChangeHistoryFns.timeAgo(change.created)}
          </div>
        </Table.TD>
        <Table.TD verticalAlign="top">
          {change.changes && !!change.changes.length && (
            <DetailToggle
              isDetailExpanded={isDetailExpanded}
              changeId={change.id}
              onDetailToggle={onDetailToggle}
            />
          )}
        </Table.TD>
        <Table.TD verticalAlign="top">
          {/* TODO(UXENG-58) OUI exception of 3px padding-top for ellipses button alignment */}
          <div style={{ paddingTop: 3 }}>
            {showEllipsisButton && (
              <ListDropdown
                activator={
                  <ButtonIcon
                    iconFill="default"
                    iconName="ellipsis-solid"
                    size="small"
                    style="plain"
                    testSection={`change-options-${change.id}`}
                  />
                }
                isNoWrap={true}
                items={[
                  {
                    text: 'Copy Share Link',
                    testSection: `change-${change.id}-link`,
                    onClick: ChangeHistoryActions.getCopyShareLinkAndNotify(
                      locationHelper.getPath(),
                      change.id,
                    ),
                  },
                ]}
              />
            )}
          </div>
        </Table.TD>
      </Table.TR>
      {shouldShowChangeDetails && (
        <ChangesTableRowDetails
          change={change}
          prettyDiffLineCap={prettyDiffLineCap}
          shouldPrettifyDiff={shouldPrettifyDiff}
        />
      )}
    </React.Fragment>
  );
};

ChangesTableRow.propTypes = {
  canUserSeeRevisionsWithoutChanges: PropTypes.bool,
  change: PropTypes.shape({
    change_type: PropTypes.string.isRequired,
    changes: PropTypes.arrayOf(
      PropTypes.shape({
        after: PropTypes.any,
        before: PropTypes.any,
        description: PropTypes.string,
        property: PropTypes.string,
      }),
    ),
    created: PropTypes.string.isRequired,
    entity: PropTypes.shape({
      api_url: PropTypes.string,
      name: PropTypes.string.isRequired,
      sub_type: PropTypes.string,
      type: PropTypes.string.isRequired,
      ui_url: PropTypes.string,
    }).isRequired,
    id: PropTypes.number.isRequired,
    project_id: PropTypes.number.isRequired,
    revisions: PropTypes.shape({
      current: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
      previous: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
    }).isRequired,
    source: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    user: PropTypes.shape({
      display_name: PropTypes.string,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string,
      id: PropTypes.string.isRequired,
      last_name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  handleShowHideAll: PropTypes.func.isRequired,
  isAllDetailExpanded: PropTypes.bool.isRequired,
  prettyDiffLineCap: PropTypes.number.isRequired,
  shouldPrettifyDiff: PropTypes.bool.isRequired,
  track: PropTypes.func.isRequired,
};

ChangesTableRow.defaultProps = {
  canUserSeeRevisionsWithoutChanges: false,
};

/**
 * Component for managing the display of the change description
 */
const ChangeDescription = ({ name, subType, summary, type, url }) => {
  if (!EntityType[type]) {
    SentryActions.withScope(scope => {
      scope.setExtra('unknownEntityType', type);
      SentryActions.captureMessage(
        `Unknown entity_type "${type}" in change history changes`,
      );
    });
  }

  const readableTypeName = ChangeHistoryFns.computeReadableType({
    subType,
    type,
  });
  const splitSummary = summary.split(`'${name}'`);
  const [actionSummary] = splitSummary;
  const urlPath = url && UrlUtil.create(url).fullpath;

  return (
    <div className="flex">
      {EntityType[type] && EntityType[type].iconName && (
        // TODO(UXENG-58) OUI exception of 4px padding-top for icon / title alignment
        <span className="soft--right">
          <Icon name={EntityType[type].iconName} size="small" />
        </span>
      )}
      <span>
        <span className="weight--bold" data-test-section="change-entity-type">
          [{readableTypeName}]
        </span>
        <span className="push-half--left" data-test-section="change-summary">
          {(!urlPath && summary) || // By default, show the plain text summary if no url is available
            (splitSummary.length > 1 ||
            type === EntityType.metric_set.entityType ? ( // If the entity name was found in the description, append the action with the a link of the name
              <React.Fragment>
                {`${actionSummary} `}
                <ReactComponentsLink
                  ariaLabel={`${name} ${readableTypeName}`}
                  data-test-section="change-link-to-entity"
                  href={urlPath}>
                  {name}
                </ReactComponentsLink>
              </React.Fragment>
            ) : (
              // If the entity name was not found in the description, wrap the entire summary in the link
              <ReactComponentsLink
                ariaLabel={`${name} ${readableTypeName}`}
                data-test-section="change-link-to-entity"
                href={urlPath}>
                {summary}
              </ReactComponentsLink>
            ))}
        </span>
      </span>
    </div>
  );
};

ChangeDescription.propTypes = {
  name: PropTypes.string.isRequired,
  subType: PropTypes.string,
  summary: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string,
};

ChangeDescription.defaultProps = {
  subType: '',
  url: '',
};

/**
 * Component for rendering Show Details/Hide Details toggle
 */
const DetailToggle = ({ isDetailExpanded, changeId, onDetailToggle }) => {
  const hideOrShowText = isDetailExpanded ? 'Hide' : 'Show';
  return (
    <div
      className="text--right"
      title={`${hideOrShowText} change details.\r\nPress Alt key and click to ${hideOrShowText.toLowerCase()} all.`}>
      <Button
        ariaLabel="Expand change detail. Press alt key and enter to expand every change detail row."
        className={classNames('flex flex-align--center', {
          isOpen: isDetailExpanded,
        })}
        onClick={event => onDetailToggle(event.altKey)}
        style="plain"
        size="small"
        width="default"
        testSection={`change-show-details-${changeId}`}>
        {hideOrShowText} details
        <Icon className="push-half--left" name="chevron-down" size="small" />
      </Button>
    </div>
  );
};

DetailToggle.propTypes = {
  changeId: PropTypes.number.isRequired,
  isDetailExpanded: PropTypes.bool.isRequired,
  onDetailToggle: PropTypes.func.isRequired,
};

export default withTrack(ChangesTableRow);
