/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types';

import React from 'react';

import { feature } from '@optimizely/js-sdk-lab/src/decorators';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

import { Attention, Button, Col, Link, Spinner, Table } from 'optimizely-oui';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Immutable, { toImmutable } from 'optly/immutable';

import Router from 'core/router';
import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import ErrorBoundary from 'core/ui/components/error_boundary';

import HistoryUtils from 'optly/utils/history';
import handleAjaxError from 'optly/utils/handle_ajax_error';
import UrlHelper from 'optly/services/url_helper';
import { FEATURE_DETAILS } from 'optly/utils/constants';

// components
import FeaturesTopbar from 'bundles/p13n/sections/features/components/topbar';
import EmptyState from 'bundles/p13n/components/entity_dashboard/empty_state';
import { pageableEntityTable } from 'bundles/p13n/components/entity_dashboard/entity_table';
import { ShowAvailableArchivedItemsCta as ShowArchivedItemsCtaIfAvailableComponent } from 'bundles/p13n/components/available_items_cta';
import { TourGuide } from 'react_components/tour_guide';

import { SortableTableHeader } from 'react_components/sortable_table';
import { TableFilterControls } from 'bundles/p13n/components/entity_dashboard/table_filter_controls';

// modules
import { loadingWhenFetchAllPages } from 'core/modules/loading/actions';
import { isLoading, isPageLoading } from 'core/modules/loading/getters';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import {
  getters as CurrentProjectGetters,
  actions as CurrentProjectActions,
} from 'optly/modules/current_project';
import { getters as EnvironmentGetters } from 'optly/modules/entity/environment';
import {
  actions as FilterableTableActions,
  fns as FilterableTableFns,
  enums as FilterableTableEnums,
} from 'optly/modules/filterable_table';
import { project_types as projectTypes } from 'optly/modules/entity/project/enums';
import { getters as UserGetters } from 'optly/modules/entity/user';
import { actions as FeatureManagerActions } from 'bundles/p13n/modules/feature_manager';
import SectionModule from 'bundles/p13n/sections/features/section_module';

import JoyrideConstants from 'bundles/p13n/modules/joyride/constants';
import JoyrideFns from 'bundles/p13n/modules/joyride/fns';
import NavConstants from 'optly/services/navigation';
import Migration from 'optly/utils/migration';
import {
  fns as PermissionsFns,
  getters as PermissionsGetters,
} from 'optly/modules/permissions';

import {
  actions as FeatureFlagActions,
  fns as FeatureFlagFns,
} from 'optly/modules/entity/feature_flag';

import EmptyFeatureFlagsSVG from '/static/img/p13n/empty-feature-flags.svg';

import FeatureTableRow from './components/feature_table_row';
import FeatureDashboardIntroDialog from './components/features_intro_dialog';
import FeaturesEmptyState from './components/features_empty_state';
import FeaturesQuickstart from './components/features_quickstart';

const { CREATING_FIRST_FEATURE_LOADING_ID, TABLE_ID } = SectionModule.enums;
const EntityTable = pageableEntityTable(TABLE_ID);

/* TODO(Acacia): Implement logic to show this link to new rollouts users
or remove. https://optimizely.atlassian.net/browse/GROWTH-151 */
const QUICKSTART_DOCS_LINK =
  'https://docs.developers.optimizely.com/rollouts/docs/quickstart';

@withTrack
@connect({
  canUseFeatureTests: PermissionsGetters.canUseFeatureTests,
  canUseTargetedRollouts:
    PermissionsGetters.canCurrentProjectUseTargetedRollouts,
  currentProject: CurrentProjectGetters.project,
  currentProjectRunningExperiments:
    SectionModule.getters.currentProjectRunningExperiments,
  currentRole: AdminAccountGetters.currentRole,
  environments: EnvironmentGetters.unarchivedEnvironmentsSortedByPriority,
  currentUser: UserGetters.currentUser,
  features: CurrentProjectGetters.features,
  isEmptyState: [
    CurrentProjectGetters.features,
    features => features.size === 0,
  ],
  isFirstPageLoading: isPageLoading(TABLE_ID, 1),
  isFlagsProject: CurrentProjectGetters.isFlagsProject,
  isFullStackProject: CurrentProjectGetters.isFullStackProject,
  isWebProject: CurrentProjectGetters.isWebProject,
  isCreatingFirstFeature: isLoading(CREATING_FIRST_FEATURE_LOADING_ID),
  canManageFeatureFlags: [
    CurrentProjectGetters.project,
    PermissionsFns.canManageFeatureFlags,
  ],
  currentProjectType: CurrentProjectGetters.currentProjectType,
  planId: AdminAccountGetters.planId,
})
@feature('first_feature_flag_guide')
@feature('user_friendly_names')
@feature('feature_dashboard_intro_modal')
@feature('quickstart_docs_link_in_features_dash')
class FeaturesPageComponent extends React.Component {
  static propTypes = {
    canManageFeatureFlags: PropTypes.bool.isRequired,
    canUseFeatureTests: PropTypes.bool.isRequired,
    canUseTargetedRollouts: PropTypes.bool.isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProjectRunningExperiments: PropTypes.instanceOf(Immutable.List)
      .isRequired,
    currentProjectType: PropTypes.oneOf(Object.values(projectTypes)).isRequired,
    currentRole: PropTypes.string.isRequired,
    currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
    environments: PropTypes.instanceOf(Immutable.List).isRequired,
    featureId: PropTypes.number, // if set open the edit feature modal
    features: PropTypes.instanceOf(Immutable.List).isRequired,
    isCreatingFirstFeature: PropTypes.bool.isRequired,
    isEmptyState: PropTypes.bool.isRequired,
    isFirstPageLoading: PropTypes.bool.isRequired,
    isFlagsProject: PropTypes.bool.isRequired,
    isFullStackProject: PropTypes.bool.isRequired,
    isWebProject: PropTypes.bool.isRequired,
    planId: PropTypes.string.isRequired,
    track: PropTypes.func.isRequired,
  };

  static defaultProps = {
    featureId: NaN,
  };

  componentDidMount() {
    const { featureId, currentUser, currentProjectType } = this.props;
    const isRollouts = currentProjectType === projectTypes.ROLLOUTS;
    if (featureId) {
      this.editFeatureFromUrl(featureId);
    }
    if (this.feature_dashboard_intro_modal && isRollouts) {
      const isEmailVerified = currentUser.get('email_verified');
      if (!isEmailVerified) {
        this.renderIntroDialog();
      }
    }
  }

  editFeatureFromUrl(featureId) {
    const { currentProject } = this.props;
    FeatureFlagActions.fetch(featureId)
      .then(featureFlag => this.editFeature(toImmutable(featureFlag)))
      .fail(
        handleAjaxError(() => {
          HistoryUtils.replaceState(
            UrlHelper.featuresHome(currentProject.get('id')),
          );
        }),
      );
  }

  archiveFeature = featureFlag =>
    FeatureFlagActions.save({
      id: featureFlag.get('id'),
      archived: true,
    });

  createFeature = () => {
    const { canUseTargetedRollouts } = this.props;
    FeatureManagerActions.showFeatureDialog().then(savedFeature => {
      if (canUseTargetedRollouts) {
        this.editFeature(savedFeature);
      }
      const _savedFeature = savedFeature.toJS();
    });
  };

  editFeature = featureFlag => {
    const { canUseTargetedRollouts, currentProject } = this.props;
    const currentProjectId = currentProject.get('id');
    const featureId = featureFlag.get('id');
    const editFeatureUrl = UrlHelper.editFeature(currentProjectId, featureId);

    if (canUseTargetedRollouts) {
      Router.redirect(editFeatureUrl);
      return;
    }

    HistoryUtils.replaceState(editFeatureUrl);
    FeatureManagerActions.showFeatureDialog(featureFlag, () => {
      HistoryUtils.replaceState(UrlHelper.featuresHome(currentProjectId));
    });
  };

  filterItemByStringWithoutName = (item, search) =>
    FilterableTableFns.matchesFields(
      item,
      ['api_name', 'description', 'id'],
      search,
    );

  filterItemByString = (item, search) =>
    FilterableTableFns.matchesFields(
      item,
      ['api_name', 'description', 'name', 'id'],
      search,
    );

  filterItemByStatus = (item, status) =>
    FilterableTableFns.matchesArchivedStatus(item, status);

  getActiveExperimentsWithFeature = featureFlag => {
    const { currentProjectRunningExperiments } = this.props;
    return currentProjectRunningExperiments
      .filter(
        experiment =>
          experiment.get('feature_flag_id') === featureFlag.get('id'),
      )
      .map(experiment => ({
        id: experiment.get('id'),
        key: experiment.get('key'),
        name: experiment.get('name'),
        projectId: experiment.get('project_id'),
      }));
  };

  unarchiveFeature = featureFlag =>
    SectionModule.actions.unarchiveFeature(featureFlag);

  fetchDataForStatus = status => {
    const { currentProject } = this.props;
    loadingWhenFetchAllPages(
      SectionModule.enums.TABLE_ID,
      FeatureFlagActions.fetchAllByArchivedState(
        currentProject.get('id'),
        status === FilterableTableEnums.status.ARCHIVED,
      ),
    );
  };

  handleOnCreateNewFeature = apiName => {
    const { currentProject, environments } = this.props;
    const featureToCreate = FeatureFlagFns.createNewFeatureWithEnvironmentConfigs(
      environments,
      currentProject.get('id'),
    ).merge({
      api_name: apiName,
      name: apiName,
    });
    const leastPriorityEnvironment = environments.last();
    const toggleFlag = FeatureFlagActions.getToggleFlagForEnvironment(
      leastPriorityEnvironment,
    );
    const isFlagOn = FeatureFlagFns.getIsFlagOnForEnvironment(
      leastPriorityEnvironment,
    );

    ui.loadingWhen(
      CREATING_FIRST_FEATURE_LOADING_ID,
      FeatureFlagActions.save(featureToCreate).then(createdFeature => {
        const firstFeatureFlag = toImmutable(createdFeature);
        ui.showReactDialog(
          FeaturesQuickstart,
          {
            props: {
              flagKey: firstFeatureFlag.get('api_name'),
              sdkKey: leastPriorityEnvironment.getIn(['datafile', 'sdk_key']),
              featureFlag: firstFeatureFlag,
              toggleFlag,
              isFlagOn,
            },
          },
          {
            isOuiDialog: true,
            fullScreen: true,
          },
        );
      }),
    );
  };

  renderEmptyState = ({ isFirstPageLoading }) => {
    const {
      canManageFeatureFlags,
      currentProject,
      isCreatingFirstFeature,
    } = this.props;

    if (isFirstPageLoading) {
      return <Spinner hasOverlay={true} />;
    }

    const archivedItemsCtaIfAvailableComponent = (
      <ShowArchivedItemsCtaIfAvailableComponent
        fetchAnyProjectEntityAndReturnTrueIfPresent={() =>
          new Promise(async resolve => {
            const projectFeature = await FeatureFlagActions.fetchPage({
              project_id: currentProject.get('id'),
              $limit: 1,
              $idsOnly: true,
            });
            resolve(!!(projectFeature && projectFeature.length));
          })
        }
        handleFetchArchivedItems={() => {
          this.fetchDataForStatus(FilterableTableEnums.status.ARCHIVED);
          FilterableTableActions.setFilter(TABLE_ID, {
            status: FilterableTableEnums.status.ARCHIVED,
          });
        }}
      />
    );

    if (this.first_feature_flag_guide) {
      return (
        <FeaturesEmptyState
          archivedItemsCtaIfAvailableComponent={
            archivedItemsCtaIfAvailableComponent
          }
          canManageFeatureFlags={canManageFeatureFlags}
          isLoading={isCreatingFirstFeature}
          onCreateNewFeature={this.handleOnCreateNewFeature}
        />
      );
    }

    const featuresLink = CurrentProjectActions.getHelpCopy(
      'features_link',
      'https://docs.developers.optimizely.com/full-stack/docs/use-feature-flags',
    );
    return (
      <EmptyState
        createButton={{
          onClick: this.createFeature,
          isDisabled: !canManageFeatureFlags,
          label: <span>Create New Feature&hellip;</span>,
          testSection: 'create-feature-button',
        }}
        headline={tr('Create and manage your feature flags.')}
        description={
          <Col small="fillSpace" isReadingColumn={true}>
            <p>
              A feature flag enables you to control the availability of a
              feature without redeploying code. Turn a feature on and off in
              production, slowly roll it out, or make it available to specific
              users.{' '}
            </p>
            {this.quickstart_docs_link_in_features_dash ? (
              <Link
                href={QUICKSTART_DOCS_LINK}
                newWindow={true}
                testSection="quickstart-docs-link">
                <Button isLink={true} style="outline" size="small">
                  Try Quickstart Guide
                </Button>
              </Link>
            ) : (
              <Link
                href={featuresLink}
                newWindow={true}
                testSection="learn-more-link">
                Learn more.
              </Link>
            )}
            {archivedItemsCtaIfAvailableComponent}
          </Col>
        }
        imagePath={EmptyFeatureFlagsSVG}
        shouldHideCreateButton={isFeatureEnabled(
          'disable_creating_full_stack_entities',
        )}
      />
    );
  };

  renderNonEmptyState = () => {
    const { canManageFeatureFlags, features, isFirstPageLoading } = this.props;
    const featuresLink = CurrentProjectActions.getHelpCopy(
      'features_link',
      'https://docs.developers.optimizely.com/full-stack/docs/use-feature-flags',
    );
    return (
      <div className="flex flex--1 flex--column">
        <p className="push-double--top push-quad--sides flush--bottom">
          A feature flag enables you to control the availability of a feature
          without redeploying code. Turn a feature on and off in production,
          slowly roll it out, or make it available to specific users.&nbsp;
          <Link href={featuresLink} newWindow={true}>
            Learn more.
          </Link>
        </p>
        <div className="push-quad--bottom">
          <Attention
            alignment="center"
            type="brand"
            testSection="sdk-compatibility-warning">
            {`${FEATURE_DETAILS.sdkWarningText} `}
            <Link newWindow={true} href={FEATURE_DETAILS.sdkWarningKbLink}>
              Learn more
            </Link>
          </Attention>
        </div>
        <div className="flex flex--none push-quad--sides push-double--ends">
          <TableFilterControls
            // NOTE: "key" is the UI name for the "api_name" key. ID search is allowed but not promoted
            inputPlaceholder={
              this.user_friendly_names
                ? 'Filter by name, key, or description'
                : 'Filter by key or description'
            }
            inputWidth="width--300"
            tableId={TABLE_ID}
            statusOptions={[
              { label: 'Active', value: FilterableTableEnums.status.ACTIVE },
              {
                label: 'Archived',
                value: FilterableTableEnums.status.ARCHIVED,
              },
            ]}
            onStatusChange={this.fetchDataForStatus}
          />
          {!isFeatureEnabled('disable_creating_full_stack_entities') && (
            <Button
              style="highlight"
              onClick={this.createFeature}
              isDisabled={!canManageFeatureFlags}
              testSection="create-feature-button">
              Create New Feature&hellip;
            </Button>
          )}
        </div>
        <EntityTable
          tableId={SectionModule.enums.TABLE_ID}
          data={features}
          entityPlural="features"
          filterItemByString={
            this.user_friendly_names
              ? this.filterItemByString
              : this.filterItemByStringWithoutName
          }
          filterItemByStatus={this.filterItemByStatus}
          renderTableRow={this.renderTableRow}
          renderTableHeader={this.renderTableHeader}
          defaultSortBy={{ field: 'api_name', type: 'string' }}
          defaultFilters={{ status: FilterableTableEnums.status.ACTIVE }}
          isLoading={isFirstPageLoading}
        />
      </div>
    );
  };

  renderTableHeader = () => {
    const { canUseFeatureTests, currentProject } = this.props;
    const showJiraIssues =
      currentProject.getIn(['jira_integration', 'enabled']) &&
      !!currentProject.getIn(['jira_integration', 'resource_id']);
    return (
      <Table.TR>
        <SortableTableHeader
          field={this.user_friendly_names ? 'name' : 'api_name'}
          type="string">
          Feature
        </SortableTableHeader>
        {showJiraIssues && <Table.TH width="300px">Jira Issues</Table.TH>}
        {canUseFeatureTests && <Table.TH>Active Experiments</Table.TH>}
        <Table.TH>Environments</Table.TH>
        <SortableTableHeader field="created" type="date">
          Created
        </SortableTableHeader>
        <SortableTableHeader field="last_modified" type="date">
          Modified
        </SortableTableHeader>
        <Table.TH />
      </Table.TR>
    );
  };

  renderTableRow = (featureFlag, index) => {
    const {
      canManageFeatureFlags,
      currentProject,
      currentProjectRunningExperiments,
    } = this.props;
    return (
      <FeatureTableRow
        activeExperiments={this.getActiveExperimentsWithFeature(featureFlag)}
        archive={this.archiveFeature}
        canManageFeatures={canManageFeatureFlags}
        currentProjectRunningExperiments={currentProjectRunningExperiments}
        editFeature={this.editFeature}
        feature={featureFlag}
        key={featureFlag.get('id')}
        rowIndex={index}
        unarchive={this.unarchiveFeature}
        jiraIntegrationSettings={currentProject.get('jira_integration')}
      />
    );
  };

  renderTopBar = () => (
    <FeaturesTopbar activeTab={NavConstants.FeaturesTabs.FEATURES_DASHBOARD} />
  );

  renderIntroDialog = () => {
    ui.showReactDialog(FeatureDashboardIntroDialog, null, {
      fullScreen: true,
      dismissOnBack: true,
      isOuiDialog: true,
    });
  };

  render() {
    const {
      currentProject,
      isEmptyState,
      isFirstPageLoading,
      planId,
    } = this.props;
    const isFreeProject = Migration.freeRolloutPlanIds.includes(planId);

    return (
      <>
        <div
          className="flex flex--1 flex--column"
          data-test-section="oasis-features-dashboard">
          {Migration.isFlagsMigrationEnabled(this.props) && (
            <div className="push-double--top push-quad--sides">
              <Attention
                alignment="center"
                type="warning"
                testSection="flags-migration-attention">
                <span
                  dangerouslySetInnerHTML={{
                    __html: isFreeProject
                      ? `${Migration.DEFAULT_ROLLOUT_ATTENTION_TEXT_PART_1}`
                      : `${Migration.attentionText} `,
                  }}
                />
                <Link href={Migration.attentionLink(currentProject.get('id'))}>
                  here
                </Link>
                {isFreeProject &&
                  ` ${Migration.DEFAULT_ROLLOUT_ATTENTION_TEXT_PART_2}`}
              </Attention>
            </div>
          )}
          {this.renderTopBar()}
          {isEmptyState && this.renderEmptyState({ isFirstPageLoading })}
          {!isEmptyState && this.renderNonEmptyState()}
        </div>
        <ErrorBoundary alternateContent=" ">
          <OptimizelyFeature feature="targeted_rollouts_tour_guide">
            {(isEnabled, variables) =>
              isEnabled && (
                <TourGuide
                  config={JoyrideFns.targetedRollouts.getFeaturesListPageTourGuide(
                    variables,
                  )}
                  guideId={
                    JoyrideConstants.JOYRIDE_IDS.TARGETED_ROLLOUTS_LIST_PAGE
                  }
                  maxSkips={variables.max_skips_list_page}
                />
              )
            }
          </OptimizelyFeature>
        </ErrorBoundary>
      </>
    );
  }
}

FeaturesPageComponent.componentId = 'features-dashboard';

export default FeaturesPageComponent;
