import keyMirror from 'optly/utils/key_mirror';

export default keyMirror({
  SAVE_LINKED_HYPOTHESIS: null,
  SAVE_HYPOTHESIS_TO_LINK: null,
  SHOULD_CREATE_HYPOTHESIS: false,
  SET_HPT_NAME: null,
  SET_HPT_PLANNED_START_DATE: null,
  SET_HPT_PLANNED_END_DATE: null,
  SET_HPT_OPEN_IN_NEW_TAB_AFTER_CREATION: false,
});
