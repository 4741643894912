import flux from 'core/flux';

import CampaignManagerStores from './campaign_manager/stores';
import CurrencyLegacyStores from './concurrency_legacy/stores';
import CurrencyStores from './concurrency/stores';
import CurrentDatasourceStores from './current_datasource/stores';
import CurrentEventStores from './current_event/stores';
import CurrentJobStatusStores from './current_job_status/stores';
import CurrentLayerStores from './current_layer/stores';
import CurrentTagStores from './current_tag/stores';
import CurrentlyEditingExperimentStores from './currently_editing_experiment/stores';
import CustomCodeStores from './custom_code/stores';
import EditorStores from './editor/stores';
import EditorIframeStores from './editor_iframe/stores';
import EditorIframeMocksStores from './editor_iframe_mocks/stores';
import HoldbackSelectorStores from './holdback_selector/stores';
import HypothesisLinkingStores from './hypothesis_linking/stores';
import LayerSettingsStores from './layer_settings/stores';
import MetricsManagerStores from './metrics_manager/stores';
import PublishStatusStores from './publish_status/stores';
import ResultsApiStores from './results_api/stores';
import SegmentDisplayStores from './segment_display/stores';
import TrackClicksChangeStores from './track_clicks_change/stores';

export const stores = {
  ...CampaignManagerStores,
  ...CurrencyLegacyStores,
  ...CurrencyStores,
  ...CurrentDatasourceStores,
  ...CurrentEventStores,
  ...CurrentJobStatusStores,
  ...CurrentLayerStores,
  ...CurrentTagStores,
  ...CurrentlyEditingExperimentStores,
  ...CustomCodeStores,
  ...EditorStores,
  ...EditorIframeStores,
  ...EditorIframeMocksStores,
  ...HoldbackSelectorStores,
  ...HypothesisLinkingStores,
  ...LayerSettingsStores,
  ...MetricsManagerStores,
  ...PublishStatusStores,
  ...ResultsApiStores,
  ...SegmentDisplayStores,
  ...TrackClicksChangeStores,
};

export default function initialize() {
  flux.registerStores(stores);
}
