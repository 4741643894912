const htmlSanitizer = require('sanitizer');

const InterestGroupConstants = require('optly/modules/entity/interest_group/constants');
const LayerEnums = require('optly/modules/entity/layer/enums');
const tr = require('optly/translate');

exports.getText = (
  campaignType,
  rawLayerName,
  isWinnerRolloutFeaureEnabled,
) => {
  const layerName = htmlSanitizer.escape(rawLayerName);
  const TEXT = {};
  TEXT[LayerEnums.campaignTypes.P13N_CAMPAIGN] = {
    name: tr('Campaign'),
    adaptive: {
      title: InterestGroupConstants.AdaptiveAudienceHelpTexts.TITLE,
      message: tr(
        `The campaign <b>{0}</b> has an experience that {1}<a href=${InterestGroupConstants.AdaptiveAudienceReferenceLinks.CONDITIONS} rel='noopener noreferrer' target='_blank'>{2}</a>`,
        layerName,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.PROCESSING,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.CONDITIONS,
      ),
    },
    archive: {
      title: tr('Archive Campaign'),
      message: tr(
        'Are you sure you want to archive the campaign <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Archive Campaign'),
      confirmationText: tr(
        'The campaign <b>{0}</b> has been archived.',
        layerName,
      ),
    },
    archiveAndPause: {
      title: tr('Archive Campaign'),
      message: tr(
        'The campaign <b>{0}</b> is currently running. Archiving a running campaign will pause it. Are you sure you want to archive it?',
        layerName,
      ),
      confirmText: tr('Archive Campaign'),
      confirmationText: tr(
        'The campaign <b>{0}</b> has been paused and archived.',
        layerName,
      ),
    },
    firstPublish: {
      title: tr('Start Campaign'),
      message: tr(
        'Are you sure you want to start the campaign <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Campaign'),
    },
    pause: {
      title: tr('Pause Campaign'),
      message: tr(
        'Are you sure you want to pause the campaign <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Pause Campaign'),
      confirmationText: tr(
        'The campaign <b>{0}</b> has been paused.',
        layerName,
      ),
    },
    publish: {
      title: tr('Publish Campaign'),
      message: tr(
        'Are you sure you want to publish the campaign <b>{0}</b>? Your draft changes will go live to the world.',
        layerName,
      ),
      inProgressMessage: tr('Publishing Campaign'),
      confirmText: tr('Publish Campaign'),
      confirmationText: tr(
        'Your changes to <b>{0}</b> are published and are now live!',
        layerName,
      ),
    },
    publishAndStart: {
      title: tr('You are publishing a paused campaign'),
      message: tr(
        'The campaign <b>{0}</b> is paused, and published changes will not affect visitors until this campaign is started. Would you like to publish and start this campaign?',
        layerName,
      ),
    },
    publishLayerOnly: {
      title: tr('Publish Campaign Settings'),
      message: tr(
        'Are you sure you want to publish the campaign settings for <b>{0}</b>? The new settings will be live, but changes made to experiences and variations will not.',
        layerName,
      ),
      confirmText: tr('Publish Campaign Settings'),
      confirmationText: tr(
        'Your changes for <b>{0}</b> are now being published.',
        layerName,
      ),
      fullPublishOptionText: tr('Publish campaign…'),
      layerOnlyPublishOptionText: tr('Publish campaign settings only…'),
    },
    start: {
      title: tr('Start Campaign'),
      message: tr(
        'Are you sure you want to start the campaign <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Campaign'),
      confirmationText: tr(
        'The campaign <b>{0}</b> has been started.',
        layerName,
      ),
    },
    unarchive: {
      message: tr('The campaign <b>{0}</b> has been unarchived.', layerName),
    },
    conclude: {
      message: tr('The campaign <b>{0}</b> has been concluded.', layerName),
    },
    deploy: {
      message: tr(
        'The campaign <b>{0}</b> has been concluded and deployed.',
        layerName,
      ),
    },
  };

  // A P13N campaign experience can have either of these `layer_policy` values: ORDERED or EQUAL_PRIORITY
  TEXT[LayerEnums.policy.ORDERED] = {
    name: tr('Experience'),
    adaptive: {
      title: InterestGroupConstants.AdaptiveAudienceHelpTexts.TITLE,
      message: tr(
        `The experience <b>{0}</b> {1}<a href=${InterestGroupConstants.AdaptiveAudienceReferenceLinks.CONDITIONS} rel='noopener noreferrer' target='_blank'>{2}</a>`,
        layerName,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.PROCESSING,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.CONDITIONS,
      ),
    },
    archive: {
      title: tr('Archive {0}', layerName),
      message: tr(
        'Are you sure you would like to archive the experience <b>{0}</b> in the current campaign?',
        layerName,
      ),
      confirmText: tr('Archive'),
      confirmationText: tr(
        'The experience <b>{0}</b> in the current campaign has been archived.',
        layerName,
      ),
    },
    pause: {
      title: tr('Pause {0}', layerName),
      message: tr(
        'Are you sure you would like to pause the experience <b>{0}</b> in the current campaign? This change will take effect immediately.',
        layerName,
      ),
      confirmText: tr('Pause'),
      confirmationText: tr(
        'The experience <b>{0}</b> in the current campaign has been paused.',
        layerName,
      ),
    },
    publish: {
      title: tr('Are you sure you want to publish this experience?'),
      message: tr(
        'Your draft changes to <b>{0}</b> will go live to the world.',
        layerName,
      ),
      confirmText: tr('Publish'),
      confirmationText: tr(
        'The experience <b>{0}</b> in the current campaign has been published.',
        layerName,
      ),
    },
    unarchive: {
      title: tr('Unarchive {0}', layerName),
      message: tr(
        'Are you sure you would like to unarchive the experience <b>{0}</b> in the current campaign?',
        layerName,
      ),
      confirmText: tr('Unarchive'),
      confirmationText: tr(
        'The experience <b>{0}</b> in the current campaign has been unarchived.',
        layerName,
      ),
    },
    unpause: {
      title: tr('Unpause {0}', layerName),
      message: tr(
        'Are you sure you would like to unpause the experience <b>{0}</b> in the current campaign? This change will take effect immediately.',
        layerName,
      ),
      confirmText: tr('Unpause'),
      confirmationText: tr(
        'The experience <b>{0}</b> in the current campaign has been unpaused.',
        layerName,
      ),
    },
  };

  // A P13N campaign experience can have either of these `layer_policy` values: ORDERED or EQUAL_PRIORITY
  TEXT[LayerEnums.policy.EQUAL_PRIORITY] = TEXT[LayerEnums.policy.ORDERED];

  TEXT[LayerEnums.campaignTypes.SINGLE_EXPERIMENT] = {
    name: tr('A/B Test'),
    adaptive: {
      title: InterestGroupConstants.AdaptiveAudienceHelpTexts.TITLE,
      message: tr(
        `The experiment <b>{0}</b> has an experience that {1}<a href=${InterestGroupConstants.AdaptiveAudienceReferenceLinks.CONDITIONS} rel='noopener noreferrer' target='_blank'>{2}</a>`,
        layerName,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.PROCESSING,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.CONDITIONS,
      ),
    },
    archive: {
      title: tr('Archive Experiment'),
      message: tr(
        'Are you sure you want to archive the experiment <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Archive Experiment'),
      confirmationText: tr(
        'The experiment <b>{0}</b> has been archived.',
        layerName,
      ),
    },
    archiveAndPause: {
      title: tr('Archive Experiment'),
      message: tr(
        'The experiment <b>{0}</b> is currently running. Archiving a running experiment will pause it. Are you sure you want to archive it?',
        layerName,
      ),
      confirmText: tr('Archive Experiment'),
      confirmationText: tr(
        'The experiment <b>{0}</b> has been paused and archived.',
        layerName,
      ),
    },
    firstPublish: {
      title: tr('Start Experiment'),
      message: tr(
        'Are you sure you want to start the experiment <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Experiment'),
    },
    pause: {
      title: tr('Pause Experiment'),
      message: tr(
        'Are you sure you want to pause the experiment <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Pause Experiment'),
      confirmationText: tr(
        'The experiment <b>{0}</b> has been paused.',
        layerName,
      ),
    },
    publish: {
      title: tr('Publish Experiment'),
      message: tr(
        'Are you sure you want to publish the experiment <b>{0}</b>? Your draft changes will go live to the world.',
        layerName,
      ),
      inProgressMessage: tr('Publishing Experiment'),
      confirmText: tr('Publish Experiment'),
      confirmationText: tr(
        'Your changes to <b>{0}</b> are published and are now live!',
        layerName,
      ),
    },
    publishAndStart: {
      title: tr('You are publishing a paused experiment'),
      message: tr(
        'The experiment <b>{0}</b> is paused, and published changes will not affect visitors until this experiment is started. Would you like to publish and start this experiment?',
        layerName,
      ),
    },
    start: {
      title: tr('Start Experiment'),
      message: tr(
        'Are you sure you want to start the experiment <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Experiment'),
      confirmationText: tr(
        'The experiment <b>{0}</b> has been started.',
        layerName,
      ),
    },
    unarchive: {
      message: tr('The experiment <b>{0}</b> has been unarchived.', layerName),
    },
    conclude: {
      message: tr('The experiment <b>{0}</b> has been concluded.', layerName),
    },
    deploy: {
      message: tr(
        'The experiment <b>{0}</b> has been concluded and deployed.',
        layerName,
      ),
    },
  };

  TEXT[LayerEnums.campaignTypes.MULTIVARIATE] = {
    name: tr('Multivariate Test'),
    adaptive: {
      title: InterestGroupConstants.AdaptiveAudienceHelpTexts.TITLE,
      message: tr(
        `The experiment <b>{0}</b> has an experience that {1}<a href=${InterestGroupConstants.AdaptiveAudienceReferenceLinks.CONDITIONS} rel='noopener noreferrer' target='_blank'>{2}</a>`,
        layerName,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.PROCESSING,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.CONDITIONS,
      ),
    },
    archive: {
      title: tr('Archive Multivariate Test'),
      message: tr(
        'Are you sure you want to archive the multivariate test <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Archive Multivariate Test'),
      confirmationText: tr(
        'The multivariate test <b>{0}</b> has been archived.',
        layerName,
      ),
    },
    archiveAndPause: {
      title: tr('Archive Multivariate Test'),
      message: tr(
        'The multivariate test <b>{0}</b> is currently running. Archiving a running multivariate test will pause it. Are you sure you want to archive it?',
        layerName,
      ),
      confirmText: tr('Archive Multivariate Test'),
      confirmationText: tr(
        'The multivariate test <b>{0}</b> has been paused and archived.',
        layerName,
      ),
    },
    firstPublish: {
      title: tr('Start Multivariate Test'),
      message: tr(
        'Are you sure you want to start the multivariate test <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Multivariate Test'),
    },
    pause: {
      title: tr('Pause Multivariate Test'),
      message: tr(
        'Are you sure you want to pause the multivariate test <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Pause Multivariate Test'),
      confirmationText: tr(
        'The multivariate test <b>{0}</b> has been paused.',
        layerName,
      ),
    },
    publish: {
      title: tr('Publish Multivariate Test'),
      message: tr(
        'Are you sure you want to publish the multivariate test <b>{0}</b>? Your draft changes will go live to the world.',
        layerName,
      ),
      inProgressMessage: tr('Publishing Multivariate Test'),
      confirmText: tr('Publish Multivariate Test'),
      confirmationText: tr(
        'Your changes to <b>{0}</b> are published and are now live!',
        layerName,
      ),
    },
    publishAndStart: {
      title: tr('You are publishing a paused multivariate test'),
      message: tr(
        'The multivariate test <b>{0}</b> is paused, and published changes will not affect visitors until this multivariate test is started. Would you like to publish and start this multivariate test?',
        layerName,
      ),
    },
    start: {
      title: tr('Start Multivariate Test'),
      message: tr(
        'Are you sure you want to start the multivariate test <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Multivariate Test'),
      confirmationText: tr(
        'The multivariate test <b>{0}</b> has been started.',
        layerName,
      ),
    },
    unarchive: {
      message: tr(
        'The multivariate test <b>{0}</b> has been unarchived.',
        layerName,
      ),
    },
    conclude: {
      message: tr(
        'The multivariate test <b>{0}</b> has been concluded.',
        layerName,
      ),
    },
    deploy: {
      message: tr(
        'The multivariate test <b>{0}</b> has been concluded and deployed.',
        layerName,
      ),
    },
  };

  TEXT[LayerEnums.campaignTypes.MULTIARMED_BANDIT] = {
    name: tr('Multi-Armed Bandit'),
    adaptive: {
      title: InterestGroupConstants.AdaptiveAudienceHelpTexts.TITLE,
      message: tr(
        `The multi-armed bandit <b>{0}</b> has an experience that {1}<a href=${InterestGroupConstants.AdaptiveAudienceReferenceLinks.CONDITIONS} rel='noopener noreferrer' target='_blank'>{2}</a>`,
        layerName,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.PROCESSING,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.CONDITIONS,
      ),
    },
    archive: {
      title: tr('Archive Multi-Armed Bandit'),
      message: tr(
        'Are you sure you want to archive the multi-armed bandit <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Archive Multi-Armed Bandit'),
      confirmationText: tr(
        'The multi-armed bandit <b>{0}</b> has been archived.',
        layerName,
      ),
    },
    archiveAndPause: {
      title: tr('Archive Multi-Armed Bandit'),
      message: tr(
        'The multi-armed bandit <b>{0}</b> is currently running. Archiving a running multi-armed bandit will pause it. Are you sure you want to archive it?',
        layerName,
      ),
      confirmText: tr('Archive Multi-Armed Bandit'),
      confirmationText: tr(
        'The multi-armed bandit <b>{0}</b> has been paused and archived.',
        layerName,
      ),
    },
    firstPublish: {
      title: isWinnerRolloutFeaureEnabled
        ? tr('Start Bandit')
        : tr('Start Multi-Armed Bandit'),
      message: tr(
        'Are you sure you want to start the multi-armed bandit <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Multi-Armed Bandit'),
    },
    pause: {
      title: tr('Pause Multi-Armed Bandit'),
      message: tr(
        'Are you sure you want to pause the multi-armed bandit <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Pause Multi-Armed Bandit'),
      confirmationText: tr(
        'The multi-armed bandit <b>{0}</b> has been paused.',
        layerName,
      ),
    },
    publish: {
      title: isWinnerRolloutFeaureEnabled
        ? tr('Publish Bandit')
        : tr('Publish Multi-Armed Bandit'),
      message: tr(
        'Are you sure you want to publish the multi-armed bandit <b>{0}</b>? Your draft changes will go live to the world.',
        layerName,
      ),
      inProgressMessage: tr('Publishing Multi-Armed Bandit'),
      confirmText: tr('Publish Multi-Armed Bandit'),
      confirmationText: tr(
        'Your changes to <b>{0}</b> are published and are now live!',
        layerName,
      ),
    },
    publishAndStart: {
      title: tr('You are publishing a paused multi-armed bandit'),
      message: tr(
        'The multi-armed bandit <b>{0}</b> is paused, and published changes will not affect visitors until this multi-armed bandit is started. Would you like to publish and start this multi-armed bandit?',
        layerName,
      ),
    },
    start: {
      title: tr('Start Multi-Armed Bandit'),
      message: tr(
        'Are you sure you want to start the multi-armed bandit <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Multi-Armed Bandit'),
      confirmationText: tr(
        'The multi-armed bandit <b>{0}</b> has been started.',
        layerName,
      ),
    },
    unarchive: {
      message: tr(
        'The multi-armed bandit <b>{0}</b> has been unarchived.',
        layerName,
      ),
    },
    conclude: {
      message: tr(
        'The multi-armed bandit <b>{0}</b> has been concluded.',
        layerName,
      ),
    },
    deploy: {
      message: tr(
        'The multi-armed bandit <b>{0}</b> has been concluded and deployed.',
        layerName,
      ),
    },
  };

  TEXT[LayerEnums.campaignTypes.FEATURE] = {
    name: tr('Feature Test'),
    adaptive: {
      title: InterestGroupConstants.AdaptiveAudienceHelpTexts.TITLE,
      message: tr(
        `The feature test <b>{0}</b> has an experience that {1}<a href=${InterestGroupConstants.AdaptiveAudienceReferenceLinks.CONDITIONS} rel='noopener noreferrer' target='_blank'>{2}</a>`,
        layerName,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.PROCESSING,
        InterestGroupConstants.AdaptiveAudienceHelpTexts.CONDITIONS,
      ),
    },
    archive: {
      title: tr('Archive Feature Test'),
      message: tr(
        'Are you sure you want to archive the feature test <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Archive Feature Test'),
      confirmationText: tr(
        'The feature test <b>{0}</b> has been archived.',
        layerName,
      ),
    },
    archiveAndPause: {
      title: tr('Archive Feature Test'),
      message: tr(
        'The feature test <b>{0}</b> is currently running. Archiving a running feature test will pause it. Are you sure you want to archive it?',
        layerName,
      ),
      confirmText: tr('Archive Feature Test'),
      confirmationText: tr(
        'The feature test <b>{0}</b> has been paused and archived.',
        layerName,
      ),
    },
    firstPublish: {
      title: tr('Start Feature Test'),
      message: tr(
        'Are you sure you want to start the feature test <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Feature Test'),
    },
    pause: {
      title: tr('Pause Feature Test'),
      message: tr(
        'Are you sure you want to pause the feature test <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Pause Feature Test'),
      confirmationText: tr(
        'The feature test <b>{0}</b> has been paused.',
        layerName,
      ),
    },
    publish: {
      title: tr('Publish Feature Test'),
      message: tr(
        'Are you sure you want to publish the feature test <b>{0}</b>? Your draft changes will go live to the world.',
        layerName,
      ),
      inProgressMessage: tr('Publishing Feature Test'),
      confirmText: tr('Publish Feature Test'),
      confirmationText: tr(
        'Your changes to <b>{0}</b> are published and are now live!',
        layerName,
      ),
    },
    publishAndStart: {
      title: tr('You are publishing a paused multi-armed bandit'),
      message: tr(
        'The feature test <b>{0}</b> is paused, and published changes will not affect visitors until this feature test is started. Would you like to publish and start this feature test?',
        layerName,
      ),
    },
    start: {
      title: tr('Start Feature Test'),
      message: tr(
        'Are you sure you want to start the feature test <b>{0}</b>?',
        layerName,
      ),
      confirmText: tr('Start Feature Test'),
      confirmationText: tr(
        'The feature test <b>{0}</b> has been started.',
        layerName,
      ),
    },
    unarchive: {
      message: tr(
        'The feature test <b>{0}</b> has been unarchived.',
        layerName,
      ),
    },
  };

  return TEXT[campaignType];
};
