/* eslint-disable import/prefer-default-export */
export const METRIC_TYPES = {
  CUSTOM: 'Custom Event Metric',
  CLICK: 'Click Event Metric',
  PAGEVIEW: 'Page Event Metric',
  GLOBAL: 'Overall Revenue',
};

/**
 * TODO: Once the compound_metrics feature flag is fully rolled out
 * move the COMPOUND_METRIC_TYPE constant inside of METRIC_TYPES
 */
export const COMPOUND_METRIC_TYPE = 'Ratio Metric';

export const GLOBAL_EVENTS = [
  {
    description: '',
    id: 'revenue',
    item_type: 'global_metric',
    name: 'Overall Revenue',
    type: null,
  },
];

export const BOOLEAN_OPERATORS = {
  is: 'equal_to',
};

export const STRING_OPERATORS = {
  is: 'equal_to',
  'is not': 'not_equal_to',
  contains: 'contains',
  'does not contain': 'does_not_contain',
};

export const NUMERIC_OPERATORS = {
  '=': 'equal_to',
  '<': 'less_than',
  '≤': 'less_than_or_equal_to',
  '>': 'greater_than',
  '≥': 'greater_than_or_equal_to',
};

export const BOOLEAN_FIELD_OPTIONS = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
];

export const COMBINE_OPTIONS = [
  { label: 'all', value: 'and' },
  { label: 'any', value: 'or' },
];

export const METRIC_TYPE_TOOLTIP_CONTENT =
  'Metric Type can not be edited after metric has been created';
export const EVENT_TOOLTIP_CONTENT =
  'Event can not be edited after metric has been created';
