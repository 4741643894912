import React from 'react';
import { Button, Dropdown, BlockList } from '@optimizely/axiom';

import { DWN_BASE_URL } from 'bundles/p13n/sections/results/constants';
import {
  isWarehouseNativeEnabled,
  getDirectNetspringUrl,
} from 'optly/utils/features';

// This file parallels the equivalent dropdown in FX here:
// https://github.com/optimizely/flags/blob/43e762a80cd01b7130318444b5f801f1cfcabb14/frontend/src/app/flag_manager/pages/rules/components/results_dropdown/ResultsDropdown.tsx#L6
export interface ResultsDropdownProps {
  accountId: number;
  areMetricsRequired: boolean;
  buttonText: string;
  experimentId: number;
  hasMetrics: boolean;
  isDraft: boolean;
  resultsUiUrl: string;
  showIcon: boolean;
}

const ResultsDropdown = ({
  accountId,
  buttonText,
  hasMetrics,
  areMetricsRequired,
  experimentId,
  isDraft,
  resultsUiUrl,
  showIcon = false,
}: ResultsDropdownProps) => {
  const isDWNEnabled = isWarehouseNativeEnabled();
  if (!isDWNEnabled) {
    return null;
  }

  const directNetspringUrl: string = getDirectNetspringUrl();

  const showOptiResults = Boolean(hasMetrics && resultsUiUrl);

  let showDWNResults = false;
  let dwnResultsUrl = '';
  // If we are using the feature flag to provide the direct DWN URL
  if (directNetspringUrl) {
    dwnResultsUrl = directNetspringUrl;
    showDWNResults = true;
  }
  // We need both the experimentId and the accountId in order to construct the DWN results link
  // Don't evaluate more if we don't have both of those
  else if (experimentId && accountId) {
    dwnResultsUrl = `${DWN_BASE_URL}/${experimentId}/?optiAccountId=${accountId}`;
    // The DWN results are only shown if metrics are not required
    showDWNResults = !areMetricsRequired;
  }

  const isDisabled = isDraft || (!showOptiResults && !showDWNResults);

  const buttonContent = showIcon ? undefined : buttonText;
  const renderActivator = showIcon
    ? ({ onBlur, onClick, buttonRef }: any) => (
        <Button
          buttonRef={buttonRef}
          isDisabled={isDisabled}
          leftIcon="chart-column"
          onBlur={onBlur}
          onClick={onClick}
          rightIcon={isDisabled ? undefined : 'angle-down'}
          style="plain"
          title="View Results">
          {buttonText}
        </Button>
      )
    : undefined;

  const optiResultsCategory = showOptiResults && (
    <BlockList.Category>
      <BlockList.Item href={resultsUiUrl}>
        <Dropdown.BlockLinkText text="Real-time Results" />
        <Dropdown.BlockLinkSecondaryText secondaryText="View results based on events sent directly to Optimizely." />
      </BlockList.Item>
    </BlockList.Category>
  );
  const dwnResultsCategory = showDWNResults && (
    <BlockList.Category>
      <BlockList.Item href={dwnResultsUrl}>
        <Dropdown.BlockLinkText text="Warehouse Native Results" />
        <Dropdown.BlockLinkSecondaryText secondaryText="View results based on data from your warehouse in Optimizely Analytics." />
      </BlockList.Item>
    </BlockList.Category>
  );

  return (
    <Dropdown
      testSection="view-results-dropdown"
      isDisabled={isDisabled}
      buttonContent={buttonContent}
      placement="bottom-end"
      arrowIcon={isDisabled ? 'none' : 'down'}
      renderActivator={renderActivator}>
      <Dropdown.Contents>
        <BlockList>
          {optiResultsCategory}
          {dwnResultsCategory}
        </BlockList>
      </Dropdown.Contents>
    </Dropdown>
  );
};

export default ResultsDropdown;
