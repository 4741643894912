/* eslint-disable class-methods-use-this */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { DialogNew, Button, Link, Spinner } from '@optimizely/axiom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Immutable from 'optly/immutable';
import SegmentTracking from 'optly/modules/segment';
import { connect } from 'core/ui/decorators';

import { actions as CurrentLayerActions } from 'bundles/p13n/modules/current_layer';
import ProjectActions from 'optly/modules/entity/project/actions';
import ProjectEnums from 'optly/modules/entity/project/enums';

import flux from 'core/flux';

import { actions as EventModuleActions } from 'optly/modules/entity/event';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import {
  actions as MetricsPickerModuleActions,
  getters as MetricsPickerModuleGetters,
} from '../metrics_manager_react/subcomponents/metrics_picker_react/component_module';

import { validateMetricForm } from './utils';
import { MetricsForm } from './metrics_form';

const subtitle = (
  <div>
    Custom tracking events allow you to capture and report on visitor actions or
    events.{' '}
    <Link
      href="https://support.optimizely.com/hc/en-us/articles/4410289407885-Overview-of-metrics"
      newWindow={true}>
      Learn more
    </Link>
  </div>
);
export function MetricsModal({
  getMetricFormToEdit,
  getScopeOptions,
  initialScopeValue,
  onClose,
  onSave,
  title,
}) {
  const [metricForm, setMetricForm] = useState({
    type: '',
    name: '',
    event: {},
    winningDirection: '',
    aggregator: '',
    scope: '',
    alias: '',
    combineOperator: 'and',
    conditions: {},
    filterByProperties: false,
    isDraft: true,
    compoundNumerator: null,
    compoundDenominator: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleFormValue = (field, value) => {
    setMetricForm(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    const errors = validateMetricForm(metricForm);
    if (!isEmpty(errors)) {
      setFormErrors(errors);
      return;
    }
    if (metricForm.type === 'Ratio Metric') {
      SegmentTracking.tracking.trackEvent('Ratio Metric Created', {
        metricName: metricForm.name,
        numeratorAggregator: metricForm.compoundNumerator?.aggregator,
        denominatorAggregator: metricForm.compoundDenominator?.aggregator,
      });
      SegmentTracking.tracking.trackEvent(
        'Ratio Metric Used As Secondary Metric',
        {
          metricName: metricForm.name,
          numeratorAggregator: metricForm.compoundNumerator?.aggregator,
          denominatorAggregator: metricForm.compoundDenominator?.aggregator,
        },
      );
    }

    onSave(metricForm);
  };

  useEffect(() => {
    if (getMetricFormToEdit) {
      setIsLoading(true);

      getMetricFormToEdit().then(metricFormValues => {
        setMetricForm(form => ({
          ...form,
          ...metricFormValues,
        }));
        setIsLoading(false);
      });
    }
  }, [getMetricFormToEdit]);

  return (
    <DialogNew
      title={title}
      subtitle={subtitle}
      className="dialog-xl-wrapper"
      onClose={onClose}
      footerButtonList={
        isLoading
          ? []
          : [
              <Button key="cancel-button" onClick={onClose}>
                Cancel
              </Button>,
              <Button
                key="save-button"
                style="highlight"
                onClick={handleSave}
                testSection="metrics-modal-save-button">
                Save
              </Button>,
            ]
      }>
      {isLoading ? (
        <div className="flex flex-justified--center">
          <Spinner />
        </div>
      ) : (
        <MetricsForm
          formValues={metricForm}
          onChange={handleFormValue}
          initialScopeValue={initialScopeValue}
          getScopeOptions={getScopeOptions}
          formErrors={formErrors}
          getMetricFormToEdit={getMetricFormToEdit}
        />
      )}
    </DialogNew>
  );
}

MetricsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  workingMetricWrappers: PropTypes.instanceOf(Immutable.List).isRequired,
};

MetricsModal.defaultProps = {
  title: '',
};

const MetricsModalMFE = lazy(() =>
  System.import('METRICS').then(module => ({ default: module.MetricsModal })),
);

@connect(() => ({
  clickEvents: MetricsPickerModuleGetters.allPageClickEvents,
  customEvents: MetricsPickerModuleGetters.customEventsGetterV2,
  pageEvents: MetricsPickerModuleGetters.allPageViewEvents,
  currentProjectOnly: MetricsPickerModuleGetters.shouldUseCurrentProject,
}))
export class MFEMetricsModalWrapper extends React.Component {
  static propTypes = {
    clickEvents: PropTypes.instanceOf(Immutable.OrderedMap),
    currentProjectOnly: PropTypes.bool.isRequired,
    customEvents: PropTypes.instanceOf(Immutable.List).isRequired,
    getMetricFormToEdit: PropTypes.func,
    getScopeOptions: PropTypes.func,
    initialScopeValue: PropTypes.any,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    pageEvents: PropTypes.object,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoadingEvents: false,
      allEventsLoaded: false,
    };
  }

  setIsLoading = isLoadingEvents => this.setState({ isLoadingEvents });

  setAllEventsLoaded = allEventsLoaded => this.setState({ allEventsLoaded });

  fetchProjectEvents = () => {
    this.setIsLoading(true);
    const projectId = flux.evaluateToJS(CurrentProjectGetters.id);

    Promise.all([
      EventModuleActions.fetchAll({
        project_id: projectId,
      }),
    ]).then(() => {
      this.setIsLoading(false);
    });
  };

  componentDidMount() {
    this.fetchProjectEvents();
  }

  getAccountEvents = () => {
    const { allEventsLoaded } = this.state;
    if (!allEventsLoaded) {
      this.setIsLoading(true);
      Promise.all([
        ProjectActions.fetchAll(
          {
            project_status: ProjectEnums.project_status.ACTIVE,
          },
          { excludeFields: ['jira_integration'] },
        ),
        CurrentLayerActions.fetchAllCrossProjectMetrics(),
      ]).then(() => {
        this.setIsLoading(false);
        this.setAllEventsLoaded(true);
      });
    }
    MetricsPickerModuleActions.setUseCurrentProject(false);
  };

  getProjectEvents = () => {
    MetricsPickerModuleActions.setUseCurrentProject(true);
  };

  render() {
    const { clickEvents, customEvents, pageEvents, ...props } = this.props;

    return (
      <Suspense
        fallback={
          <div
            style={{
              height: '100vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="oui-dialog__overlay">
            <Spinner />
          </div>
        }>
        <MetricsModalMFE
          {...props}
          clickEvents={Object.values(clickEvents.toJS())}
          customEvents={customEvents.toJS()}
          getAccountEvents={this.getAccountEvents}
          getProjectEvents={this.getProjectEvents}
          pageEvents={Object.values(pageEvents.toJS())}
          isLoadingEvents={this.state.isLoadingEvents}
        />
      </Suspense>
    );
  }
}

export default { MetricsModal };
