import $ from 'jquery';
import htmlSanitizer from 'sanitizer';

import ui from 'core/ui';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { actions as ExperimentSectionActions } from 'optly/modules/entity/experiment_section';
import { fns as LayerFns } from 'optly/modules/entity/layer';
import {
  actions as LayerExperimentActions,
  fns as LayerExperimentFns,
} from 'optly/modules/entity/layer_experiment';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui';
import SectionModuleFns from 'bundles/p13n/sections/manager_mvt/section_module/fns';
import SectionModuleGetters from 'bundles/p13n/sections/manager_mvt/section_module/getters';

import CreateSection from 'bundles/p13n/sections/manager_mvt/components/dialogs/create_section';

export function showCreateSectionDialog() {
  ui.showReactDialog(
    CreateSection,
    {
      props: {
        onCancelClick() {
          ui.hideDialog();
        },
      },
      dataBindings: {
        currentLayer: CurrentLayerGetters.layer,
        destinationLayerExperimentId:
          SectionModuleGetters.destinationLayerExperimentId,
      },
    },
    {
      fullScreen: true,
      dismissOnBack: true,
      component: {
        template: '<div class="flex--1 flex width--1-1 height--1-1"></div>',
      },
    },
  );
}

/**
 * This sets up the delete variation dialog text (grabs the variation data/checks if in partial factorial mode)
 * and displays the dialog. The user can then interact with this to delete the variation or cancel the action.
 * @param {Immutable.Map} section
 * @param {Number} variationId
 * @param {String} policy
 */
export function openDeleteVariationDialog(section, variationId, policy) {
  const variationName = section
    .get('variations')
    .find(variation => variation.get('variation_id') === variationId)
    .get('name');
  const escapedVariationName = htmlSanitizer.escape(variationName);

  let deleteMessage = tr(
    'Are you sure you want to delete the variation <strong>{0}</strong>?',
    escapedVariationName,
  );
  let confirmText = tr('Delete Variation');

  if (SectionModuleFns.isPartialFactorial(policy)) {
    deleteMessage = tr(
      `Are you sure you want to delete the variation <strong>{0}</strong>? Since your test
is currently in <strong>partial factorial mode</strong>, your traffic allocation will be reset
to an even distribution between all combinations.`,
      escapedVariationName,
    );
    confirmText = tr('Delete Variation and Reset Traffic');
  }

  P13NUIActions.confirmDeleteEntity({
    deleteAction() {
      const saveDef = ExperimentSectionActions.deleteVariationById(
        section,
        variationId,
      );
      ui.loadingWhen('variations-panel', saveDef);
      saveDef.then(savedSection => {
        LayerExperimentActions.fetch(savedSection.layer_experiment_id, true);
        ui.showNotification({
          message: tr(
            'The variation <strong>{0}</strong> has been deleted.',
            escapedVariationName,
          ),
        });
      });
    },
    entityType: variationName,
    publishWarning: tr('This change cannot be undone.'),
    message: deleteMessage,
    confirmText,
    cancelText: tr('Cancel'),
  });
}

/**
 * This sets up the delete section dialog text by grabbing the section data, checking if in partial factorial mode
 * and if the section has been published before, then it displays the dialog.
 * The user can then interact with this to delete the section or cancel the action.
 * @param {Immutable.Map} section
 * @param {Immutable.Map} currentLayer
 * @param {String} policy
 * @param {Number} destinationLayerExperimentId
 */
export function openDeleteSectionDialog(
  section,
  currentLayer,
  policy,
  destinationLayerExperimentId,
) {
  const sectionName = section.get('name');
  const hasLayerStarted = LayerFns.hasLayerStarted(currentLayer);
  const isPartialFactorial = SectionModuleFns.isPartialFactorial(policy);

  const dialogData = SectionModuleFns.generateDeleteSectionWarning(
    sectionName,
    hasLayerStarted,
    isPartialFactorial,
  );

  P13NUIActions.confirmDeleteEntity({
    deleteAction() {
      const saveDef = ExperimentSectionActions.deleteSection(
        section.toJS(),
        currentLayer.toJS(),
      );
      ui.loadingWhen('variations-panel', saveDef);
      saveDef.then(() => {
        // When we update the sections, we generate new combinations on the destination layer experiment.
        // We need to do a force fetch of the destination layer experiment so that the combinations are
        // up to date, so that any diff-ers looking at the working copy experiment vs. the live commit experiment
        // are operating based on up-to-date data.
        LayerExperimentActions.fetch(destinationLayerExperimentId, true);
        ui.showNotification({
          message: tr(
            'The section <strong>{0}</strong> has been deleted.',
            htmlSanitizer.escape(sectionName),
          ),
        });
      });
    },
    entityType: sectionName,
    publishWarning: tr('This change cannot be undone.'),
    message: dialogData.message,
    confirmText: dialogData.confirmText,
    cancelText: tr('Cancel'),
  });
}

/**
 * If newName is a valid name for the currently renaming variation, save it and
 * show a loading spinner while saving. Otherwise show a notification with the
 * validation error message.
 * @param {String} newName
 * @return {Deferred} The save deferred, or a rejected deferred if the name is
 * invalid
 */
export function saveRenamedVariation(section, newName, variationId) {
  const validation = LayerExperimentFns.validateVariationName(
    section.toJS(),
    newName,
  );
  if (!validation.valid) {
    ui.showNotification({
      message: validation.message,
      type: 'error',
    });
    return $.Deferred().reject();
  }
  const saveDef = ExperimentSectionActions.renameVariationInSection(
    section.get('id'),
    variationId,
    newName,
  );
  ui.loadingWhen('variations-panel', saveDef);
  return saveDef;
}

/**
 * Save the variation description.
 * @param {Object} section
 * @param {Array} variations
 * @return {Deferred} The save deferred, or a rejected deferred if the name is
 * invalid
 */
export function saveVariationDescription(section, variations) {
  return ExperimentSectionActions.variationDescriptionInSection(
    section.id,
    variations,
  );
}

export default {
  showCreateSectionDialog,
  openDeleteVariationDialog,
  openDeleteSectionDialog,
  saveRenamedVariation,
  saveVariationDescription,
};
