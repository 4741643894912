// we need this to be able to import it in .js file
export const VariationSummaryConstants = {
  GENERATED: 'GENERATED',
  EDITED: 'EDITED',
  DISABLED: 'DISABLED',
};

const VariationSummary = {
  GENERATED: 'GENERATED',
  EDITED: 'EDITED',
  DISABLED: 'DISABLED',
} as const;

export default VariationSummary;
