import { Store as NuclearStore } from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default NuclearStore({
  getInitialState() {
    return toImmutable({
      linkedHypothesis: null,
      hypothesisToLink: null,
      shouldCreateHypothesis: false,
      hypothesisName: null,
      hypothesisPlannedStartDate: null,
      hypothesisPlannedEndDate: null,
      openInNewTabAfterHypothesisCreation: false,
    });
  },

  initialize() {
    this.on(actionTypes.SAVE_LINKED_HYPOTHESIS, saveLinkedHypothesis);
    this.on(actionTypes.SAVE_HYPOTHESIS_TO_LINK, saveHypothesisToLink);
    this.on(actionTypes.SHOULD_CREATE_HYPOTHESIS, shouldCreateHypothesis);
    this.on(actionTypes.SET_HPT_NAME, setHypothesisName);
    this.on(
      actionTypes.SET_HPT_PLANNED_START_DATE,
      setHypothesisPlannedStartDate,
    );
    this.on(actionTypes.SET_HPT_PLANNED_END_DATE, setHypothesisPlannedEndDate);
    this.on(
      actionTypes.SET_HPT_OPEN_IN_NEW_TAB_AFTER_CREATION,
      setHypothesisOpenInNewTabAfterCreation,
    );
  },
});

function saveLinkedHypothesis(state, { hypothesis }) {
  return state.set('linkedHypothesis', hypothesis);
}

function saveHypothesisToLink(state, { hypothesis }) {
  return state.set('hypothesisToLink', hypothesis);
}

function shouldCreateHypothesis(state, { mode }) {
  return state.set('shouldCreateHypothesis', mode);
}

function setHypothesisName(state, { name }) {
  return state.set('hypothesisName', name);
}

function setHypothesisPlannedStartDate(state, { date }) {
  return state.set('hypothesisPlannedStartDate', date);
}

function setHypothesisPlannedEndDate(state, { date }) {
  return state.set('hypothesisPlannedEndDate', date);
}

function setHypothesisOpenInNewTabAfterCreation(state, { mode }) {
  return state.set('openInNewTabAfterHypothesisCreation', mode);
}
