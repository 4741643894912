import PropTypes from 'prop-types';
import React from 'react';

import { feature } from '@optimizely/js-sdk-lab/src/decorators';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';
import { Button } from 'optimizely-oui';

import flux from 'core/flux';
import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import Immutable, { toImmutable } from 'optly/immutable';

import DashboardTableFilterControls from 'bundles/p13n/components/entity_dashboard/table_filter_controls';
import EmptyResults from 'bundles/p13n/components/entity_dashboard/empty_results';
import CreateEventDialog from 'bundles/p13n/components/data_layer/create_event';
import {
  enums as FilterableTableEnums,
  actions as FilterableTableActions,
} from 'optly/modules/filterable_table';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { enums as EventModuleEnums } from 'optly/modules/entity/event';
import { actions as ViewActions } from 'optly/modules/entity/view';
import NavConstants from 'optly/services/navigation';
import UrlHelper from 'optly/services/url_helper';
import { fns as PermissionsModuleFns } from 'optly/modules/permissions';
import HistoryUtils from 'optly/utils/history';

import filterable from 'react_components/filterable';

import DataLayerTopbar from 'bundles/p13n/components/data_layer_topbar';
import { ConfigureViewSmart } from 'bundles/p13n/components/configure_view_smart';
import EditCustomEventDialog from 'bundles/p13n/components/data_layer/configure_custom_event';
import {
  actions as CurrentEventActions,
  getters as CurrentEventGetters,
} from 'bundles/p13n/modules/current_event';

import MetricsTopbar from 'bundles/p13n/sections/metrics_hub/components/metrics_topbar';

import {
  actions as SectionModuleActions,
  getters as SectionModuleGetters,
} from '../../section_module';

import CustomEventsCard from './components/custom_events_card';
import PageEventsCard from './components/page_events_card';

const TABLE_ID = FilterableTableEnums.tableIds.P13N_DATA_LAYER_EVENTS;

@connect({
  pageEvents: SectionModuleGetters.pageEventsForDashboard,
  hasFilteredPageEvents: SectionModuleGetters.hasFilteredPageEvents(TABLE_ID),
  customEvents: SectionModuleGetters.filteredAndSortedCustomEvents(TABLE_ID),
  canArchiveUserEvent: [
    CurrentProjectGetters.project,
    PermissionsModuleFns.canUpdateUserEvent,
  ],
  canArchiveView: [
    CurrentProjectGetters.project,
    PermissionsModuleFns.canDeleteView,
  ],
  canCreateUserEvent: [
    CurrentProjectGetters.project,
    project => PermissionsModuleFns.canCreateUserEvent(project),
  ],
  currentProjectId: CurrentProjectGetters.id,
  currentProject: CurrentProjectGetters.project,
  isFlagsProject: CurrentProjectGetters.isFlagsProject,
  isFullStackProject: CurrentProjectGetters.isFullStackProject,
  pluginEventTypeToName: SectionModuleGetters.pluginEventTypeToName,
})
@feature('usage_inspector_events')
@feature('metrics_hub')
class EventsDashboardComponent extends React.Component {
  static propTypes = {
    /** Permission to see if user can archive/delete events */
    canArchiveUserEvent: PropTypes.bool.isRequired,
    /** Permission to see if user can archive views */
    canArchiveView: PropTypes.bool.isRequired,
    /** Permission to see if user can create events */
    canCreateUserEvent: PropTypes.bool.isRequired,
    /** User project ID */
    currentProjectId: PropTypes.number.isRequired,
    /** List of filtered custom events */
    customEvents: PropTypes.instanceOf(Immutable.List).isRequired,
    /** If set, open the edit event modal */
    eventId: PropTypes.number,
    /** Decide whether event is custom or page view */
    eventType: PropTypes.string,
    /** Check for filtered page events */
    hasFilteredPageEvents: PropTypes.bool.isRequired,
    isFlagsProject: PropTypes.bool.isRequired,
    isFullStackProject: PropTypes.bool.isRequired,
    /** List of filtered page events */
    pageEvents: PropTypes.instanceOf(Immutable.List).isRequired,
    /** Map of plugin event IDs to their name */
    pluginEventTypeToName: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
    eventId: null,
    eventType: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCreateEventDialogLoading: false,
    };
  }

  componentDidMount() {
    const { eventId, eventType } = this.props;
    if (eventId) {
      if (eventType === EventModuleEnums.eventTypes.CUSTOM)
        this.editEvent(eventId);
      else if (eventType === EventModuleEnums.eventTypes.PAGE_VIEW)
        this.configureView(eventId);
    }
    // we need to fetch all projects and their layers to determine event usage across projects
    if (this.usage_inspector_events) {
      SectionModuleActions.fetchProjectsAndLayers();
    }
  }

  createEvent = () => {
    // disable 'Create New Event' button to prevent double clicks.
    // These double clicks tend to load dialog twice.
    this.setState({
      isCreateEventDialogLoading: true,
    });
    ui.showReactDialog(
      CreateEventDialog,
      {
        props: {
          clickEventsDisabled: false,
        },
      },
      {
        isOuiDialog: true,
        fullScreen: true,
        dismissOnBack: true,
      },
    );
    // We need to enable 'Create New Event' button after dialog is shown.
    // As ui.showReactDialog is not returning a promise, we are bound to enable
    // button after some fixed delay.
    setTimeout(
      () =>
        this.setState({
          isCreateEventDialogLoading: false,
        }),
      500,
    );
  };

  editEvent = eventId => {
    const { currentProjectId } = this.props;
    CurrentEventActions.setCurrentEventId(eventId);
    const currentEvent = flux.evaluate(CurrentEventGetters.event);
    if (currentEvent.get('archived')) {
      HistoryUtils.replaceState(UrlHelper.eventsHome(currentProjectId));
      return;
    }

    const editEventUrl = UrlHelper.eventsEdit(
      currentProjectId,
      eventId,
      EventModuleEnums.eventTypes.CUSTOM,
    );
    HistoryUtils.replaceState(editEventUrl);

    ui.showReactDialog(
      EditCustomEventDialog,
      {
        props: {
          onSave: () =>
            HistoryUtils.replaceState(UrlHelper.eventsHome(currentProjectId)),
          noRedirectOnCancel: true,
        },
      },
      {
        isOuiDialog: true,
        dismissOnBack: true,
        fullScreen: true,
        noPadding: true,
        onHideDialog: () =>
          HistoryUtils.replaceState(UrlHelper.eventsHome(currentProjectId)),
      },
    );
  };

  configureView = viewId => {
    const { currentProjectId } = this.props;
    ViewActions.fetch(viewId).then(fetchedView => {
      if (fetchedView.archived) {
        HistoryUtils.replaceState(UrlHelper.eventsHome(currentProjectId));
        return;
      }
      const editEventUrl = UrlHelper.eventsEdit(
        currentProjectId,
        viewId,
        EventModuleEnums.eventTypes.PAGE_VIEW,
      );
      HistoryUtils.replaceState(editEventUrl);
      ui.showReactDialog(
        ConfigureViewSmart,
        {
          props: {
            isInDialog: true,
            isStandAlone: true,
            viewConfiguration: toImmutable(fetchedView),
            onSave: () =>
              HistoryUtils.replaceState(UrlHelper.eventsHome(currentProjectId)),
          },
        },
        {
          fullScreen: true,
          dismissOnBack: true,
          isOuiDialog: true,
          onHideDialog: () =>
            HistoryUtils.replaceState(UrlHelper.eventsHome(currentProjectId)),
        },
      );
    });
  };

  shouldHideCreateEventBtn() {
    // Disabling Creation of new entities in Legacy Full Stack Projects
    // to force migration to Feature Experimentation / Flags
    const { isFullStackProject, isFlagsProject } = this.props;
    return (
      isFeatureEnabled('disable_creating_full_stack_entities') &&
      isFullStackProject &&
      !isFlagsProject
    );
  }

  // eslint-disable-next-line class-methods-use-this
  resetFilters = () => {
    FilterableTableActions.resetFilter(TABLE_ID);
  };

  render() {
    const { isCreateEventDialogLoading } = this.state;
    const {
      pageEvents,
      hasFilteredPageEvents,
      customEvents,
      canArchiveUserEvent,
      canArchiveView,
      canCreateUserEvent,
      currentProjectId,
      pluginEventTypeToName,
      isFullStackProject,
      isFlagsProject,
    } = this.props;

    const isLegacyFullStackProject = isFullStackProject && !isFlagsProject;
    const shouldUseMetricsHub = !isLegacyFullStackProject && this.metrics_hub;

    return (
      <div
        className="stage__item__content--column"
        data-test-section="data-layer-root">
        {shouldUseMetricsHub ? (
          <MetricsTopbar activeTab={NavConstants.MetricsHubTabs.EVENTS_TAB} />
        ) : (
          <DataLayerTopbar activeTab={NavConstants.DataLayerTabs.EVENT_TAB} />
        )}

        <div className="flex flex--1 flex--column">
          <div
            className="flex soft-quad--sides soft-double--ends flex--none background--faint"
            data-test-section="events-filter-dropdown">
            <DashboardTableFilterControls
              tableId={TABLE_ID}
              inputPlaceholder="Filter by name, API name or ID"
              inputWidth="width--300"
              statusOptions={[
                { label: 'Active', value: FilterableTableEnums.status.ACTIVE },
                {
                  label: 'Archived',
                  value: FilterableTableEnums.status.ARCHIVED,
                },
              ]}
            />
            <div className="anchor--right">
              {!this.shouldHideCreateEventBtn() && (
                <Button
                  style="highlight"
                  onClick={this.createEvent}
                  isDisabled={!canCreateUserEvent || isCreateEventDialogLoading}
                  testSection="create-event-button">
                  Create New Event
                </Button>
              )}
            </div>
          </div>

          {!hasFilteredPageEvents && customEvents.size === 0 && (
            <div className="soft--ends">
              <EmptyResults
                resetFilters={this.resetFilters}
                entityPlural={tr('events')}
                testSection="events-dashboard-empty-results"
              />
            </div>
          )}

          <div className="l-flexer l-scroll--y background--faint">
            <div className="soft-double--sides">
              {customEvents.size > 0 && (
                <CustomEventsCard
                  canArchiveUserEvent={canArchiveUserEvent}
                  editEvent={this.editEvent}
                  events={customEvents}
                  tableId={TABLE_ID}
                />
              )}
              <div data-test-section="page-events-section">
                {pageEvents.map(entry => (
                  <PageEventsCard
                    canArchiveUserEvent={canArchiveUserEvent}
                    canArchiveView={canArchiveView}
                    currentProjectId={currentProjectId}
                    configureView={this.configureView}
                    events={entry.get('events')}
                    key={entry.get('view').get('id')}
                    pluginEventTypeToName={pluginEventTypeToName}
                    view={entry.get('view')}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FilterableEventsDashboardComponent = filterable(EventsDashboardComponent);
FilterableEventsDashboardComponent.componentId = 'p13n-data-layer';

export default FilterableEventsDashboardComponent;
