/**
 * Generate JSON string in the desired format for audience IDs.
 *
 * @param {number[]} audienceIds - An array of audience IDs.
 * @returns {string} - The JSON string in the required format.
 */
import { AUDIENCE_OPTIONS, MATCH_TYPES } from './constants';

export default function generateAudienceJSON({
  audienceIds,
  audienceType,
  audienceConditions,
}) {
  if (audienceType === AUDIENCE_OPTIONS.CURRENT_AUDIENCE) {
    return audienceConditions;
  }
  if (!audienceIds || !audienceIds.length) {
    return null;
  }
  const result = [
    MATCH_TYPES.AND,
    ...audienceIds.map(id => ({ audience_id: id })),
  ];
  return JSON.stringify(result);
}
