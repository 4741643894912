const $ = require('jquery');

const flux = require('core/flux');

const AdminAccount = require('optly/modules/admin_account');
const Audience = require('optly/modules/entity/audience');
const CurrentProject = require('optly/modules/current_project');
const PermissionsModuleFns = require('optly/modules/permissions/fns');
const ProjectEnums = require('optly/modules/entity/project/enums');
const ViewFns = require('optly/modules/entity/view/fns');

module.exports = {
  /**
   * Helper method to evaluate the current dashboard url based on the store state
   * and do a history.pushState() with the new url
   */
  pushUrl(title) {
    // Todo(james): implement pushstate updating.
    /*
      var url = this.getDashboardUrl();
      historyUtil.pushState(url, title);
    */
  },
  /**
   * Helper method to evaluate the current dashboard url based on the store state
   * and do a history.replaceState() with the new url
   */
  replaceUrl(title) {
    // Todo(james): implement pushstate updating.
    /*
      var url = this.getDashboardUrl();
      historyUtil.replaceState(url, title);
    */
  },

  /**
   * Fetches an audience for editing, or if no audience specified, then it creates a new one
   *
   * @param {Integer} audienceId The id of the audience to edit. If not passed in, then we create a new one
   *
   * @returns {Deferred}
   */
  fetchOrCreateAudience(audienceId, name) {
    let fetchAudienceDeferred = $.Deferred();

    if (audienceId) {
      Audience.actions.fetch(audienceId, true).then(fetchedAudience => {
        fetchAudienceDeferred.resolve(fetchedAudience);
      });
    } else {
      fetchAudienceDeferred = fetchAudienceDeferred.resolve(
        Audience.fns.createAudienceEntity({
          project_id: flux.evaluate(CurrentProject.getters.id),
          name,
        }),
      );
    }

    return fetchAudienceDeferred;
  },

  /**
   * Authorizes the user to create an audience
   */
  async authorizeAudienceCreate(projectId) {
    return Audience.actions.authroizeAudienceAction(projectId, 0, 'modify');
  },

  /**
   * Creates a view for editing and sets it as the current editing view
   */
  createView(name) {
    return ViewFns.createSmartViewEntity({
      project_id: flux.evaluate(CurrentProject.getters.id),
      name,
    });
  },
};
