import React from 'react';

import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  DialogNew,
  Fieldset,
  Dropdown,
  Label,
  Textarea,
} from '@optimizely/axiom';
import { api as SegmentJSAPI } from '@optimizely/segment-js';

import { enums as LayerEnums } from 'optly/modules/entity/layer';

import Immutable from 'optly/immutable';

import { CONCLUSION_MAX_LEN } from 'optly/utils/constants';

const title = 'Conclude Experiment';
const subTitle =
  "This changes the experiment status, so it appears as 'Concluded' in the experiment list. Upon concluding, you can no longer re-publish your experiment.";

const titleP13N = 'Conclude Campaign';
const subTitleP13N =
  "This changes the campaign status, so it appears as 'Concluded'. Upon concluding, you can no longer re-publish your campaign.";

const deployLabel = 'Conclude and deploy';
const deployText =
  'After concluding, immediately choose a winning variation to deploy. You can also do this at a later time.';

const POSITIVE_RESULT_OPTION = {
  id: 'positive',
  value: 'Positive',
};

const NEGATIVE_RESULT_OPTION = {
  id: 'negative',
  value: 'Negative',
};

const INCONCLUSIVE_RESULT_OPTION = {
  id: 'inconclusive',
  value: 'Inconclusive',
};

const resultsOptions = [
  POSITIVE_RESULT_OPTION,
  NEGATIVE_RESULT_OPTION,
  INCONCLUSIVE_RESULT_OPTION,
];

export default class ConcludeExperimentDialog extends React.PureComponent {
  static propTypes = {
    allowDeploy: PropTypes.bool,
    layer: PropTypes.instanceOf(Immutable.Map).isRequired,
    onCloseHandler: PropTypes.func.isRequired,
    submitConclusion: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allowDeploy: false,
  };

  state = {
    result: POSITIVE_RESULT_OPTION.id,
    conclusions: '',
    isConcluding: false,
    deployChecked: false,
  };

  updateResultOutcome = result => () => this.setState({ result });

  onConclusionsChangeHandler = ({ target }) => {
    this.setState({ conclusions: target.value });
  };

  onConcludeClick = () => {
    const { submitConclusion } = this.props;
    const { result, conclusions, deployChecked } = this.state;

    SegmentJSAPI.track('Clicks on final conclude CTA');

    this.setState({ isConcluding: true });

    submitConclusion({
      result,
      conclusions,
      deployChecked,
    });
  };

  isPersonalizationCampaign = () => {
    const { layer } = this.props;
    return layer.get('type') === LayerEnums.type.PERSONALIZATION;
  };

  shouldShowDeployCheckbox = () => {
    const { allowDeploy } = this.props;
    return !this.isPersonalizationCampaign() && allowDeploy;
  };

  onDeployCheckboxChange = () => {
    this.setState(prevState => ({ deployChecked: !prevState.deployChecked }));
  };

  render() {
    const { onCloseHandler } = this.props;
    const { result, conclusions, isConcluding, deployChecked } = this.state;

    const dialogContent = (
      <>
        <Fieldset>
          <Label>Results Outcome</Label>
          <Dropdown
            buttonContent={resultsOptions.find(({ id }) => id === result).value}
            arrowIcon="down"
            placement="bottom-start"
            fullWidth={true}>
            <Dropdown.Contents className="test">
              {resultsOptions.map(({ id, value }) => (
                <Dropdown.ListItem key={id}>
                  <Dropdown.BlockLink
                    onClick={this.updateResultOutcome(id)}
                    testSection={`conclude-experiment-dialog-result-option-${id}`}>
                    <Dropdown.BlockLinkText text={value} />
                  </Dropdown.BlockLink>
                </Dropdown.ListItem>
              ))}
            </Dropdown.Contents>
          </Dropdown>
        </Fieldset>
        <Fieldset>
          <Textarea
            value={conclusions}
            label="Conclusions"
            onChange={this.onConclusionsChangeHandler}
            placeholder="Briefly summarize the learnings from this experiment."
            testSection="conclude-experiment-dialog-conclusions"
            isRequired={true}
            maxLength={CONCLUSION_MAX_LEN}
          />
        </Fieldset>
        {this.shouldShowDeployCheckbox() && (
          <Fieldset>
            <Checkbox
              checked={deployChecked}
              label={deployLabel}
              description={deployText}
              onClick={this.onDeployCheckboxChange}
              data-test-section="conclude-experiment-dialog-deploy-checkbox"
            />
          </Fieldset>
        )}
      </>
    );

    return (
      <DialogNew
        title={this.isPersonalizationCampaign() ? titleP13N : title}
        subtitle={this.isPersonalizationCampaign() ? subTitleP13N : subTitle}
        className="conclude-experiment-dialog"
        testSection="conclude-experiment-dialog"
        onClose={onCloseHandler}
        hasCloseButton={true}
        footerButtonList={[
          <Button
            key="close"
            onClick={onCloseHandler}
            style="plain"
            testSection="conclude-experiment-dialog-cancel-button">
            Cancel
          </Button>,
          <Button
            key="conclude"
            onClick={this.onConcludeClick}
            style="highlight"
            isDisabled={!conclusions || isConcluding}
            testSection="conclude-experiment-dialog-conclude-button">
            {deployChecked ? 'Next' : 'Conclude'}
          </Button>,
        ]}>
        {dialogContent}
      </DialogNew>
    );
  }
}
